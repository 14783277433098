import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { modalstyle } from "./PopupsStyling";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { blogscoverurl } from "../../Apis/Api";
import {
  EditBlogs,
  GetallBlogs,
} from "../../Redux/Slices/Resources/Resources.action";
import { Categorys } from "./CommonData";
import { errortost, sucesstost } from "../Alerts/CustomAlert";

const EditBlogPopup = ({ modalcontrols }) => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const { iseditblogpopup, closeeditpopup, editfiledata,value,count,row_count } = modalcontrols;
  const [mycategory, setmycategory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [blogTitle, setBlogTitle] = useState("");
  const [blogDescription, setBlogDescription] = useState("");
  const [blogcoverimg, setBlogCoverImg] = useState("");
  const [coverImgPreview, setCoverImgPreview] = useState(null);
  // const [isFree, setIsFree] = useState(true);
  // const [price, setPrice] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (editfiledata) {
      setmycategory(
        editfiledata?.category
          ?.filter((v) => v.status == 1)
          .map((j) => j.cat_type) ?? []
      );
      setBlogTitle(editfiledata?.title || "");
      setBlogDescription(editfiledata?.description || "");
      setBlogCoverImg(editfiledata?.cover_img || "");
      setCoverImgPreview(null); // Clear previous preview
      // setIsFree(editfiledata?.price == 0 ? true : false);
      // setPrice(editfiledata?.price);
    }
  }, [editfiledata]);

  const handleCoverImageChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      // Check if the selected file is an image
      const allowedThumbnailTypes = ["image/jpeg", "image/png", "image/gif"];

      if (allowedThumbnailTypes.indexOf(selectedFile.type) === -1) {
        toast.error("Invalid file type. Please select a valid image file.", {
          position: "top-right",
          autoClose: 3000,
        });
        // Clear the input and preview
        e.target.value = null;
        setBlogCoverImg("");
        setCoverImgPreview(null);

        return;
      }

      setBlogCoverImg(selectedFile);
      setCoverImgPreview(URL.createObjectURL(selectedFile));
    } else {
      setBlogCoverImg("");
      setCoverImgPreview(null);
    }
  };
  let handeldradiobutton = (e) => {
    // setIsFree(e.target.value == "free" ? true : false);
  };
  const handleEditBlog = () => {
    const formData = new FormData();
    formData.append("id", editfiledata?.id);

    // Check if any field is edited
    const isAnyFieldEdited =
      mycategory !== editfiledata?.cat_type ||
      blogTitle !== editfiledata?.title ||
      blogDescription !== editfiledata?.description ||
      blogcoverimg !== editfiledata?.cover_img;
    if (!isAnyFieldEdited) {
      console.log("No fields are edited.");
      return;
    }
    if (mycategory !== editfiledata?.cat_type) {
      formData.append("cat_type", mycategory);
    }
    if (blogTitle !== editfiledata?.title) {
      formData.append("title", blogTitle);
    }
    if (blogDescription !== editfiledata?.description) {
      formData.append("description", blogDescription);
    }
    if (blogcoverimg !== editfiledata?.cover_img) {
      formData.append("cover_img", blogcoverimg);
    }
    // if (price) {
    //   if (isFree) {
    //     formData.append("price", 0);
    //   } else {
    //     formData.append("price", price);
    //   }
    // }
    setLoading(true);
    dispatch(EditBlogs(formData))
      .unwrap()
      .then((result) => {
        if (result?.s === 1) {
          dispatch(GetallBlogs({value:value,count:count,row_count:row_count}));
          setLoading(false);
          closeeditpopup();
          sucesstost("Updated successfully");
        } else {
          setLoading(false);
          closeeditpopup();
          errortost("Something went wrong, please try again!");
        }
      });
  };

  const handeldcategoryselect = (event) => {
    const selectedCategoryIds = event.target.value;
    setmycategory(selectedCategoryIds);
  };

  return (
    <div>
      <Modal open={iseditblogpopup} onClose={closeeditpopup}>
        <Box
          sx={{
            ...modalstyle,
            borderRadius: "10px",
            width: { xs: "50vw", md: "80vw" },
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              padding: "20px 0px",
              cursor: "pointer",
            }}
          >
            <Typography
              sx={{
                fontSize: "22px",
                fontFamily: "Poppins",
                fontWeight: "600",
                lineHeight: "120%",
                color: "#000",
              }}
            >
              Edit Blog
            </Typography>
          </Box>

<Box sx={{height:"78vh",overflow:"auto"}}>

<Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              width: "50%",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <InputLabel
                id="demo-select-small-label"
                sx={{
                  fontSize: "15px",
                }}
              >
                Select Category
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                sx={{
                  width: "100%",
                  height: "50px",
                  borderRadius: "10px",
                  border: "1px solid #F68582",
                  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
                }}
                value={mycategory}
                input={<OutlinedInput label="Tag" />}
                onChange={handeldcategoryselect}
                renderValue={(selected) =>
                  selected
                    .map(
                      (value) =>
                        Categorys?.find((category) => category?.value === value)
                          ?.lable
                    )
                    .join(",")
                }
                MenuProps={MenuProps}
              >
                {Categorys?.map((name) => (
                  <MenuItem nuItem key={name?.value} value={name?.value}>
                    <Checkbox checked={mycategory.includes(name.value)} />
                    <ListItemText primary={name?.lable} />
                  </MenuItem>
                ))}
              </Select>
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <InputLabel
                id="demo-select-small-label"
                sx={{
                  fontSize: "15px",
                }}
              >
                Enter Blogs Title
              </InputLabel>
              <OutlinedInput
                sx={{
                  width: "100%",
                  height: "50px",
                  borderRadius: "10px",
                  border: "1px solid #F68582",
                  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
                }}
                id="outlined-adornment-weight"
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
                value={blogTitle}
                onChange={(e) => setBlogTitle(e.target.value)}
                name="blogTitle"
              />
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <InputLabel
                id="demo-select-small-label"
                sx={{
                  fontSize: "14px",
                }}
              >
                Upload Blogs Cover Image
              </InputLabel>

              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  border: "1px solid #F68582",
                  padding: "10px",
                  borderRadius: "10px",
                }}
              >
                {coverImgPreview ? (
                  <img
                    style={{
                      height: "60px",
                      width: "90px",
                      borderRadius: "10px",
                    }}
                    src={coverImgPreview}
                    alt=""
                  />
                ) : (
                  <img
                    style={{
                      height: "60px",
                      width: "90px",
                      borderRadius: "10px",
                    }}
                    src={`${blogscoverurl}/${editfiledata?.cover_img}`}
                    alt=""
                  />
                )}

                <Input
                  type="file"
                  id="audio-cover-file-input"
                  style={{ display: "none" }}
                  onChange={handleCoverImageChange}
                />
                <label htmlFor="audio-cover-file-input">
                  <Button
                    variant="contained"
                    component="span"
                    sx={{
                      background: "rgba(246, 133, 130, 0.50)",
                      textDecoration: "capitalize",
                      color: "#000",
                      height: "30px",
                      borderRadius: "10px",
                      transition: "background 0.3s ease",
                      "&:hover": {
                        background: "rgba(246, 133, 130, 0.70)",
                      },
                    }}
                  >
                    <Typography sx={{ textTransform: "capitalize" }}>
                      Browse
                    </Typography>
                  </Button>
                </label>
              </Box>
            </Box>
          </Box>

          <Box sx={{ mt: "20px" }}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <InputLabel
                id="demo-select-small-label"
                sx={{
                  fontSize: "14px",
                }}
              >
                Description
              </InputLabel>
              <ReactQuill
                defaultValue={blogDescription}
                value={blogDescription}
                onChange={(value) => setBlogDescription(value)}
                style={{
                  width: "100%",
                  textAlign: "left",
                  borderRadius: "15px",
                  borderRight: "40px",
                  border: "none",
                  color: "#000",
                }}
                theme="snow"
                modules={{
                  toolbar: {
                    container: [
                      [
                        {
                          header: "1",
                          style: {
                            background: "rgba(246, 133, 130, 0.20)",
                          },
                        },
                        {
                          header: "2",
                          style: {
                            background: "rgba(246, 133, 130, 0.20)",
                          },
                        },
                      ],
                      [{ font: [] }],
                      ["bold", "italic", "underline", "strike"],
                      [{ color: [] }, { background: [] }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      ["link"],
                      ["clean"],
                      [{ size: ["small", false, "large", "huge"] }],
                    ],
                  },
                }}
              />
            </Box>
{/* 
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <FormControl sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography sx={{}}>Price</Typography>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="free"
                    name="radio-buttons-group"
                    value={isFree ? "free" : "paid"}
                    onChange={handeldradiobutton}
                    sx={{ display: "flex", flexDirection: "row" }}
                  >
                    <FormControlLabel
                      sx={{ color: "#000" }}
                      value="free"
                      control={
                        <Radio
                          color="primary" // Set the desired color, such as "primary", "secondary", "default", or a custom color code
                          sx={{
                            "&.Mui-checked": {
                              color: "#F68582", // Replace with your custom color code
                            },
                          }}
                        />
                      }
                      label="Free"
                    />
                    <FormControlLabel
                      sx={{ color: "#000" }}
                      value="paid"
                      control={
                        <Radio
                          color="primary" // Set the desired color, such as "primary", "secondary", "default", or a custom color code
                          sx={{
                            "&.Mui-checked": {
                              color: "#F68582", // Replace with your custom color code
                            },
                          }}
                        />
                      }
                      label="Paid"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              {!isFree && (
                <OutlinedInput
                  sx={{
                    width: "100%",
                    height: "50px",
                    borderRadius: "10px",
                    border: "1px solid #F68582",
                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                  }}
                  id="outlined-adornment-weight"
                  aria-describedby="outlined-weight-helper-text"
                  type="Number"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  name="price"
                  placeholder="Enter Price"
                  required={!isFree} // Make the input required only when it's not free
                  onKeyDown={(evt) =>
                    ["e", "E", "+", "-"].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  />
              )}
            </Box> */}

            <Button
              disabled={loading}
              onClick={handleEditBlog}
              sx={{
                height: "40px",
                mt: "20px",
                width: "30%",
                textTransform: "capitalize",
                color: "#000",
                boxShadow:
                  "2px 2px 8px 0px rgba(143, 95, 11, 0.50), 0px 3px 11.111px 0px #FBB15A inset, 0px -11px 11.111px 0px #D68835 inset",
                background:
                  " linear-gradient(180deg, #FFCE98 0%, #F4AA50 100%);",
              }}
            >
              {loading ? (
                <CircularProgress sx={{ color: "#fff" }} />
              ) : (
                <Typography>Update</Typography>
              )}
            </Button>
          </Box>

</Box>
         
        </Box>
      </Modal>
    </div>
  );
};

export default EditBlogPopup;
