import axios from "axios";
import { Baseurl, getUserdata } from "../../../Apis/Api";
import { createAsyncThunk } from "@reduxjs/toolkit";

//  -------------  Get Groupe Users Joined In Groupe Sessions  -----------

export const GetusersJoinedInGroupe = createAsyncThunk(
  "Session/getusersjoinedingroupe",
  async ({ groupeid, count }) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      let data = await axios.get(
        `${Baseurl}/admin/group_sessions_get_id?id=${groupeid}&count=${count}`,
        config
      );
      return data?.data;
    } catch (error) {
      throw error;
    }
  }
);
//  -------------  Get Groupe Users Joined In Groupe Sessions  -----------

export const GetusersJoinedInSessions = createAsyncThunk(
  "Session/GetusersJoinedInSessions",
  async ( id) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      let data = await axios.get(
        `${Baseurl}/admin/session-joined-user?availability_id=${id}`,
        config
      );
      return data?.data;
    } catch (error) {
      throw error;
    }
  }
);

// ------ Get Admin Sessions By Date ----------

export const GetAdminSessionsByDate = createAsyncThunk(
  "Session/getadminsessionsbydate",
  async (date) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      let data = await axios.get(
        `${Baseurl}/admin/availibity_date_get_all?date=${date}`,
        config
      );

      return data.data;
    } catch (error) {
      throw error;
    }
  }
);

//  -------------  Get Groupe Sessions   -----------

export const GetGroupeSessionlist = createAsyncThunk(
  "Session/getgroupesession",
  async ({ count, row_count }) => {
    
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      let data = await axios.get(
        `${Baseurl}/admin/availability-get?count=${count}&row_count=${row_count}&type=2&is_session=1`,
        config
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);

//  -------------  Get Individuals Sessions    -----------

export const GetIndividualSessions = createAsyncThunk(
  "Session/getindividualssessions",
  async ({ count, row_count }) => {
 
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      }
      let data = await axios.get(
        `${Baseurl}/admin/availability-get?count=${count}&row_count=${row_count}&type=1&is_session=1`,
        config
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }
)


// //  ------------- Add Session Avilability -----------

export const AddSessionAvilability = createAsyncThunk(
  "Session/addsessionavilability",
  async (formdata) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      let data = await axios.post(
        `${Baseurl}/admin/availibity_add`,
        formdata,
        config
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);

// //  ------------- Add Session Avilability -----------

export const AddAdminGroupeSession = createAsyncThunk(
  "Session/addgroupesession",
  async (formdata) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      let data = await axios.post(
        `${Baseurl}/admin/group_sessions_add`,
        formdata,
        config
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);

// //  ------------- Update Session link  -----------

export const AddUpdateIndiviSessionLink = createAsyncThunk(
  "Session/addupdateindiviSessionLink",
  async (formdata) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      let data = await axios.post(
        `${Baseurl}/admin/individual_senssion_book_edit`,
        formdata,
        config
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);

// //  ------------- Update Groupe Session link  -----------

export const AddUpdateGroupeSessionLink = createAsyncThunk(
  "Session/AddUpdateGroupeSessionLink",
  async (formdata) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };

      let data = await axios.post(
        `${Baseurl}/admin/group_sessions_edit`,
        formdata,
        config
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);

//  -------------  Get Admin Avilability Sessions    -----------

export const GetAdminAvilabilitySessions = createAsyncThunk(
  "Session/getadminavilability",
  async ({ count, row_count }) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      let data = await axios.get(
        `${Baseurl}/admin/availibity_get_all?count=${count}&row_count=${row_count}`,
        config
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);

// //  ------------- Add Session Avilability -----------
export const DeleteAvilabilitySession = createAsyncThunk(
  "Session/deleteavilabilitySession",
  async (formdata) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      let data = await axios.post(
        `${Baseurl}/admin/availability-edit`,
        formdata,
        config
      );

      return data.data;
    } catch (error) {
      throw error;
    }
  }
);

// //  ------------- Add Session Avilability -----------
export const EditAvilabilitySessionDetails = createAsyncThunk(
  "Session/EditAvilabilitySessionDetails",
  async (formdata) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      let data = await axios.post(
        `${Baseurl}/admin/availability-edit`,
        formdata,
        config
      );

      return data.data;
    } catch (error) {
      throw error;
    }
  }
);

// //  ------------- Add Session Avilability -----------
export const DeleteGroupeSession = createAsyncThunk(
  "Session/DeleteGroupeSession",
  async (id) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };

      let data = await axios.post(
        `${Baseurl}/admin/group_senssion_delete`,
        { id: id },
        config
      );

      return data.data;
    } catch (error) {
      throw error;
    }
  }
);

// -------------- Add Coaches Details -----------
export const AddnewCoachesDetails = createAsyncThunk(
  "Session/addnewCoachesDetails",
  async (formdata) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      let data = await axios.post(
        `${Baseurl}/admin/coach-details-add`,
        formdata,
        config
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);

// -------------- get all Coaches by sessions -----------
export const GetCoachesDetailslist = createAsyncThunk(
  "Session/getcoacheslist",
  async (id) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      let data = await axios.get(
        `${Baseurl}/coach-details-get?ref_id=${id}`,
        config
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);

// -------------- get all Coaches by sessions -----------
export const GetCoacheslist = createAsyncThunk(
  "Session/getallCoacheslist",
  async ({ count, row_count }) => {
    console.log(row_count);
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      let data = await axios.get(
        `${Baseurl}/admin/coach-get-all?count=${count}&row_count=${row_count}`,
        config
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);

// -------------- Get Coach Details By id -----------
export const GetCoachDetailsByid = createAsyncThunk(
  "Session/GetCoachDetails",
  async (id) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      let data = await axios.get(
        `${Baseurl}/admin/coach-details-get?coach_id=${id}`,
        config
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);

// -------------- Get Coach Details By id -----------
export const GetCoachAvilabilityByid = createAsyncThunk(
  "Session/GetCoachAvilabilityByid",
  async ({ id, type }) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      let url = `${Baseurl}/admin/coach-avalibility?coach_id=${id}`;
      if (type) {
        url += `&type=${type}`;
      }
      let data = await axios.get(url, config);
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);

// -------------- Edit coach Details -----------
export const EditCoachesDetails = createAsyncThunk(
  "Session/EditCoachesDetails",
  async (formdata) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      let data = await axios.post(
        `${Baseurl}/admin/coach-details-edit`,
        formdata,
        config
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);

//  -------------  Get Coaches List by Date and time  -----------
export const GetCoachesListByDateandTime = createAsyncThunk(
  "Session/GetCoachesListByDateandTime",
  async ({ date, start_time, end_time }) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
        params: {
          date,
          start_time,
          end_time,
        },
      };
      let data = await axios.get(`${Baseurl}/admin/coach-get-all`, config);
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);

// -------------- Add Coaches Avilability In this section -----------
export const AddSelectedCoachAvilability = createAsyncThunk(
  "Session/AddSelectedCoachAvilability",
  async (formdata) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      let data = await axios.post(
        `${Baseurl}/admin/availability-add`,
        formdata,
        config
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);
// -------------- Add Coaches Avilability In this section -----------
export const EditSelectedCoachAvilability = createAsyncThunk(
  "Session/EditSelectedCoachAvilability",
  async (formdata) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      let data = await axios.post(
        `${Baseurl}/admin/availability-edit`,
        formdata,
        config
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);

//  -------------  Get Coaches List by Date and time  -----------
export const GetallCoachesAvilability = createAsyncThunk(
  "Session/GetallCoachesAvilability",
  async ({ type, count,row_count }) => {
 
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      let data = await axios.get(
        type == 0
          ? `${Baseurl}/admin/availability-get?count=${count}&row_count=${row_count}`
          : `${Baseurl}/admin/availability-get?count=${count}&row_count=${row_count}&type=${type}`,
        config
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);
//  -------------  Get Coaches List by Date and time  -----------
export const GetSessionDataById = createAsyncThunk(
  "Session/GetSessionDataById",
  async (id) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      let data = await axios.get(
        `${Baseurl}/admin/availability-details?availability_id=${id}`,
        config
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);
//  -------------  Get Coaches List by Date and time  -----------
export const GetCoachDeleteStatus = createAsyncThunk(
  "Session/GetCoachDeleteStatus",
  async ({ id, date }) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      let data = await axios.get(
        `${Baseurl}/admin/coach-delete-status?coach_id=${id}&date=${date}`,
        config
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);
//  -------------  Get Coaches List by Date and time  -----------
export const GetCoachSessionPriceGroup = createAsyncThunk(
  "Session/GetCoachSessionPriceGroup",
  async (formdata) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      let data = await axios.post(
        `${Baseurl}/admin/calculate-availability-price`,
        formdata,
        config
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);
 