import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl, getUserdata } from "../../../Apis/Api";

export const GetallAudios = createAsyncThunk(
  "resources/getallaudios",
  async ({ value, count, row_count }) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      // if (!value) {
      //   let data = await axios.get(
      //     `${Baseurl}/admin/audio_get?count=${count}&row_count=${row_count}`,
      //     config
      //   );
      //   return data.data;
      // } else {
        let data = await axios.get(
          `${Baseurl}/admin/audio_get?cat_type=${value}&count=${count}&row_count=${row_count}`,
          config
        );
        return data.data;
      // }
    } catch (error) {
      throw error;
    }
  }
);

export const AddNewAudio = createAsyncThunk(
  "resources/addnewaudio",
  async (audiodata) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      let data = await axios.post(
        `${Baseurl}/admin/audio_add`,
        audiodata,
        config
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);

export const AddNewVideo = createAsyncThunk(
  "resources/addnewvideo",
  async (videodata) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      let data = await axios.post(
        `${Baseurl}/admin/video_add`,
        videodata,
        config
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);

export const EditAudio = createAsyncThunk(
  "resources/editaudio",
  async (audiodata) => {
    console.log(audiodata);
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      let data = await axios.post(
        `${Baseurl}/admin/audio_edit`,
        audiodata,
        config
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);

export const EditVideo = createAsyncThunk(
  "resources/editvideo",
  async (videodata) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      let data = await axios.post(
        `${Baseurl}/admin/video_edit`,
        videodata,
        config
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);

export const GetallVideos = createAsyncThunk(
  "resources/getallvideos",
  async ({ value, count,row_count }) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      // if (filtervalue == 0) {
      //   let data = await axios.get(
      //     `${Baseurl}/admin/video_get?count=${page}`,
      //     config
      //   );
      //   return data.data;
      // } else {
        let data = await axios.get(
          `${Baseurl}/admin/video_get?cat_type=${value}&count=${count}&row_count=${row_count}`,
          config
        );
        return data.data;
      // }
    } catch (error) {
      throw error;
    }
  }
);

export const AddNewBlog = createAsyncThunk(
  "resources/addnewblog",
  async (blogdata) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      let data = await axios.post(
        `${Baseurl}/admin/blog_add`,
        blogdata,
        config
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);

export const GetallBlogs = createAsyncThunk(
  "resources/getallblogs",
  async ({value,count,row_count}) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      // if (filterdkey == 0) {
      //   let data = await axios.get(
      //     `${Baseurl}/admin/blogs_get?count=${page}`,
      //     config
      //   );
      //   return data.data;
      // } else {
        let data = await axios.get(
          `${Baseurl}/admin/blogs_get?cat_type=${value}&count=${count}&row_count=${row_count}`,
          config
        );
        return data.data;
      // }
    } catch (error) {
      throw error;
    }
  }
);
export const EditBlogs = createAsyncThunk(
  "resources/editblogs",
  async (blogsdata) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      let data = await axios.post(
        `${Baseurl}/admin/blogs_edit`,
        blogsdata,
        config
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);

export const GetAllCategory = createAsyncThunk(
  "resources/GetAllCategory",
  async ( ) => {
    try {
     
        let data = await axios.get(
          `${Baseurl}/topics/get_topics`,
        );
        console.log(data);
        return data.data;
      // }
    } catch (error) {
      throw error;
    }
  }
);