import { Button, TablePagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
} from "@mui/material";
import Linkicon from "../../../../Utils/Logos/Linkicon.png";
import Viewicon from "../../../../Utils/Logos/viewmore.png";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteGroupeSession,
  GetGroupeSessionlist,
} from "../../../../Redux/Slices/Sessions/Session.action";
import { groupe_scoverurl } from "../../../../Apis/Api";
import {
  errortost,
  sucesstost,
} from "../../../../Components/Alerts/CustomAlert";
import addlinkicon from "../../../../Utils/Logos/AddLink.png";
import deletelogo from "../../../../Utils/Logos/deleteuser.png";

import moment from "moment";
import { useNavigate } from "react-router-dom";
import Group_UserList from "./Group_UserList";
import { formatTime } from "../../../../Apis/Functions";
import Swal from "sweetalert2";
import addcoachimage from "../../../../Utils/Logos/UserAdd.png";
import ViewGroupedetails from "../../../../Components/Popups/ViewGroupedetails";
const columns = [
  { id: "#", label: "#" },
  { id: "Date", label: "Date " },
  { id: " Group Image", label: "Group Image" },
  { id: "Topic", label: "Topic" },
  { id: "Time ", label: "Time" },
  { id: "Price", label: "Price" },

  { id: "Link", label: "Meeting Link / View" },
  { id: "Action", label: "Action" },
];
const Group_Schedules = () => {
  let dispatch = useDispatch();

  let Groupesessions = useSelector((store) => store?.Sessions);

  let [isuserlistopen, setisuserlistopen] = useState(false);

  let closeuserlistmodal = () => {
    setisuserlistopen(false);
  };
  let openlistmodal = (id) => {
    setgroupeid(id);
    setisuserlistopen(true);
  };
  let [groupeid, setgroupeid] = useState();

  // -------------- Paginations -----------
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(
      GetGroupeSessionlist({
        count: newPage * rowsPerPage,
        row_count: rowsPerPage,
      })
    );
  };
  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  }
  let [selecteddata, setselecteddata] = useState([]);
  let [isgroupedetailopen, setisgroupedetailopen] = useState(false);
  let navigate = useNavigate();
  let opengroupdetailspopup = (groupedata) => {
    // setselecteddata(groupedata);
    // setisgroupedetailopen(true);
    navigate("./view_group_details", { state: groupedata?.id });
  };

  let closegroupdetailspopup = () => {
    setisgroupedetailopen(false);
  };

  let DeletethisGroupeSession = (groupId) => {
    Swal.fire({
      title: "Are you sure you want to delete this session?",
      text: "By deleting the session, the refund process will be initiated for those users who have already paid for the session.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(DeleteGroupeSession(groupId))
          .unwrap()
          .then((result) => {
            if (result.s === 1) {
              dispatch(
                GetGroupeSessionlist({
                  count: page * rowsPerPage,
                  row_count: rowsPerPage,
                })
              );
              sucesstost("Session deleted Sucessfully !");
            } else {
              errortost("Something Wents wrong .");
            }
          });
      }
    });
  };
  useEffect(() => {
    dispatch(
      GetGroupeSessionlist({
        count: page * rowsPerPage,
        row_count: rowsPerPage,
      })
    );
  }, [rowsPerPage]);

  let OpenThislinktourl = (row) => {
    if (row?.link) {
      const formattedLink = row.link.startsWith("http")
        ? row.link
        : `http://${row.link}`;
      window.open(formattedLink, "_blank", "noopener,noreferrer");
    }
  };
  return (
    <>
      {isgroupedetailopen ? (
        <ViewGroupedetails
          modalcontrols={{
            isgroupedetailopen,
            closegroupdetailspopup,
            selecteddata,
            count: page * rowsPerPage,
            rowsPerPage,
          }}
        />
      ) : (
        ""
      )}

      {isuserlistopen ? (
        <Group_UserList
          modalcontrols={{ isuserlistopen, closeuserlistmodal, groupeid }}
        />
      ) : (
        ""
      )}

      <Box sx={{ mt: "20px" }}>
        <Paper style={{ width: "100%", overflow: "hidden" }}>
          <TableContainer style={{ maxHeight: "75vh" }}>
            <Table stickyHeader sx={{}}>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      sx={{
                        textAlign: "center",
                        fontSize: "15px",
                        lineHeight: "120%",
                        fontFamily: "Montserrat",
                        fontWeight: "600",
                        background: "#F68582",
                        color: "#fff",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody sx={{ textAlign: "center" }}>
                {Groupesessions?.GroupeSessionList?.r?.length < 1 ||
                Groupesessions?.GroupeSessionList?.r == null ? (
                  <Box sx={{ textAlign: "center", padding: "10px", m: "10px" }}>
                    No Data
                  </Box>
                ) : (
                  Groupesessions?.GroupeSessionList?.r?.map((row, i) => (
                    <TableRow key={row.id} sx={{ textAlign: "center" }}>
                      <TableCell sx={{ textAlign: "center" }}>
                        {i + 1}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {row?.date
                          ? (() => {
                              const inputDate = new Date(row.date);
                              return `${inputDate
                                .getDate()
                                .toString()
                                .padStart(2, "0")}/${(inputDate.getMonth() + 1)
                                .toString()
                                .padStart(2, "0")}/${inputDate.getFullYear()}`;
                            })()
                          : "N/A"}
                      </TableCell>

                      <TableCell sx={{ textAlign: "center" }}>
                        {" "}
                        <img
                          src={` ${groupe_scoverurl}/${row?.cover_img}`}
                          style={{
                            height: "40px",
                            width: "50px",
                            borderRadius: "10px",
                          }}
                          alt=""
                        />
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {row?.title}1
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {" "}
                        {formatTime(row?.start_time)} -{" "}
                        {formatTime(row?.end_time)}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        ${row?.price.toFixed(2)}
                      </TableCell>

                      <TableCell
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Box
                          sx={{
                            height: "50px",
                            display: "flex",

                            mt: "5px",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            onClick={() => opengroupdetailspopup(row)}
                            style={{
                              width: row?.link !== null ? "28px" : "30px",
                              height: row?.link !== null ? "28px" : "30px",
                              cursor: "pointer",
                            }}
                            src={row?.link !== null ? Viewicon : addlinkicon}
                            alt="editlogo"
                          />
                        </Box>
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <Box
                          sx={{
                            height: "50px",
                            display: "flex",
                            gap: "20px",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {row?.link ? (
                            <img
                              onClick={() => OpenThislinktourl(row)}
                              style={{
                                width: "50px",
                                height: "50px",
                                cursor: "pointer",
                              }}
                              src={Linkicon}
                              alt="editlogo"
                            />
                          ) : (
                            <img
                              onClick={() => opengroupdetailspopup(row)}
                              style={{
                                width: "30px",
                                height: "30px",
                                cursor: "pointer",
                              }}
                              src={addlinkicon}
                              alt="editlogo"
                            />
                          )}
                          <img
                            onClick={() => DeletethisGroupeSession(row?.id)}
                            style={{
                              width: "30px",
                              height: "30px",
                              cursor: "pointer",
                            }}
                            src={deletelogo}
                            alt="editlogo"
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <TablePagination
            sx={{ mb: "-20px" }}
            // component="div"
            count={Groupesessions?.GroupeSessionList?.count || 0}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Box>
    </>
  );
};

export default Group_Schedules;
