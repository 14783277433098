import { Box, Typography } from '@mui/material'
import React from 'react'

const PopupTitle = ({popuptitle}) => {
  return (
    <>

     <Box sx={{display:"flex" ,justifyContent:"center" ,alignItems:"center"}}>

     <Typography
            sx={{
              fontWeight: "600",
              color: "#292D32",
              fontSize: "18px",
              fontFamily: "Poppins",
              textAlign: "center",
              fontSize: "20px",
            }}
          >
          {popuptitle}
          </Typography>

        </Box>   
    </>
  )
}

export default PopupTitle