import { createSlice } from "@reduxjs/toolkit";
import {
  Delete_Block,
  GetAllUsers,
  SearchUser,
  getEbooksdatabyuser,
  getGroupeSessionsbyid,
  getIndividualSessionsbyid,
  getuserbyid,
} from "./User.action";

let initialState = {
  loading: false,
  error: null,
  UsersData: [],
  massage: "",
  userbyid: {},
  UserIndividualSessions:{},
  UserGroupeSessions:{},
  UserEbooksData:{},
};
const UserSlice = createSlice({
  name: "Users",
  initialState,
  extraReducers: (builder) => {

    // ---------- Get all Audios --------
    builder.addCase(GetAllUsers.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(GetAllUsers.fulfilled, (state, action) => {
      state.loading = false;
      state.UsersData = action?.payload;
    });
    builder.addCase(GetAllUsers.rejected, (state, action) => {
      state.error = true;
      state.loading = false;
    });

    //  --- Delete user by id -----
    builder.addCase(Delete_Block.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(Delete_Block.fulfilled, (state, action) => {
      state.loading = false;
      state.UsersData = action?.payload?.r;
    });
    builder.addCase(Delete_Block.rejected, (state, action) => {
      state.error = true;
      state.loading = false;
    });

    //  --- Get user by id -----
    builder.addCase(getuserbyid.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getuserbyid.fulfilled, (state, action) => {
      state.loading = false;
      state.userbyid = { ...action?.payload?.r };
    });
    builder.addCase(getuserbyid.rejected, (state, action) => {
      state.error = true;
      state.loading = false;
    });

    //  --- Get user onsearch -----
    builder.addCase(SearchUser.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(SearchUser.fulfilled, (state, action) => {
      state.loading = false;
      state.UsersData = action?.payload;
    });
    builder.addCase(SearchUser.rejected, (state, action) => {
      state.error = true;
      state.loading = false;
    });

    // ------------------------ User Sessions _---------------
 
    // -------------- Get Individual User Sessions ------------------
    builder.addCase(getIndividualSessionsbyid.pending, (state, action) => {
      state.loading = true;
    })
    builder.addCase(getIndividualSessionsbyid.fulfilled, (state, action) => {
      state.loading = false;
      
      state.UserIndividualSessions = action?.payload;
    });
    builder.addCase(getIndividualSessionsbyid.rejected, (state, action) => {
      state.error = true;
      state.loading = false;
    });

    // -------------- Get Groupe User Sessions ------------------
    builder.addCase(getGroupeSessionsbyid.pending, (state, action) => {
      state.loading = true;
    })
    builder.addCase(getGroupeSessionsbyid.fulfilled, (state, action) => {
      state.loading = false;
      state.UserGroupeSessions = action?.payload;
    });
    builder.addCase(getGroupeSessionsbyid.rejected, (state, action) => {
      state.error = true;
      state.loading = false;
    });

    // -------------- Get Ebooks  ------------------
    builder.addCase(getEbooksdatabyuser.pending, (state, action) => {
      state.loading = true;
    })
    builder.addCase(getEbooksdatabyuser.fulfilled, (state, action) => {
      state.loading = false;
      state.UserEbooksData = action?.payload;
    });
    builder.addCase(getEbooksdatabyuser.rejected, (state, action) => {
      state.error = true;
      state.loading = false;
    });
  },
});
export default UserSlice.reducer;
