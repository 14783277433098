import { FormControl, MenuItem, Select, TablePagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
} from "@mui/material";
import deletelogo from "../../../../Utils/Logos/deleteuser.png";
import editlogo from "../../../../Utils/Logos/edit.png";
import { useDispatch, useSelector } from "react-redux";
import {
  GetAdminAvilabilitySessions,
  GetallCoachesAvilability,
} from "../../../../Redux/Slices/Sessions/Session.action";
import Viewicon from "../../../../Utils/Logos/viewmore.png";

import DeleteAffermation from "../../../../Components/Popups/DeleteAffermation";
import DeleteAvilability from "../../../../Components/Popups/DeleteAvilability";
import moment from "moment";
import { formatTime } from "../../../../Apis/Functions";
import { useLocation, useNavigate } from "react-router-dom";

const columns = [
  { id: "#", label: "#" },
  { id: "Date", label: "Date" },
  { id: "Timefrom", label: "Time (From)" },
  { id: "Timeto", label: "Time (T0)" },
  { id: "Price", label: "Price" },
  { id: "Action", label: "Action" },
];

const AvilabilityTable = () => {
  let dispatch = useDispatch();
let params=useLocation()
  let Avilabilitydata = useSelector((store) => store?.Sessions);
  // ----------- Delete Affermation ----------
  let [isdeleteAvilability, setisdeleteAvilability] = useState(false);
  let [deletedata, setdeletedata] = useState({});
  let opendeletemodal = (row) => {
    setdeletedata(row);
    setisdeleteAvilability(true);
  };
  let closedeleteAvilability = () => {
    setisdeleteAvilability(false);
  };
  // -------------- Paginations ---------------
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(  params.state!==null ? params?.state?.row_count: 10);
  let [filteredvalue, setfilteredvalue] = useState( params.state!==null ? params?.state?.type : 0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(
      GetallCoachesAvilability({
        type: filteredvalue,
        count: newPage * rowsPerPage,
      })
    );
  };
  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };
  useEffect(() => {
    dispatch(
      GetallCoachesAvilability({ type: filteredvalue, count: 0, row_count: rowsPerPage })
    );
  }, [rowsPerPage]);
  const getfilterdvalue = (filtererdvalue) => {
    setfilteredvalue(filtererdvalue);
    setPage(0);
    dispatch(
      GetallCoachesAvilability({
        type: filtererdvalue,
        count: 0,
        row_count: rowsPerPage,
      })
    );
  };

  let navigate = useNavigate();
  let RedirectToDetails = (row) => {
    navigate("/dashboard/avilability/view_avilability_Details", {
      state:{
        id: row?.id
        ,
        type: filteredvalue,
      count: 0,
      row_count: rowsPerPage,
        }
      
    });
  };

  return (
    <>
      {isdeleteAvilability ? (
        <DeleteAvilability
          modalcontrols={{
            isdeleteAvilability,
            closedeleteAvilability,
            deletedata,
            count: page * rowsPerPage,
            rowsPerPage,
            filteredvalue,
          }}
        />
      ) : (
        ""
      )}
      <FormControl
        sx={{ width: "30%", height: "40%", mt: "20px" }}
        size="small"
      >
        <Select
          sx={{
            width: "60%",
            height: "40px",
            border: "1px solid #F68582",
            background: "#fff",
          }}
          value={filteredvalue}
          labelId="demo-select-small-label"
          id="demo-select-small"
          label="All"
          onChange={(e) => getfilterdvalue(e.target.value)}
          defaultValue={0} // Set the value corresponding to "All" as the default value
        >
          <MenuItem value={0}>All</MenuItem>
          <MenuItem value={1}>Individual </MenuItem>
          <MenuItem value={2}>Group </MenuItem>
        </Select>
      </FormControl>
      <Box sx={{ mt: "20px" }}>
        <Paper style={{ width: "100%", overflow: "hidden" }}>
          <TableContainer style={{ maxHeight: "72vh" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      sx={{
                        textAlign: "center",
                        fontSize: "15px",
                        lineHeight: "120%",
                        fontFamily: "Montserrat",
                        fontWeight: "600",
                        background: "#F68582",
                        color: "#fff",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody sx={{ textAlign: "center" }}>
                {Avilabilitydata?.GetallcoachesAvilability?.r?.length < 1 ||
                Avilabilitydata?.GetallcoachesAvilability?.r == null ? (
                  <Box sx={{ textAlign: "center", padding: "10px", m: "10px" }}>
                    No Data
                  </Box>
                ) : (
                  Avilabilitydata?.GetallcoachesAvilability?.r.map((row, i) => (
                    <TableRow key={row.id} sx={{ textAlign: "center" }}>
                      <TableCell sx={{ textAlign: "center" }}>
                        {i + 1}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {row?.date
                          ? (() => {
                              const inputDate = new Date(row.date);
                              return `${inputDate
                                .getDate()
                                .toString()
                                .padStart(2, "0")}/${(inputDate.getMonth() + 1)
                                .toString()
                                .padStart(2, "0")}/${inputDate.getFullYear()}`;
                            })()
                          : "N/A"}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {formatTime(row?.start_time)}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {formatTime(row?.end_time)}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        $ {row?.price.toFixed(2)}
                      </TableCell>
                      <TableCell
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Box
                          sx={{
                            height: "50px",
                            display: "flex",
                            gap: "20px",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            onClick={() => RedirectToDetails(row)}
                            style={{
                              width: "30px",
                              height: "30px",
                              cursor: "pointer",
                            }}
                            src={Viewicon}
                            alt=""
                          />
                          <img
                            onClick={() => opendeletemodal(row)}
                            style={{
                              width: "30px",
                              height: "30px",
                              cursor: "pointer",
                            }}
                            src={deletelogo}
                            alt=""
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <TablePagination
            sx={{ mb: "-20px" }}
            // component="div"
            count={Avilabilitydata?.GetallcoachesAvilability?.count || 0}
            page={page}
            onPageChange={handleChangePage}
            // rowsPerPage={10}
            // rowsPerPageOptions={[]}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPage={rowsPerPage}
          />
        </Box>
      </Box>
    </>
  );
};

export default AvilabilityTable;
