import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Box, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import UserDetailsBox from "../../../../Components/UserDetails/UserDetailsBox";
import { getuserbyid } from "../../../../Redux/Slices/Users/User.action";
let commonfont = {
  fontStyle: "Poppins",
  fontSize: "18px",
  fontWeight: "550",
  lineHeight: "100%",
};

const UserDetails = () => {
  const { userId } = useParams();
  const location = useLocation();
  const currentPath = location.pathname;
  let [selectedtab, setselectedtab] = useState(1);
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let links = [
    { id: 1, name: "Individual Sessions", path: `/dashboard/user/${userId}` },
    {
      id: 2,
      name: "Group Sessions",
      path: `/dashboard/user/${userId}/groupesession`,
    },
    { id: 3, name: "Ebooks", path: `/dashboard/user/${userId}/ebooksession` },
  ]


  let data = useSelector((store) => store.Users)


  useEffect(() => {
    dispatch(getuserbyid(userId));
  }, []);

  let getback = () => {
    navigate("/dashboard/users");
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "20px",
          padding: "20px 0px",
        }}
      >
        <ArrowBackIosIcon sx={{ cursor: "pointer" }} onClick={getback} />
        <Typography
          onClick={getback}
          sx={{
            fontFamily: "Poppins",
            fontSize: "25px",
            cursor: "pointer",
            fontWeight: "600",
          }}
        >
          User Details
        </Typography>
      </Box>

      <Box
        sx={{ width: "95vw", display: "flex", justifyContent: "space-between" }}
      >
        <Box
          sx={{
            width: "25%",
            border: "1px solid #F68582",
            borderRadius: "10px",
            minHeight: "87vh",
            maxHeight: "87vh",
            overflow: "auto",
            boxSizing: "border-box",
            "&::-webkit-scrollbar": {
              width: "3px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#F68582",
              borderRadius: "8px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#F68582",
            },
            "&::-webkit-scrollbar-track": {
              borderRadius: "10px",
            },
          }}
        >

          <UserDetailsBox data={data} />
          
        </Box>

        <Box sx={{ width: "73%" }}>
          <Box sx={{ display: "flex", gap: "20px", width: "100%" }}>
        
        
            {links?.map((li, i) => (
              <Box>
                <Link to={li.path} style={{ textDecoration: "none" }}>
                  <Typography
                    onClick={() => setselectedtab(i + 1)}
                    sx={{
                      ...commonfont,
                      cursor: "pointer",
                      position: "relative",
                      textDecoration: "none",
                      borderBottom:
                        currentPath == li?.path
                          ? "3px solid #F68582"
                          : "3px solid transparent",
                      color: currentPath == li?.path ? "black" : "gray",
                      "&:hover": {
                        textDecoration: "none", // Remove the default underline
                        borderBottom: "3px solid #F68582", // Add the color as an underline
                        transition: "border-bottom 0.2s ease-in-out", // Add transition for the border-bottom
                      },
                    }}
                  >
                    {li?.name}
                  </Typography>
                </Link>
              </Box>
            ))}



          </Box>

          <Box sx={{ width: "100%" }}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default UserDetails;
