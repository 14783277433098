import { createSlice } from "@reduxjs/toolkit";
import { AddPrivacyandTermsConditiondata,   GetPrivacydata, GetTermsAndConditionata, UpdatePrivacyandTermsConditiondata } from "./SettingData.action";

let initialState = {
  loading: false,
  error: null,
  PrivacyPolicy:{},
  TermsAndConditions:{}
};
const SessionSlice = createSlice({
  name: "Sessions",
  initialState,
  extraReducers: (builder) => {
    // --------- Get Privacy policy  ---------
    builder
      .addCase(GetPrivacydata.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetPrivacydata.fulfilled, (state, action) => {
        state.loading = false;
        state.PrivacyPolicy = action?.payload;
      })
      .addCase(GetPrivacydata.rejected, (state) => {
        state.error = true;
        state.loading = false;
      })
    // --------- Get Terms And condition Data  ---------
    builder
      .addCase(GetTermsAndConditionata.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetTermsAndConditionata.fulfilled, (state, action) => {
        state.loading = false;
        state.TermsAndConditions = action?.payload;
      })
      .addCase(GetTermsAndConditionata.rejected, (state) => {
        state.error = true;
        state.loading = false;
      })

    // --------- Add Privacy Policy   ---------
    builder
      .addCase(AddPrivacyandTermsConditiondata.pending, (state) => {
        state.loading = true;
      })
      .addCase(AddPrivacyandTermsConditiondata.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(AddPrivacyandTermsConditiondata.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
    // --------- Add Privacy Policy   ---------
    builder
      .addCase(UpdatePrivacyandTermsConditiondata.pending, (state) => {
        state.loading = true;
      })
      .addCase(UpdatePrivacyandTermsConditiondata.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(UpdatePrivacyandTermsConditiondata.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
   
  },
});

export default SessionSlice.reducer;
