import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  TablePagination,
} from "@mui/material";

import EmailInput from "../../../Components/Inputs/EmailInput";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import viewmoreicon from "../../../Utils/Logos/viewmore.png";
import {
  GetAllUsers,
  SearchUser,
} from "../../../Redux/Slices/Users/User.action";
import { debounce } from "lodash";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import TitleText from "../../../Components/TitleText";
import { User_Profileimage } from "../../../Apis/Api";
const columns = [
  { id: "#", label: "#" },
  { id: "Name", label: "Profile Photo " },
  { id: "Email", label: "User Name " },
  { id: "Phone Number", label: "Full Name" },
  { id: "User Plan", label: "Email" },
  { id: "Action", label: "Mobile Number" },
  { id: "Action", label: "Action" },
];

const UsersMangament = () => {
  let data = useSelector((store) => store?.Users);
  let dispatch = useDispatch();
  let navigate = useNavigate();

  let viewuserDetails = (id) => {
    navigate(`/dashboard/user/${id}`);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleDebouncedSearch = debounce((searchValue) => {
    dispatch(SearchUser({searchValue:searchValue,count: page * rowsPerPage, row_count: rowsPerPage}));
  }, 300);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(
      GetAllUsers({ count: newPage * rowsPerPage, row_count: rowsPerPage })
    );
  };

  useEffect(() => {
    dispatch(
      GetAllUsers({ count: page * rowsPerPage, row_count: rowsPerPage })
    );
  }, [page, rowsPerPage]);

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    dispatch(GetAllUsers({ count: 0, row_count: rowsPerPage }));
  };

  const handleInputvalue = (Value) => {
    setPage(0);
    handleDebouncedSearch(Value);
  }

  let Rowdata = data?.UsersData?.r?.map((data, i) => ({
    rowid: i + 1 + page * 10,
    ...data,
  }))

  return (
    <>
      <TitleText title={"User Management"} />
      
      <Box
        sx={{
          boxShadow: "none",
          outline: "none",
          mb: "20px",
          border: "none",
          mt: "20px",
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        <Box sx={{ mb: "20px" }}>
          <EmailInput placeholder={"Search User"} onChange={handleInputvalue} />
        </Box>
        <Paper style={{ width: "100%", overflow: "hidden" }}>
          <TableContainer style={{ maxHeight: "71vh" }}>
            <Table stickyHeader sx={{}}>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      sx={{
                        textAlign: "center",
                        fontSize: "15px",
                        lineHeight: "120%",
                        fontFamily: "Montserrat",
                        fontWeight: "600",
                        background: "#F68582",
                        color: "#fff",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {Rowdata?.length < 1 ? (
                  <Box sx={{ textAlign: "center", padding: "10px", m: "10px" }}>
                    No Data
                  </Box>
                ) : (
                  Rowdata?.map((row, i) => (
                    <TableRow key={row.id} sx={{ textAlign: "center" }}>
                      <TableCell sx={{ textAlign: "center" }}>
                        {row?.rowid}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {row?.profile_img == null ? (
                          <AccountCircleIcon
                            sx={{ height: "40px", width: "50px" }}
                          />
                        ) : (
                          <img
                            src={`${User_Profileimage}/${row?.profile_img}`}
                            style={{
                              height: "40px",
                              width: "50px",
                              borderRadius: "10px",
                            }}
                            alt=""
                          />
                        )}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {" "}
                        {row?.user_name == null ? (
                          <Typography>-</Typography>
                        ) : (
                          row?.user_name
                        )}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {" "}
                        {row?.full_name}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {" "}
                        {row?.email}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {" "}
                        {row?.cc} {row?.phone_no}
                      </TableCell>
                      <TableCell
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Box
                          sx={{
                            height: "50px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            onClick={() => viewuserDetails(row?.id)}
                            style={{
                              width: "35px",
                              height: "35px",
                              cursor: "pointer",
                            }}
                            src={viewmoreicon}
                            alt=""
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Box>
          <TablePagination
            sx={{ mb: "-20px" }}
            component="div"
            count={data?.UsersData?.count || 0}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPage={rowsPerPage}
          />
        </Box>
      </Box>
    </>
  );
};

export default UsersMangament;
