import axios from "axios";
import { Baseurl, getUserdata } from "../../../Apis/Api";
import { createAsyncThunk } from "@reduxjs/toolkit";

//  -------------  Get EbooksList  -----------
export const GetEbooklist = createAsyncThunk(
  "Ebooks/getebookslist",
  async ({count,row_count}) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      }
      let data = await axios.get(`${Baseurl}/admin/ebooks_get?count=${count}&row_count=${row_count}`, config);
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);
 
//  -------------  Get all Categories -----------
export const Getallcategorie = createAsyncThunk(
  "Ebooks/getallcategories",
  async () => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      let data = await axios.get(
        `${Baseurl}/admin/ebooks_category_get`,
        config
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);

 
//  ------------- Delete item from Ebooklist -----------
export const DeleteEbookitem = createAsyncThunk(
  "Ebooks/deleteebooklistitem",
  async (deletedata) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      }
      let data = await axios.post
      ( `${Baseurl}/admin/ebooks_edit`,
        deletedata,
        config
      )
      return data.data;
    } catch (error) {
      throw error;
    }
  }
)
 
//  ------------- Delete item from Ebooklist -----------
export const DeleteEbookCategory = createAsyncThunk(
  "Ebooks/deleteebookcategoryitem",
  async (deletedata) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      }

      let data = await axios.post
      ( `${Baseurl}/admin/edit_ebooks_category`,
        deletedata,
        config
      )
      return data.data;
    } catch (error) {
      throw error;
    }
  }
)



//  ------------- Add New Ebook Item -----------
export const AddEbookitem = createAsyncThunk(
  "Ebooks/addebookitem",
  async (ebookitem) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      let data = await axios.post(
        `${Baseurl}/admin/ebooks_add`,
        ebookitem,
        config
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }
)

//  ------------- Add New Ebook Item -----------
export const EditEbookitems = createAsyncThunk(
  "Ebooks/editebookitems",
  async (ebookitem) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      let data = await axios.post(
        `${Baseurl}/admin/ebooks_edit`,
        ebookitem,
        config
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }
)

//  -------------  Get all Categories -----------
export const GetEbookDetail = createAsyncThunk(
  "Ebooks/getebookdetail",
  async (id) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      let data = await axios.get(
        `${Baseurl}/admin/ebooks_get_details?ebook_id=${id}`,
        config
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);

//  ------------- Add New Categories -----------
export const Addcategorie = createAsyncThunk(
  "Ebooks/addnewcategorie",
  async (categorie) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      let data = await axios.post(
        `${Baseurl}/admin/ebooks_category_add`,
        categorie,
        config
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }
)




//  ------------- Update Categories -----------
export const UpdateCategory = createAsyncThunk(
  "Ebooks/updatecategorie",
  async (categorieval) => {
    try {

      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      }

      let data = await axios.post(
        `${Baseurl}/admin/edit_ebooks_category`,
        categorieval,
        config
      )

      return data.data

    } catch (error) {
      throw error;
    }
  }
)





