import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  EditCoachesDetails,
  GetCoachDeleteStatus,
  GetCoacheslist,
} from "../../../../Redux/Slices/Sessions/Session.action";
import { Coach_imageUrl } from "../../../../Apis/Api";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Viewicon from "../../../../Utils/Logos/viewmore.png";
import TitleText from "../../../../Components/TitleText";
import { CommonButtonstyle } from "../../../../Components/CommonCss";
import { Link, Outlet, useNavigate } from "react-router-dom";
import deletelogo from "../../../../Utils/Logos/deleteuser.png";
import Swal from "sweetalert2";
import {
  errortost,
  sucesstost,
} from "../../../../Components/Alerts/CustomAlert";
import { date } from "yup";

const columns = [
  { id: "#", label: "#" },
  { id: " Name", label: " Name" },
  { id: " Profile", label: "Profile" },
  { id: "Title", label: "Title" },
  { id: "email", label: "Email" },
  { id: "Session Price ", label: " Price / Hr " },
  { id: "View", label: "View" },
  { id: "Delete", label: "Delete" },
];
const CoachDataListing = () => {
  let dispatch = useDispatch();
  let StoreCoachesList = useSelector((store) => store?.Sessions);
  let navigate = useNavigate();
  let navigateTocoachprofile = (row) => {
    navigate("/dashboard/coaches/view_Coach", { state: row?.id });
  };
  // ---------- Paginations -------------
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(
      GetCoacheslist({ count: newPage * rowsPerPage, row_count: rowsPerPage })
    );
  };
  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    dispatch(GetCoacheslist({ count: 0, row_count: rowsPerPage }));
  };

  let DeleteThisCoach = (id) => {
    dispatch(
      GetCoachDeleteStatus({
        id: id,
        date: new Date().toISOString().split("T")[0],
      })
    )
      .unwrap()
      .then((result) => {
        if (result?.s == 1) {
          let formdata = new FormData();
          formdata.append("id", id);
          formdata.append("status", 0);
          Swal.fire({
            title: "Want to Delete this Coach ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "red",
            cancelButtonColor: "gray",
            confirmButtonText: "Yes, delete it!",
          }).then((result) => {
            if (result.isConfirmed) {
              dispatch(EditCoachesDetails(formdata))
                .unwrap()
                .then((result) => {
                  if (result?.s === 1) {
                    dispatch(GetCoacheslist({ count: 0, row_count: 10 }));
                    sucesstost("Coach Deleted Sucessfully !");
                  } else {
                    errortost(result?.m);
                  }
                });
            }
          });
        } else {
          Swal.fire({
            title: "Oho",
            text: result?.m,
            icon: "warning",
            confirmButtonColor: "  rgba(246, 133, 130, 1)",
            cancelButtonColor: "#d33",
          });
        }
      });
  };
  useEffect(() => {
    dispatch(GetCoacheslist({ count: 0, row_count: 10 }));
  }, []);
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: "20px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <TitleText title={"Coach Managment "} style={"0"} />
          <Button
            onClick={() => navigate("./add_coach")}
            sx={{ ...CommonButtonstyle, width: "20%" }}
          >
            Add New Coach
          </Button>
        </Box>
      </Box>
      <Box sx={{ mt: "20px" }}>
        <Paper style={{ width: "100%", overflow: "hidden" }}>
          <TableContainer style={{ maxHeight: "80vh" }}>
            <Table stickyHeader sx={{}}>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      sx={{
                        textAlign: "center",
                        fontSize: "15px",
                        lineHeight: "120%",
                        fontFamily: "Montserrat",
                        fontWeight: "600",
                        background: "#F68582",
                        color: "#fff",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody sx={{ textAlign: "center" }}>
                {StoreCoachesList?.CoachesList?.r?.length < 1 ||
                StoreCoachesList?.CoachesList?.r == null ? (
                  <Box sx={{ textAlign: "center", padding: "10px", m: "10px" }}>
                    No Data
                  </Box>
                ) : (
                  StoreCoachesList?.CoachesList?.r?.map((row, i) => (
                    <TableRow key={row.id} sx={{ textAlign: "center" }}>
                      <TableCell sx={{ textAlign: "center" }}>
                        {i + 1}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {row?.coach_name ? row?.coach_name : " - "}
                      </TableCell>

                      <TableCell sx={{ textAlign: "center" }}>
                        {" "}
                        {row?.profile_img == null ? (
                          <AccountCircleIcon
                            sx={{ height: "40px", width: "50px" }}
                          />
                        ) : (
                          <img
                            src={`${Coach_imageUrl}/${row?.profile_img}`}
                            style={{
                              height: "40px",
                              width: "50px",
                              borderRadius: "10px",
                              objectFit: "cover",
                            }}
                            alt=""
                          />
                        )}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {row?.title ? row?.title : " - "}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {row?.email ? row?.email : " - "}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        ${row?.price ? row?.price.toFixed(2) : " - "}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {/* <Link to={"/dashboard/coaches/view_Coach"}> */}
                        <Box
                          sx={{
                            height: "50px",
                            display: "flex",
                            gap: "20px",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            onClick={() => navigateTocoachprofile(row)}
                            style={{
                              width: "30px",
                              height: "30px",
                              cursor: "pointer",
                            }}
                            src={Viewicon}
                            alt="editlogo"
                          />
                        </Box>
                        {/* </Link> */}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <Box
                          sx={{
                            height: "50px",
                            display: "flex",
                            gap: "20px",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            onClick={() => DeleteThisCoach(row?.id)}
                            style={{
                              width: "30px",
                              height: "30px",
                              cursor: "pointer",
                            }}
                            src={deletelogo}
                            alt="editlogo"
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <TablePagination
            sx={{ mb: "-20px" }}
            count={StoreCoachesList?.CoachesList?.count || 0}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Box>
    </div>
  );
};

export default CoachDataListing;
