import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { modalstyle } from "./PopupsStyling";
import PopupTitle from "./PopupComponent/PopupTitle";
import ActionButton from "./PopupComponent/ActionButton";
import TostContainer from "../Alerts/TostContainer";
import { errortost, sucesstost } from "../Alerts/CustomAlert";
import { useDispatch, useSelector } from "react-redux";
import { Ebokscoverurl } from "../../Apis/Api";
// import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import moment from "moment";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import {
  AddEbookitem,
  GetEbooklist,
} from "../../Redux/Slices/Ebooks/Ebooks.action";
import {
  AddAdminGroupeSession,
  GetAdminAvilabilitySessions,
  GetAdminSessionsByDate,
  GetGroupeSessionlist,
} from "../../Redux/Slices/Sessions/Session.action";
import { formatTime } from "../../Apis/Functions";
let inputstyle = {
  width: "100%",
  height: "50px",
  borderRadius: "10px",
  border: "1px solid #F68582",
  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
};

let Inputboxstyle = {
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  gap: "10px",
};

let Lablestyle = {
  fontSize: "15px",
  mt: "10px",
  fontWeight: "500",
};

const AddGroupeSession = ({ modalcontrols = {} }) => {
  const { isGroupeSessionopen, closeGroupesession } = modalcontrols || {};

  const [group_name, setgroup_name] = useState("");
  const [Addlink, setAddlink] = useState("");
  const [price, setPrice] = useState("");
  const [description, setdescription] = useState("");
  const [cover_img, setcover_img] = useState(null);
  let [loading, setloading] = useState(false);
  let dispatch = useDispatch();
  let TimingsData = useSelector((store) => store?.Sessions);

  const [formErrors, setFormErrors] = useState({
    group_name: "",
    Addlink: "",
    price: "",
    description: "",
    cover_img: "",
  });

  const resetForm = () => {
    setgroup_name("");
    setAddlink("");
    setPrice("");
    setdescription("");
    setcover_img(null);
    setFormErrors({
      title: "",
      Addlink: "",
      price: "",
      description: "",
      cover_img: "",
    });
  };
  const validateForm = () => {
    const groupNameValue = group_name || "";
    // const authorNameValue = Addlink || "";
    const priceValue = price || "";
    const descriptionValue = description || "";
    const coverImgValue = cover_img || "";

    const errors = {
      group_name: groupNameValue === "" ? "Group name is required." : "",
      // Addlink: authorNameValue === "" ? "Author name is required." : "",
      price: priceValue === "" ? "Price is required." : "",
      description: descriptionValue === "" ? "Description is required." : "",
      cover_img: coverImgValue === "" ? "Cover image is required." : "",
    };

    setFormErrors(errors);

    return Object.values(errors).every((error) => error === "");
  };

  const initialFormState = {
    date: "",
    start_time: "",
    end_time: "",
    price: "",
  };
  const [formData, setFormData] = useState({ ...initialFormState });
  const [validation, setValidation] = useState({
    date: false,
    start_time: true,
    end_time: true,
    price: true,
    // link: true,
  });

  const handleDateChange = (event) => {
    if (event && event.target) {
      const selectedDate = event.target.value;
      dispatch(GetAdminSessionsByDate(selectedDate));
      setFormData({ ...formData, date: selectedDate });
      setValidation({ ...validation, date: true });
    } else {
      // Handle the case when event is undefined or does not have a target
      console.error("Invalid event object:", event);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormErrors({ ...formErrors, [name]: false });
    if (name === "group_name") {
      setgroup_name(value);
    } else if (name === "Addlink") {
      setAddlink(value);
    } else if (name === "description") {
      setdescription(value);
    } else if (name === "price") {
      setPrice(value);
    }
  };

  const [validationErrors, setValidationErors] = useState({
    date: "",
    start_time: "",
    end_time: "",
  });

  const handleFileChange = (event, fileType) => {
    const file = event.target.files[0];
    if (!file) {
      console.log(`Please select a file for ${fileType}`);
      errortost(`Please select a file for ${fileType}`);
      setFormErrors({ ...formErrors, [fileType]: true });
      return;
    }
    if (fileType === "image" && !file.type.startsWith("image/")) {
      errortost("Please select a valid image file");
      setFormErrors({ ...formErrors, [fileType]: true });
      return;
    }
    setFormErrors({ ...formErrors, [fileType]: false });
    if (fileType === "image") {
      setcover_img(file);
    }
  };
  const HandeldEbookButton = () => {
    const isValid = validateForm();
    const isFormValid =
      formData.date && formData.start_time && formData.end_time;
    if (isFormValid && isValid) {
      const formDataToSend = new FormData();
      formDataToSend.append("group_name", group_name);
      // if (Addlink !== "") {
      //   formDataToSend.append("link", Addlink);
      // }

      formDataToSend.append("cover_img", cover_img);
      formDataToSend.append("price", price);
      formDataToSend.append("description", description);
      formDataToSend.append("start_time", formData.start_time);
      formDataToSend.append("end_time", formData.end_time);
      formDataToSend.append("date", formData.date);

      setloading(true);
      dispatch(AddAdminGroupeSession(formDataToSend))
        .unwrap()
        .then((result) => {
          if (result.s === 1) {
            setloading(false);
            dispatch(GetGroupeSessionlist({ count: 0, row_count: 10 }));
            closeGroupesession();
            sucesstost("Group Session Added Successfully!");
            resetForm();
          } else {
            setloading(false);
            closeGroupesession();
            dispatch(GetGroupeSessionlist({ count: 0, row_count: 10 }));
            errortost("Something went wrong.");
            resetForm();
          }
        });
    } else {
      setValidation({
        date: !!formData.date,
        start_time: !!formData.start_time,
        end_time: !!formData.end_time,
      });
      setloading(false);
      errortost("Please fill in all the fields!");
    }
  };
  let popuptitle = "Add Group Sessions";

  // ------------ Add Start time -------------
  const handlestart_timeChange = (time) => {
    const formattedTime = moment.utc(time).format("HH:mm:ss");
    setFormData({ ...formData, start_time: formattedTime });
    if (!formData.date) {
      setValidation({ ...validation, date: false });
      setValidationErors({
        ...validationErrors,
        date: "Please select a date before choosing the time",
      });
      return;
    }
    const startTime = moment(formattedTime, "HH:mm:ss");
    const endTime = moment(formData.end_time, "HH:mm:ss");
    if (endTime.isValid() && endTime.isBefore(startTime)) {
      // setistimeavilable(true);
      setValidation({ ...validation, end_time: false });
      setValidationErors({
        ...validationErrors,
        end_time: "End time must be after start time.",
      });
      return;
    }
    const overlappingSlot = TimingsData?.AdminGroupeAvilability?.find((el) => {
      const bookedStartTime = moment(el.start_time, "HH:mm:ss");
      const bookedEndTime = moment(el.end_time, "HH:mm:ss");
      return (
        startTime.isBetween(bookedStartTime, bookedEndTime) ||
        endTime.isBetween(bookedStartTime, bookedEndTime) ||
        bookedStartTime.isBetween(startTime, endTime) ||
        bookedEndTime.isBetween(startTime, endTime)
      );
    });
    if (overlappingSlot) {
      // setistimeavilable(true);
      setValidation({ ...validation, start_time: false });
      setValidationErors({
        ...validationErrors,
        start_time: "This slot is already booked. Select another time.",
      });
      return;
    }
    setValidation({ ...validation, start_time: true });
    setValidationErors({ ...validationErrors, start_time: "" });
  };

  const handleend_timeChange = (time) => {
    const formattedTime = moment.utc(time).format("HH:mm:ss");
    setFormData({ ...formData, end_time: formattedTime });
    if (!formData.date) {
      setValidation({ ...validation, date: false });
      setValidationErors({
        ...validationErrors,
        date: "Please select a date before choosing the time",
      });
      return;
    }

    const startTime = moment(formData.start_time, "HH:mm:ss");
    const endTime = moment(formattedTime, "HH:mm:ss");
    if (startTime.isValid() && endTime.isBefore(startTime)) {
      // setistimeavilable(true);
      setValidation({ ...validation, end_time: false });
      setValidationErors({
        ...validationErrors,
        end_time: "End time must be after start time.",
      });
      return;
    }
    const overlappingSlot = TimingsData?.AdminGroupeAvilability?.find((el) => {
      const bookedStartTime = moment(el.start_time, "HH:mm:ss");
      const bookedEndTime = moment(el.end_time, "HH:mm:ss");
      return (
        startTime.isBetween(bookedStartTime, bookedEndTime) ||
        endTime.isBetween(bookedStartTime, bookedEndTime) ||
        bookedStartTime.isBetween(startTime, endTime) ||
        bookedEndTime.isBetween(startTime, endTime)
      );
    });
    if (overlappingSlot) {
      // setistimeavilable(true);
      setValidation({ ...validation, end_time: false });
      setValidationErors({
        ...validationErrors,
        end_time: "This slot is already booked. Select another time.",
      });
      return;
    }
    setValidation({ ...validation, end_time: true });
    setValidationErors({ ...validationErrors, end_time: "" });
  };

  return (
    <>
      <TostContainer />
      <Modal
        open={isGroupeSessionopen}
        onClose={() => {
          closeGroupesession();
          resetForm();
        }}
      >
        <Box
          sx={{
            ...modalstyle,
            borderRadius: "10px",
            width: "40%", // Adjusted width
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            boxSizing: "border-box",
            overflow: "hidden", // Added overflow property
          }}
        >
          <PopupTitle popuptitle={popuptitle} />
          <Box
            sx={{ height: "80vh", overflow: "auto", boxSizing: "border-box" }}
          >
            {/* --------- Ebooks Title ------- */}

            <Box sx={{ ...Inputboxstyle }}>
              <InputLabel
                id="demo-select-small-label"
                sx={{
                  // ...Lablestyle,
                  color: formErrors.group_name ? "red" : "#000",
                }}
              >
                Session Title
              </InputLabel>

              <OutlinedInput
                sx={{
                  ...inputstyle,
                }}
                id="outlined-adornment-weight"
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
                // value={group_name}
                placeholder="Session Title"
                onChange={handleChange}
                name="group_name"
                value={group_name?.trimStart()}
              />
            </Box>

            {/* --------- Date ------- */}
            <Box sx={{ ...Inputboxstyle, mt: "10px" }}>
              <InputLabel sx={{ color: validation.date ? "#000" : "red" }}>
                Date
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <TextField
                  sx={{ width: "100%" }}
                  type="date"
                  onChange={handleDateChange}
                  error={!validation.date}
                  inputProps={{
                    min: moment().format("YYYY-MM-DD"), // Set the minimum allowed date to today
                  }}
                />
              </LocalizationProvider>
            </Box>
            <Box>
              {!validation.date ? (
                <Typography sx={{ color: "#000" }}>
                  Please Select Date{" "}
                </Typography>
              ) : (
                <Typography sx={{ color: "#000" }}>Booked Slots </Typography>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                maxHeight: "12vh",
                overflow: "auto",
                mt: "10px",
                mb: "10px",
              }}
            >
              {!validation.date ? (
                // Show a message if date is not selected
                ""
              ) : TimingsData?.AdminGroupeAvilability?.length === 0 ? (
                // Show a message if there are no booked slots
                <Typography sx={{ color: "gray" }}>
                  No Slots are booked for this day ......
                </Typography>
              ) : (
                // Show booked slots if date is selected
                TimingsData?.AdminGroupeAvilability?.map((el) => (
                  <Box
                    sx={{
                      display: "flex",
                      color: "#000",
                      gap: "10px",
                      alignItems: "center",
                      mt: "10px",
                    }}
                  >
                    from
                    <Typography
                      sx={{
                        color: "#000",
                        border: "1px solid #F68582",
                        padding: "2px 20px",
                        borderRadius: "10px",
                      }}
                    >
                      {formatTime(el?.start_time)} 🕒
                    </Typography>
                    to
                    <Typography
                      sx={{
                        color: "#000",
                        border: "1px solid #F68582",
                        padding: "2px 20px",
                        borderRadius: "10px",
                      }}
                    >
                      {formatTime(el?.end_time)} 🕒
                    </Typography>
                  </Box>
                ))
              )}
            </Box>

            {/* ------------------------- Select Timings --------------------- */}

            <Box
              sx={{
                display: "flex",
                color: "#000",
                justifyContent: "space-between",
                gap: "5px",
                flexDirection: "column",
                mt: "5px",
              }}
            >
              <Typography>Select Time</Typography>
              <Box
                sx={{
                  display: "flex",
                  color: "#000",
                  justifyContent: "space-between",
                  gap: "40px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    width: "100%",
                  }}
                >
                  <InputLabel
                    sx={{ color: validation?.start_time ? "#000" : "red" }}
                  >
                    from
                  </InputLabel>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <TimePicker
                      onChange={handlestart_timeChange}
                      // value={formData.start_time}
                      slotProps={{
                        openPickerIcon: {
                          style: {
                            color: "black",
                          },
                        },
                      }}
                      sx={{
                        borderRadius: "10px",
                        width: "100%",
                      }}
                      // label="Choose End Time"
                      disabled={!validation.date}
                    />
                  </LocalizationProvider>

                  <Typography sx={{ color: "#000" }}>
                    {validationErrors?.start_time}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    width: "100%",
                  }}
                >
                  <InputLabel
                    sx={{ color: validation?.end_time ? "#000" : "red" }}
                  >
                    to
                  </InputLabel>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <TimePicker
                      onChange={handleend_timeChange}
                      // value={formData?.end_time}
                      slotProps={{
                        openPickerIcon: {
                          style: {
                            color: "black",
                          },
                        },
                      }}
                      sx={{
                        borderRadius: "10px",
                        width: "100%",
                      }}
                      // label="Choose End Time"
                      disabled={!validation.date}
                    />
                  </LocalizationProvider>
                  <Typography sx={{ color: "#000" }}>
                    {validationErrors?.end_time}
                  </Typography>
                </Box>
              </Box>
            </Box>
            {/* --------- Author Name ------- */}
            <Box sx={{ ...Inputboxstyle }}>
              <InputLabel
                id="demo-select-small-label"
                sx={{
                  ...Lablestyle,
                  color: formErrors.Addlink ? "red" : "#000",
                }}
              >
                Add Link
              </InputLabel>
              <OutlinedInput
                sx={{
                  ...inputstyle,
                }}
                id="outlined-adornment-weight"
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
                placeholder="Enter Link"
                value={Addlink?.trimStart()}
                onChange={handleChange}
                name="Addlink"
              />
            </Box>

            <Box sx={{ ...Inputboxstyle }}>
              <InputLabel
                id="demo-select-small-label"
                sx={{
                  ...Lablestyle,
                  color: formErrors.cover_img ? "red" : "#000",
                }}
              >
                Upload Book Cover Image
              </InputLabel>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  border: "1px solid #F68582",
                  padding: "10px",
                  borderRadius: "10px",
                  boxSizing: "border-box",
                }}
              >
                <Typography noWrap style={{ color: "#000", width: "50%" }}>
                  {cover_img ? (
                    // <Typography>{cover_img?.name}</Typography>
                    <img
                      style={{ width: "150px", height: "100%" }}
                      src={URL.createObjectURL(cover_img)}
                      alt="cover_img"
                    />
                  ) : (
                    <Typography> Choose cover_img </Typography>
                  )}
                </Typography>

                {/* */}
                <Input
                  type="file"
                  id="image-cover-file-input"
                  style={{ display: "none" }}
                  onChange={(event) => handleFileChange(event, "image")}
                />
                <label htmlFor="image-cover-file-input">
                  <Button
                    variant="contained"
                    component="span"
                    sx={{
                      background: "rgba(246, 133, 130, 0.50)",
                      textDecoration: "capitalize",
                      color: "#000",
                      height: "30px",
                      borderRadius: "10px",
                      transition: "background 0.3s ease", // Add a smooth transition for the background change
                      "&:hover": {
                        background: "rgba(246, 133, 130, 0.70)", // Slightly darker color on hover
                      },
                    }}
                  >
                    <Typography sx={{ textTransform: "capitalize" }}>
                      Browse
                    </Typography>
                  </Button>
                </label>
              </Box>
            </Box>

            {/* ------------ Description ----------------- */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                boxSizing: "border-box",
              }}
            >
              <InputLabel
                id="demo-select-small-label"
                sx={{
                  ...Lablestyle,
                  color: formErrors.description ? "red" : "#000",
                }}
              >
                Description
              </InputLabel>
              <TextField
                value={description?.trimStart()}
                onChange={handleChange}
                name="description"
                id="filled-multiline-static"
                sx={{
                  width: "100%",
                  borderRadius: "10px",
                  boxSizing: "border-box",
                  border: "1px solid #F68582",
                  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
                }}
                placeholder="Description"
                //   label="Multiline"
                multiline
                rows={4}
                //   defaultValue="Default Value"
                //   variant="filled"
              />
            </Box>

            {/* ------ Price ---------- */}
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <Typography sx={{ color: formErrors.price ? "red" : "#000" }}>
                Price
              </Typography>

              <OutlinedInput
                sx={{
                  width: "100%",
                  height: "50px",
                  borderRadius: "10px",
                  border: "1px solid #F68582",
                  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                }}
                id="outlined-adornment-weight"
                aria-describedby="outlined-weight-helper-text"
                type="Number"
                value={price}
                onChange={handleChange}
                name="price"
                onKeyDown={(evt) =>
                  ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
                }
                autoComplete="off"
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "40%",
                m: "auto",
                mt: "20px",
              }}
            >
              <ActionButton
                title={"Done"}
                loading={loading}
                onclick={HandeldEbookButton}
              />
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AddGroupeSession;
