import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import TitleText from "../../../../Components/TitleText";
const Resources = () => {
  let commonfont = {
    fontStyle: "Poppins",
    fontSize: "18px",
    fontWeight: "550",
    lineHeight: "100%",
  };

  let links = [
    { id: 1, name: "Audio", path: "/dashboard/resources" },
    { id: 2, name: "Video", path: "/dashboard/resources/video" },
    { id: 3, name: "Blogs", path: "/dashboard/resources/blogs" },
  ];

  const location = useLocation();
  const currentPath = location.pathname;
  let [selectedtab, setselectedtab] = useState(1);
  return (
    <>
      <Box>
        <Box
          sx={{
            display: currentPath.startsWith(
              `/dashboard/resources/blogs/addblogs`
            )
              ? "none"
              : "block",
          }}
        >
          <Box
            sx={{
              mb: "20px",
              mt: "20px",
            }}
          >
            <TitleText title={"Resources"} />
          </Box>
          <Box sx={{ display: "flex", gap: "20px" }}>
            {links?.map((li, i) => (
              <Box>
                <Link to={li.path} style={{ textDecoration: "none" }}>
                  <Typography
                    onClick={() => setselectedtab(i + 1)}
                    sx={{
                      ...commonfont,
                      cursor: "pointer",
                      position: "relative",
                      textDecoration: "none",
                      borderBottom:
                        currentPath == li?.path
                          ? "3px solid #F68582"
                          : "3px solid transparent",
                      color: currentPath == li?.path ? "black" : "gray",
                      "&:hover": {
                        textDecoration: "none", // Remove the default underline
                        borderBottom: "3px solid #F68582", // Add the color as an underline
                        transition: "border-bottom 0.2s ease-in-out", // Add transition for the border-bottom
                      },
                    }}
                  >
                    {li?.name}
                  </Typography>
                </Link>
              </Box>
            ))}
          </Box>
        </Box>

        <Box>
          <Outlet />
        </Box>
      </Box>
    </>
  );
};

export default Resources;
