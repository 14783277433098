import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { modalstyle } from "./PopupsStyling";
import PopupTitle from "./PopupComponent/PopupTitle";
import ActionButton from "./PopupComponent/ActionButton";
import { useDispatch } from "react-redux";
import {
  AddAffermationitem,
  GetAffermationslist,
} from "../../Redux/Slices/ManageContent/ManageContent.action";
import { errortost, sucesstost } from "../Alerts/CustomAlert";

const Add_UpdateAffermation = ({ modalcontrols, row ,rowsPerPage,count}) => {
  let dispatch = useDispatch();
  let { isaffermationopen, closeAffermativepopup } = modalcontrols;
  let [affermationdesc, setaffermationdesc] = useState(
    row !== null ? row?.affirmations : ""
  );
  let [loading, setloading] = useState(false);

  let HandeldAffermationInput = (e) => {
    
    setaffermationdesc(e?.target?.value);
  };

  let popuptitle = row == null ? "Add Affirmation" : "Edit Affirmation";

  let AddUpdateAffermation = () => {
    const trimmedAffermation = affermationdesc.trim();
  
    // Check if the trimmed affirmation is empty
    if (trimmedAffermation === "") {
      // Show an error message or handle the empty input case
      errortost("Please enter an Affirmation!");
      return;
    }
  
    let affermativedata = new FormData();
  
    if (row !== null) {
      affermativedata.append("id", row?.id);
    }
  
    affermativedata.append("affirmations", trimmedAffermation);
    setloading(false);
  
    try {
      dispatch(
        AddAffermationitem({
          affermativedata: affermativedata,
          type: row !== null ? 0 : 1,
        })
      )
        .unwrap()
        .then((result) => {
          if (result?.s === 1) {
            setloading(false);
            sucesstost(`Affirmation ${row !== null ? "Updated" : "Added"}!`);
            dispatch(GetAffermationslist({ count: count,row_count:rowsPerPage }));
            closeAffermativepopup();
          } else {
            setloading(false);
            dispatch(GetAffermationslist({ count: count,row_count:rowsPerPage }));
            errortost("Something went wrong, please try again!");
            closeAffermativepopup();
          }
        });
    } catch (err) {
      errortost("Something went wrong, please try again!");
      closeAffermativepopup();
      dispatch(GetAffermationslist({ count: count,row_count:rowsPerPage}));
    }
  };
  

  return (
    <>
      <Modal open={isaffermationopen} onClose={closeAffermativepopup}>
        <Box
          sx={{
            ...modalstyle,
            borderRadius: "10px",
            width: { xs: "50vw", md: "40vw" },
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <PopupTitle popuptitle={popuptitle} />
          <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <InputLabel
              id="demo-select-small-label"
              sx={{
                fontSize: "15px",
                color: "#000",
                fontWeight: "550",
                // color: formErrors.VideoTitle ? "red" : "#000",
              }}
            >
              Description
            </InputLabel>
            <TextField
              name="Description"
              id="filled-multiline-static"
              sx={{
                width: "100%",
                borderRadius: "10px",
                border: "1px solid #F68582",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
              }}
              placeholder="Enter Affirmations here ..."
              multiline
              rows={4}
              value={affermationdesc?.trimStart()}
              onChange={HandeldAffermationInput}
            />
          </Box>

          <Box sx={{ width: "40%", margin: "auto" }}>
            <ActionButton
              title={row == null ? "Add" : "Update"}
              onclick={AddUpdateAffermation}
              loading={loading}
            />
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Add_UpdateAffermation;
