import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { modalstyle } from "./PopupsStyling";

import { Ebokscoverurl } from "../../Apis/Api";
import { useDispatch } from "react-redux";
import {
  DeleteEbookCategory,
  DeleteEbookitem,
  GetEbooklist,
  Getallcategorie,
} from "../../Redux/Slices/Ebooks/Ebooks.action";
import { errortost, sucesstost } from "../Alerts/CustomAlert";
const DeleteEbook = ({ modalcontrols }) => {
  let { isdeleteEbook, closedeleteebook, deleteselected, ebooktype,rowsPerPage,count } =
    modalcontrols;
  let dispatch = useDispatch();
  let [loading, setloading] = useState(false);
  let deletethisfile = () => {
    let deleteitemdata = new FormData();
    deleteitemdata.append("status", 0);
    deleteitemdata.append("id", deleteselected?.id);
    setloading(true)
    dispatch(
      ebooktype == "ebooklist"
        ? DeleteEbookitem(deleteitemdata)
        : DeleteEbookCategory(deleteitemdata)
    )
      .unwrap()
      .then((result) => {
        setloading(false);
        if (result?.s === 1) {
          sucesstost("Deleted Sucessfully !");
          dispatch(
            ebooktype == "ebooklist" ? GetEbooklist({count:count,row_count:rowsPerPage }) : Getallcategorie()
          );
          closedeleteebook();
        } else {
          errortost("Something wents wrong please try again !");
          setloading(false);
          closedeleteebook();
        }
      })
  }
  
  return (
    <>
      <Modal open={isdeleteEbook} onClose={closedeleteebook}>
        <Box
          sx={{
            ...modalstyle,
            borderRadius: "10px",
            width: { xs: "50vw", md: "350px" },
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Typography
            sx={{
              color: "#000",
              fontFamily: "Poppins",
              display: "flex",
              gap: "5px",
            }}
          >
            Want to Delete this{" "}
            {ebooktype == "ebooklist" ? (
              <Typography> Ebook </Typography>
            ) : (
              <Typography> Ebook category </Typography>
            )}
          </Typography>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: ebooktype == "ebooklist" ? "" : "center",
              gap: "13px",
              padding: "10px",
              background: "rgba(128, 128, 128, 0.15)",
              borderRadius: "10px",
            }}
          >
            {ebooktype == "ebooklist" ? (
              <img
                style={{ width: "50px", height: "50px", borderRadius: "10px" }}
                src={`${Ebokscoverurl}/${deleteselected?.cover_img}`}
                alt="user image"
              />
            ) : (
              ""
            )}

            <Typography sx={{ color: "#000", textAlign: "center" }}>
              {ebooktype == "ebooklist"
                ? deleteselected?.title
                : deleteselected?.cat_name}{" "}
            </Typography>
          </Box>

          <Box sx={{ display: "flex", gap: "10px" }}>
            <Button
              onClick={deletethisfile}
              sx={{
                textTransform: "capitalize",
                mt: "10px",
                width: "100%",
                borderRadius: "14px",
                background: "#FF1607",
                color: "#fff",
                padding: "8px",
                height: "50px",
                "&:hover": {
                  background: "#A30000",
                },
              }}
            >
              {loading ? (
                <CircularProgress sx={{ color: "#fff" }} />
              ) : (
                <Typography>Delete</Typography>
              )}
            </Button>
            <Button
              onClick={() => closedeleteebook()}
              sx={{
                textTransform: "capitalize",
                mt: "10px",
                borderRadius: "14px",
                background: "#E8E8E8",
                width: "100%",
                color: "#000",
                padding: "8px",
                "&:hover": {
                  background: "#B0B0B0",
                },
              }}
            >
              No
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default DeleteEbook;
