import axios from "axios";
import { Baseurl, getUserdata } from "../../../Apis/Api";
import { createAsyncThunk } from "@reduxjs/toolkit";
  
//  -------------  Get Groupe Sessions   -----------

export const GetPrivacydata = createAsyncThunk(
  "Setting/Getprivacydata",
  async (type) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      }
      let data = await axios.get(`${Baseurl}/admin/terms_conditions_privacy_policy_get?type=2`, config);
      return data.data;
    } catch (error) {
      throw error;
    }
  }
)
 
//  -------------  Get Terms and conditons     -----------

export const GetTermsAndConditionata = createAsyncThunk(
  "Setting/GetTermsAndConditionata",
  async () => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      }
      let data = await axios.get(`${Baseurl}/admin/terms_conditions_privacy_policy_get?type=1`, config);
      return data.data;
    } catch (error) {
      throw error;
    }
  }
)

  
  //  -------------  Add Privacy and Terms Condition Data -----------

export const AddPrivacyandTermsConditiondata = createAsyncThunk(
  "Setting/AddGPrivacyandTermsConditiondata",
  async ({Contentdata,type}) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      }
      let data = await axios.post(
        `${Baseurl}/admin/terms_conditions_privacy_policy_add?type=${type}`,
        Contentdata,
        config
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }
)
  
  //  ------------- Update Privacy and Terms Condition Data -----------

export const UpdatePrivacyandTermsConditiondata = createAsyncThunk(
  "Setting/UpdatePrivacyandTermsConditiondata",
  async ({Contentdata,type}) => {
    console.log(Contentdata);
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      }
      let data = await axios.post(
        `${Baseurl}/admin/terms_conditions_privacy_policy_update?type=${type}`,
        Contentdata,
        config
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }
)
 


 
 

 
 