import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { modalstyle } from "./PopupsStyling";
import { useDispatch } from "react-redux";
import { errortost, sucesstost } from "../Alerts/CustomAlert";
import {
  DeletethisAffermation,
  GetAffermationslist,
} from "../../Redux/Slices/ManageContent/ManageContent.action";
const DeleteAffermation = ({ modalcontrols }) => {
  let { isdeleteAffermation, closedeleteAffermation, deletedata,rowsPerPage,count } =
    modalcontrols;
  let [loading, setloading] = useState(false);
  let dispatch = useDispatch();

  let deletethisdata = () => {
    setloading(true)
    let deletethisdata = new FormData();
    deletethisdata.append("id", deletedata?.id);
    deletethisdata.append("status", 0);
    dispatch(DeletethisAffermation(deletethisdata))
      .unwrap()
      .then((result) => {
        if (result?.s === 1) {
          setloading(false);
          sucesstost("Deleted Sucessfully !");
          dispatch(GetAffermationslist({ count:count, row_count:rowsPerPage}));
          closedeleteAffermation()
        } else {

          setloading(false);
          errortost("Something Wents wrong please try again !")
          closedeleteAffermation()
          dispatch(GetAffermationslist({ count:count,row_count:rowsPerPage  }));
        }
      })

  }
 

  return (
    <>
      <Modal open={isdeleteAffermation} onClose={closedeleteAffermation}>
        <Box
          sx={{
            ...modalstyle,
            borderRadius: "10px",
            width: { xs: "50vw", md: "350px" },
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Typography
            sx={{
              color: "#000",
              fontFamily: "Poppins",
              display: "flex",
              gap: "5px",
            }}
          >
            Want to Delete this Affermation ?
          </Typography>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "13px",
              padding: "10px",
              background: "rgba(128, 128, 128, 0.15)",
              borderRadius: "10px",
            }}
          >
            <Typography
              noWrap
              sx={{
                color: "#000",
                textAlign: "center",
                width: "100%",
                overflow: "hidden",
              }}
            >
              {deletedata?.affirmations}
            </Typography>
          </Box>

          <Box sx={{ display: "flex", gap: "10px" }}>
            <Button
              onClick={deletethisdata}
              sx={{
                textTransform: "capitalize",
                mt: "10px",
                width: "100%",
                borderRadius: "14px",
                background: "#FF1607",
                color: "#fff",
                padding: "8px",
                height: "50px",
                "&:hover": {
                  background: "#A30000",
                },
              }}
            >
              {loading ? (
                <CircularProgress sx={{ color: "#fff" }} />
              ) : (
                <Typography>Delete</Typography>
              )}
            </Button>
            <Button
              onClick={() => closedeleteAffermation()}
              sx={{
                textTransform: "capitalize",
                mt: "10px",
                borderRadius: "14px",
                background: "#E8E8E8",
                width: "100%",
                color: "#000",
                padding: "8px",
                "&:hover": {
                  background: "#B0B0B0",
                },
              }}
            >
              No
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default DeleteAffermation;
