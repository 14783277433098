import React, { useEffect, useState } from "react";
import TitleText from "../../../Components/TitleText";
import { Box, InputLabel, OutlinedInput, Typography } from "@mui/material";
import ActionButton from "../../../Components/Popups/PopupComponent/ActionButton";
import AffirmationsTable from "../../../Components/Popups/AffirmationsTable";
import { useDispatch, useSelector } from "react-redux";
import {
  AddUpdateMantra,
  GetMantra,
} from "../../../Redux/Slices/ManageContent/ManageContent.action";
import TostContainer from "../../../Components/Alerts/TostContainer";
import { errortost, sucesstost } from "../../../Components/Alerts/CustomAlert";

const ContentManagment = () => {
  let MntraData = useSelector((store) => store?.ManageContent);
  let [mantra, setmantra] = useState("");
  let [isAvilable, setisAvilable] = useState(true);
  let disptach = useDispatch();
  let [loading, setloading] = useState(false);
  useEffect(() => {
    disptach(GetMantra());
  }, []);

  useEffect(() => {
    if (MntraData.Mantra) {
      setisAvilable(true);
      setmantra(MntraData?.Mantra?.mantra);
    } else {
      setisAvilable(false);
    }
  }, [MntraData]);


 
  let handeldMantraChange = (e) => {


    setmantra(e.target.value);


  };

  let addMyNewMantra = () => {

    const trimmedMantra = mantra?.trim();

    // Check if the trimmed mantra is empty
    if (trimmedMantra === "") {
      // Show an error message or handle the empty input case
      errortost("Please enter a mantra!");
      return;
    }

    let formData = new FormData();
    formData.append("mantra", mantra);
    if (isAvilable) {
      formData.append("id", MntraData?.Mantra?.id);
    }

    let actionType = isAvilable ? "edit" : "add";

    setloading(true)

    disptach(AddUpdateMantra({ mantra: formData, actionType: actionType }))
      .unwrap()
      .then((result) => {
        if (result?.s === 1) {
          setloading(false)
          sucesstost("Mantra Updated Success !")
          disptach(GetMantra())
        } else {
          setloading(false);
          errortost("Something Wents Wrong ! ")
        }
      })
  }


  return (
    <>





      <TostContainer />


      <Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <TitleText title={"Manage Content"} />
        <Box
          sx={{
            padding: "25px",
            boxShadow: "0px 0px 25px 0px rgba(0, 0, 0, 0.25)",
            borderRadius: "30px",
          }}
        >
          <Typography
            sx={{ fontWeight: "600", fontFamily: "Poppins", fontSize: "20px" }}
          >
            Mantra
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "90%",
            }}
          >
            <InputLabel
              id="demo-select-small-label"
              sx={{
                fontSize: "16px",
                mt: "10px",
                fontFamily: "Poppins",
                // color: formErrors.audioTitle ? "red" : "#000",
              }}
            >
              Enter Your Mantra
            </InputLabel>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                width: "80%",
              }}
            >
              <OutlinedInput
                sx={{
                  width: "100%",
                  height: "50px",
                  borderRadius: "10px",
                  border: "1px solid #F68582",
                  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
                }}
                id="outlined-adornment-weight"
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
                value={mantra?.trimStart()}
                onChange={handeldMantraChange}
                name="audioTitle"
              />
              <Box sx={{ width: "30%" }}>
                <ActionButton
                  onclick={addMyNewMantra}
                  title={isAvilable ? "Update" : "Add"}
                  loading={loading}
                />
              </Box>
            </Box>
          </Box>
        </Box>

        <AffirmationsTable />
      </Box>
    </>
  );
};

export default ContentManagment;
