import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../Pages/AuthPages/Login";
import ForgotPassword from "../Pages/AuthPages/ForgotPassword";
import Dashboard from "../Pages/DashboardPages/Dashboard";
import Dashboardpage from "../Pages/DashboardPages/Pages/Dashboardpage";
import ContentManagment from "../Pages/DashboardPages/Pages/ContentManagment";
import Ebooks from "../Pages/DashboardPages/Pages/EbooksPages/Ebooks";
import Resources from "../Pages/DashboardPages/Pages/Resourcespage/Resources";
import Setting from "../Pages/DashboardPages/Pages/Settings/Setting";
import UsersMangament from "../Pages/DashboardPages/Pages/UsersMangament";
import Notification from "../Pages/DashboardPages/Pages/Notification";
import UserDetails from "../Pages/DashboardPages/Pages/UserDetails/UserDetails";
import Audiopage from "../Pages/DashboardPages/Pages/Resourcespage/Audiopage";
import Videopage from "../Pages/DashboardPages/Pages/Resourcespage/Videopage";
import Blogspage from "../Pages/DashboardPages/Pages/Resourcespage/Blogspage";
import AddBlogs from "../Pages/DashboardPages/Pages/Resourcespage/AddBlogs";
import EbookList from "../Pages/DashboardPages/Pages/EbooksPages/EbookList";
import EbooksCategories from "../Pages/DashboardPages/Pages/EbooksPages/EbooksCategories";
import IndividualSession from "../Pages/DashboardPages/Pages/UserDetails/IndividualSession";
import GroupeSession from "../Pages/DashboardPages/Pages/UserDetails/GroupeSession";
import EbooksSession from "../Pages/DashboardPages/Pages/UserDetails/EbooksSession";
import Schedules from "../Pages/DashboardPages/Pages/SchedulesPages/Sedules";
import Individual_Schedules from "../Pages/DashboardPages/Pages/SchedulesPages/Individual_Schedules";
import Group_Schedules from "../Pages/DashboardPages/Pages/SchedulesPages/Group_Schedules";
import Avilability from "../Pages/DashboardPages/Pages/Avilability/Avilability";
import TermsAndConditions from "../Pages/DashboardPages/Pages/Settings/TermsAndConditions";
import PrivacyPolicy from "../Pages/DashboardPages/Pages/Settings/PrivacyPolicy";
import Coaches from "../Pages/DashboardPages/Pages/Coaches/Coaches";
import AddNewCoach from "../Pages/DashboardPages/Pages/Coaches/AddNewCoach";
import ViewCoachDetails from "../Pages/DashboardPages/Pages/Coaches/ViewCoachDetails";
import CoachDataListing from "../Pages/DashboardPages/Pages/Coaches/CoachListing";
import AddCoachAvilability from "../Pages/DashboardPages/Pages/Avilability/AddCoachAvilability";
import AvilabilityDataListing from "../Pages/DashboardPages/Pages/Avilability/AvilabilityDataListing";
import IndividualAvilabilityAdd from "../Pages/DashboardPages/Pages/Avilability/IndividualAvilabilityAdd";
import GroupAvilabilityAdd from "../Pages/DashboardPages/Pages/Avilability/GroupAvilabilityAdd";
import ViewAvilabilityDetails from "../Pages/DashboardPages/Pages/Avilability/ViewAvilabilityDetails";
import EditCoachDetails from "../Pages/DashboardPages/Pages/Coaches/EditCoachDetails";
import MainViewDetailsPage from "../Pages/DashboardPages/Pages/Coaches/MainViewDetailsPage";
import IndividualDatas from "../Pages/DashboardPages/Pages/SchedulesPages/IndividualDatas";
import ViewIndividualDetails from "../Pages/DashboardPages/Pages/SchedulesPages/ViewIndividualDetails";
import ViewGroupSessionDetails from "../Pages/DashboardPages/Pages/SchedulesPages/ViewGroupSessionDetails";
import GroupSessionDatas from "../Pages/DashboardPages/Pages/SchedulesPages/GroupDatas";
import EditAvilabilityDetails from "../Pages/DashboardPages/Pages/Avilability/EditAvilabilityDetails";
import ViewSessionAvilabilityDetails from "../Pages/DashboardPages/Pages/Avilability/ViewSessionAvilabilityDetails";
import EditIndividualDetails from "../Pages/DashboardPages/Pages/Avilability/EditIndividualDetails";

const CombineRouting = () => {
  const myRoutes = [
    {
      path: "/",
      element: <Login />,
      childRoutes: [],
    },
    {
      path: "/forgotpassword",
      element: <ForgotPassword />,
      childRoutes: [],
    },
    {
      path: "/dashboard",
      element: <Dashboard />,
      type: 1,
      childRoutes: [
        { path: "", element: <Dashboardpage /> },
        { path: "users", element: <UsersMangament /> },
        {
          path: "user/:userId",
          element: <UserDetails />,
          childRoutes: [
            { path: "", element: <IndividualSession /> },
            {
              path: "groupesession",
              element: <GroupeSession />,
            },
            { path: "ebooksession", element: <EbooksSession /> },
          ],
        },
        { path: "managecontent", element: <ContentManagment /> },
        {
          path: "ebooks",
          element: <Ebooks />,
          childRoutes: [
            { path: "", element: <EbookList /> },
            { path: "ebookscategories", element: <EbooksCategories /> },
          ],
        },
        {
          path: "schedules",
          element: <Schedules />,
          childRoutes: [
            {
              path: "",
              element: <IndividualDatas />,

              childRoutes: [
                { path: "", element: <Individual_Schedules /> },
                {
                  path: "view_individual_details",
                  element: <ViewIndividualDetails />,
                },
              ],
            },
            {
              path: "groupsession",
              element: <GroupSessionDatas />,

              childRoutes: [
                { path: "", element: <Group_Schedules /> },
                {
                  path: "view_group_details",
                  element: <ViewGroupSessionDetails />,
                },
              ],
            },
          ],
        },
        {
          path: "avilability",
          element: <Avilability />,
          childRoutes: [
            { path: "", element: <AvilabilityDataListing /> },
            {
              path: "view_avilability_Details",
              element: <ViewSessionAvilabilityDetails />,
              childRoutes: [
                { path: "", element: <ViewAvilabilityDetails /> },
                { path: "update1_details", element: <EditIndividualDetails /> },
                { path: "update_details", element: <EditAvilabilityDetails /> },
              ],
            },

            {
              path: "add_avilability/",
              element: <AddCoachAvilability />,

              childRoutes: [
                { path: "", element: <IndividualAvilabilityAdd /> },
                {
                  path: "group_avilability",
                  element: <GroupAvilabilityAdd />,
                },
              ],
            },
          ],
        },

        {
          path: "coaches",
          element: <Coaches />,
          childRoutes: [
            { path: "", element: <CoachDataListing /> },
            { path: "add_coach", element: <AddNewCoach /> },
            {
              path: "view_Coach",
              element: <MainViewDetailsPage />,
              childRoutes: [
                { path: "", element: <ViewCoachDetails /> },
                { path: "edit_details", element: <EditCoachDetails /> },
              ],
            },
          ],
        },
        {
          path: "resources/*",
          element: <Resources />,
          childRoutes: [
            { path: "", element: <Audiopage /> },
            { path: "video", element: <Videopage /> },
            { path: "blogs", element: <Blogspage /> },
            { path: "blogs/addblogs", element: <AddBlogs /> },
          ],
        },
        {
          path: "setting",
          element: <Setting />,
          childRoutes: [
            { path: "", element: <TermsAndConditions /> },
            { path: "privacypolicy", element: <PrivacyPolicy /> },
          ],
        },
        { path: "notifications", element: <Notification /> },
      ],
    },
  ];

  return (
    <Routes>
      {myRoutes.map((route, index) => (
        <Route key={index} path={route.path} element={route.element}>
          {route.childRoutes.map((firstchildRoute, childIndex) => (
            <Route
              key={childIndex}
              path={firstchildRoute.path}
              element={firstchildRoute.element}
            >
              {firstchildRoute.childRoutes?.map(
                (secondchildRoute, secondChildIndex) => (
                  <Route
                    key={secondChildIndex}
                    path={secondchildRoute.path}
                    element={secondchildRoute.element}
                  >
                    {secondchildRoute.childRoutes?.map(
                      (thirdchildRoute, thirdChildIndex) => (
                        <Route
                          key={thirdChildIndex}
                          path={thirdchildRoute.path}
                          element={thirdchildRoute.element}
                        >
                          {thirdchildRoute.childRoutes?.map(
                            (fourthchildRoute, fourthChildIndex) => (
                              <Route
                                key={fourthChildIndex}
                                path={fourthchildRoute.path}
                                element={fourthchildRoute.element}
                              />
                            )
                          )}
                        </Route>
                      )
                    )}
                  </Route>
                )
              )}
            </Route>
          ))}
        </Route>
      ))}
    </Routes>
  );
};

export default CombineRouting;
