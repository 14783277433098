import {
  Box,
  Button,
  CircularProgress,
  OutlinedInput,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import logoimg from "../../Utils/Logos/mindfulnesslogo.PNG";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import { Baseurl } from "../../Apis/Api";
const ForgotPassword = () => {
  let [loading, setloading] = useState(false);
  const [errors, setErrors] = useState({});
  const validationSchema = yup.object().shape({
    email: yup.string().email("Invalid email").required("Email is required"),
  });
  const [formData, setFormData] = useState({
    email: "",
  });
  let navigate = useNavigate();
  let forgotpassword = () => {
    validationSchema
      .validate(formData, { abortEarly: false })
      .then(async () => {
        setloading(true);
        try {
          let data = await axios.post(`${Baseurl}/auth/forgot-password`, {
            ...formData,
          })
          if (data.data) {
            if (data.data.s == 0) {
              Swal.fire({
                position: "bottom-center",
                icon: "error",
                title: "Oops...",
                text: data?.data?.m,
              });
            } else if (data.data.s == 1) {
              Swal.fire({
                position: "top-center",
                icon: "success",
                text: data?.data?.m + data?.data?.r,
                showConfirmButton: false,
                title: "Success",
                timer: 1500,
                customClass: {
                  popup: "your-custom-popup-class", // Add a custom class for the popup
                },
              })

              setTimeout(() => {
                navigate("/");
              }, 1000);
            } else {
              Swal.fire({
                position: "bottom-center",
                icon: "error",
                title: "Oops...",
                text: "Something wents wrong please try again later !",
              });

              navigate("/");
            }
            setloading(false);
          }
        } catch (error) {
          setloading(false);
        }
        setErrors("");
      })
      .catch((err) => {
        const validationErrors = {};

        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        setErrors(validationErrors);
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <>
      <Box
        sx={{
          background:
            "linear-gradient(180deg, #EFBABD 0%, #D0D1D2 48.42%, #B6E4E4 100%);",
          height: "100vh",
          width: "100vw",
          display: "flex",
          fontFamily: "Poppins",
        }}
      >
        <Box
          sx={{
            padding: "50px",
            background: "rgba(255, 255, 255, 0.50)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            color: "#000",
            borderRadius: "30px",
            width: "40%",
            boxSizing: "border-box",
            m: "auto",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "15px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                fontFamily: "Poppins",
                gap: "10px",
              }}
            >
              <Box sx={{ width: "200px", height: "200px" }}>
                <img
                  src={logoimg}
                  style={{ width: "100%", height: "100%" }}
                  alt="logoimg"
                />
              </Box>

              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "35px",
                  fontWeight: "700",
                }}
              >
                Forgot Password?
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontWeight: "500",
                }}
              >
                Get Reset Password Link
              </Typography>
            </Box>

            {/* <IntroTitle
                title={"Welcome Back, Admin"}
                desc={"Enter Your Username/Email & Password to Login "}
              /> */}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "15px",
              }}
            >
              <Box>
                <OutlinedInput
                  id={"vhgbftbnfkgsvf"}
                  name="email"
                  aria-describedby="outlined-weight-helper-text"
                  placeholder={"Email"}
                  //  type={"password"}
                  value={formData.email}
                  onChange={handleInputChange}
                  sx={{
                    width: "100%",
                    color: "black",
                    fontSize: "20px",
                    borderRadius: "8px",
                    fontFamily: "Montserrat",
                    borderColor: "  #DADADA",
                    outline: "none",
                    "& .MuiFilledInput-root": {
                      background: "var(--white-100, #FFF)",
                      outline: "none",
                    },
                    "&:hover": {
                      "& fieldset": {
                        outline: "none",
                        // background: "var(--white-100, #FFF)",
                      },
                      "& .MuiInputBase-input": {
                        color: "black", // Change the color to black on hover
                      },
                    },
                    "& fieldset": { border: "none" },
                    width: "100%",
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        display: "none",
                      },
                    "& .MuiInputBase-input": {
                      color: "black",
                      fontSize: { xs: "13px", md: "16px" },
                      fontWeight: 400,
                      lineHeight: "24px",
                      border: "2px solid var(--Button-Color, #F68582)",
                      padding: "10px 10px",
                      background: "var(--white-100, #FFF)",
                      outline: "none",
                      borderRadius: "10px",
                      "&::placeholder": {
                        color: "rgba(41, 45, 50, 0.50)",
                        background: "var(--white-100, #FFF)",
                        opacity: 0.5,
                        fontSize: { xs: "13px", md: "16px" },
                        fontWeight: 400,
                        lineHeight: "10px",
                        outline: "none",
                      },
                    },
                    "&:focus": {
                      outline: "none",
                      boxShadow: "none",
                      color: "rgba(41, 45, 50, 0.50)",
                    },
                    "&.Mui-focused fieldset": {
                      //   borderColor: "#FFFF",
                      outline: "none",
                      border: "solid red",
                    },
                  }}
                  inputProps={{ autoComplete: "off" }}
                />

                {errors.email && (
                  <Typography sx={{ color: "red", fontSize: "12px" }}>
                    {errors.email}
                  </Typography>
                )}
              </Box>
            </Box>

            <Button
              onClick={forgotpassword}
              sx={{
                background: "linear-gradient(180deg, #FFCE98 0%, #F4AA50 100%)",
                textTransform: "capitalize",
                color: "#292D32",
                width: "100%",
                padding: "15px",
                height: "55px",
                fontFamily: "Montserrat",
                borderRadius: "15px",
                boxShadow:
                  " 2px 2px 8px 0px rgba(143, 95, 11, 0.50), 0px 3px 11.111px 0px #FBB15A inset, 0px -11px 11.111px 0px #D68835 inset;",
                "&:hover": {
                  backgroundColor: "#252422", // Change the background color on hover
                },
              }}
            >
              {loading ? (
                <CircularProgress sx={{ color: "#fff" }} />
              ) : (
                <Typography>Reset</Typography>
              )}
            </Button>

            <Link style={{ textDecoration: "none", color: "#000" }} to={"/"}>
              <Typography
                sx={{
               fontSize: "16px",
                  fontWeight: "600",
                  fontFamily: "Poppins",
                  // mb: "5px",
                  textAlign: "left",
                }}
              >
                Back to Login?
              </Typography>
            </Link>
          </Box>
        </Box>
      </Box>
      {/* </Box>    */}
    </>
  );
};

export default ForgotPassword;
