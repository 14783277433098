import axios from "axios";
import { Baseurl, getUserdata } from "../../../Apis/Api";
import { createAsyncThunk } from "@reduxjs/toolkit";
 
//  -------------  Get Dashboard Data   -----------
export const GetDashAnalyticsdata = createAsyncThunk(
    "DashboardData/getanyalitics",
    async (type) => {
      try {
        let { apikey, token } = getUserdata;
        const config = {
          headers: {
            apikey,
            token,
          },
        }
        const typeParam = type ? `?type=${type}` : "";
        let data = await axios.get(`${Baseurl}/admin/analytics_get${typeParam}`, config);
        return data.data;
      } catch (error) {
        throw error;
      }
    }
  )
//  -------------  Get Dashboard Data   -----------
export const GetRecentIndividualSessions = createAsyncThunk(
    "DashboardData/getrecentindividualsessions",
    async ({count,row_count}) => {
      try {
        let { apikey, token } = getUserdata;
        const config = {
          headers: {
            apikey,
            token,
          },
        }
        let data = await axios.get(`${Baseurl}/admin/recent_individuals_sessions?count=${count}`, config);
        return data.data;
      } catch (error) {
        throw error;
      }
    }
  )


   
//  -------------  Get Dashboard Data   -----------
export const GetDashGraphData = createAsyncThunk(
  "DashboardData/getdashgraphdata",
  async () => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      }
      let data = await axios.get(`${Baseurl}/admin/total_earning_graph_get`, config);
      return data.data;
    } catch (error) {
      throw error;
    }
  }
)

 

// ----------------------- Notifications -------------------

export const AddNotification = createAsyncThunk(
  "DashboardData/addnotification",
  async (notification) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      }

      let data = await axios.post(`${Baseurl}/notification/send_notification`,notification, config);
      return data.data;
    } catch (error) {
      throw error;
    }
  }
)

 
// ----------------------- Edit Notifications -------------------

export const EditNotification = createAsyncThunk(
  "DashboardData/editnotification",
  async (notification) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      }

      let data = await axios.post(`${Baseurl}/notification/notification_schedule_edit`,notification, config);
      return data.data;
    } catch (error) {
      throw error;
    }
  }
)

 
//  -------------   Get Notifications Data   -----------
export const GetNotifications = createAsyncThunk(
"DashboardData/getnotification",
async () => {
  try {
    let { apikey, token } = getUserdata;
    const config = {
      headers: {
        apikey,
        token,
      },
    }
    let data = await axios.get(`${Baseurl}/notification/schedule_notification_get`, config);
    return data.data;
  } catch (error) {
    throw error;
  }
}
)


