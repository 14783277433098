import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";
import { modalstyle } from "./PopupsStyling";
import { imagebaseurl } from "../../Apis/Api";
import { useDispatch, useSelector } from "react-redux";
 
import { Navigate, useNavigate } from "react-router-dom";
import { Delete_Block, getuserbyid } from "../../Redux/Slices/Users/User.action";
const Deleteuser = ({ modalcontrols }) => {

  let { isdeleteu, closedeleteu, data } = modalcontrols;

  let storeData = useSelector((Store) => Store?.Users);

  let dispatch = useDispatch();
  let actiondata = {
    id: data?.userbyid?.id,
    status: 0,
  }

  let navigate = useNavigate();
  let handleDeleteUser = () => {
    dispatch(Delete_Block(actiondata));
    dispatch(getuserbyid(data?.userbyid?.id));
    if (!storeData?.loading) {
      closedeleteu();
      navigate("/dashboard/users");
    }
  }
 
  return (
    <>
      <Modal open={isdeleteu} onClose={closedeleteu}>
        <Box
          sx={{
            ...modalstyle,
            borderRadius: "10px",
            width: { xs: "50vw", md: "350px" },
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Typography sx={{ color: "#000", fontFamily: "Poppins" }}>
            Want to Delete this user ?
          </Typography>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "13px",
              padding: "10px",
              background: "rgba(128, 128, 128, 0.15)",
              borderRadius: "10px",
            }}
          >
            <img
              style={{ width: "50px", height: "50px", borderRadius: "10px" }}
              src={`${imagebaseurl}/${data?.userbyid?.profile_img}`}
              alt="user image"
            />
            <Typography sx={{ color: "#000" }}>
              {data?.userbyid?.full_name}{" "}
            </Typography>
          </Box>

          <Box sx={{ display: "flex", gap: "10px" }}>
            <Button
              onClick={handleDeleteUser}
              sx={{
                textTransform: "capitalize",
                mt: "10px",
                width: "100%",
                borderRadius: "14px",
                background: "#FF1607",
                color: "#fff",
                padding: "8px",
                "&:hover": {
                  background: "#A30000",
                },
              }}
            >
              Delete
            </Button>
            <Button
              onClick={() => closedeleteu()}
              sx={{
                textTransform: "capitalize",
                mt: "10px",
                borderRadius: "14px",
                background: "#E8E8E8",
                width: "100%",
                color: "#000",
                padding: "8px",
                "&:hover": {
                  background: "#B0B0B0",
                },
              }}
            >
              No
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Deleteuser;
