import {
  Box,
  Button,
  CircularProgress,
  Input,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import ActionButton from "../../../../Components/Popups/PopupComponent/ActionButton";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  AddSelectedCoachAvilability,
  GetCoachSessionPriceGroup,
  GetCoachesListByDateandTime,
} from "../../../../Redux/Slices/Sessions/Session.action";
import { Coach_imageUrl } from "../../../../Apis/Api";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ClearIcon from "@mui/icons-material/Clear";
import toast, { toastConfig } from "react-simple-toasts";
import "react-simple-toasts/dist/theme/dark.css";
import { useNavigate } from "react-router-dom";
let inputstyle = {
  width: "100%",
  height: "50px",
  borderRadius: "10px",
  border: "1px solid #F68582",
  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
};

let Inputboxstyle = {
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  gap: "10px",
};

let Lablestyle = {
  fontSize: "15px",
  mt: "10px",
  fontWeight: "500",
};
const IndividualAvailabilityAdd = () => {
  const [isGetCoachlistTimeSelected, setisGetCoachlistTimeSelected] =
    useState(false);
  const [selectedCoaches, setSelectedCoaches] = useState([]);
  const [link, setAddlink] = useState("");
  const [price, setPrice] = useState(0);
  const [title, setTitle] = useState("");
  const [description, setdescription] = useState("");
  const [cover_img, setcover_img] = useState(null);
  let [CoachesList, setCoacheslist] = useState([]);
  console.log(selectedCoaches + "Selected Coaches ");
  const [formErrors, setFormErrors] = useState({
    title: "",
    description: "",
    cover_img: "",
  });
  const initialFormState = {
    date: "",
    start_time: null,
    end_time: null,
  };
  const [formData, setFormData] = useState({ ...initialFormState });
  const [loading, setloading] = useState(false);
  const [validation, setValidation] = useState({
    date: false,
    start_time: true,
    end_time: true,
  });
  let dispatch = useDispatch();
  const handleDateChange = (event) => {
    if (isGetCoachlistTimeSelected) {
      setisGetCoachlistTimeSelected(false);
      setCoacheslist([]);
      setSelectedCoaches([]);
      return;
    }
    if (event && event.target) {
      const selectedDate = event.target.value;
      setFormData({ ...formData, date: selectedDate });
      setValidation({ ...validation, date: true });
    } else {
      console.error("Invalid event object:", event);
    }
  };
  let [istimeavilable, setistimeavilable] = useState(false);
  let navigate = useNavigate();
  const handlestart_timeChange = (time) => {
    setFormData({ ...formData, start_time: time });
    if (!formData.date) {
      setValidation({ ...validation, date: false });
      setValidationErors({
        ...validationErrors,
        date: "Please select a date before choosing the time",
      });
      return;
    }
    const startTime = moment(time, "HH:mm:ss");
    const endTime = moment(formData.end_time, "HH:mm:ss");
    if (endTime.isValid() && endTime.isBefore(startTime)) {
      setistimeavilable(true);
      setValidation({ ...validation, end_time: false });
      setValidationErors({
        ...validationErrors,
        end_time: "End time must be after start time.",
      });
      return;
    }
    setValidation({ ...validation, start_time: true });
    setValidationErors({ ...validationErrors, start_time: "" });
  };

  const handleend_timeChange = (time) => {
    setFormData({ ...formData, end_time: time });
    if (!formData.date) {
      setValidation({ ...validation, date: false });
      setValidationErors({
        ...validationErrors,
        date: "Please select a date before choosing the time",
      });
      return;
    }
    const startTime = moment(formData.start_time, "HH:mm:ss");
    const endTime = moment(time, "HH:mm:ss");
    if (startTime.isValid() && endTime.isBefore(startTime)) {
      setistimeavilable(true);
      setValidation({ ...validation, end_time: false });
      setValidationErors({
        ...validationErrors,
        end_time: "End time must be after start time.",
      });
      return;
    }
    setValidation({ ...validation, end_time: true });
    setValidationErors({ ...validationErrors, end_time: "" });
  };

  const [validationErrors, setValidationErors] = useState({
    date: "",
    start_time: "",
    end_time: "",
  });

  const handleActionClick = () => {
    const isFormValid =
      formData.date &&
      formData.start_time &&
      formData.end_time &&
      validation.date &&
      validation.start_time &&
      validation.end_time;

    if (!isFormValid) {
      setValidation({
        date: !!formData.date,
        start_time: !!formData.start_time,
        end_time: !!formData.end_time,
      });

      // Show error toast for missing fields
      if (!formData.date && !formData.start_time && !formData.end_time) {
        toast("Please fill all the fields");
        return;
      }
      if (!formData.start_time && !formData.end_time) {
        toast("Please select a start and end time");
      }
      return; // Exit early if form is not valid
    } else {
      let collecteddata = new FormData();
      collecteddata.append("date", formData.date);
      collecteddata.append(
        "start_time",
        moment.utc(formData.start_time).format("HH:mm:ss")
      );
      collecteddata.append(
        "end_time",
        moment.utc(formData.end_time).format("HH:mm:ss")
      );
      setloading(true);
      if (!isGetCoachlistTimeSelected) {
        // ------------ Get Coaches List ---------------
        dispatch(
          GetCoachesListByDateandTime({
            date: formData.date,
            start_time: moment.utc(formData.start_time).format("HH:mm:ss"),
            end_time: moment.utc(formData.end_time).format("HH:mm:ss"),
          })
        )
          .unwrap()
          .then((result) => {
            if (result.s === 1) {
              setloading(false);
              setisGetCoachlistTimeSelected(true);
              setCoacheslist(result?.r);
              setFormData({
                ...formData,
                start_time: null,
                end_time: null,
              });
              setValidation({
                ...validation,
                start_time: false,
                end_time: false,
              });
              setValidationErors({
                ...validationErrors,
                start_time: "",
                end_time: "",
              });
            } else {
              // Handle the case where fetching the coaches list fails
              setloading(false);
            }
          });
        return;
      } else {
        // Handle other cases if necessary
      }
    }
  };
  const SelectThisCoach = (data) => {
    const isCoachSelected = selectedCoaches.some(
      (coach) => coach.id === data.id
    );
    if (isCoachSelected) {
      toast("This coach is already selected");
    } else {
      setSelectedCoaches((prevSelectedCoaches) => {
        const updatedCoaches = [...prevSelectedCoaches, data];
        GetPriceSubtotal(updatedCoaches);
        return updatedCoaches;
      });
    }
  };

  const RemoveThisCoachFromList = (data) => {
    setSelectedCoaches((prevSelectedCoaches) => {
      const updatedCoaches = prevSelectedCoaches.filter(
        (coach) => coach.id !== data.id
      );
      GetPriceSubtotal(updatedCoaches);
      return updatedCoaches;
    });
  };

  toastConfig({
    theme: "dark",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormErrors({ ...formErrors, [name]: false });
    if (name === "description") {
      setdescription(value);
    } else if (name === "title") {
      setTitle(value);
    }
  };
  const validateForm = () => {
    const titleValue = title || "";
    const descriptionValue = description || "";
    const coverImgValue = cover_img || "";
    const errors = {
      title: titleValue === "" ? "Author name is required." : "",
      description: descriptionValue === "" ? "Description is required." : "",
      cover_img: coverImgValue === "" ? "Cover image is required." : "",
    };
    setFormErrors(errors);
    return Object.values(errors).every((error) => error === "");
  };
  const handleFileChange = (event, fileType) => {
    const file = event.target.files[0];
    if (!file) {
      console.log(`Please select a file for ${fileType}`);
      toast(`Please select a file for ${fileType}`);
      setFormErrors({ ...formErrors, [fileType]: true });
      return;
    }
    if (fileType === "image" && !file.type.startsWith("image/")) {
      toast("Please select a valid image file");
      setFormErrors({ ...formErrors, [fileType]: true });
      return;
    }
    setFormErrors({ ...formErrors, [fileType]: false });
    if (fileType === "image") {
      setcover_img(file);
    }
  };
  let [addDataLoading, setaddDataLoading] = useState(false);

  let addGroupeCoachAvilability = () => {
    const isValid = validateForm();
    const isFormValid =
      formData.date &&
      formData.start_time &&
      formData.end_time &&
      validation.date &&
      validation.start_time &&
      validation.end_time;
    if (!isFormValid) {
      setValidation({
        date: !!formData.date,
        start_time: !!formData.start_time,
        end_time: !!formData.end_time,
      });
      if (!formData.date || !formData.start_time || !formData.end_time) {
        toast("Please fill all the required fields !");
        return;
      }
    } else if (!isValid) {
      setaddDataLoading(false);
      toast("Please fill in all the required fields !");
    } else {
      let collecteddata = new FormData();
      collecteddata.append("date", formData.date);
      collecteddata.append(
        "start_time",
        moment.utc(formData.start_time).format("HH:mm:ss")
      );
      collecteddata.append(
        "end_time",
        moment.utc(formData.end_time).format("HH:mm:ss")
      );
      if (selectedCoaches.length < 1) {
        toast("please select Coach");
        return;
      }
      const coachIds = selectedCoaches.map((coach) => coach.id).join(",");
      collecteddata.append("coach_id", coachIds);
      collecteddata.append("type", 2);
      collecteddata.append("title", title);
      collecteddata.append("cover_img", cover_img);
      collecteddata.append("price", price);
      collecteddata.append("description", description);
      dispatch(AddSelectedCoachAvilability(collecteddata))
        .unwrap()
        .then((result) => {
          if (result.s === 1) {
            setaddDataLoading(false);
            setisGetCoachlistTimeSelected(false);
            toast("Avilability Added successfully !");
            setFormData({
              ...formData,
              date: null,
              start_time: null,
              end_time: null,
            });
            setValidation({
              ...validation,
              date: false,
              start_time: false,
              end_time: false,
            });
            setValidationErors({
              ...validationErrors,
              date: "",
              start_time: "",
              end_time: "",
            });
            setisGetCoachlistTimeSelected(false);
            setCoacheslist([]);
            setSelectedCoaches([]);
            navigate("/dashboard/avilability");
          } else {
            setaddDataLoading(false);
          }
        });
    }
  };
  let handelPrice = (e) => {
    setPrice(e.target.value);
  };

  let [checkcoachesloading, setcheckcoachesloading] = useState(false);
  let checkAvilableCoaches = () => {
    const isFormValid =
      formData.date &&
      formData.start_time &&
      formData.end_time &&
      validation.date &&
      validation.start_time &&
      validation.end_time;
    if (!isFormValid) {
      setValidation({
        date: !!formData.date,
        start_time: !!formData.start_time,
        end_time: !!formData.end_time,
      });
      if (!formData.date && !formData.start_time && !formData.end_time) {
        toast("Please fill all the fields");
        return;
      }
      if (!formData.start_time && !formData.end_time) {
        toast("Please select a start and end time");
      }
      return;
    } else {
      let collecteddata = new FormData();
      collecteddata.append("date", formData.date);
      collecteddata.append(
        "start_time",
        moment.utc(formData.start_time).format("HH:mm:ss")
      );
      collecteddata.append(
        "end_time",
        moment.utc(formData.end_time).format("HH:mm:ss")
      );
      setcheckcoachesloading(true);
      dispatch(
        GetCoachesListByDateandTime({
          date: formData.date,
          start_time: moment.utc(formData.start_time).format("HH:mm:ss"),
          end_time: moment.utc(formData.end_time).format("HH:mm:ss"),
        })
      )
        .unwrap()
        .then((result) => {
          if (result.s === 1) {
            setcheckcoachesloading(false);
            if (result?.r?.length >= 1) {
              console.log(result?.r);
              setisGetCoachlistTimeSelected(true);
              setCoacheslist(result?.r);
            } else {
              toast("No Coachs Avilable at this time !");
              setcheckcoachesloading(false);
              setisGetCoachlistTimeSelected(false);
              setCoacheslist([]);
              setSelectedCoaches([]);
            }
          } else {
            setcheckcoachesloading(false);
          }
        });
      return;
    }
  };

  let GetPriceSubtotal = (updatedCoaches) => {
    let pricedata = new FormData();
    if (updatedCoaches?.length >= 1) {
      const coachIds = updatedCoaches?.map((coach) => coach?.id).join(",");
      pricedata.append("coach_ids", coachIds);
      pricedata.append("date", formData?.date);
      pricedata.append(
        "start_time",
        moment.utc(formData?.start_time).format("HH:mm:ss")
      );
      pricedata.append(
        "end_time",
        moment.utc(formData?.end_time).format("HH:mm:ss")
      );
      dispatch(GetCoachSessionPriceGroup(pricedata))
        .unwrap()
        .then((result) => {
          setPrice(result?.r);
          // document.getElementById('priceInput')?.value = result?.r || 0;
        });
    } else {
      setPrice(0);
      // document.getElementById('priceInput').value = 0;
    }
  };

  return (
    <div>
      <Box
        sx={{
          borderRadius: "10px",
          width: "100%",
          padding: "0px 20px",
          display: "flex",
          justifyContent: "space-between",
          boxSizing: "border-box",
        }}
      >
        <Box
          sx={{
            borderRadius: "10px",
            width: { xs: "50vw", md: "40vw" },
            padding: "10px",
            height: "79vh",
            overflow: "auto",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                width: "100%",
              }}
            >
              <InputLabel sx={{ color: validation.date ? "#000" : "red" }}>
                Date
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <TextField
                  sx={{ width: "100%" }}
                  type="date"
                  onChange={handleDateChange}
                  error={!validation.date}
                  inputProps={{
                    min: moment().format("YYYY-MM-DD"), // Set the minimum allowed date to today
                  }}
                />
              </LocalizationProvider>
            </Box>
            <Box
              sx={{
                display: "flex",
                color: "#000",
                justifyContent: "space-between",
                gap: "5px",
                flexDirection: "column",
                mt: "5px",
              }}
            >
              <Typography>Select Time</Typography>
              <Box
                sx={{
                  display: "flex",
                  color: "#000",
                  justifyContent: "space-between",
                  gap: "40px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    width: "100%",
                  }}
                >
                  <InputLabel
                    sx={{ color: validation.start_time ? "#000" : "red" }}
                  >
                    From
                  </InputLabel>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <TimePicker
                      value={formData.start_time}
                      onChange={handlestart_timeChange}
                      slotProps={{
                        openPickerIcon: {
                          style: {
                            color: "black",
                          },
                        },
                      }}
                      sx={{
                        borderRadius: "10px",
                        width: "100%",
                      }}
                      disabled={
                        !validation.date ||
                        (isGetCoachlistTimeSelected &&
                          selectedCoaches?.length >= 1)
                      }
                    />
                  </LocalizationProvider>
                  <Typography sx={{ color: "#000" }}>
                    {validationErrors?.start_time}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    width: "100%",
                  }}
                >
                  <InputLabel
                    sx={{ color: validation.end_time ? "#000" : "red" }}
                  >
                    To
                  </InputLabel>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <TimePicker
                      value={formData.end_time}
                      onChange={handleend_timeChange}
                      slotProps={{
                        openPickerIcon: {
                          style: {
                            color: "black",
                          },
                        },
                      }}
                      sx={{
                        borderRadius: "10px",
                        width: "100%",
                      }}
                      disabled={
                        !validation.date ||
                        (isGetCoachlistTimeSelected &&
                          selectedCoaches?.length >= 1)
                      }
                    />
                  </LocalizationProvider>
                  <Typography sx={{ color: "#000" }}>
                    {validationErrors?.end_time}
                  </Typography>
                </Box>
              </Box>
              {selectedCoaches?.length >= 1 ? (
                ""
              ) : (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    mt: "10px",
                  }}
                >
                  {" "}
                  <Button
                    onClick={checkAvilableCoaches}
                    variant="outlined"
                    disabled={checkcoachesloading}
                    sx={{
                      textAlign: "end",
                      cursor: "pointer",
                      color: "red",
                      border: "1px solid red",
                      width: "30%",
                      height: "40px",
                      textTransform: "capitalize",
                    }}
                  >
                    {checkcoachesloading ? (
                      <CircularProgress sx={{ height: "35px" }} />
                    ) : (
                      "    Check Availability"
                    )}
                  </Button>
                </Box>
              )}

              <Typography sx={{ fontWeight: "600" }}>
                {selectedCoaches?.length == 0
                  ? "Please Select Coach"
                  : "Selected Coach"}{" "}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  padding: "10px 0px",
                  maxHeight: "37vh",
                  overflowY: "auto",
                  mt: "0px",
                }}
              >
                {selectedCoaches?.map((coach) => (
                  <Box
                    key={coach.id}
                    sx={{
                      display: "flex",
                      gap: "20px",
                      padding: "10px",
                      alignItems: "center",
                      boxSizing: "border-box",
                      justifyContent: "space-between",
                      borderRadius: "15px",
                      background: "rgba(246, 133, 130, 0.25)",
                      cursor: "pointer",
                      transition: "background 0.1s ease-in-out",
                      "&:hover": {
                        background: "rgba(246, 133, 130, 0.60)",
                      },
                    }}
                  >
                    <Box sx={{ display: "flex", gap: "10px", width: "100%" }}>
                      {coach?.profile_img ? (
                        <img
                          src={`${Coach_imageUrl}/${coach.profile_img}`}
                          style={{
                            width: "57px",
                            height: "57px",
                            borderRadius: "15px",
                            objectFit: "cover",
                          }}
                          alt=""
                        />
                      ) : (
                        <AccountCircleIcon
                          sx={{ height: "57px", width: "57px" }}
                        />
                      )}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          alignItems: "center",
                          boxSizing: "border-box",
                        }}
                      >
                        <Box sx={{ width: "75%" }}>
                          <Typography sx={{ fontWeight: "600" }}>
                            {coach?.coach_name}
                          </Typography>
                          <Typography
                            sx={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              maxWidth: "400px",
                            }}
                          >
                            {coach?.description}
                          </Typography>
                        </Box>

                        <Typography
                          sx={{
                            overflow: "hidden",
                            display: "flex",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            width: "20%",
                            justifySelf: "flex-end",
                            fontWeight: "600",
                          }}
                        >
                          {coach?.price}$/hr
                        </Typography>
                      </Box>
                    </Box>

                    <ClearIcon onClick={() => RemoveThisCoachFromList(coach)} />
                  </Box>
                ))}
              </Box>
              <Box
                sx={{
                  display: selectedCoaches.length >= 1 ? "flex" : "none",
                  flexDirection: "column",
                  gap: "15px",
                }}
              >
                <Box>
                  <Box sx={{ ...Inputboxstyle }}>
                    <InputLabel
                      id="demo-select-small-label"
                      sx={{
                        ...Lablestyle,
                        // color: formErrors.price ? "red" : "#000",
                      }}
                    >
                      Session Price
                    </InputLabel>
                    <OutlinedInput
                      sx={{
                        ...inputstyle,
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                          {
                            display: "none",
                          },
                      }}
                      type="Number"
                      id="outlined-adornment-weight"
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                      }}
                      placeholder="Update Session price"
                      value={price || 0}
                      onChange={handelPrice}
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-"].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      name="price"
                    />
                  </Box>
                </Box>
                <Box sx={{ ...Inputboxstyle }}>
                  <InputLabel
                    id="demo-select-small-label"
                    sx={{
                      ...Lablestyle,
                      color: formErrors.title ? "red" : "#000",
                    }}
                  >
                    Session Title
                  </InputLabel>
                  <OutlinedInput
                    sx={{
                      ...inputstyle,
                    }}
                    id="outlined-adornment-weight"
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                    placeholder="Enter Link"
                    value={title?.trimStart()}
                    onChange={handleChange}
                    name="title"
                  />
                </Box>

                <Box sx={{ ...Inputboxstyle }}>
                  <InputLabel
                    id="demo-select-small-label"
                    sx={{
                      ...Lablestyle,
                      color: formErrors.cover_img ? "red" : "#000",
                    }}
                  >
                    Upload Cover Image
                  </InputLabel>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      border: "1px solid #F68582",
                      padding: "10px",
                      borderRadius: "10px",
                      boxSizing: "border-box",
                    }}
                  >
                    <Typography noWrap style={{ color: "#000", width: "50%" }}>
                      {cover_img ? (
                        // <Typography>{cover_img?.name}</Typography>
                        <img
                          style={{ width: "150px", height: "100%" }}
                          src={URL.createObjectURL(cover_img)}
                          alt="cover_img"
                        />
                      ) : (
                        <Typography> Choose cover_img </Typography>
                      )}
                    </Typography>

                    {/* */}
                    <Input
                      type="file"
                      id="image-cover-file-input"
                      style={{ display: "none" }}
                      onChange={(event) => handleFileChange(event, "image")}
                    />
                    <label htmlFor="image-cover-file-input">
                      <Button
                        variant="contained"
                        component="span"
                        sx={{
                          background: "rgba(246, 133, 130, 0.50)",
                          textDecoration: "capitalize",
                          color: "#000",
                          height: "30px",
                          borderRadius: "10px",
                          transition: "background 0.3s ease", // Add a smooth transition for the background change
                          "&:hover": {
                            background: "rgba(246, 133, 130, 0.70)", // Slightly darker color on hover
                          },
                        }}
                      >
                        <Typography sx={{ textTransform: "capitalize" }}>
                          Browse
                        </Typography>
                      </Button>
                    </label>
                  </Box>
                </Box>
                {/* ------------ Description ----------------- */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    boxSizing: "border-box",
                  }}
                >
                  <InputLabel
                    id="demo-select-small-label"
                    sx={{
                      ...Lablestyle,
                      color: formErrors.description ? "red" : "#000",
                    }}
                  >
                    Description
                  </InputLabel>
                  <TextField
                    value={description?.trimStart()}
                    onChange={handleChange}
                    name="description"
                    id="filled-multiline-static"
                    sx={{
                      width: "100%",
                      borderRadius: "10px",
                      boxSizing: "border-box",
                      border: "1px solid #F68582",
                      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
                    }}
                    placeholder="Description"
                    //   label="Multiline"
                    multiline
                    rows={4}
                    //   defaultValue="Default Value"
                    //   variant="filled"
                  />
                </Box>

                <Box sx={{ width: "50%", m: "auto" }}>
                  <ActionButton
                    disabled={addDataLoading}
                    onclick={addGroupeCoachAvilability}
                    loading={addDataLoading}
                    title={"Add Group Availability"}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <hr />
        <Box
          sx={{
            width: "40%",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            padding: "10px",
            boxSizing: "border-box",
            height: "88vh",
            mt: "-80px",
          }}
        >
          <Typography
            sx={{ textAlign: "center", fontSize: "18px", fontWeight: "600" }}
          >
            Select Coaches
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              height: "80vh",
              overflow: "auto",
              padding: "10px 10px 0px 10px",
            }}
          >
            {!isGetCoachlistTimeSelected ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Please Select Date and time
              </Box>
            ) : CoachesList?.length === 0 ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                No coaches available at selected time
              </Box>
            ) : (
              CoachesList?.map((el) => (
                <Box
                  onClick={() => SelectThisCoach(el)}
                  sx={{
                    width: "100%",
                    display: "flex",
                    gap: "20px",
                    padding: "15px",
                    boxSizing: "border-box",
                    borderRadius: "15px",
                    background: " rgba(246, 133, 130, 0.25)",
                    cursor: "pointer",
                    transition: "background 0.1s ease-in-out",
                    "&:hover": {
                      background: " rgba(246, 133, 130, 0.60)",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      width: "100%",
                      boxSizing: "border-box",
                    }}
                  >
                    {el?.profile_img ? (
                      <img
                        src={`${Coach_imageUrl}/${el?.profile_img}`}
                        style={{
                          width: "57px",
                          height: "57px",
                          borderRadius: "15px",
                          objectFit: "cover",
                        }}
                        alt=""
                      />
                    ) : (
                      <AccountCircleIcon
                        sx={{ height: "57px", width: "57px" }}
                      />
                    )}

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        alignItems: "center",
                        boxSizing: "border-box",
                      }}
                    >
                      <Box sx={{ width: "75%" }}>
                        <Typography sx={{ fontWeight: "600" }}>
                          {el?.coach_name}
                        </Typography>
                        <Typography
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            maxWidth: "400px",
                          }}
                        >
                          {el?.description}
                        </Typography>
                      </Box>

                      <Typography
                        sx={{
                          overflow: "hidden",
                          display: "flex",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          width: "20%",
                          justifySelf: "flex-end",
                          fontWeight: "600",
                        }}
                      >
                        {el?.price}$/hr
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ))
            )}
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default IndividualAvailabilityAdd;
