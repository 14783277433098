import { Box, Button, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import { modalstyle } from "./PopupsStyling";
import { User_Profileimage, imagebaseurl } from "../../Apis/Api";
import { useDispatch, useSelector } from "react-redux";
import {
  Delete_Block,
  getuserbyid,
} from "../../Redux/Slices/Users/User.action";
import { errortost, sucesstost } from "../Alerts/CustomAlert";
import TostContainer from "../Alerts/TostContainer";
import { useParams } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import toast, { toastConfig } from 'react-simple-toasts';
import 'react-simple-toasts/dist/theme/dark.css';

const BlockUser = ({ modalcontrols }) => {
  let { isblocku, closeblocku, data } = modalcontrols;
  toastConfig({
    theme: 'dark',
  });
  let dispatch = useDispatch();
  let actiondata = {
    id: data?.userbyid?.id,
    status: data?.userbyid?.status == -1 ? 1 : -1,
  };
  let [loading, setloading] = useState(false);
  const { userId } = useParams();

  let handleBlockUser = () => {
    dispatch(Delete_Block(actiondata))
      .unwrap()
      .then((result) => {
        setloading(false);
        if (result?.s === 1) {
          setloading(false);
          dispatch(getuserbyid(userId));
          toast( data?.userbyid?.status == -1 ?" User Unblocked !":"User blocked !" );
          closeblocku();
        } else {
          closeblocku();
          setloading(false);
          dispatch(getuserbyid(userId));
          toast( "Something Wents wrong please try again" );
        }
      });
  };

  return (
    <>
      <TostContainer />
      <Modal open={isblocku} onClose={closeblocku}>
        <Box
          sx={{
            ...modalstyle,
            borderRadius: "10px",
            width: { xs: "50vw", md: "350px" },
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Typography sx={{ color: "#000", fontFamily: "Poppins" }}>
            Want to {data?.userbyid?.status == -1 ? "UnBlock" : "Block"} this
            user ?
          </Typography>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "13px",
              padding: "10px",
              background: "rgba(128, 128, 128, 0.15)",
              borderRadius: "10px",
            }}
          >
            {data?.userbyid?.profile_img == null ? (
              <AccountCircleIcon sx={{ height: "40px", width: "50px" }} />
            ) : (
              <img
                src={`${User_Profileimage}/${data?.userbyid?.profile_img}`}
                style={{
                  height: "40px",
                  width: "50px",
                  borderRadius: "10px",
                }}
                alt=""
              />
            )}
            <Typography sx={{ color: "#000" }}>
              {data?.userbyid?.full_name}{" "}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Button
              onClick={handleBlockUser}
              sx={{
                textTransform: "capitalize",
                mt: "10px",
                width: "100%",
                borderRadius: "14px",
                background: "#FF1607",
                color: "#fff",
                padding: "8px",
                "&:hover": {
                  background: "#A30000",
                },
              }}
            >
              {loading ? (
                <Typography>Loading</Typography>
              ) : (
                <Typography>
                  {data?.userbyid?.status == -1 ? "UnBlock" : "Block"}
                </Typography>
              )}
            </Button>
            <Button
              onClick={() => closeblocku()}
              sx={{
                textTransform: "capitalize",
                mt: "10px",
                borderRadius: "14px",
                background: "#E8E8E8",
                width: "100%",
                color: "#000",
                padding: "8px",
                "&:hover": {
                  background: "#B0B0B0",
                },
              }}
            >
              No
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default BlockUser;
