import { configureStore } from "@reduxjs/toolkit";
import UserReducer from "./Slices/Users/User.slice";
import ResourcesReducer from "./Slices/Resources/Resources.slice";
import EbooksReducer from "./Slices/Ebooks/Ebooks.slice";
import SessionsReducer from "./Slices/Sessions/Session.slice";
import ManageContentReducer from "./Slices/ManageContent/ManageContent.slice";
import DashboardReducer from "./Slices/Dashboard/Dashboard.slice";
import SettingReducer from "./Slices/SettingData/Setting.slice";
// import NotificationReducer from "./Slices/Notification/Notification.slice";
export const Store = configureStore({
  reducer: {
    Dashboard: DashboardReducer,
    Users: UserReducer,
    Resources: ResourcesReducer,
    Ebooks: EbooksReducer,
    Sessions: SessionsReducer,
    ManageContent: ManageContentReducer,
    Settings:SettingReducer,
  },
});
