import { Button, Chip, TablePagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,

  // TablePagination,
} from "@mui/material";
import deletelogo from "../../../../Utils/Logos/deleteuser.png";
import editlogo from "../../../../Utils/Logos/edit.png";
import { useDispatch, useSelector } from "react-redux";
import {
  GetEbooklist,
  Getallcategorie,
} from "../../../../Redux/Slices/Ebooks/Ebooks.action";
import { Ebokscoverurl } from "../../../../Apis/Api";
import Add_Edit_Ebooklist from "../../../../Components/Popups/Add_Edit_Ebooklist";
import DeleteEbook from "../../../../Components/Popups/DeleteEbook";

const columns = [
  { id: "#", label: "#" },
  { id: "Date", label: "Date " },
  { id: "EBooks Photo", label: "Cover Image" },
  { id: "Categories", label: "Categories" },
  { id: "Title", label: "Title" },
  { id: "Author name", label: "Author name" },
  // { id: "Price", label: "Price" },
  // { id: "No of Download", label: "No of Download" },
  { id: "Action", label: "Action" },
];
const EbookList = () => {
  let dispatch = useDispatch();
  let Ebookslist = useSelector((store) => store?.Ebooks);

  // -------- Edit Ebook ----------
  let [isEbookopen, setisEbookopen] = useState(false);
  let [rowdata, setrowdata] = useState(null);

  let openEbook = (data) => {
    setisEbookopen(true);
    setrowdata(data);
  };

  let closeEbook = () => {
    setisEbookopen(false);
  };

  let popuptitle = "Edit Ebook";

  // --------- Delete Conformation Popup ------------
  let ebooktype = "ebooklist";
  let [deleteselected, setdeleteselected] = useState({});
  let [isdeleteEbook, setisdeleteebook] = useState();

  let openebooklistdelete = (data) => {
    setdeleteselected(data);
    setisdeleteebook(true);
  };
  let closedeleteebook = () => {
    setisdeleteebook(false);
  };





  // ---------- Paginations -------------
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
 
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(GetEbooklist({ count:newPage * rowsPerPage,row_count:rowsPerPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    // dispatch(GetAllUsers(0)); // Reset to the first page
    dispatch(GetEbooklist({count:0 ,row_count:rowsPerPage}));
  }; 
  let Rowdata =  Ebookslist?.EbooksList?.r?.map((data, i) => ({
    rowid: i + 1 + page * 10,
    ...data,
  }));

  useEffect(() => {
    dispatch(GetEbooklist({count:page,row_count:rowsPerPage }));
    dispatch(Getallcategorie());
  }, [rowsPerPage]);
  return (
    <>
      {isdeleteEbook && (
        <DeleteEbook
          modalcontrols={{
            isdeleteEbook,
            closedeleteebook,
            deleteselected,
            ebooktype,
            rowsPerPage,
            count:page * rowsPerPage
          }}
        />
      )}

      {isEbookopen && (
        <Add_Edit_Ebooklist
          row={{ rowdata }}
          rowid={rowdata?.id}
          modalcontrols={{ isEbookopen, closeEbook, popuptitle,rowsPerPage,count:page * rowsPerPage }}
        />
      )}

      <Box sx={{ mt: "20px" }}>
        <Paper style={{ width: "100%", overflow: "hidden" }}>
          <TableContainer style={{ maxHeight: "75vh" }}>
            <Table stickyHeader sx={{}}>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      sx={{
                        textAlign: "center",
                        fontSize: "15px",
                        lineHeight: "120%",
                        fontFamily: "Montserrat",
                        fontWeight: "600",
                        background: "#F68582",
                        color: "#fff",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody sx={{ textAlign: "center" }}>
                {Rowdata?.length < 1  || Ebookslist?.EbooksList?.r==null ? (
                  <Box sx={{ textAlign: "center", padding: "10px", m: "10px" }}>
                    No Data
                  </Box>
                ) : (
                  Rowdata?.map((row, i) => (
                    <TableRow key={row.id} sx={{ textAlign: "center" }}>
                      <TableCell sx={{ textAlign: "center" }}>
                      {row?.rowid}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {row?.created_at
                          ? (() => {
                              const inputDate = new Date(row.created_at);
                              return `${(inputDate.getMonth() + 1)
                                .toString()
                                .padStart(2, "0")}/${inputDate
                                .getDate()
                                .toString()
                                .padStart(2, "0")}/${inputDate.getFullYear()}`;
                            })()
                          : "N/A"}
                      </TableCell>

                      <TableCell sx={{ textAlign: "center" }}>
                        {" "}
                        <img
                          src={` ${Ebokscoverurl}/${row?.cover_img}`}
                          style={{
                            height: "40px",
                            width: "50px",
                            borderRadius: "10px",
                          }}
                          alt=""
                        />
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {row?.cat_name}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {" "}
                        {row?.title}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {row?.author_name}
                      </TableCell>
                      {/* <TableCell sx={{ textAlign: "center" }}>
                      {row?.price == 0 ? (
                          <Chip
                            label="Free"
                            color="success"
                            variant="outlined"
                          />
                        ) : (
                          <Typography>${row?.price}</Typography>
                        )}
                      </TableCell> */}
                      {/* <TableCell sx={{ textAlign: "center" }}>
               
                        no of dawonloads
                  
                      </TableCell> */}

                      <TableCell
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Box
                          sx={{
                            height: "50px",
                            display: "flex",
                            gap: "20px",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            onClick={() => openebooklistdelete(row)}
                            style={{
                              width: "30px",
                              height: "30px",
                              cursor: "pointer",
                            }}
                            src={deletelogo}
                            alt="deletelogo"
                          />
                          <img
                            onClick={() => openEbook(row)}
                            style={{
                              width: "30px",
                              height: "30px",
                              cursor: "pointer",
                            }}
                            src={editlogo}
                            alt="editlogo"
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <TablePagination
            sx={{ mb: "-20px" }}
            // component="div"
            count={Ebookslist?.EbooksList?.count || 0}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            // rowsPerPageOptions={[]}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Box>
    </>
  );
};

export default EbookList;
