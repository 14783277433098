import { createSlice } from "@reduxjs/toolkit";
import {
  AddAdminGroupeSession,
  AddCoachAvilability,
  AddGroupeSession,
  AddSelectedCoachAvilability,
  AddSessionAvilability,
  AddUpdateIndiviSessionLink,
  AddnewCoachesDetails,
  DeleteGroupeSession,
  EditSelectedCoachAvilability,
  GetAdminAvilabilitySessions,
  GetAdminSessionsByDate,
  GetCoachAvilabilityByid,
  GetCoachDeleteStatus,
  GetCoachDetailsByid,
  GetCoachSessionPriceGroup,
  GetCoachesDetailslist,
  GetCoachesListByDateandTime,
  GetCoacheslist,
  GetGroupeSessionlist,
  GetIndividualSessions,
  GetSessionDataById,
  GetallCoachesAvilability,
  GetusersJoinedInGroupe,
  GetusersJoinedInSessions,
  UserJoinedTosession,
} from "./Session.action";

let initialState = {
  loading: false,
  error: null,
  GroupeSessionList: {},
  IndividualSessions: {},
  AdminAvilability: {},
  AdminSessionsByDate: [],
  AdminGroupeAvilability: [],
  UsersjoindinGroupe: [],
  CoachesList: [],
  CoachDetails:{},
  CoachAvilability:{},
  CoachListbyDateandTime:[],
  GetallcoachesAvilability:[],
  GetAvilibilityDetailsById:{},
  usersJoinedInSession:{
} ,
coachsubtotalgroupe:"",
UsersJoinedTosession:[]
};
const SessionSlice = createSlice({
  name: "Sessions",
  initialState,
  extraReducers: (builder) => {
    // --------- Get users Joined In Groupe  ---------
    builder
      .addCase(GetusersJoinedInGroupe.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetusersJoinedInGroupe.fulfilled, (state, action) => {
        state.loading = false;
        state.UsersjoindinGroupe = action?.payload?.r?.user_details;
      })
      .addCase(GetusersJoinedInGroupe.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
    // --------- Get users Joined In Groupe  ---------
    builder
      .addCase(GetusersJoinedInSessions.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetusersJoinedInSessions.fulfilled, (state, action) => {
        state.loading = false;
        state.usersJoinedInSession = action?.payload ;
      })
      .addCase(GetusersJoinedInSessions.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });

    // --------- Get Admin sessions by Date  ---------
    builder
      .addCase(GetAdminSessionsByDate.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetAdminSessionsByDate.fulfilled, (state, action) => {
        state.loading = false;
        state.AdminSessionsByDate = action?.payload?.r?.booked_senssions;
        // console.log(action?.payload?.r?.booked_senssions)
        state.AdminGroupeAvilability = action?.payload?.r?.booked_senssions;
      })
      .addCase(GetAdminSessionsByDate.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
    // --------- Get Admin sessions by Date  ---------
    builder
      .addCase(AddAdminGroupeSession.pending, (state) => {
        state.loading = true;
      })
      .addCase(AddAdminGroupeSession.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(AddAdminGroupeSession.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });

    // --------- Get Groupe sessions  ---------
    builder
      .addCase(GetGroupeSessionlist.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetGroupeSessionlist.fulfilled, (state, action) => {
        state.loading = false;
        state.GroupeSessionList = action?.payload;
      })
      .addCase(GetGroupeSessionlist.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
    // --------- Get Groupe sessions  ---------
    builder
      .addCase(DeleteGroupeSession.pending, (state) => {
        state.loading = true;
      })
      .addCase(DeleteGroupeSession.fulfilled, (state, action) => {
        state.loading = false;
        // state.GroupeSessionList = action?.payload;
      })
      .addCase(DeleteGroupeSession.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
    // --------- Get Induidual sessions  ---------
    builder
      .addCase(GetIndividualSessions.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetIndividualSessions.fulfilled, (state, action) => {
        state.loading = false;
        state.IndividualSessions = action?.payload;
      })
      .addCase(GetIndividualSessions.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });

    // --------- Add session Avilability ---------
    builder
      .addCase(AddSessionAvilability.pending, (state) => {
        state.loading = true;
      })
      .addCase(AddSessionAvilability.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(AddSessionAvilability.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });

    // --------- Get Admin Avilability Sessions ---------
    builder
      .addCase(GetAdminAvilabilitySessions.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetAdminAvilabilitySessions.fulfilled, (state, action) => {
        state.loading = false;
        state.AdminAvilability = action?.payload;
      })
      .addCase(GetAdminAvilabilitySessions.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
 

    // // ---------  Update Individual Session Link ---------
    builder
      .addCase(AddUpdateIndiviSessionLink.pending, (state) => {
        state.loading = true;
      })
      .addCase(AddUpdateIndiviSessionLink.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(AddUpdateIndiviSessionLink.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });

    // // ---------  Add  Session Coaches ---------
    builder
      .addCase(AddnewCoachesDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(AddnewCoachesDetails.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(AddnewCoachesDetails.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
    // // ---------   Get Session Coaches ---------
    builder
      .addCase(GetCoachesDetailslist.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetCoachesDetailslist.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(GetCoachesDetailslist.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
    // // ---------   Get All Coaches List ---------
    builder
      .addCase(GetCoacheslist.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetCoacheslist.fulfilled, (state, action) => {
        state.loading = false;
        state.CoachesList = action.payload;
      })
      .addCase(GetCoacheslist.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
    // // ---------   Get Coaches Details ---------
    builder
      .addCase(GetCoachDetailsByid.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetCoachDetailsByid.fulfilled, (state, action) => {
        state.loading = false;
        state.CoachDetails = action.payload;
      })
      .addCase(GetCoachDetailsByid.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
    // // ---------   Get Coach avilability Details ---------
    builder
      .addCase(GetCoachAvilabilityByid.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetCoachAvilabilityByid.fulfilled, (state, action) => {
        state.loading = false;
        state.CoachAvilability = action.payload;
      })
      .addCase(GetCoachAvilabilityByid.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
    // // ---------   Get Coach List By Date and Time ---------
    builder
      .addCase(GetCoachesListByDateandTime.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetCoachesListByDateandTime.fulfilled, (state, action) => {
        state.loading = false;
        state.CoachListbyDateandTime = action.payload;
      })
      .addCase(GetCoachesListByDateandTime.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
    // // ---------   Get Coach List By Date and Time ---------
    builder
      .addCase(AddSelectedCoachAvilability.pending, (state) => {
        state.loading = true;
      })
      .addCase(AddSelectedCoachAvilability.fulfilled, (state, action) => {
        state.loading = false;
      
      })
      .addCase(AddSelectedCoachAvilability.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
    // // ---------   Get Coach List By Date and Time ---------
    builder
      .addCase(EditSelectedCoachAvilability.pending, (state) => {
        state.loading = true;
      })
      .addCase(EditSelectedCoachAvilability.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(EditSelectedCoachAvilability.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
    // // ---------   Get Coach List By Date and Time ---------
    builder
      .addCase(GetallCoachesAvilability.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetallCoachesAvilability.fulfilled, (state, action) => {
        state.loading = false;
        state.GetallcoachesAvilability=action?.payload
      
      })
      .addCase(GetallCoachesAvilability.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
    // // ---------   Get Coach Data By id ---------
    builder
      .addCase(GetSessionDataById.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetSessionDataById.fulfilled, (state, action) => {
        state.loading = false;
        state.GetAvilibilityDetailsById=action?.payload
      
      })
      .addCase(GetSessionDataById.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
    builder
      .addCase(GetCoachDeleteStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetCoachDeleteStatus.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(GetCoachDeleteStatus.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
    builder
      .addCase(GetCoachSessionPriceGroup.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetCoachSessionPriceGroup.fulfilled, (state, action) => {
        state.loading = false;
        state.coachsubtotalgroupe=action?.payload?.r
      })
      .addCase(GetCoachSessionPriceGroup.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
    
  },
});

export default SessionSlice.reducer;
