import React, { useEffect, useState } from "react";
import DetailsTable from "./DetailsTable";
import { useLocation, useParams } from "react-router-dom";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Select,
  Button,
  FormControl,
  MenuItem,
  TablePagination,
  Chip,
  // TablePagination,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Viewicon from "../../../../Utils/Logos/viewmore.png";

import { getIndividualSessionsbyid } from "../../../../Redux/Slices/Users/User.action";
import { formatTime } from "../../../../Apis/Functions";
const IndividualSession = () => {
  const { userId } = useParams();
  const columns = [
    { id: "#", label: "#" },
    { id: "Date", label: "Date" },
    { id: "Time Selected", label: "Time Selected" },
    { id: "Topic", label: "Topic" },
    { id: "Payment", label: "Payment" },
    { id: "Payment Status", label: "Payment Status" },
    // { id: "Action", label: "Action" },
  ];
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(getIndividualSessionsbyid({ user_id:userId, count: 0 }));
  }, []);
  let sessionsdata = useSelector((store) => store?.Users);

  // ---------- paginations ----------
  const [page, setPage] = useState(0)

  const [rowsPerPage, setRowsPerPage] = useState(10)
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(getIndividualSessionsbyid({ user_id:userId, count:newPage * rowsPerPage }));
  }

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    dispatch(getIndividualSessionsbyid({ user_id:userId, count:0 }));
  }
   
  return (
    <>
      <Box sx={{ mt: "20px" }}>
        <Paper style={{ width: "100%", overflow: "hidden" }}>
          <TableContainer style={{ maxHeight: "71vh" }}>
            <Table stickyHeader sx={{}}>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      sx={{
                        textAlign: "center",
                        fontSize: "15px",
                        lineHeight: "120%",
                        fontFamily: "Montserrat",
                        fontWeight: "600",
                        background: "#F68582",
                        color: "#fff",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody sx={{ textAlign: "center" }}>
                {sessionsdata?.UserIndividualSessions?.r?.length < 1 ||sessionsdata?.UserIndividualSessions?.r==null ?  (
                  <Box sx={{ textAlign: "center", padding: "10px", m: "10px" }}>
                    No Data
                  </Box>
                ) : (
                  sessionsdata?.UserIndividualSessions?.r?.map((row, i) => (
                    <TableRow key={row.id} sx={{ textAlign: "center" }}>
                      <TableCell sx={{ textAlign: "center" }}>
                        {i + 1}
                      </TableCell>

                      <TableCell sx={{ textAlign: "center" }}>
                        {" "}
                        {row?.date
                          ? (() => {
                              const inputDate = new Date(row.date);
                              return `${inputDate
                                .getDate()
                                .toString()
                                .padStart(2, "0")}/${(inputDate.getMonth() + 1)
                                .toString()
                                .padStart(2, "0")}/${inputDate.getFullYear()}`;
                            })()
                          : "N/A"}
      
                      
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {" "}
                      
      
                        {formatTime(row?.start_time) } to {formatTime(row?.end_time)}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {" "}
                      
      
                      {row?.topic}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {" "}
                      
      
                      ${row?.price}
                      </TableCell>

                      <TableCell sx={{ textAlign: "center" }}>
                        {" "}
                        <Chip
                          sx={{  background:
                            row?.payment_status === 1
                              ? "green"
                              : row?.payment_status === 0
                              ? "red"
                              : row?.payment_status === -1
                              ? "gray"
                              : "gray",
                          color: "#fff",}}
                          label={
                            row?.payment_status === 1
                              ? "Completed"
                              : row?.payment_status === 0
                              ? "Failed"
                              : row?.payment_status === -1
                              ? "Refund"
                              : "Refund"
                          }

                          // color={
                          //   row?.payment_status === 1
                          //     ? "sucess"
                          //     : row?.payment_status === 0
                          //     ? "error"
                          //     : row?.payment_status === -1
                          //     ? "gray"
                          //     : "gray"
                          // }
                          
                        />
                      </TableCell>
                      {/* <TableCell sx={{ textAlign: "center" }}>
                        <Box
                          sx={{
                            height: "50px",
                            display: "flex",
                            gap: "20px",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            // onClick={() => openuserdetailspopup(row)}
                            style={{
                              width: "30px",
                              height: "30px",
                              cursor: "pointer",
                            }}
                            src={Viewicon}
                            alt="editlogo"
                          />
                        </Box>

                      </TableCell> */}
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <TablePagination
            sx={{ mb: "-20px" }}
            // component="div"
            count={sessionsdata?.UserIndividualSessions?.count || 0}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Box>
    </>
  );
};

export default IndividualSession;
