import {
  Box,
  Button,
  CircularProgress,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import ActionButton from "../../../../Components/Popups/PopupComponent/ActionButton";
import moment from "moment";

import { useDispatch, useSelector } from "react-redux";
import {
  AddSelectedCoachAvilability,
  EditSelectedCoachAvilability,
  GetCoachSessionPriceGroup,
  GetCoachesListByDateandTime,
  GetSessionDataById,
  GetusersJoinedInSessions,
} from "../../../../Redux/Slices/Sessions/Session.action";
import { Coach_imageUrl } from "../../../../Apis/Api";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ClearIcon from "@mui/icons-material/Clear";
import toast, { toastConfig } from "react-simple-toasts";
import "react-simple-toasts/dist/theme/dark.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import TitleText from "../../../../Components/TitleText";
let Inputboxstyle = {
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  gap: "10px",
};

let Lablestyle = {
  fontSize: "15px",
  mt: "10px",
  fontWeight: "500",
};
let inputstyle = {
  width: "100%",
  height: "50px",
  borderRadius: "10px",
  border: "1px solid #F68582",
  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
};

const EditIndividualDetails = () => {
  let location = useLocation();
  const initialDataSessionid = location?.state?.id || {};
  useEffect(() => {
    dispatch(GetSessionDataById(initialDataSessionid));
    dispatch(GetusersJoinedInSessions(initialDataSessionid));
  }, [initialDataSessionid, location]);
  let DetailsById = useSelector((store) => store?.Sessions);
  let [isCoachselected, setisCoachselected] = useState(false);
  const [isGetCoachlistTimeSelected, setisGetCoachlistTimeSelected] =
    useState(false);
  const [selectedCoaches, setSelectedCoaches] = useState(
    DetailsById?.GetAvilibilityDetailsById?.r?.coaches || []
  );
  let [CoachesList, setCoacheslist] = useState([]);
  const initialFormState = {
    date: DetailsById?.GetAvilibilityDetailsById?.r?.date || "",
    start_time: DetailsById?.GetAvilibilityDetailsById?.r?.start_time
      ? moment
          .utc(
            DetailsById?.GetAvilibilityDetailsById?.r?.start_time,
            "HH:mm:ss"
          )
          .local()
      : null,
    end_time: DetailsById?.GetAvilibilityDetailsById?.r?.end_time
      ? moment
          .utc(DetailsById.GetAvilibilityDetailsById.r.end_time, "HH:mm:ss")
          .local()
      : null,
  }
  let [price, setPrice] = useState(
    DetailsById?.GetAvilibilityDetailsById?.r?.price || 0
  );
  console.log(initialFormState?.date);
  const [formData, setFormData] = useState({ ...initialFormState });
  const [loading, setloading] = useState(false);
  const [validation, setValidation] = useState({
    date: true,
    start_time: true,
    end_time: true,
  });
  let dispatch = useDispatch();
  let handelPrice = (e) => {
    setPrice(e.target.value);
  };

  let [istimeavilable, setistimeavilable] = useState(false);
  const [validationErrors, setValidationErors] = useState({
    date: "",
    start_time: "",
    end_time: "",
  });
  let navigate = useNavigate();

  const handleActionClick = () => {
    if (DetailsById?.usersJoinedInSession?.r?.length < 1) {
      const isFormValid =
        formData.date &&
        formData.start_time &&
        formData.end_time &&
        validation.date &&
        validation.start_time &&
        validation.end_time;
      if (!isFormValid) {
        setValidation({
          date: !!formData.date,
          start_time: !!formData.start_time,
          end_time: !!formData.end_time,
        });
        if (!formData.date && !formData.start_time && !formData.end_time) {
          toast("Please fill all the fields");
          return;
        }
        if (!formData.start_time && !formData.end_time) {
          toast("Please select a start and end time");
        }
        return;
      } else {
        let collecteddata = new FormData();
        collecteddata.append("date", formData.date);
        collecteddata.append(
          "start_time",
          moment.utc(formData.start_time).format("HH:mm:ss")
        );
        collecteddata.append(
          "end_time",
          moment.utc(formData.end_time).format("HH:mm:ss")
        );
        setloading(true);
        if (selectedCoaches.length < 1) {
          toast("please select Coach");
          return;
        }
        const coachIds = selectedCoaches.map((coach) => coach.id).join(",");
        collecteddata.append("coach_id", coachIds);
        collecteddata.append("price", price);
        collecteddata.append("availability_id", initialDataSessionid);
        dispatch(EditSelectedCoachAvilability(collecteddata))
          .unwrap()
          .then((result) => {
            if (result.s === 1) {
              setloading(false);
              setisGetCoachlistTimeSelected(false);
              toast("Detail Updated !");
            } else {
              setloading(false);
            }
          });
      }
    } else {
      toast("User have joined this session, you cannot make changes to it.");
    }
  };
  const SelectThisCoach = (data) => {
    setSelectedCoaches([data]); // Replace the current selected coach with the new one
    GetPriceSubtotal([data]);
    setisCoachselected(true);
  };

  let GetPriceSubtotal = (updatedCoaches) => {
    let pricedata = new FormData();
    if (updatedCoaches?.length >= 1) {
      const coachIds = updatedCoaches?.map((coach) => coach?.id).join(",");
      pricedata.append("coach_ids", coachIds);
      pricedata.append("date", formData?.date);
      pricedata.append(
        "start_time",
        moment.utc(formData?.start_time).format("HH:mm:ss")
      );
      pricedata.append(
        "end_time",
        moment.utc(formData?.end_time).format("HH:mm:ss")
      );
      dispatch(GetCoachSessionPriceGroup(pricedata))
        .unwrap()
        .then((result) => {
          console.log(result);
          setPrice(result?.r);
          // document.getElementById('priceInput')?.value = result?.r || 0;
        });
    } else {
      setPrice(0);
      // document.getElementById('priceInput').value = 0;
    }
  };

  toastConfig({
    theme: "dark",
  });
  let [checkcoachesloading, setcheckcoachesloading] = useState(false);

  let checkAvilableCoaches = () => {
    const isFormValid =
      formData.date &&
      formData.start_time &&
      formData.end_time &&
      validation.date &&
      validation.start_time &&
      validation.end_time;
    if (!isFormValid) {
      setValidation({
        date: !!formData.date,
        start_time: !!formData.start_time,
        end_time: !!formData.end_time,
      });
      if (!formData.date && !formData.start_time && !formData.end_time) {
        toast("Please fill all the fields");
        return;
      }
      if (!formData.start_time && !formData.end_time) {
        toast("Please select a start and end time");
      }
      return;
    } else {
      let collecteddata = new FormData();
      collecteddata.append("date", formData.date);
      collecteddata.append(
        "start_time",
        moment.utc(formData.start_time).format("HH:mm:ss")
      );
      collecteddata.append(
        "end_time",
        moment.utc(formData.end_time).format("HH:mm:ss")
      );
      setcheckcoachesloading(true);
      dispatch(
        GetCoachesListByDateandTime({
          date: formData.date,
          start_time: moment.utc(formData.start_time).format("HH:mm:ss"),
          end_time: moment.utc(formData.end_time).format("HH:mm:ss"),
        })
      )
        .unwrap()
        .then((result) => {
          if (result?.s === 1) {
            setloading(false);
            if (result?.r?.length >= 1) {
              setisGetCoachlistTimeSelected(true);
              setcheckcoachesloading(false);
              setCoacheslist(result?.r);
            } else {
              toast("No Coachs Avilable at this time !");
              setcheckcoachesloading(false);
            }
          } else {
            setcheckcoachesloading(false);
          }
        });
      return;
    }
  };
  const resetSelectedCoach = () => {
    setSelectedCoaches(DetailsById?.GetAvilibilityDetailsById?.r?.coaches);
    setPrice(DetailsById?.GetAvilibilityDetailsById?.r?.price);
    setisCoachselected(false);
  };

  let getbacktoList = () => {
    navigate(`/dashboard/avilability `, {
      state: {
        id: initialDataSessionid,
        type: location?.state?.type,
        count: location?.state?.count,
        row_count: location?.state?.row_count,
      },
    });
  };

  let getbacktodetails = () => {
    navigate(`/dashboard/avilability/view_avilability_Details `, {
      state: {
        id: initialDataSessionid,
        type: location?.state?.type,
        count: location?.state?.count,
        row_count: location?.state?.row_count,
      },
    });
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          gap: "18px",
          width: "100%",
          mt: "20px",
          pb: "20px",
        }}
      >
        <Box
          onClick={getbacktoList}
          style={{
            display: "flex",
            gap: "18px",
            color: "#000",
            cursor: "pointer",
          }}
        >
          {"<"} <TitleText title={"Availability "} style={"0"} />{" "}
        </Box>{" "}
        <Box
          onClick={() => getbacktodetails()}
          style={{
            display: "flex",
            gap: "18px",
            color: "#000",
            cursor: "pointer",
          }}
        >
          {"<"} <TitleText title={"Availability Details "} style={"0"} />{" "}
        </Box>{" "}
        {">"}
        <TitleText title={"Update Details "} style={"0"} />
      </Box>
      <Box
        sx={{
          borderRadius: "10px",
          width: "100%",
          padding: "0px 20px",
          display: "flex",
          justifyContent: "space-between",
          boxSizing: "border-box",
          mt: "60px",
        }}
      >
        <Box
          sx={{
            borderRadius: "10px",
            width: { xs: "50vw", md: "40vw" },
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                width: "100%",
              }}
            >
              <InputLabel sx={{ color: validation.date ? "#000" : "red" }}>
                Date
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <TextField
                  sx={{ width: "100%" }}
                  type="date"
                  //   onChange={handleDateChange}
                  value={formData?.date}
                  error={!validation.date}
                  //   disabled={DetailsById?.usersJoinedInSession?.r?.length>=1}
                  disabled={true}
                  inputProps={{
                    min: moment().format("YYYY-MM-DD"), // Set the minimum allowed date to today
                  }}
                />
              </LocalizationProvider>
            </Box>
            <Box
              sx={{
                display: "flex",
                color: "#000",
                justifyContent: "space-between",
                gap: "5px",
                flexDirection: "column",
                mt: "5px",
              }}
            >
              <Typography>Select Time</Typography>
              <Box
                sx={{
                  display: "flex",
                  color: "#000",
                  justifyContent: "space-between",
                  gap: "40px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    width: "100%",
                  }}
                >
                  <InputLabel
                    sx={{ color: validation.start_time ? "#000" : "red" }}
                  >
                    From
                  </InputLabel>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <TimePicker
                      value={formData?.start_time}
                      //   onChange={handlestart_timeChange}
                      slotProps={{
                        openPickerIcon: {
                          style: {
                            color: "black",
                          },
                        },
                      }}
                      sx={{
                        borderRadius: "10px",
                        width: "100%",
                      }}
                      //   disabled={
                      //     !validation.date ||
                      //     (isGetCoachlistTimeSelected &&
                      //       selectedCoaches?.length >= 1)|| DetailsById?.usersJoinedInSession?.r?.length>=1
                      //   }
                      disabled={true}
                    />
                  </LocalizationProvider>
                  <Typography sx={{ color: "#000" }}>
                    {validationErrors?.start_time}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    width: "100%",
                  }}
                >
                  <InputLabel
                    sx={{ color: validation.end_time ? "#000" : "red" }}
                  >
                    To
                  </InputLabel>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <TimePicker
                      value={formData?.end_time}
                      //   onChange={handleend_timeChange}
                      slotProps={{
                        openPickerIcon: {
                          style: {
                            color: "black",
                          },
                        },
                      }}
                      sx={{
                        borderRadius: "10px",
                        width: "100%",
                      }}
                      //   disabled={
                      //     !validation.date ||
                      //     (isGetCoachlistTimeSelected &&
                      //       selectedCoaches?.length >= 1)|| DetailsById?.usersJoinedInSession?.r?.length>=1
                      //   }
                      disabled={true}
                    />
                  </LocalizationProvider>
                  <Typography sx={{ color: "#000" }}>
                    {validationErrors?.end_time}
                  </Typography>
                </Box>
              </Box>
            </Box>
            {selectedCoaches?.length >= 1 && false ? (
              ""
            ) : (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  mt: "10px",
                }}
              >
                {" "}
                <Button
                  onClick={checkAvilableCoaches}
                  variant="outlined"
                  disabled={checkcoachesloading}
                  sx={{
                    textAlign: "end",
                    cursor: "pointer",
                    color: "red",
                    border: "1px solid red",
                    width: "30%",
                    height: "40px",
                    textTransform: "capitalize",
                  }}
                >
                  {checkcoachesloading ? (
                    <CircularProgress sx={{ height: "35px" }} />
                  ) : (
                    "    Check Availability"
                  )}
                </Button>
              </Box>
            )}
            {selectedCoaches.length >= 1 ? (
              <Box sx={{ width: "50%", m: "auto" }}>
                <ActionButton
                  disabled={loading || selectedCoaches?.length < 1}
                  onclick={handleActionClick}
                  loading={loading}
                  title={"Update"}
                />
              </Box>
            ) : (
              ""
            )}
          </Box>
          <Box sx={{ ...Inputboxstyle }}>
            <InputLabel
              id="demo-select-small-label"
              sx={{
                ...Lablestyle,
              }}
            >
              Session Price
            </InputLabel>
            <OutlinedInput
              sx={{
                ...inputstyle,
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                  {
                    display: "none",
                  },
              }}
              type="Number"
              id="outlined-adornment-weight"
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
              placeholder="Update Session price"
              value={price || 0}
              onChange={handelPrice}
              onKeyDown={(evt) =>
                ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
              }
              name="price"
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              padding: "10px 0px",
              overflowY: "auto",
              mt: "10px",
            }}
          >
            {selectedCoaches?.map((coach) => (
              <Box
                key={coach.id}
                sx={{
                  display: "flex",
                  gap: "20px",
                  padding: "10px",
                  alignItems: "center",
                  boxSizing: "border-box",
                  justifyContent: "space-between",
                  borderRadius: "15px",
                  background: "rgba(246, 133, 130, 0.25)",
                  cursor: "pointer",
                  transition: "background 0.1s ease-in-out",
                  "&:hover": {
                    background: "rgba(246, 133, 130, 0.60)",
                  },
                }}
              >
                <Box sx={{ display: "flex", gap: "10px", width: "100%" }}>
                  {coach?.profile_img ? (
                    <img
                      src={`${Coach_imageUrl}/${coach.profile_img}`}
                      style={{
                        width: "57px",
                        height: "57px",
                        borderRadius: "15px",
                        objectFit: "cover",
                      }}
                      alt=""
                    />
                  ) : (
                    <AccountCircleIcon sx={{ height: "57px", width: "57px" }} />
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "center",
                      boxSizing: "border-box",
                    }}
                  >
                    <Box sx={{ width: "75%" }}>
                      <Typography sx={{ fontWeight: "600" }}>
                        {coach?.coach_name}
                      </Typography>
                      <Typography
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          maxWidth: "400px",
                        }}
                      >
                        {coach?.description}
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        overflow: "hidden",
                        display: "flex",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        width: "20%",
                        justifySelf: "flex-end",
                        fontWeight: "600",
                      }}
                    >
                      {coach?.price}$/hr
                    </Typography>
                  </Box>
                </Box>

                {/* <ClearIcon onClick={() => RemoveThisCoachFromList(coach)} /> */}
              </Box>
            ))}
          </Box>
          {isCoachselected ? (
            <Button
              sx={{
                display: "flex",
                justifySelf: "flex-end",
                width: "80px",
                textAlign: "end",
                cursor: "pointer",
                color: "red",
                border: "1px solid red",
                width: "20%",
                height: "40px",
                textTransform: "capitalize",
              }}
              onClick={() => resetSelectedCoach()}
            >
              Reset
            </Button>
          ) : (
            ""
          )}
        </Box>
        <hr />
        <Box
          sx={{
            width: "40%",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            padding: "10px",
            boxSizing: "border-box",
            height: "88vh",
            mt: "-80px",
          }}
        >
          <Typography
            sx={{ textAlign: "center", fontSize: "18px", fontWeight: "600" }}
          >
            Select Coaches
          </Typography>

          {checkcoachesloading ? (
            <CircularProgress />
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                height: "80vh",
                overflow: "auto",
                padding: "10px 10px 0px 10px",
              }}
            >
              {!isGetCoachlistTimeSelected ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Please Select Date and time
                </Box>
              ) : CoachesList?.length === 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  No coaches available at selected time
                </Box>
              ) : (
                CoachesList?.map((el) => (
                  <Box
                    onClick={() => SelectThisCoach(el)}
                    sx={{
                      width: "100%",
                      display: "flex",
                      gap: "20px",
                      padding: "15px",
                      boxSizing: "border-box",
                      borderRadius: "15px",
                      background: " rgba(246, 133, 130, 0.25)",
                      cursor: "pointer",
                      transition: "background 0.1s ease-in-out",
                      "&:hover": {
                        background: " rgba(246, 133, 130, 0.60)",
                      },
                    }}
                  >
                    <Box sx={{ display: "flex", gap: "10px", width: "100%" }}>
                      {el?.profile_img ? (
                        <img
                          src={`${Coach_imageUrl}/${el?.profile_img}`}
                          style={{
                            width: "57px",
                            height: "57px",
                            borderRadius: "15px",
                            objectFit: "cover",
                          }}
                          alt=""
                        />
                      ) : (
                        <AccountCircleIcon
                          sx={{ height: "57px", width: "57px" }}
                        />
                      )}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          alignItems: "center",
                          boxSizing: "border-box",
                        }}
                      >
                        <Box sx={{ width: "75%" }}>
                          <Typography sx={{ fontWeight: "600" }}>
                            {el?.coach_name}
                          </Typography>
                          <Typography
                            sx={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              maxWidth: "400px",
                            }}
                          >
                            {el?.description}
                          </Typography>
                        </Box>

                        <Typography
                          sx={{
                            overflow: "hidden",
                            display: "flex",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            width: "20%",
                            justifySelf: "flex-end",
                            fontWeight: "600",
                          }}
                        >
                          {el?.price}$/hr
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                ))
              )}
            </Box>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default EditIndividualDetails;
