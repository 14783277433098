import {
  Box,
  Divider,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { modalstyle } from "./PopupsStyling";
import PopupTitle from "./PopupComponent/PopupTitle";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import ActionButton from "./PopupComponent/ActionButton";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
  EditNotification,
  GetNotifications,
} from "../../Redux/Slices/Dashboard/Dashboard.action";
import { errortost, sucesstost } from "../Alerts/CustomAlert";

const Edit_Notifications = ({ modalcontrols }) => {
  let { isNotificationOpen, CloseEditPopup, selectedData } = modalcontrols;
  let popuptitle = "Edit Notification !";

  const [title, setTitle] = useState(selectedData?.title || "");
  const [description, setDescription] = useState(selectedData?.body || "");
  const [selectedDateTime, setSelectedDateTime] = useState(
    moment.utc(selectedData?.date_time).local()
  );
  const [titleError, setTitleError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [dateTimeError, setDateTimeError] = useState(false);

  const validateInputs = () => {
    let isValid = true;

    // Title validation
    if (!title.trim()) {
      setTitleError(true);
      isValid = false;
    } else {
      setTitleError(false);
    }

    // Description validation
    if (!description.trim()) {
      setDescriptionError(true);
      isValid = false;
    } else {
      setDescriptionError(false);
    }

    // DateTime validation
    if (!selectedDateTime.isValid()) {
      setDateTimeError(true);
      isValid = false;
    } else {
      setDateTimeError(false);
    }

    return isValid;
  };
  let dispatch = useDispatch();

  const handleSubmit = () => {
    if (validateInputs()) {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("message", description);
      formData.append(
        "date_time",
        moment.utc(selectedDateTime).format("YYYY-MM-DD hh:mm:ss")
      )
      formData.append("type", 1);
      formData.append("id", selectedData?.id);
      formData.append("status", 1);
      dispatch(EditNotification(formData))
        .unwrap()
        .then((result) => {
          if (result?.s === 1) {
            sucesstost("Notification Updated Sucesses !")
            dispatch(GetNotifications())
            CloseEditPopup()
          } else {
            errortost(result?.m)
            CloseEditPopup();
          }
        })
    } else {
      errortost("All Fields Are Necessary !")
    }
  };

  return (
    <>
      <Modal open={isNotificationOpen} onClose={CloseEditPopup}>
        <Box
          sx={{
            ...modalstyle,
            borderRadius: "10px",
            width: { xs: "50vw", md: "40vw" },
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <PopupTitle popuptitle={popuptitle} />

          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <InputLabel
                id="demo-select-small-label"
                sx={{
                  fontSize: "15px",
                  color: titleError ? "red" : "#000",
                }}
              >
                Notification Title
              </InputLabel>
              <OutlinedInput
                placeholder="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                error={titleError}
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <InputLabel
                id="demo-select-small-label"
                sx={{
                  fontSize: "15px",
                  color: descriptionError ? "red" : "#000",
                }}
              >
                Description
              </InputLabel>

              <TextField
                placeholder="Description"
                multiline
                rows={5}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                error={descriptionError}
              />
            </Box>

            {/* {notificationcondition == 1 ? (
          ""
        ) : ( */}
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              sx={{ mt: "-10px" }}
            >
              <DemoContainer components={["DateTimePicker"]}>
                <InputLabel
                  id="demo-select-small-label"
                  sx={{
                    fontSize: "15px",
                    color: dateTimeError ? "red" : "#000",
                  }}
                >
                  Select Date and Time
                </InputLabel>
                <DateTimePicker
                  sx={{
                    borderRadius: "10px",
                    width: "100%",
                    "& label": {
                      color: "#000",
                    },
                    "& .MuiInputBase-input": {
                      color: "#000",
                    },
                  }}
                  slotProps={{
                    openPickerIcon: {
                      style: {
                        color: "rgba(246, 133, 130, 1)",
                      },
                    },
                  }}
                  // onChange={handeldDateandTime}
                  inputFormat={{
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                  }}
                  renderInput={(props) => <TextField {...props} />}
                  inputProps={{
                    min: moment().format("YYYY-MM-DD"), // Set the minimum allowed date to today
                  }}
                  minDate={moment()}
                  // value={selectedDateTime}
                  defaultValue={selectedDateTime}
                  onChange={(newDateTime) => setSelectedDateTime(newDateTime)}
                  error={dateTimeError}
                />
              </DemoContainer>
            </LocalizationProvider>
            {/* )} */}

            {/* ------- Date and Time Input ------------- */}

            <Box sx={{ width: "50%", m: "auto", mt: "20px" }}>
              <ActionButton title={"Send"} onclick={handleSubmit} />
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Edit_Notifications;
