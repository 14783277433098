import {
  Box,
  Grid,
  Paper,
  Typography,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Select,
  Button,
  FormControl,
  MenuItem,
  TablePagination,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import deletelogo from "../../Utils/Logos/deleteuser.png";
import editlogo from "../../Utils/Logos/edit.png";
import ActionButton from "./PopupComponent/ActionButton";
import { useDispatch, useSelector } from "react-redux";
import { GetAffermationslist } from "../../Redux/Slices/ManageContent/ManageContent.action";
import AddAffermation from "./AddAffermation";
import DeleteAffermation from "./DeleteAffermation";
const columns = [
  { id: "#", label: "#" },
  { id: "Date", label: "Date " },
  { id: "Affirmations", label: "Affirmations " },
  { id: "Action", label: "Action" },
];

const AffirmationsTable = () => {
  let dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  let Affermationdata = useSelector((store) => store?.ManageContent);
  useEffect(() => {
    dispatch(GetAffermationslist({ count: 0, row_count: rowsPerPage }));
  }, [rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(
      GetAffermationslist({
        count: newPage * rowsPerPage,
        row_count: rowsPerPage,
      })
    );
  };
  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    // dispatch(GetAffermationslist({ count: 0,row_count:rowsPerPage }));
  };
  // --------- Add Affermations Popup ------------
  let [isaffermationopen, setisaffermationopen] = useState(false);
  let [rowdata, setrowdata] = useState(null);
  let openAffermativepopup = (row) => {
    setisaffermationopen(true);
    if (row) {
      setrowdata(row);
    } else {
      setrowdata(null);
    }
  };

  let closeAffermativepopup = () => {
    setisaffermationopen(false);
  };

  // ----------- Delete Affermation ----------
  let [isdeleteAffermation, setisdeleteAffermation] = useState(false);
  let [deletedata, setdeletedata] = useState({});

  let opendeletemodal = (row) => {
    setdeletedata(row);
    setisdeleteAffermation(true);
  };

  let closedeleteAffermation = () => {
    setisdeleteAffermation(false);
  };

  let Rowdata = Affermationdata?.Affirmations.r?.map((data, i) => ({
    rowid: i + 1 + page * 10,
    ...data,
  }))

  return (
    <>
      {isdeleteAffermation ? (
        <DeleteAffermation
          modalcontrols={{
            isdeleteAffermation,
            closedeleteAffermation,
            deletedata,
            rowsPerPage,count:page * rowsPerPage
          }}
        />
      ) : (
        ""
      )}

      {isaffermationopen ? (
        <AddAffermation
          modalcontrols={{ isaffermationopen, closeAffermativepopup }}
          row={rowdata}
          rowsPerPage={rowsPerPage}
          count={page * rowsPerPage}
        />
      ) : (
        ""
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          borderRadius: "30px",
          padding: "25px",
          boxShadow: " 0px 0px 25px 0px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{ fontWeight: "600", fontFamily: "Poppins", fontSize: "20px" }}
          >
            Affirmations
          </Typography>

          <Box sx={{ width: "15%" }}>
            <ActionButton
              onclick={() => openAffermativepopup(null)}
              title={"Add More"}
            />
          </Box>
        </Box>
        {/* ---------- table --------- */}

        <Box sx={{}}>
          <Paper style={{ width: "100%", overflow: "hidden" }}>
            <TableContainer style={{ height: "43vh" }}>
              <Table stickyHeader sx={{}}>
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        sx={{
                          textAlign: "center",
                          fontSize: "15px",
                          lineHeight: "120%",
                          fontFamily: "Montserrat",
                          fontWeight: "600",
                          background: "#F68582",
                          color: "#fff",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody sx={{ textAlign: "center" }}>
                  {Rowdata?.length < 1 ? (
                    <Box
                      sx={{ textAlign: "center", padding: "10px", m: "10px" }}
                    >
                      No Data
                    </Box>
                  ) : (
                    Rowdata?.map((row, i) => (
                      <TableRow key={row.id} sx={{ textAlign: "center" }}>
                        <TableCell sx={{ textAlign: "center" }}>
                          {row?.rowid}
                        </TableCell>

                        <TableCell sx={{ textAlign: "center" }}>
                          {row?.created_at
                            ? (() => {
                                const inputDate = new Date(row.created_at);
                                return `${inputDate
                                  .getDate()
                                  .toString()
                                  .padStart(2, "0")}/${(
                                  inputDate.getMonth() + 1
                                )
                                  .toString()
                                  .padStart(
                                    2,
                                    "0"
                                  )}/${inputDate.getFullYear()}`;
                              })()
                            : "N/A"}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {row?.affirmations}
                        </TableCell>

                        <TableCell
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          <Box
                            sx={{
                              height: "50px",
                              display: "flex",
                              gap: "20px",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img
                              onClick={() => opendeletemodal(row)}
                              style={{
                                width: "30px",
                                height: "30px",
                                cursor: "pointer",
                              }}
                              src={deletelogo}
                              alt=""
                            />
                            <img
                              onClick={() => openAffermativepopup(row)}
                              style={{
                                width: "30px",
                                height: "30px",
                                cursor: "pointer",
                              }}
                              src={editlogo}
                              alt=""
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>

          <Box>
            <TablePagination
              sx={{ mb: "-20px" }}
              component="div"
              count={Affermationdata?.Affirmations?.count || 0}
              page={page}
              onPageChange={handleChangePage}
              // rowsPerPage={10}
              // rowsPerPageOptions={[]}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPage={rowsPerPage}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AffirmationsTable;
