import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Select,
  Button,
  FormControl,
  MenuItem,
  TablePagination,
  Chip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { GetAllCategory, GetallAudios } from "../../../../Redux/Slices/Resources/Resources.action";
import { audiocoverurl } from "../../../../Apis/Api";
import deletelogo from "../../../../Utils/Logos/deleteuser.png";
import editlogo from "../../../../Utils/Logos/edit.png";
import AddAudioPopup from "../../../../Components/Popups/AddAudioPopup";
import DeleteResources from "../../../../Components/Popups/DeleteResources";
import EditAudioPopup from "../../../../Components/Popups/EditAudioPopup";
import { fetchCategories } from "../../../../Components/Popups/CommonData";
const Audiopage = () => {
  const columns = [
    { id: "#", label: "#" },
    { id: "Audio", label: "Audio " },
    { id: "Category", label: "Category " },
    { id: "Audio Name", label: "Audio Name" },
    { id: "Length", label: "Length" },
    // { id: "Price", label: "Price" },
    { id: "Action", label: "Action" },
  ];
  const dispatch = useDispatch();
  const storeAudiodata = useSelector((store) => store?.Resources);
  const [isaddaudioopen, setisaudioopen] = useState(false);
  const [Audiodata, setAudiodata] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  useEffect(() => {
    setAudiodata(storeAudiodata?.AudioFiles?.r || []);
  }, [storeAudiodata, storeAudiodata]);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(GetallAudios({value:filteredvalue,count:page * rowsPerPage,row_count: rowsPerPage}));
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  }
let [filteredvalue,setfilteredvalue]=useState(0)

  const getfilterdvalue = (filteredvalue) => {
    setfilteredvalue(filteredvalue)
    setPage(0);
   
    dispatch(GetallAudios( {value:filteredvalue,count:page * rowsPerPage,row_count: rowsPerPage}));
  };

  let openisaudio = () => {
    setisaudioopen(true);
  }

  let closeisaudio = () => {
    setisaudioopen(false);
  }

  // --------- Delete Conformation Popup ------------
  let resourcetype = "audio";
  let [deleteselected, setdeleteselected] = useState({});
  let [isdeleteresource, setisdeleteresource] = useState();

  let openresorcedelete = (data) => {
    setdeleteselected(data);
    setisdeleteresource(true);
  }

  let closedeleteresource = () => {
    setisdeleteresource(false);
  }


  // --------- Edit popuo Popup ------------

  let [editfiledata, seteditfiledata] = useState({})

  let [iseditpopup, setiseditpopup] = useState(false)

  let openeditpopup = (data) => {
    seteditfiledata(data);
    setiseditpopup(true);
  }

  let closeeditpopup = () => {
    setiseditpopup(false);
  }

  useEffect(() => {
    dispatch(GetallAudios({value:filteredvalue,count:page * rowsPerPage,row_count: rowsPerPage}));
    dispatch(GetAllCategory())
  }, [page, rowsPerPage]);

  let Rowdata = Audiodata?.map((data, i) => ({
    rowid: i + 1 + page * 10,
    ...data,
  }))

console.log(storeAudiodata?.CategoryList)

  return (
    <>
      {iseditpopup ? (
        <EditAudioPopup
          modalcontrols={{ iseditpopup, closeeditpopup, editfiledata ,value:filteredvalue,count:page * rowsPerPage,row_count: rowsPerPage}}
        />
      ) : (
        ""
      )}

      {isdeleteresource ? (
        <DeleteResources
          modalcontrols={{
            isdeleteresource,
            closedeleteresource,
            resourcetype,
            deleteselected,
            value:filteredvalue,count:page * rowsPerPage,row_count: rowsPerPage
          }}
        />
      ) : (
        ""
      )}
      {isaddaudioopen ? (
        <AddAudioPopup modalcontrols={{ isaddaudioopen, closeisaudio ,value:filteredvalue,count:page * rowsPerPage,row_count: rowsPerPage}} />
      ) : (
        ""
      )}

      <Box
        sx={{
          width: "100%",
          height: "40px",
          display: "flex",
          justifyContent: "space-between",
          mt: "15px",
        }}
      >
        <Box
          sx={{
            width: "20%",
            height: "20%",
            display: "flex",
            alignItems: "center",
            background: "rgba(246, 133, 130, 0.50)",
          }}
        >
          <FormControl sx={{ width: "100%", height: "100%" }} size="small">
            <Select
              sx={{
                width: "100%",
                height: "60px",
                border: "1px solid #F68582",
                background: "#fff",
              }}
              labelId="demo-select-small-label"
              id="demo-select-small"
              label="All"
              onChange={(e) => getfilterdvalue(e.target.value)}
              defaultValue={0} // Set the value corresponding to "All" as the default value
            >
              <MenuItem value={0}>All</MenuItem>
              {storeAudiodata?.CategoryList?.map((el)=>
              <MenuItem value={el?.id}>{el?.name} </MenuItem>
              )}
             
            </Select>
          </FormControl>
        </Box>
        <Button
          onClick={openisaudio}
          sx={{
            padding: "5px 40px",
            textTransform: "capitalize",
            color: "#000",
            boxShadow:
              "2px 2px 8px 0px rgba(143, 95, 11, 0.50), 0px 3px 11.111px 0px #FBB15A inset, 0px -11px 11.111px 0px #D68835 inset",
            background: " linear-gradient(180deg, #FFCE98 0%, #F4AA50 100%);",
          }}
        >
          Add More
        </Button>
      </Box>
      <Box sx={{ mt: "20px" }}>
        <Paper style={{ width: "100%", overflow: "hidden" }}>
          <TableContainer style={{ maxHeight: "71vh" }}>
            <Table stickyHeader sx={{}}>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      sx={{
                        textAlign: "center",
                        fontSize: "15px",
                        lineHeight: "120%",
                        fontFamily: "Montserrat",
                        fontWeight: "600",
                        background: "#F68582",
                        color: "#fff",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody sx={{ textAlign: "center" }}>
                {Rowdata?.length < 1 ? (
                  <Box sx={{ textAlign: "center", padding: "10px", m: "10px" }}>
                    No Data
                  </Box>
                ) : (
                  Rowdata?.map((row, i) => (
                    <TableRow key={row.id} sx={{ textAlign: "center" }}>
                      <TableCell sx={{ textAlign: "center" }}>
                      {row?.rowid}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <img
                          src={` ${audiocoverurl}/${row?.cover_img}`}
                          style={{
                            height: "40px",
                            width: "50px",
                            borderRadius: "10px",
                          }}
                          alt=""
                        />
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <Tooltip



                          title={
                            <Box>
                              {row?.category?.map((item, index) => (
                                <Typography
                                  sx={{ fontSize: "14px" }}
                                  key={index}
                                >
                                  {item?.name}
                                </Typography>
                              ))}
                            </Box>
                          }
                        >
                          <Typography
                            noWrap
                            sx={{
                              width: "180px",
                              display: "flex",
                              m: "auto",
                              textOverflow: "ellipsis",
                              cursor: "pointer",
                              justifyContent:"center",
                              textAlign:"center"
                            }}
                          >
                           {row?.category[0]?.name}, {row?.category[1]?.name} {row?.category?.length<2?"":<span>....</span>} 
                            {/* {row?.category[1]?.name}  */}
                            {/* {row?.category?.map((item, index) => (
                              <Typography sx={{ fontSize: "14px" }} key={index}>
                                {item?.name},
                              </Typography>
                            ))} */}
                          </Typography> 
                        </Tooltip>
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {row?.title}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {" "}
                        {row?.length}
                      </TableCell>
                      {/* <TableCell sx={{ textAlign: "center" }}>
                        {" "}
                        {row?.price == 0 ? (
                          <Chip
                            label="Free"
                            color="success"
                            variant="outlined"
                          />
                        ) : (
                          <Typography>${row?.price}</Typography>
                        )}
                      </TableCell> */}

                      <TableCell
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Box
                          sx={{
                            height: "50px",
                            display: "flex",
                            gap: "20px",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            onClick={() => openresorcedelete(row)}
                            style={{
                              width: "30px",
                              height: "30px",
                              cursor: "pointer",
                            }}
                            src={deletelogo}
                            alt=""
                          />
                          <img
                            onClick={() => openeditpopup(row)}
                            style={{
                              width: "30px",
                              height: "30px",
                              cursor: "pointer",
                            }}
                            src={editlogo}
                            alt=""
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        <Box>
          <TablePagination
            sx={{ mb: "-20px" }}
            component="div"
            count={storeAudiodata?.AudioFiles?.count || 0}
            page={page}
            onPageChange={handleChangePage}
            // rowsPerPage={10}
            // rowsPerPageOptions={[]}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Box>
    </>
  );
};

export default Audiopage;
