import { OutlinedInput } from '@mui/material'
import React from 'react'

const CommonInput = ({ onChange, value, placeholder }) => {
  return (
    <div>  
        <OutlinedInput
        id={"vhgbftbnfkgsvf"}
        name="email"
        aria-describedby="outlined-weight-helper-text"
        placeholder={placeholder}
        onChange={onChange}
        value={value }
        sx={{
          width: "100%",
          color: "black",
          fontSize: "20px",
          borderRadius: "8px",
          fontFamily: "Montserrat",
          borderColor: "  #DADADA",
          outline: "none",
          "& .MuiFilledInput-root": {
            background: "var(--white-100, #FFF)",
            outline: "none",
          },
          "&:hover": {
            "& fieldset": {
              outline: "none",
              // background: "var(--white-100, #FFF)",
            },
            "& .MuiInputBase-input": {
              color: "black", // Change the color to black on hover
            },
          },
          "& .MuiInputBase-input": {
            color: "black",
            fontSize: { xs: "13px", md: "16px" },
            fontWeight: 400,
            lineHeight: "24px",
            border: "2px solid var(--Button-Color, #F68582)",
            padding: "8px 15px",
            background: "var(--white-100, #FFF)",
            outline: "none",
            borderRadius: "10px",
            "&::placeholder": {
              color: "rgba(41, 45, 50, 0.50)",
              background: "var(--white-100, #FFF)",
              opacity: 0.5,
              fontSize: { xs: "13px", md: "16px" },
              fontWeight: 400,
              lineHeight: "10px",
              outline: "none",
            },
          },
          "&:focus": {
            outline: "none",
            boxShadow: "none",
            color: "rgba(41, 45, 50, 0.50)",
          },
          "&.Mui-focused fieldset": {
            //   borderColor: "#FFFF",
            outline: "none",
            border: "solid red",
          },
        }}
        inputProps={{ autoComplete: "off" }}
      />
    </div>
  )
}

export default CommonInput