import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import ClearIcon from "@mui/icons-material/Clear";
import React, { useEffect, useState } from "react";
import { modalstyle } from "./PopupsStyling";
import PopupTitle from "./PopupComponent/PopupTitle";
import ActionButton from "./PopupComponent/ActionButton";
import TostContainer from "../Alerts/TostContainer";
import { errortost, sucesstost } from "../Alerts/CustomAlert";
import { useDispatch, useSelector } from "react-redux";
import { Ebokscoverurl } from "../../Apis/Api";
import {
  AddEbookitem,
  EditEbookitems,
  GetEbookDetail,
  GetEbooklist,
} from "../../Redux/Slices/Ebooks/Ebooks.action";
let inputstyle = {
  width: "100%",
  height: "50px",
  borderRadius: "10px",
  border: "1px solid #F68582",
  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
};

let Inputboxstyle = {
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  gap: "10px",
};

let Lablestyle = {
  fontSize: "15px",
  mt: "10px",
  fontWeight: "500",
};

const Add_Edit_Ebooklist = ({ modalcontrols, row, rowid }) => {
  console.log(rowid);
  let { isEbookopen, closeEbook, popuptitle, rowsPerPage, count } =
    modalcontrols;
  const [videoList, setVideoList] = useState([]);
  let [RemovedVideoFileListId, setRemovedVideoFileListId] = useState([]);
  const [title, settitle] = useState(
    row?.rowdata !== null ? row?.rowdata?.title : ""
  );
  const [author_name, setauthor_name] = useState(
    row ? row?.rowdata?.author_name : ""
  );
  // const [price, setPrice] = useState(row ? row?.rowdata?.price : "");
  const [cat_type, setcat_type] = useState(row ? row?.rowdata?.cat_type : "");
  const [description, setdescription] = useState(
    row ? row?.rowdata?.description : ""
  );
  const [cover_img, setcover_img] = useState(
    row ? row?.rowdata?.cover_img : null
  );
  const [file, setfile] = useState(row ? row?.rowdata?.file : null);

  const [video_files, setvideo_files] = useState([]);
  let [VideothumbnailFile, setVideothumbnailFile] = useState([]);
  const [newvideofilenames, setnewvideofilenames] = useState([]);
  const handleVideoFileChange = async (event) => {
    const files = Array.from(event?.target?.files);
    const videoFiles = files.filter((file) => {
      if (!file.type.startsWith("video/")) {
        errortost(`File ${file.name} is not a video file.`);
        return false;
      }
      return true;
    });
  
    if (videoFiles.length > 0) {
      const updatedFiles = [...video_files, ...videoFiles];
      // Check if updatedFiles contains new video files
      if (updatedFiles.length > video_files.length) {
        setvideo_files(updatedFiles);
        setnewvideofilenames(updatedFiles);
        
        const thumbnailPromises = videoFiles.map((file, index) =>
          captureThumbnail(file, index)
        );
        const thumbnails = await Promise.all(thumbnailPromises);
        const Updatedvideothumb = [...VideothumbnailFile, ...thumbnails];
        setVideothumbnailFile(Updatedvideothumb);
      }
    }
  };

  const captureThumbnail = (file, index) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement("video");
      const canvas = document.createElement("canvas");

      video.src = URL.createObjectURL(file);
      video.crossOrigin = "anonymous"; // Handle cross-origin videos

      video.onloadedmetadata = () => {
        video.currentTime = video.duration / 2; // Capture middle frame
      };

      video.onseeked = () => {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const context = canvas.getContext("2d");
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        canvas.toBlob((blob) => {
          resolve(blob);
        }, "image/png");
      };

      video.onerror = () => {
        reject("Error loading video");
      };
    });
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = [...video_files];
    const updatedVideoThumbFiles = [...VideothumbnailFile];
    updatedFiles.splice(index, 1);
    updatedVideoThumbFiles.splice(index, 1);
    setvideo_files(updatedFiles);
    setVideothumbnailFile(updatedVideoThumbFiles);
    setnewvideofilenames(updatedFiles);
  };

  // const [isFree, setIsFree] = useState(true);
  let categorydata = useSelector((store) => store?.Ebooks);
  let [loading, setloading] = useState(false);
  let [editloading, seteditloading] = useState(false);
  let dispatch = useDispatch();
  let [previewimg, setpreviewimg] = useState();
  let [newfilename, setnewfilename] = useState("");
  const [formErrors, setFormErrors] = useState({
    title: "",
    author_name: "",
    price: "",
    cat_type: "",
    description: "",
    cover_img: "",
    file: "",
    // video_files: "",
  });

  const resetForm = () => {
    settitle("");
    setauthor_name("");
    // setPrice("");
    setcat_type("");
    setdescription("");
    setcover_img(null);
    setfile(null);
    // setvideo_files([]);
    // setIsFree(true);
    setpreviewimg(null);
    setFormErrors({
      title: "",
      author_name: "",
      // price: "",
      cat_type: "",
      description: "",
      cover_img: "",
      file: "",
      // video_files: "",
    });
  };
  const validateForm = () => {
    const titleValue = title || "";
    const authorNameValue = author_name || "";
    // const priceValue = price || "";
    const catTypeValue = cat_type || "";
    const descriptionValue = description || "";
    const coverImgValue = cover_img || "";
    const fileValue = file || "";
    // const videoFileValue = video_files || ""; // Validate video file
    const errors = {
      title: titleValue === "" ? "Title is required." : "",
      author_name: authorNameValue === "" ? "Author name is required." : "",
      // price: priceValue === "" ? "Price is required." : "",
      cat_type: catTypeValue === "" ? "Category type is required." : "",
      description: descriptionValue === "" ? "Description is required." : "",
      cover_img: coverImgValue === "" ? "Cover image is required." : "",
      file: fileValue === "" ? "File is required." : "",
      // video_files: videoFileValue === "" ? "Video file is required." : "",
    };
    setFormErrors(errors);
    return Object.values(errors).every((error) => error === "");
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormErrors({ ...formErrors, [name]: false });
    if (name === "title") {
      settitle(value);
    } else if (name === "author_name") {
      setauthor_name(value);
    } else if (name === "cat_type") {
      setcat_type(value);
    } else if (name === "description") {
      setdescription(value);
    } 
    // else if (name === "price") {
    //   setPrice(value);
    // }
  };

  const handleFileChange = (event, fileType) => {
    const file = event.target.files[0];
    if (!file) {
      console.log(`Please select a file for ${fileType}`);
      setFormErrors({ ...formErrors, [fileType]: true });
      return;
    }
    if (fileType === "image" && !file.type.startsWith("image/")) {
      setFormErrors({ ...formErrors, [fileType]: true });
      return;
    }
    if (fileType === "file" && !file.type.startsWith("application/pdf")) {
      setFormErrors({ ...formErrors, [fileType]: true });
      errortost("Please select correct file type");
      return;
    }

    setFormErrors({ ...formErrors, [fileType]: false });
    if (fileType === "image") {
      const objectURL = URL.createObjectURL(file);
      setpreviewimg(objectURL);
      setcover_img(file);
      console.log(file);
    } else if (fileType === "file" && file.type.startsWith("application/pdf")) {
      setfile(file);
      setnewfilename(file);
    }
  };
  let EbookDetailsdata = useSelector((store) => store?.Ebooks);
  useEffect(() => {
    dispatch(GetEbookDetail(rowid));
  }, [rowid]);
  let [isuploadedvideoedited, setisuploadedvideoedited] = useState(false);

  useEffect(() => {
    if (EbookDetailsdata?.EbookDetails?.r?.ebooks_videos) {
      setVideoList(EbookDetailsdata?.EbookDetails?.r?.ebooks_videos);
    } else if (rowid) {
      setVideoList();
    }
  }, [EbookDetailsdata]);

  const handleUploadedVideoRemoveFile = (index) => {
    const removedFileId = videoList[index]?.id; // Assuming each video file has an 'id' property
    setVideoList((prevVideoList) =>
      prevVideoList?.filter((_, i) => i !== index)
    );
    setRemovedVideoFileListId((prevIds) => [...prevIds, removedFileId]);
    setisuploadedvideoedited(true);
  };
  const HandeldEbookButton = () => {
    const isValid = validateForm();
    if (isValid) {
      const formData = new FormData();
      if (row) {
        if (title !== row.rowdata.title) {
          formData.append("title", title);
        }
        if (cat_type !== row.rowdata.cat_type) {
          formData.append("cat_type", cat_type);
        }
        if (author_name !== row.rowdata.author_name) {
          formData.append("author_name", author_name);
        }
        if (description !== row.rowdata.description) {
          formData.append("description", description);
        }
        if (cover_img !== row.rowdata.cover_img) {
          formData.append("cover_img", cover_img);
        }
        if (file !== row.rowdata.file) {
          formData.append("file", file);
        }
        if (isuploadedvideoedited || video_files?.length >= 1) {
          if (isuploadedvideoedited) {
            formData.append(
              "remove_video_ids",
              RemovedVideoFileListId
            );
          } else if (video_files?.length >= 1) {
            video_files?.forEach((file, index) => {
              formData?.append(`video_file[${index}]`, file);
            });
            // console.log(VideothumbnailFile);
            VideothumbnailFile?.forEach((file, index) => {
              formData.append(`video_thumb[${index}]`, file);
            });
          }
        }
        // if (price !== row.rowdata.price) {
        //   formData.append("price", price);
        // }
        formData.append("id", row.rowdata?.id);
        seteditloading(true);
        dispatch(EditEbookitems(formData))
          .unwrap()
          .then((result) => {
            if (result.s === 1) {
              seteditloading(false);
              dispatch(GetEbooklist({ count: 0, row_count: 10 }));
              sucesstost("Ebook Edited Sucessfully !");
              closeEbook();
              setVideothumbnailFile([]);

              setisuploadedvideoedited(false);
              resetForm();
            } else {
              seteditloading(false);
              dispatch(GetEbooklist({ count: 0, row_count: 10 }));
              errortost("Something Wents wrong .");
              closeEbook();
              setisuploadedvideoedited(false);
              setVideothumbnailFile([]);

              resetForm();
            }
          });
      } else {
        formData.append("title", title);
        formData.append("author_name", author_name);
        formData.append("cat_type", cat_type);
        formData.append("cover_img", cover_img);
        formData.append("description", description);
        formData.append("file", file);
        // formData.append("price", price);
        video_files?.forEach((file, index) => {
          formData.append(`video_file[${index}]`, file);
        });
        VideothumbnailFile?.forEach((file, index) => {
          formData.append(`video_thumb[${index}]`, file);
        });
        setloading(true);
        dispatch(AddEbookitem(formData))
          .unwrap()
          .then((result) => {
            if (result.s === 1) {
              setloading(false);
              dispatch(GetEbooklist({ count: 0, row_count: 10 }));
              sucesstost("Ebook Added Sucessfully !");
              closeEbook();
              resetForm();
              setnewfilename("");
              setnewvideofilenames([]);
              setisuploadedvideoedited(false);
              setvideo_files([]);
              setVideothumbnailFile([]);
            } else {
              setloading(false);
              errortost("Something Wents wrong .");
              closeEbook();
              resetForm();
              setnewvideofilenames([]);
              dispatch(GetEbooklist({ count: 0, row_count: 10 }));
              setvideo_files([]);
              setnewfilename("");
              setisuploadedvideoedited(false);
              setVideothumbnailFile([]);
            }
          });
      }
    }
  };

  return (
    <>
      <TostContainer />
      <Modal open={isEbookopen} onClose={closeEbook}>
        <Box
          sx={{
            ...modalstyle,
            borderRadius: "10px",
            width: "40%", // Adjusted width
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            boxSizing: "border-box",
            overflow: "hidden", // Added overflow property
          }}
        >
          <PopupTitle popuptitle={popuptitle} />
          <Box
            sx={{ height: "80vh", overflow: "auto", boxSizing: "border-box" }}
          >
            {/* --------- Ebooks Title ------- */}

            <Box sx={{ ...Inputboxstyle }}>
              <InputLabel
                id="demo-select-small-label"
                ls
                sx={{
                  color: formErrors.title ? "red" : "#000",
                }}
              >
                Enter Ebooks Title
              </InputLabel>

              <OutlinedInput
                sx={{
                  ...inputstyle,
                }}
                id="outlined-adornment-weight"
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
                value={title?.trimStart()}
                placeholder=" Title"
                onChange={handleChange}
                name="title"
              />
            </Box>

            {/* --------- Author Name ------- */}
            <Box sx={{ ...Inputboxstyle }}>
              <InputLabel
                id="demo-select-small-label"
                sx={{
                  ...Lablestyle,
                  color: formErrors.author_name ? "red" : "#000",
                }}
              >
                Enter Author name
              </InputLabel>
              <OutlinedInput
                sx={{
                  ...inputstyle,
                }}
                id="outlined-adornment-weight"
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
                placeholder="Author name"
                value={author_name?.trimStart()}
                onChange={handleChange}
                name="author_name"
              />
            </Box>
            {/* ----- Select Category ------ */}

            <Box sx={{ ...Inputboxstyle }}>
              <InputLabel
                id="demo-select-small-label"
                sx={{
                  ...Lablestyle,
                  color: formErrors.cat_type ? "red" : "#000",
                }}
              >
                Select Category
              </InputLabel>
              <Select
                sx={{
                  width: "100%",
                  height: "50px", // Adjust the height as needed
                  borderRadius: "10px",
                  border: "1px solid #F68582",
                  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
                }}
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={cat_type}
                onChange={handleChange}
                name="cat_type"
                placeholder="Select Category"
              >
                <MenuItem value="">Select Category</MenuItem>
                {categorydata?.EbooksCategories.map((el) => (
                  <MenuItem value={el?.id}> {el?.cat_name} </MenuItem>
                ))}
              </Select>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                boxSizing: "border-box",
              }}
            >
              <InputLabel
                id="demo-select-small-label"
                sx={{
                  ...Lablestyle,
                  color: formErrors.description ? "red" : "#000",
                }}
              >
                Description
              </InputLabel>
              <TextField
                value={description?.trimStart()}
                onChange={handleChange}
                name="description"
                id="filled-multiline-static"
                sx={{
                  width: "100%",
                  borderRadius: "10px",
                  boxSizing: "border-box",
                  border: "1px solid #F68582",
                  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
                }}
                placeholder="Description"
                multiline
                rows={4}
              />
            </Box>

            <Box sx={{ ...Inputboxstyle }}>
              <InputLabel
                id="demo-select-small-label"
                sx={{
                  ...Lablestyle,
                  color: formErrors.cover_img ? "red" : "#000",
                }}
              >
                Upload Book Cover Image
              </InputLabel>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  border: "1px solid #F68582",
                  padding: "10px",
                  borderRadius: "10px",
                  boxSizing: "border-box",
                }}
              >
                {previewimg ? (
                  <img
                    style={{ width: "150px", height: "100%" }}
                    src={previewimg}
                    alt="cover_img"
                  />
                ) : row ? (
                  <Box>
                    <img
                      style={{ width: "150px", height: "100%" }}
                      src={`${Ebokscoverurl}/${row?.rowdata?.cover_img}`}
                      alt=""
                    />
                  </Box>
                ) : (
                  <Typography sx={{ color: "#000" }}>Choose Image </Typography>
                )}
                <Input
                  type="file"
                  id="image-cover-file-input"
                  style={{ display: "none" }}
                  onChange={(event) => handleFileChange(event, "image")}
                />
                <label htmlFor="image-cover-file-input">
                  <Button
                    variant="contained"
                    component="span"
                    sx={{
                      background: "rgba(246, 133, 130, 0.50)",
                      textDecoration: "capitalize",
                      color: "#000",
                      height: "30px",
                      borderRadius: "10px",
                      transition: "background 0.3s ease", // Add a smooth transition for the background change
                      "&:hover": {
                        background: "rgba(246, 133, 130, 0.70)", // Slightly darker color on hover
                      },
                    }}
                  >
                    <Typography sx={{ textTransform: "capitalize" }}>
                      Browse
                    </Typography>
                  </Button>
                </label>
              </Box>
            </Box>

            <Box sx={{ ...Inputboxstyle }}>
              <InputLabel
                id="demo-select-small-label"
                sx={{
                  ...Lablestyle,
                  color: formErrors.file ? "red" : "#000",
                }}
              >
                Upload File
              </InputLabel>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  border: "1px solid #F68582",
                  padding: "10px",
                  borderRadius: "10px",
                }}
              >
                <Typography noWrap style={{ color: "#000", width: "50%" }}>
                  {newfilename ? (
                    <Typography>{newfilename?.name}</Typography>
                  ) : row ? (
                    <Box>{row?.rowdata?.file}</Box>
                  ) : (
                    <Typography> Upload file </Typography>
                  )}
                </Typography>
                <Input
                  type="file"
                  id="file-cover-file-input"
                  style={{ display: "none", boxSizing: "border-box" }}
                  onChange={(event) => handleFileChange(event, "file")}
                />

                <label htmlFor="file-cover-file-input">
                  <Button
                    variant="contained"
                    component="span"
                    sx={{
                      background: "rgba(246, 133, 130, 0.50)",
                      textDecoration: "capitalize",
                      color: "#000",
                      height: "30px",
                      borderRadius: "10px",
                      transition: "background 0.3s ease", // Add a smooth transition for the background change
                      "&:hover": {
                        background: "rgba(246, 133, 130, 0.70)", // Slightly darker color on hover
                      },
                    }}
                  >
                    <Typography sx={{ textTransform: "capitalize" }}>
                      Browse
                    </Typography>
                  </Button>
                </label>
              </Box>
            </Box>

            <Box sx={{ ...Inputboxstyle }}>
              <InputLabel
                id="demo-select-small-label"
                sx={{
                  ...Lablestyle,
                  color: "#000",
                }}
              >
                Upload Video Files
              </InputLabel>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  border: "1px solid red",
                  padding: "10px",
                  borderRadius: "10px",
                }}
              >
                <InputLabel
                  id="demo-select-small-label"
                  sx={{
                    ...Lablestyle,
                    color: "#000",
                  }}
                >
                  {newvideofilenames?.length >= 1 ? (
                    <Typography>
                      {newvideofilenames?.length} files selected
                    </Typography>
                  ) : (
                    "Upload files "
                  )}
                </InputLabel>
                <Input
                  type="file"
                  name="video_files"
                  accept="video/*"
                  id="video-cover-file-input"
                  inputProps={{ multiple: true }}
                  onChange={handleVideoFileChange}
                  style={{ display: "none", boxSizing: "border-box" }}
                />

                <label htmlFor="video-cover-file-input">
                  <Button
                    variant="contained"
                    component="span"
                    sx={{
                      background: "rgba(246, 133, 130, 0.50)",
                      textDecoration: "capitalize",
                      color: "#000",
                      height: "30px",
                      borderRadius: "10px",
                      transition: "background 0.3s ease", // Add a smooth transition for the background change
                      "&:hover": {
                        background: "rgba(246, 133, 130, 0.70)", // Slightly darker color on hover
                      },
                    }}
                  >
                    <Typography sx={{ textTransform: "capitalize" }}>
                      {newvideofilenames?.length >= 1 ? "Add more" : "Browse"}
                    </Typography>
                  </Button>
                </label>
              </Box>
              <Box sx={{ padding: "10px" }}>
                <Typography
                  sx={{ color: "#000", fontSize: "16px", mb: "10px" }}
                >
                  Selected Video Files
                </Typography>
                {newvideofilenames?.length > 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    {newvideofilenames?.map((file, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          background: "  rgba(246, 133, 130, 0.25)",
                          padding: "5px 15px",
                          borderRadius: "10px",
                        }}
                      >
                        <Typography sx={{ fontSize: "13px", color: "black" }}>
                          {file?.name}
                        </Typography>

                        <ClearIcon
                          onClick={() => handleRemoveFile(index)}
                          sx={{ color: "red", cursor: "pointer" }}
                        />
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
              {row && videoList?.length >= 1 ? (
                <Box sx={{ padding: "10px" }}>
                  <Typography sx={{ color: "#000", mb: "10px" }}>
                    Uploaded Video Files
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    {videoList?.length >= 1
                      ? videoList?.map((el, index) => (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              background: "  rgba(246, 133, 130, 0.25)",
                              padding: "5px 15px",
                              borderRadius: "10px",
                            }}
                          >
                            <Typography
                              sx={{ color: "#000", fontSize: "13px" }}
                            >
                              {el?.video_file}
                            </Typography>

                            <ClearIcon
                              onClick={() =>
                                handleUploadedVideoRemoveFile(index)
                              }
                              sx={{ color: "red", cursor: "pointer" }}
                            />
                          </Box>
                        ))
                      : ""}
                  </Box>
                </Box>
              ) : (
                ""
              )}
            </Box>

            {/* <Box sx={{ ...Inputboxstyle }}>
              <InputLabel
                id="demo-select-small-label"
                sx={{
                  ...Lablestyle,
                  color: formErrors.cover_img ? "red" : "#000",
                }}
              >
                Upload Video Thubnails
              </InputLabel>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  border: "1px solid #F68582",
                  padding: "10px",
                  borderRadius: "10px",
                  boxSizing: "border-box",
                  gap: "10px",
                }}
              >
                {previewImageThumbnails.length >= 1 ? (
                  previewImageThumbnails?.map((el) => (
                    <Box sx={{ position: "relative", border: "1px solid red" }}>
                      <img
                        style={{ width: "150px", height: "100%" }}
                        src={el}
                        alt="video_thumbnails"
                      />
                      <ClearIcon
                        // onClick={() =>
                        //   handleUploadedVideoRemoveFile(index)
                        // }
                        sx={{
                          color: "red",
                          cursor: "pointer",
                          position: "absolute",
                          top: "-10px",
                          right: "-10px",
                        }}
                      />
                    </Box>
                  ))
                ) : row ? (
                  <Box>
                    <img
                      style={{ width: "150px", height: "100%" }}
                      src={`${Ebokscoverurl}/${row?.rowdata?.cover_img}`}
                      alt=""
                    />
                  </Box>
                ) : (
                  <Typography sx={{ color: "#000" }}>Choose Image </Typography>
                )}
                <Input
                  type="file"
                  id="image-thubnail-file-input"
                  style={{ display: "none" }}
                  inputProps={{ multiple: true }}
                  onChange={handleImageThumbnails}
                />
                <label htmlFor="image-thubnail-file-input">
                  <Button
                    variant="contained"
                    component="span"
                    sx={{
                      background: "rgba(246, 133, 130, 0.50)",
                      textDecoration: "capitalize",
                      color: "#000",
                      height: "30px",
                      borderRadius: "10px",
                      transition: "background 0.3s ease", // Add a smooth transition for the background change
                      "&:hover": {
                        background: "rgba(246, 133, 130, 0.70)", // Slightly darker color on hover
                      },
                    }}
                  >
                    <Typography sx={{ textTransform: "capitalize" }}>
                      Browse
                    </Typography>
                  </Button>
                </label>
              </Box>
            </Box> */}
            {/* ------ Price ---------- */}
            {/* <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                width: "100%",
                mt: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <FormControl
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      color: formErrors.price ? "red" : "#000",
                      mb: "10px",
                    }}
                  >
                    Price
                  </Typography>

                  <OutlinedInput
                    sx={{
                      width: "100%",
                      height: "50px",
                      borderRadius: "10px",
                      border: "1px solid #F68582",
                      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                        {
                          display: "none",
                        },
                    }}
                    id="outlined-adornment-weigdht"
                    aria-describedby="outlined-weight-helper-text"
                    value={price}
                    onChange={handleChange}
                    name="price"
                    type="Number"
                    onKeyDown={(evt) =>
                      ["e", "E", "+", "-"].includes(evt.key) &&
                      evt.preventDefault()
                    }
                  />
                </FormControl>
              </Box>
            </Box> */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "40%",
                m: "auto",
                mt: "20px",
              }}
            >
              <ActionButton
                title={"Done"}
                loading={loading || editloading}
                onclick={HandeldEbookButton}
              />
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Add_Edit_Ebooklist;
