import { createSlice } from "@reduxjs/toolkit";
import {
  AddNewAudio,
  AddNewBlog,
  AddNewVideo,
  EditAudio,
  EditBlogs,
  EditVideo,
  GetAllCategory,
  GetallAudios,
  GetallBlogs,
  GetallVideos,
} from "./Resources.action";

let initialState = {
  loading: false,
  error: null,
  AudioFiles: [],
  VideoFiles: [],
  BlogFiles: [],
  message: "", // Corrected typo from 'massage' to 'message'
  isFileUpload: null,
  CategoryList:[]
};

const ResourcesSlice = createSlice({
  name: "Users",
  initialState,
  extraReducers: (builder) => {
    // --------- Add New Audio ------------
    builder
      .addCase(AddNewAudio.pending, (state) => {
        state.loading = true;
      })
      .addCase(AddNewAudio.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(AddNewAudio.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });

    // --------- Add New video ------------
    builder
      .addCase(AddNewVideo.pending, (state) => {
        state.loading = true;
      })
      .addCase(AddNewVideo.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(AddNewVideo.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });

    // --------- Add New Blog ------------
    builder
      .addCase(AddNewBlog.pending, (state) => {
        state.loading = true;
      })
      .addCase(AddNewBlog.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(AddNewBlog.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });


    // ---------- Get all Audios --------
    builder
      .addCase(GetallAudios.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetallAudios.fulfilled, (state, action) => {
        state.loading = false;
        state.AudioFiles = action?.payload;
      })
      .addCase(GetallAudios.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });

    // ---------- Get all videos --------
    builder
      .addCase(GetallVideos.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetallVideos.fulfilled, (state, action) => {
        state.loading = false;
        state.VideoFiles = action?.payload;
      })
      .addCase(GetallVideos.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });

    // ---------- Get all Blogs --------
    builder
      .addCase(GetallBlogs.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetallBlogs.fulfilled, (state, action) => {
        state.loading = false;
        state.BlogFiles = action?.payload;
      })
      .addCase(GetallBlogs.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
  

      
    // --------- Edit Audio ------------
    builder
      .addCase(EditAudio.pending, (state) => {
        state.loading = true;
      })
      .addCase(EditAudio.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(EditAudio.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });

      
    // --------- Edit Video ------------
    builder
      .addCase(EditVideo.pending, (state) => {
        state.loading = true;
      })
      .addCase(EditVideo.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(EditVideo.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });

    // ---------- Edit Blog --------
    builder
      .addCase(EditBlogs.pending, (state) => {
        state.loading = true;
      })
      .addCase(EditBlogs.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(EditBlogs.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });

        // ---------- Get all Category --------
    builder
    .addCase(GetAllCategory.pending, (state) => {
      state.loading = true;
    })
    .addCase(GetAllCategory.fulfilled, (state, action) => {
      state.loading = false;
      state.CategoryList = action?.payload?.r
    })
    .addCase(GetAllCategory.rejected, (state) => {
      state.error = true;
      state.loading = false;
    });

  },
});

export default ResourcesSlice.reducer;
