import React, { useState } from "react";
import TitleText from "../../../../Components/TitleText";
import { Box, Button, Typography } from "@mui/material";
import { Link, Outlet, useLocation } from "react-router-dom";
import { CommonButtonstyle } from "../../../../Components/CommonCss";
import AddAvailabilityPopup from "../../../../Components/Popups/AddAvailabilityPopup";
import { sucesstost } from "../../../../Components/Alerts/CustomAlert";
import AddGroupeSession from "../../../../Components/Popups/AddGroupeSession";
const Schedules = () => {
  let commonfont = {
    fontStyle: "Poppins",
    fontSize: "20px",
    fontWeight: "550",
    lineHeight: "100%",
  };

  let links = [
    { id: 1, name: "Individual Sessions", path: "/dashboard/schedules" },
    {
      id: 2,
      name: "Group Sessions",
      path: "/dashboard/schedules/groupsession",
    },
  ];

  const location = useLocation();
  const currentPath = location.pathname;
  let [Categorievalue, setcategorivalue] = useState("");
  let [loading, setloading] = useState(false);

  // ----------- Delete Affermation ----------
  let [isGroupeSessionopen, setisGroupeSessionopen] = useState(false);

  let opengroupesessionmodal = () => {
    setisGroupeSessionopen(true);
  };

  let closeGroupesession = () => {
    setisGroupeSessionopen(false);
  };

  return (
    <>
      {/* { isGroupeSessionopen ?<AddGroupeSession modalcontrols={{isGroupeSessionopen,closeGroupesession}}/>:""} */}

      <TitleText title={"Sessions"} />

      {currentPath == "/dashboard/schedules/view_individual_details"  || currentPath == "/dashboard/schedules/groupsession/view_group_details" ? (
        ""
      ) : (
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: "20px",
            }}
          >
            <Box sx={{ display: "flex", gap: "20px" }}>
              {links?.map((li, i) => (
                <Box>
                  <Link to={li.path} style={{ textDecoration: "none" }}>
                    <Typography
                      // onClick={() => setselectedtab(i + 1)}
                      sx={{
                        ...commonfont,
                        cursor: "pointer",
                        fontSize: "18px",
                        position: "relative",
                        textDecoration: "none",
                        borderBottom:
                          currentPath == li?.path
                            ? "3px solid #F68582"
                            : "3px solid transparent",
                        color: currentPath == li?.path ? "black" : "gray",
                        "&:hover": {
                          textDecoration: "none", // Remove the default underline
                          borderBottom: "3px solid #F68582", // Add the color as an underline
                          transition: "border-bottom 0.2s ease-in-out", // Add transition for the border-bottom
                        },
                      }}
                    >
                      {li?.name}
                    </Typography>
                  </Link>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      )}

      <Box>
        <Outlet />
      </Box>
    </>
  );
};

export default Schedules;
