import { Box, Typography } from "@mui/material";
import React from "react";

const Infobox = ({ data }) => {
  console.log(data);
  return (
    <>
      <Box
        sx={{
          padding: "24px",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          width: "100%",
          background: data.el?.backgraund,
          boxShadow: "0px 0px 10px 0px rgba(255, 255, 255, 0.25)",
          borderRadius: "16px",
        }}
      >
        <Typography
          sx={{ fontWeight: "600", fontSize: "16px", lineHeight: "28px" }}
        >
          {data?.el?.title}
        </Typography>
        <Typography
          sx={{ fontWeight: "600", fontSize: "24px", lineHeight: "28px" }}
        >
          {" "}
         {data?.el?.value} {data?.el?.more}
        </Typography>
      </Box>
    </>
  );
};

export default Infobox;
