import React, { useEffect, useState } from "react";
import DetailsTable from "./DetailsTable";
import { useLocation, useParams } from "react-router-dom";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Select,
  Button,
  FormControl,
  MenuItem,
  TablePagination,
  Chip,
  // TablePagination,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getEbooksdatabyuser } from "../../../../Redux/Slices/Users/User.action";
import { Ebokscoverurl } from "../../../../Apis/Api";
const EbooksSession = () => {
  const columns = [
    { id: "#", label: "#" },
    { id: "Book", label: "Book" },
    { id: "Title", label: "Title" },
    { id: "Author", label: "Author" },
    { id: "Price", label: "Price" },
    { id: "purchase date", label: "purchase date" },
    { id: "Transection ID", label: "Transection ID" },
  ];

  const { userId } = useParams();

  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(getEbooksdatabyuser({ user_id: userId, count: 0 }));
  }, []);

  let sessionsdata = useSelector((store) => store?.Users);

  // ---------- paginations ----------

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(
      getEbooksdatabyuser({ user_id: userId, count: newPage * rowsPerPage })
    );
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    dispatch(getEbooksdatabyuser({ user_id: userId, count: 0 }));
  };

  return (
    <div>
      <Box sx={{ mt: "20px" }}>
        <Paper style={{ width: "100%", overflow: "hidden" }}>
          <TableContainer style={{ maxHeight: "71vh" }}>
            <Table stickyHeader sx={{}}>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      sx={{
                        textAlign: "center",
                        fontSize: "15px",
                        lineHeight: "120%",
                        fontFamily: "Montserrat",
                        fontWeight: "600",
                        background: "#F68582",
                        color: "#fff",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody sx={{ textAlign: "center" }}>
                {sessionsdata?.UserEbooksData?.r?.length < 1 ? (
                  <Box sx={{ textAlign: "center", padding: "10px", m: "10px" }}>
                    No Data
                  </Box>
                ) : (
                  sessionsdata?.UserEbooksData?.r?.map((row, i) => (
                    <TableRow key={row.id} sx={{ textAlign: "center" }}>
                      <TableCell sx={{ textAlign: "center" }}>
                        {i + 1}
                      </TableCell>

                      <TableCell sx={{ textAlign: "center" }}>
                        {" "}
                        <img
                          src={` ${Ebokscoverurl}/${row?.cover_img}`}
                          style={{
                            height: "40px",
                            width: "50px",
                            borderRadius: "10px",
                          }}
                          alt=""
                        />
                      </TableCell>

                      <TableCell sx={{ textAlign: "center" }}>
                        {" "}
                        {row?.title}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {" "}
                        {row?.author_name}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {" "}
                        ${row?.price}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {" "}
                        {row?.created_at
                          ? (() => {
                              const inputDate = new Date(row.created_at);
                              return `${inputDate
                                .getDate()
                                .toString()
                                .padStart(2, "0")}/${(inputDate.getMonth() + 1)
                                .toString()
                                .padStart(2, "0")}/${inputDate.getFullYear()}`;
                            })()
                          : "N/A"}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {" "}
                        {row?.transation_id}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <TablePagination
            sx={{ mb: "-20px" }}
            // component="div"
            count={sessionsdata?.UserEbooksData?.count || 0}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Box>
    </div>
  );
};

export default EbooksSession;
