import {
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import TitleText from "../../../../Components/TitleText";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import ActionButton from "../../../../Components/Popups/PopupComponent/ActionButton";
import { Link, Outlet, useNavigate } from "react-router-dom";

const AddCoachAvilability = () => {
  const [selectedValue, setSelectedValue] = React.useState("Individual");
  let navigate = useNavigate();
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    navigate(event?.target.value == "Individual" ? "" : "./group_avilability");
  };
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          gap: "18px",
          width: "100%",
          mt: "20px",
          boxSizing: "border-box",
        }}
      >
        <Link
          to={"/dashboard/avilability"}
          style={{ display: "flex", gap: "18px", color: "#000" }}
        >
          {"<"} <TitleText title={"Availability"} style={"0"} />{" "}
        </Link>{" "}
        {">"}
        <TitleText title={"Add Availability "} style={"0"} />
      </Box>

      <Box sx={{ padding: "20px 0px 10px 20px" }}>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            value={selectedValue}
            onChange={handleChange}
          >
            <Box sx={{ display: "flex" }}>
              <FormControlLabel
                value="Individual"
                sx={{
                  "& .MuiRadio-root.Mui-checked": {
                    color: "rgba(246, 133, 130, 1)", // Color of the radio button when checked
                  },
                  "& .MuiFormControlLabel-label": {
                    "&.Mui-checked": {
                      color: "rgba(246, 133, 130, 1)", // Color of the label when checked
                    },
                  },
                }}
                control={<Radio />}
                label="Individual"
              />

              <FormControlLabel
                value="Group"
                sx={{
                  "& .MuiRadio-root.Mui-checked": {
                    color: "rgba(246, 133, 130, 1)", // Color of the radio button when checked
                  },
                  "& .MuiFormControlLabel-label": {
                    "&.Mui-checked": {
                      color: "rgba(246, 133, 130, 1)", // Color of the label when checked
                    },
                  },
                }}
                control={<Radio />}
                label="Group"
              />
            </Box>
          </RadioGroup>
        </FormControl>
      </Box>

      <Box>
        <Outlet />
      </Box>
    </div>
  );
};

export default AddCoachAvilability;
