 let CommonButtonstyle={
    height: "40px",
    // mt: "20px",
    width: "30%",
    textTransform: "capitalize",
    color: "#000",
    boxShadow:
      "2px 2px 8px 0px rgba(143, 95, 11, 0.50), 0px 3px 11.111px 0px #FBB15A inset, 0px -11px 11.111px 0px #D68835 inset",
    background: " linear-gradient(180deg, #FFCE98 0%, #F4AA50 100%);",
}

export  {CommonButtonstyle}