import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Baseurl, getUserdata } from "../../../../Apis/Api";
import { GetallBlogs } from "../../../../Redux/Slices/Resources/Resources.action";
import axios from "axios";
import { useDispatch } from "react-redux";
import { CommonButtonstyle } from "../../../../Components/CommonCss";
import { Categorys } from "../../../../Components/Popups/CommonData";
const AddBlogs = () => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const [category, setCategory] = useState([]);
  const [blogTitle, setBlogTitle] = useState("");
  const [blogDescription, setBlogDescription] = useState("");
  const [blogImage, setBlogImage] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [loading, setloading] = useState(false);
  // const [price, setPrice] = useState(0);
  // const [isFree, setIsFree] = useState(true);
  let dispatch = useDispatch();
  let navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "radio-buttons-group") {
      // Handle radio button change and update isFree state
      // setIsFree(value === "free");
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    } else if (!file.type.startsWith("image/")) {
      console.log("Please select an image file");
      setFormErrors({ ...formErrors, blogImage: true });
      return;
    } else {
      setFormErrors({ ...formErrors, blogImage: false }); // Reset the error if an image is selected
      setBlogImage(file);
    }
  };
  const handleselectChange = (event) => {
    const {
      target: { value },
    } = event;
    setCategory(typeof value === "string" ? value.split(",") : value);
  };
  const validateForm = () => {
    const errors = {
      blogTitle: blogTitle.trim() === "",
      blogDescription: blogDescription.trim() === "",
      blogImage: blogImage === null || formErrors.blogImage,
    };
    setFormErrors(errors);
    return Object.values(errors).every((error) => !error);
  };

  const handleFormSubmit = async () => {
    if (validateForm()) {
      const f = new FormData();
      f.append("cat_type", category);
      f.append("title", blogTitle);
      f.append("cover_img", blogImage);
      f.append("description", blogDescription);
      // f.append("price", price);
      try {
        setloading(true);
        let { apikey, token } = getUserdata;
        const config = {
          headers: {
            apikey,
            token,
          },
        };
        let data = await axios.post(`${Baseurl}/admin/blogs_add`, f, config);
        if (data.status == 200) {
          setloading(false);
          setCategory([]);
          setBlogTitle("");
          setBlogImage(null);
          setBlogDescription("");
          toast.success("File added sucessfully", {
            position: "top-right",
            autoClose: 800,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          navigate("/dashboard/resources/blogs");
          dispatch(GetallBlogs(0, 10));
        } else {
          setloading(false);
        }
      } catch (err) {
        toast.error(" Faild ! Please try again ! ", {
          position: "top-right",
          autoClose: 800,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setloading(false);
        setCategory([]);
        setBlogTitle("");
        setBlogImage(null);
        setBlogDescription("");
      }
    } else {
      console.log("Form validation failed. Please check errors.");
    }
  };

  let navigatetoback = () => {
    navigate("/dashboard/resources/blogs");
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          padding: "20px 0px",
          cursor: "pointer",
        }}
      >
        <ArrowBackIosIcon
          onClick={navigatetoback}
          sx={{ height: "30px", width: "30px" }}
        />
        <Typography
          onClick={navigatetoback}
          sx={{
            fontSize: "22px",
            fontFamily: "Poppins",
            fontWeight: "600",
            lineHeight: "120%",
          }}
        >
          Add New Blogs
        </Typography>
      </Box>

      {/* --------------------------  Form ----------------------------- */}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          width: "50%",
        }}
      >
        {/* --------- Select Category -------------- */}

        <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <InputLabel
            id="demo-select-small-label"
            sx={{
              fontSize: "15px",
              color: formErrors.category ? "red" : "#000",
            }}
          >
            Select Category
          </InputLabel>

          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            sx={{
              width: "100%",
              height: "50px", // Adjust the height as needed
              borderRadius: "10px",
              border: "1px solid #F68582",
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
            }}
            value={category}
            onChange={handleselectChange}
            input={<OutlinedInput label="Tag" />}
            renderValue={(selected) =>
              selected
                .map(
                  (value) =>
                    Categorys.find((category) => category.value === value)
                      ?.lable
                )
                .join(", ")
            }
            MenuProps={MenuProps}
          >
            {Categorys.map((name) => (
              <MenuItem key={name.value} value={name?.value}>
                <Checkbox checked={category.indexOf(name?.value) > -1} />
                <ListItemText primary={name?.lable} />
              </MenuItem>
            ))}
          </Select>
        </Box>

        {/* ------------ blog title -------------- */}
        <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <InputLabel
            id="demo-select-small-label"
            sx={{
              fontSize: "15px",
              color: formErrors.blogTitle ? "red" : "#000",
            }}
          >
            Enter Blogs Title
          </InputLabel>

          <OutlinedInput
            sx={{
              width: "100%",
              height: "50px",
              borderRadius: "10px",
              border: "1px solid #F68582",
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
            }}
            id="outlined-adornment-weight"
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
            value={blogTitle?.trimStart()}
            onChange={(e) => setBlogTitle(e.target.value)}
            name="audioTitle"
          />
        </Box>

        {/* -------------- Cover Image ------------- */}

        <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <InputLabel
            id="demo-select-small-label"
            sx={{
              fontSize: "14px",
              color: formErrors.blogImage ? "red" : "#000",
            }}
          >
            Upload Blogs Cover Image
          </InputLabel>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              border: "1px solid #F68582",
              padding: "10px",
              borderRadius: "10px",
            }}
          >
            <Typography noWrap style={{ color: "#000", width: "50%" }}>
              {blogImage ? `${blogImage?.name}` : "Choose cover image"}
            </Typography>

            <Input
              type="file"
              id="audio-cover-file-input"
              style={{ display: "none" }}
              onChange={(event) => handleFileChange(event)}
            />
            <label htmlFor="audio-cover-file-input">
              <Button
                variant="contained"
                component="span"
                sx={{
                  background: "rgba(246, 133, 130, 0.50)",
                  textDecoration: "capitalize",
                  color: "#000",
                  height: "30px",
                  borderRadius: "10px",
                  transition: "background 0.3s ease", // Add a smooth transition for the background change
                  "&:hover": {
                    background: "rgba(246, 133, 130, 0.70)", // Slightly darker color on hover
                  },
                }}
              >
                <Typography sx={{ textTransform: "capitalize" }}>
                  Browse
                </Typography>
              </Button>
            </label>
          </Box>
        </Box>
      </Box>

      <Box sx={{ mt: "20px" }}>
        {/* --------------------- Description ------------------ */}
        <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <InputLabel
            id="demo-select-small-label"
            sx={{
              fontSize: "14px",
              color: formErrors.blogDescription ? "red" : "#000",
            }}
          >
            Description
          </InputLabel>
          <ReactQuill
            value={blogDescription?.trimStart()}
            onChange={(value) => setBlogDescription(value)}
            style={{
              width: "100%",
              textAlign: "left",
              borderRadius: "15px",
              borderRight: "40px",
              border: "none",
            }}
            theme="snow"
            modules={{
              toolbar: {
                container: [
                  [
                    {
                      header: "1",
                      style: { background: "rgba(246, 133, 130, 0.20)" },
                    },
                    {
                      header: "2",
                      style: { background: "rgba(246, 133, 130, 0.20)" },
                    },
                  ],
                  [{ font: [] }],
                  ["bold", "italic", "underline", "strike"],
                  [{ color: [] }, { background: [] }],
                  [{ list: "ordered" }, { list: "bullet" }],
                  ["link"],
                  ["clean"],
                  [{ size: ["small", false, "large", "huge"] }], // Font size dropdown
                ],
              },
            }}
          />
        </Box>

        {/* <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <FormControl
              sx={{ display: "flex", flexDirection: "column", mt: "10px" }}
            >
              <Typography
                sx={{
                  color: formErrors.price ? "red" : "#000",
                  fontSize: "14px",
                }}
              >
                Price
              </Typography>

              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="free"
                name="radio-buttons-group"
                value={isFree ? "free" : "paid"}
                onChange={handleChange}
                sx={{ display: "flex", flexDirection: "row" }}
              >
                <FormControlLabel
                  sx={{ color: "#000" }}
                  value="free"
                  control={
                    <Radio
                      color="primary" // Set the desired color, such as "primary", "secondary", "default", or a custom color code
                      sx={{
                        "&.Mui-checked": {
                          color: "#F68582", // Replace with your custom color code
                        },
                      }}
                    />
                  }
                  label="Free"
                />
                <FormControlLabel
                  sx={{ color: "#000" }}
                  value="paid"
                  control={
                    <Radio
                      color="primary" // Set the desired color, such as "primary", "secondary", "default", or a custom color code
                      sx={{
                        "&.Mui-checked": {
                          color: "#F68582", // Replace with your custom color code
                        },
                      }}
                    />
                  }
                  label="Paid"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          {!isFree && (
            <OutlinedInput
              sx={{
                width: "100%",
                height: "50px",
                borderRadius: "10px",
                border: "1px solid #F68582",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                {
                  display: "none",
                },
              }}
              id="outlined-adornment-weight"
              aria-describedby="outlined-weight-helper-text"
              value={price}
              type="Number"
              onChange={(e) => setPrice(e.target.value)}
              name="price"
              required={!isFree} // Make the input required only when it's not free
              onKeyDown={(evt) =>
                ["e", "E", "+", "-", "."].includes(evt.key) &&
                evt.preventDefault()
              }
              />
          )}
        </Box> */}

        <Button
          disabled={loading}
          onClick={handleFormSubmit}
          sx={{
            ...CommonButtonstyle,
            mt: "20px",
          }}
        >
          {loading ? (
            <CircularProgress sx={{ color: "#fff" }} />
          ) : (
            <Typography>Done</Typography>
          )}
        </Button>
      </Box>
    </>
  );
};

export default AddBlogs;
