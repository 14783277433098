import moment from "moment";

export function debounce(func, delay) {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  }

  export const formatTime = (timeString) => {
  
    const utcTime = moment.utc(timeString, "HH:mm:ss");
    // Use the local offset of the user's browser
    const localTime = utcTime.local();
    const formattedHours = localTime.format("h");
    const minutes = localTime.format("mm");
    const period = localTime.format("A");
    return `${formattedHours}:${minutes} ${period}`;
  };









// // ------------ Add Start time -------------
// export  const handlestart_timeChange = (time) => {
//   const formattedTime = moment.utc(time).format("HH:mm:ss");
//   setFormData({ ...formData, start_time: formattedTime });
//   if (!formData.date) {
//     setValidation({ ...validation, date: false });
//     setValidationErors({
//       ...validationErrors,
//       date: "Please select a date before choosing the time",
//     });
//     return;
//   }
//   const startTime = moment(formattedTime, "HH:mm:ss");
//   const endTime = moment(formData.end_time, "HH:mm:ss");
//   if (endTime.isValid() && endTime.isBefore(startTime)) {
//     setistimeavilable(true);
//     setValidation({ ...validation, end_time: false });
//     setValidationErors({
//       ...validationErrors,
//       end_time: "End time must be after start time.",
//     });
//     return;
//   }
//   const overlappingSlot = TimingsData?.AdminSessionsByDate?.find((el) => {
//     const bookedStartTime = moment(el.start_time, "HH:mm:ss");
//     const bookedEndTime = moment(el.end_time, "HH:mm:ss");
//     return (
//       startTime.isBetween(bookedStartTime, bookedEndTime) ||
//       endTime.isBetween(bookedStartTime, bookedEndTime) ||
//       bookedStartTime.isBetween(startTime, endTime) ||
//       bookedEndTime.isBetween(startTime, endTime)
//     );
//   });
//   if (overlappingSlot) {
//     setistimeavilable(true);
//     setValidation({ ...validation, start_time: false });
//     setValidationErors({
//       ...validationErrors,
//       start_time: "This slot is already booked. Select another time.",
//     });
//     return;
//   }
//   setValidation({ ...validation, start_time: true });
//   setValidationErors({ ...validationErrors, start_time: "" });
// };


// export const handleend_timeChange = (time) => {
//   const formattedTime =moment.utc(time).format("HH:mm:ss");
//   setFormData({ ...formData, end_time: formattedTime });
//   if (!formData.date) {
//     setValidation({ ...validation, date: false });
//     setValidationErors({
//       ...validationErrors,
//       date: "Please select a date before choosing the time",
//     });
//     return;
//   }
  
//   const startTime = moment(formData.start_time, "HH:mm:ss");
//   const endTime = moment(formattedTime, "HH:mm:ss");
//   if (startTime.isValid() && endTime.isBefore(startTime)) {
//     setistimeavilable(true);
//     setValidation({ ...validation, end_time: false });
//     setValidationErors({
//       ...validationErrors,
//       end_time: "End time must be after start time.",
//     });
//     return;
//   }
//   const overlappingSlot = TimingsData?.AdminSessionsByDate?.find((el) => {
//     const bookedStartTime = moment(el.start_time, "HH:mm:ss");
//     const bookedEndTime = moment(el.end_time, "HH:mm:ss");
//     return (
//       startTime.isBetween(bookedStartTime, bookedEndTime) ||
//       endTime.isBetween(bookedStartTime, bookedEndTime) ||
//       bookedStartTime.isBetween(startTime, endTime) ||
//       bookedEndTime.isBetween(startTime, endTime)
//     );
//   });
//   if (overlappingSlot) {
//     setistimeavilable(true);
//     setValidation({ ...validation, end_time: false });
//     setValidationErors({
//       ...validationErrors,
//       end_time: "This slot is already booked. Select another time.",
//     });
//     return;
//   }
//   setValidation({ ...validation, end_time: true });
//   setValidationErors({ ...validationErrors, end_time: "" });
// };
