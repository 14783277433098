import React, { useEffect } from "react";
import AvilabilityTable from "./AvilabilityTable";
import { useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { CommonButtonstyle } from "../../../../Components/CommonCss";
import TitleText from "../../../../Components/TitleText";
import { useDispatch } from "react-redux";
import { GetallCoachesAvilability } from "../../../../Redux/Slices/Sessions/Session.action";

const AvilabilityDataListing = () => {

  let navigate = useNavigate();
  let RedirectToaddAvilability = () => {
    navigate("/dashboard/avilability/add_avilability");
  }

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TitleText title={"Availability"} />
        <Button
          onClick={RedirectToaddAvilability}
          sx={{ ...CommonButtonstyle, width: "20%", mb: "-90px" }}
        >
          Add Availability
        </Button>
      </Box>

      <AvilabilityTable />
    </div>
  );
};

export default AvilabilityDataListing;
