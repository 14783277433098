import { createSlice } from "@reduxjs/toolkit";
import {
  AddNotification,
  EditNotification,
  GetDashAnalyticsdata,
  GetDashGraphData,
  GetNotifications,
  GetRecentIndividualSessions,
} from "./Dashboard.action";
let initialState = {
  loading: false,
  error: null,
  AnalyticsData: [],
  RecentIndividualSessions: {},
  GraphData: [],
  Notification: [],
};

const Dashboardslice = createSlice({
  name: "DashboardData",
  initialState,
  extraReducers: (builder) => {


    
    // --------- Get Dashboard Content ---------
    builder
      .addCase(GetDashAnalyticsdata.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetDashAnalyticsdata.fulfilled, (state, action) => {
        state.loading = false;
        state.AnalyticsData = action?.payload?.r;
      })
      .addCase(GetDashAnalyticsdata.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
    // --------- Get Dashboard Graph ---------
    builder
      .addCase(GetDashGraphData.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetDashGraphData.fulfilled, (state, action) => {
        state.loading = false;
        state.GraphData = action?.payload?.r;
      })
      .addCase(GetDashGraphData.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });

    // --------- Get Recent Users sessions List  ---------
    builder
      .addCase(GetRecentIndividualSessions.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetRecentIndividualSessions.fulfilled, (state, action) => {
        state.loading = false;
        state.RecentIndividualSessions = action?.payload;
      })
      .addCase(GetRecentIndividualSessions.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });




    // ---------------- Notifications ----------------

    // ---------  Add Notification ---------
    builder
      .addCase(AddNotification.pending, (state) => {
        state.loading = true;
      })
      .addCase(AddNotification.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(AddNotification.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
    // ---------  Get Notification ---------

    builder
      .addCase(GetNotifications.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetNotifications.fulfilled, (state, action) => {
        state.loading = false;
        state.Notification = action?.payload;
      })
      .addCase(GetNotifications.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });

    // ---------  Edit Notification ---------

    builder
      .addCase(EditNotification.pending, (state) => {
        state.loading = true;
      })
      .addCase(EditNotification.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(EditNotification.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
  },
});

export default Dashboardslice.reducer;
