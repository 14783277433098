import React, { useState } from "react";
import TitleText from "../../../../Components/TitleText";
import { Box, Button, CircularProgress, OutlinedInput, Typography } from "@mui/material";
import { Link, Outlet, useLocation } from "react-router-dom";
import { CommonButtonstyle } from "../../../../Components/CommonCss";

const Setting = () => {
  let commonfont = {
    fontStyle: "Poppins",
    fontSize: "20px",
    fontWeight: "550",
    lineHeight: "100%",
  };
  let links = [
    { id: 1, name: "Terms And Conditions", path: "/dashboard/setting" },
    {
      id: 2,
      name: "Privacy Policy",
      path: "/dashboard/setting/privacypolicy",
    },
  ]

  const location = useLocation();
  const currentPath = location.pathname;
  
  return (
    <>
      <TitleText title={"Settings"}/>
<Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: "20px",

          }}
        >
          <Box sx={{ display: "flex", gap: "20px" }}>
            {links?.map((li, i) => (
              <Box>
                <Link to={li.path} style={{ textDecoration: "none" }}>
                  <Typography
                    // onClick={() => setselectedtab(i + 1)}
                    sx={{
                      ...commonfont,
                      cursor: "pointer",
                      fontSize: "18px",
                      position: "relative",
                      textDecoration: "none",
                      borderBottom:
                        currentPath == li?.path
                          ? "3px solid #F68582"
                          : "3px solid transparent",
                      color: currentPath == li?.path ? "black" : "gray",
                      "&:hover": {
                        textDecoration: "none", // Remove the default underline
                        borderBottom: "3px solid #F68582", // Add the color as an underline
                        transition: "border-bottom 0.2s ease-in-out", // Add transition for the border-bottom
                      },
                    }}
                  >
                    {li?.name}
                  </Typography>
                </Link>
              </Box>
            ))}
          </Box>
{/* <Box sx={{width:"10%"}}>

          {currentPath == "/dashboard/ebooks" ? (
            <Button
              // onClick={openEbook}
              sx={{ ...CommonButtonstyle, width: "100%" }}
            >
              Add More
            </Button>
          ) : (
            <Box
              sx={{
                width: "100%",
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
                gap: "3%",
              }}
            >
            

              <Button
                // onClick={AddnewCategori}
                sx={{ ...CommonButtonstyle, width: "100%" }}
              >
                {false ? (
                  <CircularProgress sx={{ color: "#fff" }} />
                ) : (
                  <Typography> Save </Typography>
                )}
              </Button>
            </Box>
          )}
</Box> */}
        </Box>
      </Box>
      <Box sx={{mt:"20px"}}>
        <Outlet />
      </Box>

    </>
  )
}

export default Setting