import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { modalstyle } from "./PopupsStyling";
import { useDispatch } from "react-redux";
import { errortost, sucesstost } from "../Alerts/CustomAlert";
 
import { DeleteAvilabilitySession, GetAdminAvilabilitySessions, GetallCoachesAvilability } from "../../Redux/Slices/Sessions/Session.action";
import moment from "moment";
const DeleteAvilability = ({ modalcontrols }) => {
  let { isdeleteAvilability, closedeleteAvilability, deletedata ,count,rowsPerPage,filteredvalue} =
    modalcontrols;
 


  let [loading, setloading] = useState(false);
  let dispatch = useDispatch();
  let deletethisdata = () => {
    const sessionDate = moment(deletedata?.date, "YYYY-MM-DD");
    const currentDate = moment().startOf('day');
  
    if (sessionDate.isAfter(currentDate)) { // Updated condition to only allow future dates
      setloading(true);
      let deletethisdata = new FormData();
      deletethisdata.append("availability_id", deletedata?.id);
      deletethisdata.append("status", 0);
      dispatch(DeleteAvilabilitySession(deletethisdata))
        .unwrap()
        .then((result) => {
          setloading(false);
          if (result?.s === 1) {
            sucesstost("Deleted Successfully!");
            dispatch(GetallCoachesAvilability({  type: filteredvalue,count: 0, row_count: rowsPerPage }));
            closedeleteAvilability();
          } else {
            errortost("Something went wrong, please try again!");
            closedeleteAvilability();
          }
        });
    } else {
      errortost("You cannot delete a past or current session!");
      closedeleteAvilability();
    }
  };
  

  const formatTime = (timeString) => {
    // Assuming timeString is in UTC
    const utcTime = moment.utc(timeString, "HH:mm:ss");
    // Use the local offset of the user's browser
    const localTime = utcTime.local();
    const formattedHours = localTime.format("h");
    const minutes = localTime.format("mm");
    const period = localTime.format("A");

    return `${formattedHours}:${minutes} ${period}`;
  };



  return (
    <>
      <Modal open={isdeleteAvilability} onClose={closedeleteAvilability}>
        <Box
          sx={{
            ...modalstyle,
            borderRadius: "10px",
            width: { xs: "50vw", md: "350px" },
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Typography
            sx={{
              color: "#000",
              fontFamily: "Poppins",
              display: "flex",
              gap: "5px",
            }}
          >
            Want to Delete this Availability ?
          </Typography>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "13px",
              padding: "10px",
              background: "rgba(128, 128, 128, 0.15)",
              borderRadius: "10px",
            }}
          >
            <Typography
              noWrap
              sx={{
                color: "#000",
                textAlign: "center",
                width: "100%",
                overflow: "hidden",
              }}
            >
              Time :   {formatTime(deletedata?.start_time)}  to  {formatTime(deletedata?.end_time)}
            </Typography>
          </Box>

          <Box sx={{ display: "flex", gap: "10px" }}>
            <Button
              onClick={deletethisdata}
              sx={{
                textTransform: "capitalize",
                mt: "10px",
                width: "100%",
                borderRadius: "14px",
                background: "#FF1607",
                color: "#fff",
                padding: "8px",
                height: "50px",
                "&:hover": {
                  background: "#A30000",
                },
              }}
            >
              {loading ? (
                <CircularProgress sx={{ color: "#fff" }} />
              ) : (
                <Typography>Delete</Typography>
              )}
            </Button>
            <Button
              onClick={() => closedeleteAvilability()}
              sx={{
                textTransform: "capitalize",
                mt: "10px",
                borderRadius: "14px",
                background: "#E8E8E8",
                width: "100%",
                color: "#000",
                padding: "8px",
                "&:hover": {
                  background: "#B0B0B0",
                },
              }}
            >
              No
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default DeleteAvilability;
