export let modalstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "200px",
  bgcolor: "#fff",
  boxShadow: 24,
  p: 4,
  color: "#FBFBFB",
  fontFamily: "Raleway",
  focus: "none",
  outline: "none",
  padding: "30px",
};

export let ButtonCss = {
  height: "50px",
  width: "40%",
  textTransform: "capitalize",
  color: "#000",
  boxShadow:
    "2px 2px 8px 0px rgba(143, 95, 11, 0.50), 0px 3px 11.111px 0px #FBB15A inset, 0px -11px 11.111px 0px #D68835 inset",
  background: " linear-gradient(180deg, #FFCE98 0%, #F4AA50 100%);",
};
