

// src/apiService.js
import axios from 'axios';
import { Baseurl,getUserdata } from '../../Apis/Api';

export async function fetchCategories() {
  try {
     
    let data = await axios.get(`${Baseurl}/topics/get_topics` );
   console.log(data);
   
    return data.data;
  } catch (error) {
    throw error;
  }
}

export let Categorys = [
  { lable: "Mind ",value:1 },
  { lable: "Body ",value:2 },
  { lable: "Spirit ",value:3 },
  { lable: "Motivational",value:4 },
  { lable: "Inspirational",value:5 },
  { lable: "Self Love",value:6 },
] 


// {{mindfulness}}/api/topics/get_topics