import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { modalstyle } from "./PopupsStyling";
import { videocoverurl } from "../../Apis/Api";
import { useDispatch } from "react-redux";
import {
  EditVideo,
  GetallVideos,
} from "../../Redux/Slices/Resources/Resources.action";
import { toast } from "react-toastify";
import { Categorys } from "./CommonData";

const EditVideoPopup = ({ modalcontrols }) => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const {
    isvideoeditpopup,
    closevideoeditpopup,
    editfiledata,
    value,
    count,
    row_count,
  } = modalcontrols;
  const [mycategory, setmycategory] = useState([]);
  const [VideoTitle, setVideoTitle] = useState("");
  const [videoduration, setVideoduration] = useState("");
  const [VideoThumbnail, setVideoThumbnail] = useState(null);
  const [VideoFile, setVideoFile] = useState(null);
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  // const [isFree, setIsFree] = useState(true);
  // const [price, setPrice] = useState(0);
  let dispatch = useDispatch();
  useEffect(() => {
    if (editfiledata) {
      setmycategory(
        editfiledata?.category
          ?.filter((v) => v.status == 1)
          .map((j) => j.cat_type) ?? []
      );
      setVideoTitle(editfiledata?.title || "");
      setVideoThumbnail(null); // Reset VideoThumbnail state when opening the modal
      setVideoFile(editfiledata?.VideoFile || null);
      setDescription(editfiledata?.description || "");
      // setIsFree(editfiledata?.price == 0 ? true : false);
      // setPrice(editfiledata?.price);
    }
  }, [editfiledata]);

  const handeldEditVideo = () => {
    const isAnyFieldEdited =
      mycategory !== editfiledata?.category ||
      VideoTitle !== editfiledata?.title ||
      (VideoThumbnail && VideoThumbnail !== editfiledata?.VideoThumbnail) ||
      (VideoFile && VideoFile !== editfiledata?.VideoFile) ||
      description !== editfiledata?.description;
    // If no field is edited, do not dispatch the data
    if (!isAnyFieldEdited) {
      console.log("No fields are edited.");
      return;
    }
    const formData = new FormData();

    if (mycategory !== editfiledata?.category) {
      formData.append("cat_type", mycategory);
    }
    // if (price) {
    //   if (isFree) {
    //     formData.append("price", 0);
    //   } else {
    //     formData.append("price", price);
    //   }
    // }
    if (VideoTitle !== editfiledata?.title) {
      formData.append("title", VideoTitle);
    }
    if (VideoThumbnail && VideoThumbnail !== editfiledata?.VideoThumbnail) {
      formData.append("thumb", VideoThumbnail);
    }
    if (VideoFile && VideoFile !== editfiledata?.VideoFile) {
      formData.append("file", VideoFile);
      // Append videoduration only if VideoFile is selected
      if (videoduration !== editfiledata?.videoduration) {
        formData.append("length", videoduration);
      }
    }

    if (description !== editfiledata?.description) {
      formData.append("description", description);
    }
    formData.append("id", editfiledata?.id);
    formData.append("status", 1);
    setLoading(true);
    dispatch(EditVideo(formData))
      .unwrap()
      .then((result) => {
        if (result?.s == 1) {
          setLoading(false);
          dispatch(
            GetallVideos({ value: 0, count: count, row_count: row_count })
          );
          closevideoeditpopup();
          toast.success("Updated sucessfully", {
            position: "top-right",
            autoClose: 800,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          setLoading(false);
          closevideoeditpopup();
          toast.error("Something wents wrong please try again ! ", {
            position: "top-right",
            autoClose: 800,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      });
  };

  const handleChange = (event, fieldType) => {
    const value =
      event.target.type === "file" ? event.target.files[0] : event.target.value;
    if (fieldType === "VideoTitle") {
      setVideoTitle(value);
    } 
    // else if (fieldType === "price") {
    //   setPrice(value, "price");
    // } 
    // else if (event.target.name === "radio-buttons-group") {
    //   setIsFree(value === "free");
    // } 
    else if (fieldType === "VideoThumbnail") {
      // Validate thumbnail type
      const allowedThumbnailTypes = ["image/jpeg", "image/png", "image/gif"];
      if (value && allowedThumbnailTypes.indexOf(value.type) === -1) {
        toast.error(
          "Invalid thumbnail type. Please select a valid image file.",
          {
            position: "top-right",
            autoClose: 3000,
          }
        );
        return;
      }
      setVideoThumbnail(value);
    } else if (fieldType === "VideoFile") {
      const allowedFileTypes = ["video/mp4", "video/webm", "video/quicktime"];
      if (value && allowedFileTypes.indexOf(value.type) === -1) {
        toast.error("Invalid file type. Please select a valid video file.", {
          position: "top-right",
          autoClose: 3000,
          // Customize error message and duration as needed
        });
        return;
      }

      setVideoFile(value);
      extractVideoDuration(value);
    } else if (fieldType === "description") {
      setDescription(value);
    } else if (fieldType === "videoLength") {
      setVideoduration(value);
    }
  };

  const extractVideoDuration = (videoFile) => {
    const video = document.createElement("video");
    video.src = URL.createObjectURL(videoFile);
    video.addEventListener("loadedmetadata", () => {
      if (!isNaN(video.duration) && isFinite(video.duration)) {
        const durationInSeconds = Math.round(video.duration);
        const minutes = Math.floor(durationInSeconds / 60);
        const seconds = durationInSeconds % 60;
        let formattedDuration = "";
        if (minutes > 0) {
          formattedDuration += `${minutes} min `;
        }
        formattedDuration += `${seconds} sec`;
        setVideoduration(formattedDuration);
      } else {
        console.error("Error: Unable to retrieve video duration.");
      }
      URL.revokeObjectURL(video.src);
      video.remove();
    });
    video.addEventListener("error", () => {
      console.error("Error: Unable to load the video file.");
    });
  };

  const handeldcategoryselect = (event) => {
    const selectedCategoryIds = event.target.value;
    setmycategory(selectedCategoryIds);
  };
  return (
    <Modal open={isvideoeditpopup} onClose={closevideoeditpopup}>
      <Box
        sx={{
          ...modalstyle,
          borderRadius: "10px",
          width: { xs: "50vw", md: "40vw" },
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <Typography
          sx={{
            fontWeight: "600",
            color: "#292D32",
            fontSize: "18px",
            fontFamily: "Poppins",
            textAlign: "center",
            fontSize: "20px",
          }}
        >
          Edit Video
        </Typography>

        <Box
          sx={{
            height: "85vh",
            overflow: "auto",
            boxSizing: "border-box",
            gap: "5px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "5px",
            }}
          >
            <InputLabel
              id="demo-select-small-label"
              sx={{
                fontSize: "15px",
              }}
            >
              Select Category
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              sx={{
                width: "100%",
                height: "50px",
                borderRadius: "10px",
                border: "1px solid #F68582",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
              }}
              value={mycategory}
              input={<OutlinedInput label="Tag" />}
              onChange={handeldcategoryselect}
              renderValue={(selected) =>
                selected
                  .map(
                    (value) =>
                      Categorys?.find((category) => category?.value === value)
                        ?.lable
                  )
                  .join(",")
              }
              MenuProps={MenuProps}
            >
              {Categorys?.map((name) => (
                <MenuItem nuItem key={name?.value} value={name?.value}>
                  <Checkbox checked={mycategory.includes(name.value)} />
                  <ListItemText primary={name?.lable} />
                </MenuItem>
              ))}
            </Select>
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              mt: "10px",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <InputLabel
                id="demo-select-small-label"
                sx={{
                  fontSize: "15px",
                }}
              >
                Enter Video Title
              </InputLabel>
              <OutlinedInput
                sx={{
                  width: "100%",
                  height: "50px",
                  borderRadius: "10px",
                  border: "1px solid #F68582",
                  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
                }}
                id="outlined-adornment-weight"
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
                value={VideoTitle}
                onChange={(e) => handleChange(e, "VideoTitle")}
                name="VideoTitle"
              />
            </Box>
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              boxSizing: "border-box",
              mt: "10px",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <InputLabel
                id="demo-select-small-label"
                sx={{
                  fontSize: "15px",
                }}
              >
                Video thumbnail
              </InputLabel>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  border: "1px solid #F68582",
                  padding: "10px",
                  borderRadius: "10px",
                }}
              >
                {VideoThumbnail ? (
                  <img
                    style={{ width: "100px", height: "80px" }}
                    src={URL.createObjectURL(VideoThumbnail)}
                    alt="Video Thumbnail Preview"
                  />
                ) : (
                  <img
                    style={{ width: "100px", height: "80px" }}
                    src={`${videocoverurl}/${editfiledata?.thumb}`}
                    alt="Existing Video Thumbnail"
                  />
                )}
                <Input
                  type="file"
                  id="audio-cover-file-input"
                  style={{ display: "none" }}
                  onChange={(event) => handleChange(event, "VideoThumbnail")}
                />
                <label htmlFor="audio-cover-file-input">
                  <Button
                    variant="contained"
                    component="span"
                    sx={{
                      background: "rgba(246, 133, 130, 0.50)",
                      textDecoration: "capitalize",
                      color: "#000",
                      height: "30px",
                      borderRadius: "10px",
                      transition: "background 0.3s ease",
                      "&:hover": {
                        background: "rgba(246, 133, 130, 0.70)",
                      },
                    }}
                  >
                    <Typography sx={{ textTransform: "capitalize" }}>
                      Browse
                    </Typography>
                  </Button>
                </label>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              boxSizing: "border-box",
              mt: "10px",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <InputLabel
                id="demo-select-small-label"
                sx={{
                  fontSize: "15px",
                }}
              >
                Upload File
              </InputLabel>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  border: "1px solid #F68582",
                  padding: "10px",
                  borderRadius: "10px",
                }}
              >
                <Typography noWrap style={{ color: "#000", width: "50%" }}>
                  {VideoFile ? VideoFile.name : editfiledata?.file}
                </Typography>
                <Input
                  type="file"
                  id="music-file-input"
                  style={{ display: "none" }}
                  onChange={(event) => handleChange(event, "VideoFile")}
                />
                <label htmlFor="music-file-input">
                  <Button
                    variant="contained"
                    component="span"
                    sx={{
                      background: "rgba(246, 133, 130, 0.50)",
                      textDecoration: "capitalize",
                      color: "#000",
                      height: "30px",
                      borderRadius: "10px",
                      transition: "background 0.3s ease",
                      "&:hover": {
                        background: "rgba(246, 133, 130, 0.70)",
                      },
                    }}
                  >
                    <Typography sx={{ textTransform: "capitalize" }}>
                      Browse
                    </Typography>
                  </Button>
                </label>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              overflow: "hidden",
              mt: "10px",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <InputLabel
                id="demo-select-small-label"
                sx={{
                  fontSize: "15px",
                }}
              >
                Description
              </InputLabel>
              <TextField
                onChange={(e) => handleChange(e, "description")}
                id="filled-multiline-static"
                sx={{
                  width: "100%",
                  borderRadius: "10px",
                  border: "1px solid #F68582",
                  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
                }}
                value={description}
                multiline
                rows={4}
              />
            </Box>
          </Box>
          {/* <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              mt: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <FormControl sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ color: "gray" }}>Price</Typography>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="free"
                  name="radio-buttons-group"
                  value={isFree ? "free" : "paid"}
                  onChange={handleChange}
                  sx={{ display: "flex", flexDirection: "row" }}
                >
                  <FormControlLabel
                    sx={{ color: "#000" }}
                    value="free"
                    control={
                      <Radio
                        color="primary" // Set the desired color, such as "primary", "secondary", "default", or a custom color code
                        sx={{
                          "&.Mui-checked": {
                            color: "#F68582", // Replace with your custom color code
                          },
                        }}
                      />
                    }
                    label="Free"
                  />

                  <FormControlLabel
                    sx={{ color: "#000" }}
                    value="paid"
                    control={
                      <Radio
                        color="primary" // Set the desired color, such as "primary", "secondary", "default", or a custom color code
                        sx={{
                          "&.Mui-checked": {
                            color: "#F68582", // Replace with your custom color code
                          },
                        }}
                      />
                    }
                    label="Paid"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            {!isFree && (
              <OutlinedInput
                sx={{
                  width: "100%",
                  height: "50px",
                  borderRadius: "10px",
                  border: "1px solid #F68582",
                  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                }}
                id="outlined-adornment-weight"
                aria-describedby="outlined-weight-helper-text"
                value={price}
                type="Number"
                onChange={(e) => handleChange(e, "price")}
                name="price"
                required={!isFree} // Make the input required only when it's not free
                onKeyDown={(evt) =>
                  ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
                }
              />
            )}
          </Box> */}
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              disabled={loading}
              onClick={handeldEditVideo}
              sx={{
                height: "50px",
                width: "40%",
                textTransform: "capitalize",
                color: "#000",
                boxShadow:
                  "2px 2px 8px 0px rgba(143, 95, 11, 0.50), 0px 3px 11.111px 0px #FBB15A inset, 0px -11px 11.111px 0px #D68835 inset",
                background:
                  " linear-gradient(180deg, #FFCE98 0%, #F4AA50 100%);",
              }}
            >
              {loading ? (
                <CircularProgress sx={{ color: "#fff" }} />
              ) : (
                <Typography>Done</Typography>
              )}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default EditVideoPopup;
