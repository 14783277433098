import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl, getUserdata } from "../../../Apis/Api";

export const GetAllUsers = createAsyncThunk(
  "users/getallusers",
  async ({count,row_count }) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      let data = await axios.get(`${Baseurl}/user/get?count=${count}&row_count=${row_count}`, config);
 
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);

export const Delete_Block = createAsyncThunk(
  "users/Delete_Block",
  async (actiondata) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      let data = await axios.post(
        `${Baseurl}/user/edit`,
        actiondata,
        config
      );

      return data.data;
    } catch (error) {
      throw error;
    }
  }
);
 
export const getuserbyid = createAsyncThunk(
  "users/userby_id",
  async (userid) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      let data = await axios.get(`${Baseurl}/user/get?id=${userid}`, config);
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);


// -------------  get IndividualSessions by id -----------

export const getIndividualSessionsbyid = createAsyncThunk(
  "users/IndividualSessionsby_id",
  async ({user_id,count}) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      let data = await axios.get(
        `${Baseurl}/user/individual_senssion_book_get?user_id=${user_id}&count=${count}`,
        config
      );
  
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);



// -------------  get GroupeSessions by id  -----------
export const getGroupeSessionsbyid = createAsyncThunk(
  "users/GroupeSeesionsbyuserid",
  async ({user_id,count}) => {
    
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      let data = await axios.get(
        `${Baseurl}/user/group_senssion_book_get?user_id=${user_id}&count=${count}`,
        config
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);



// -------------  get GroupeSessions by id  -----------
export const getEbooksdatabyuser = createAsyncThunk(
  "users/getEbooksbyuser",
  async ({user_id,count}) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      let data = await axios.get(
        `${Baseurl}/user/ebooks_senssion_book_get?user_id=${user_id}&count=${count}`,
        config
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);



export const SearchUser = createAsyncThunk(
  "users/searchuser",
  async ({searchValue,count,row_count}) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      }

      let data = await axios.get(
        `${Baseurl}/admin/search_users?search=${searchValue}&count=${count}&row_count=${row_count}`,
        config
      );
    
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);





 