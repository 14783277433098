import React, { useEffect, useState } from "react";
import TitleText from "../../../Components/TitleText";
import { Box, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import Infobox from "../../../Components/DashboardCompo/Infobox";
import { useDispatch, useSelector } from "react-redux";
import {
  GetDashAnalyticsdata,
  GetRecentIndividualSessions,
} from "../../../Redux/Slices/Dashboard/Dashboard.action";
import RecentSessionsTable from "./RecentSessionsTable";
import EarningsChart from "./Chart/EarningsChart";

const Dashboardpage = () => {
  let dispatch = useDispatch()
  useEffect(() => {
    dispatch(GetDashAnalyticsdata());
    dispatch(GetRecentIndividualSessions());
  }, []);
  let Dashboarddata = useSelector((store) => store?.Dashboard);
  let UserManagment = [
    {
      title: "Total User",
      backgraund: "rgba(244, 170, 80, 0.25)",
      value: Dashboarddata?.AnalyticsData?.total_users,
      more: "+",
    },
    {
      title: "Total Sessions",
      backgraund: "rgba(193, 146, 126, 0.25)",
      value: Dashboarddata?.AnalyticsData?.total_sessions,
      more: "+",
    },
  ];
  let ResourcesManagment = [
    {
      title: "Total Books",
      backgraund: "#E3F5FF",
      value: Dashboarddata?.AnalyticsData?.total_books,
    },
    {
      title: "Total Videos",
      backgraund: "#E5ECF6",
      value: Dashboarddata?.AnalyticsData?.total_videos,
    },
    {
      title: "Total Audios",
      backgraund: "#E3F5FF",
      value: Dashboarddata?.AnalyticsData?.total_audios,
    },
    {
      title: "Total Blogs",
      backgraund: "rgba(217, 160, 152, 0.25)",
      value: Dashboarddata?.AnalyticsData?.total_blogs,
    },
  ];
  let [selectvalue, setselectvalue] = useState(0);
  let handeldselecttag = (e) => {
    setselectvalue(e.target.value);
    dispatch(GetDashAnalyticsdata(selectvalue));
  };
  return (
    <>
      <Box sx={{ mb: "10px" }}>
        <TitleText title={"Dashboard"} />
      </Box>
      <Box
        sx={{
          height: "89vh",
          overflowY: "auto",
          width: "100%",
          padding: "5px",
          boxSizing: "border-box",
          "&::-webkit-scrollbar": {
            width: "0px", // Set the width of the scrollbar
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "transparent", // Set the color of the thumb (the moving part)
          },
          scrollbarColor: "transparent transparent", // Set the color of the thumb and track (for Firefox)
          scrollbarWidth: "thin",
        
      
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            // mt: "20px",
            gap: "15px",
            
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: "5px",
              gap: "15px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                borderRadius: "30px",
                width: "40%",
                boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.25)",
                padding: "20px 30px",
              
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontWeight: "600" }}>
                  User Managements
                </Typography>
                <Box sx={{ width: "40%" }}>
                  <Select
                    sx={{
                      width: "100%",
                      height: "30px",
                      borderRadius: "10px",
                      background: "rgba(246, 133, 130, 0.25)",
                    }}
                    value={selectvalue}
                    onChange={handeldselecttag}
                    labelId="demo-customized-select-label"
                    id="demo-customized-select"
                  >
                    <MenuItem disabled value={0}>
                      Select{" "}
                    </MenuItem>
                    <MenuItem value={1}>Weekly</MenuItem>
                    <MenuItem value={2}>Monthly</MenuItem>
                  </Select>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                {UserManagment?.map((el) => (
                  <Infobox data={{ el }} />
                ))}
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                borderRadius: "30px",
                width: "60%",
                boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.25)",
                padding: "20px 30px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontWeight: "600" }}>Resources</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                {ResourcesManagment?.map((el) => (
                  <Infobox data={{ el }} />
                ))}
              </Box>
            </Box>
          </Box>

          {/* ------------ Chart ---------- */}
          <Box sx={{}}>
            <EarningsChart />
          </Box>

          {/* ------------------------ Recent Individual Sessions --------------------- */}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              borderRadius: "30px",
              boxSizing: "border-box",
              width: "100%",
              boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.25)",
              padding: "20px 30px",
              mb: "0px",
            }}
          >
            <Typography sx={{ fontWeight: "600" }}>
              Recent Individuals Sessions
            </Typography>
            <RecentSessionsTable />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Dashboardpage;
