import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  ListItemText,
  Menu,
  MenuItem,
  Modal,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";
import { modalstyle } from "./PopupsStyling";
import { useDispatch } from "react-redux";
import { audiocoverurl } from "../../Apis/Api";
import {
  EditAudio,
  GetallAudios,
} from "../../Redux/Slices/Resources/Resources.action";
import { toast } from "react-toastify";
import { Categorys } from "./CommonData";
import TostContainer from "../Alerts/TostContainer";
import { errortost, sucesstost } from "../Alerts/CustomAlert";

const EditAudioPopup = ({ modalcontrols }) => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const { iseditpopup, closeeditpopup, editfiledata,value,count,row_count } = modalcontrols;
  const [mycategory, setmycategory] = useState([]);
  const [audioTitle, setAudioTitle] = useState("");
  const [audioCoverFile, setAudioCoverFile] = useState(null);
  const [audiofile, setAudioFile] = useState(null);
  const [audioduration, setAudioDuration] = useState("");
  const [loading, setLoading] = useState(false);
  // const [isFree, setIsFree] = useState(true);
  // const [price, setPrice] = useState(0);
  useEffect(() => {
    if (editfiledata) {
      setmycategory(
        editfiledata?.category
          ?.filter((v) => v.status == 1)
          .map((j) => j.cat_type) ?? []
      );
      setAudioTitle(editfiledata?.title || "");
      setAudioCoverFile(null);
      setAudioFile(null);
      // setIsFree(editfiledata?.price == 0 ? true : false);
      // setPrice(editfiledata?.price);
    }
  }, [editfiledata]);

  const handleChange = (e, fieldType) => {
    const value = e.target.type === "file" ? e.target.files[0] : e.target.value;
    if (fieldType === "audioTitle") {
      setAudioTitle(value);
    } else if (fieldType === "audioCoverFile") {
      handleFileChange(value, "audioCoverFile");
    } else if (fieldType === "audiofile") {
      handleFileChange(value, "audiofile");
    }
    //  else if (fieldType === "price") {
    //   setPrice(value, "price");
    // } else if (e.target.name === "radio-buttons-group") {
    //   setIsFree(value === "free");
    // }
  };

  const handleFileChange = (file, fileType) => {
    const allowedFileTypes =
      fileType === "audioCoverFile"
        ? ["image/jpeg", "image/png", "image/gif"]
        : ["audio/mp3", "audio/wav", "audio/mpeg"];

    if (file && allowedFileTypes.indexOf(file.type) === -1) {
      toast.error(
        `Invalid ${
          fileType === "audioCoverFile" ? "image" : "audio"
        } file type. Please select a valid file.`,
        {
          position: "top-right",
          autoClose: 3000,
        }
      );
      return;
    }
    if (fileType === "audioCoverFile") {
      setAudioCoverFile(file);
    } else if (fileType === "audiofile") {
      setAudioFile(file);
      extractAudioDuration(file);
    }
  };

  const extractAudioDuration = (audioFile) => {
    const audio = new Audio();
    audio.src = URL.createObjectURL(audioFile);
    audio.addEventListener("loadedmetadata", () => {
      if (!isNaN(audio.duration) && isFinite(audio.duration)) {
        const durationInSeconds = Math.round(audio.duration);
        const minutes = Math.floor(durationInSeconds / 60);
        const seconds = durationInSeconds % 60;
        const formattedDuration = `${minutes} min ${seconds} sec`;
        setAudioDuration(formattedDuration);
      } else {
        console.error("Error: Unable to retrieve audio duration.");
      }
      URL.revokeObjectURL(audio.src);
    });

    audio.addEventListener("error", () => {
      console.error("Error: Unable to load the audio file.");
    });
  };
  const dispatch = useDispatch()

  const handleEditAudio = async () => {
    const isAnyFieldEdited =
      mycategory !== editfiledata?.category ||
      audioTitle !== editfiledata?.title ||
      (audioCoverFile && audioCoverFile !== editfiledata?.audioCoverFile) ||
      (audiofile && audiofile !== editfiledata?.audiofile);
    if (!isAnyFieldEdited) {
      console.log("No fields are edited.");
      return;
    }
    const formData = new FormData();
    if (mycategory !== editfiledata?.category) {
      formData.append("cat_type", mycategory);
    }
    if (audioTitle !== editfiledata?.title) {
      formData.append("title", audioTitle);
    }
    // if (price) {
    //   if (isFree) {
    //     formData.append("price", 0);
    //   } else {
    //     formData.append("price", price)
    //   }
    // }
    if (audioCoverFile && audioCoverFile !== editfiledata?.audioCoverFile) {
      formData.append("cover_img", audioCoverFile);
    }
    if (audiofile && audiofile !== editfiledata?.file) {
      formData.append("file", audiofile);
      if (audioduration !== editfiledata?.audioduration) {
        formData.append("length", audioduration);
      }
    }
    formData.append("id", editfiledata?.id);
    try {
      setLoading(true);
      dispatch(EditAudio(formData))
        .unwrap()
        .then((result) => {
          if (result?.s == 1) {
            setLoading(false);
            dispatch(GetallAudios({value:value,count:count,row_count:row_count}));
            closeeditpopup();
            sucesstost("Updated sucessfully");
          } else {
            setLoading(false);
            closeeditpopup();
            errortost("Something wents wrong please try again ! ");
          }
        });
    } catch (error) {
      errortost("Something wents wrong please try again ! ");
    }
  }

  const handeldcategoryselect = (event) => {
    const selectedCategoryIds = event.target.value;
    setmycategory(selectedCategoryIds);
  };

  return (
    <>
      <TostContainer />
      <Modal open={iseditpopup} onClose={closeeditpopup}>
        <Box
          sx={{
            ...modalstyle,
            borderRadius: "10px",
            width: { xs: "50vw", md: "40vw" },
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Typography
            sx={{
              fontWeight: "600",
              color: "#292D32",
              fontSize: "18px",
              fontFamily: "Poppins",
              textAlign: "center",
              fontSize: "20px",
            }}
          >
            Edit Audio
          </Typography>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "5px",
            }}
          >
            <InputLabel
              id="demo-select-small-label"
              sx={{
                fontSize: "15px",
              }}
            >
              Select Category
            </InputLabel>

            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              sx={{
                width: "100%",
                height: "50px",
                borderRadius: "10px",
                border: "1px solid #F68582",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
              }}
              value={mycategory}
              input={<OutlinedInput label="Tag" />}
              onChange={handeldcategoryselect}
              renderValue={(selected) =>
                selected
                  .map(
                    (value) =>
                      Categorys?.find((category) => category?.value === value)
                        ?.lable
                  )
                  .join(",")
              }
              MenuProps={MenuProps}
            >
              {Categorys?.map((name) => (
                <MenuItem nuItem key={name?.value} value={name?.value}>
                  <Checkbox checked={mycategory.includes(name.value)} />
                  <ListItemText primary={name?.lable} />
                </MenuItem>
              ))}
            </Select>

            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <InputLabel
                id="demo-select-small-label"
                sx={{
                  fontSize: "15px",
                }}
              >
                Enter Audio Title
              </InputLabel>

              <OutlinedInput
                sx={{
                  width: "100%",
                  height: "50px",
                  borderRadius: "10px",
                  border: "1px solid #F68582",
                  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
                }}
                id="outlined-adornment-weight"
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
                value={audioTitle}
                onChange={(e) => handleChange(e, "audioTitle")}
                name="audioTitle"
              />
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "5px",
            }}
          >
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                border: "1px solid #F68582",
                padding: "10px",
                borderRadius: "10px",
              }}
            >
              <Box
                sx={{
                  border: "1px solid #000",
                  borderRadius: "20px",
                  padding: "5px",
                }}
              >
                {audioCoverFile ? (
                  <img
                    style={{ width: "100px", height: "80px" }}
                    src={URL.createObjectURL(audioCoverFile)}
                    alt="audio cover Preview"
                  />
                ) : (
                  <img
                    style={{ width: "100px", height: "80px" }}
                    src={`${audiocoverurl}/${editfiledata?.cover_img}`}
                    alt="Existing audio Thumbnail"
                  />
                )}
              </Box>
              <Input
                type="file"
                id="audio-cover-file-input"
                style={{ display: "none" }}
                onChange={(event) => handleChange(event, "audioCoverFile")}
              />
              <label htmlFor="audio-cover-file-input">
                <Button
                  variant="contained"
                  component="span"
                  sx={{
                    background: "rgba(246, 133, 130, 0.50)",
                    textDecoration: "capitalize",
                    color: "#000",
                    height: "30px",
                    borderRadius: "10px",
                    transition: "background 0.3s ease",
                    "&:hover": {
                      background: "rgba(246, 133, 130, 0.70)",
                    },
                  }}
                >
                  <Typography sx={{ textTransform: "capitalize" }}>
                    Change File
                  </Typography>
                </Button>
              </label>
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                border: "1px solid #F68582",
                padding: "10px",
                borderRadius: "10px",
              }}
            >
              <Typography noWrap style={{ color: "#000", width: "50%" }}>
                {audiofile ? audiofile.name : editfiledata?.file}
              </Typography>
              <Input
                type="file"
                id="music-file-input"
                style={{ display: "none" }}
                onChange={(event) => handleChange(event, "audiofile")}
              />
              <label htmlFor="music-file-input">
                <Button
                  variant="contained"
                  component="span"
                  sx={{
                    background: "rgba(246, 133, 130, 0.50)",
                    textDecoration: "capitalize",
                    color: "#000",
                    height: "30px",
                    borderRadius: "10px",
                    transition: "background 0.3s ease",
                    "&:hover": {
                      background: "rgba(246, 133, 130, 0.70)",
                    },
                  }}
                >
                  <Typography sx={{ textTransform: "capitalize" }}>
                    Change File
                  </Typography>
                </Button>
              </label>
            </Box>

            {/* <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <FormControl sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography sx={{}}>Price</Typography>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="free"
                    name="radio-buttons-group"
                    value={isFree ? "free" : "paid"}
                    onChange={handleChange}
                    sx={{ display: "flex", flexDirection: "row" }}
                  >
                    <FormControlLabel
                      sx={{ color: "#000" }}
                      value="free"
                      control={
                        <Radio
                          color="primary" // Set the desired color, such as "primary", "secondary", "default", or a custom color code
                          sx={{
                            "&.Mui-checked": {
                              color: "#F68582", // Replace with your custom color code
                            },
                          }}
                        />
                      }
                      label="Free"
                    />

                    <FormControlLabel
                      sx={{ color: "#000" }}
                      value="paid"
                      control={
                        <Radio
                          color="primary" // Set the desired color, such as "primary", "secondary", "default", or a custom color code
                          sx={{
                            "&.Mui-checked": {
                              color: "#F68582", // Replace with your custom color code
                            },
                          }}
                        />
                      }
                      label="Paid"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              {!isFree && (
                <OutlinedInput
                  sx={{
                    width: "100%",
                    height: "50px",
                    borderRadius: "10px",
                    border: "1px solid #F68582",
                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                  }}
                  id="outlined-adornment-weight"
                  aria-describedby="outlined-weight-helper-text"
                  value={price}
                  type="Number"
                  onChange={(e) => handleChange(e, "price")}
                  name="price"
                  onKeyDown={(evt) =>
                    ["e", "E", "+", "-",].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  required={!isFree} // Make the input required only when it's not free
                />
              )}
            </Box> */}
          </Box>

          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              onClick={handleEditAudio}
              sx={{
                height: "50px",
                width: "40%",
                textTransform: "capitalize",
                color: "#000",
                boxShadow:
                  "2px 2px 8px 0px rgba(143, 95, 11, 0.50), 0px 3px 11.111px 0px #FBB15A inset, 0px -11px 11.111px 0px #D68835 inset",
                background:
                  " linear-gradient(180deg, #FFCE98 0%, #F4AA50 100%);",
              }}
            >
              {loading ? (
                <CircularProgress sx={{ color: "#fff" }} />
              ) : (
                <Typography> Update </Typography>
              )}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default EditAudioPopup;
