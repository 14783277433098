import React, { useEffect, useState } from "react";
import TitleText from "../../../../Components/TitleText";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import dummycoachimage from "../../../../Utils/Logos/dummyprofile.jpg";
import { useDispatch, useSelector } from "react-redux";
import {
  GetCoachAvilabilityByid,
  GetCoachDetailsByid,
} from "../../../../Redux/Slices/Sessions/Session.action";
 
import { Coach_imageUrl, groupe_scoverurl } from "../../../../Apis/Api";
import { GetEbooklist } from "../../../../Redux/Slices/Ebooks/Ebooks.action";
import deletelogo from "../../../../Utils/Logos/deleteuser.png";
import editlogo from "../../../../Utils/Logos/edit.png";
import dummyprofileimage from "../../../../Utils/Logos/dummyprofile.jpg";
import { formatTime } from "../../../../Apis/Functions";
import EditNoteIcon from '@mui/icons-material/EditNote';
const columns = [
  { id: "#", label: "#" },
  { id: "CoverImage", label: "Cover Image" },
  { id: "Title", label: "Title" },
  { id: "Date", label: "Date " },
  { id: "startTime", label: "start Time" },
  { id: "EndTime ", label: "End Time" },
 
];
const ViewCoachDetails = () => {
  let location = useLocation();
  let dispatch = useDispatch();
  let [coachavilList, setcoachavilList] = useState([]);
  useEffect(() => {
    dispatch(GetCoachDetailsByid(location?.state));
    dispatch(GetCoachAvilabilityByid({ id: location?.state, type: 1 }))
      .unwrap()
      .then((result) => {
        setcoachavilList(result?.r);
      });
  }, []);
  let coachDetails = useSelector((store) => store?.Sessions);
  let [details, setdetails] = useState(coachDetails?.CoachDetails?.r);
  let navigate = useNavigate();
  let RedirectToEditDetails = () => {
    navigate("/dashboard/coaches/view_Coach/edit_details", {
      state:coachDetails?.CoachDetails?.r,
    });
  }


  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: "18px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            gap: "18px",
            width: "100%",
          }}
        >
          <Link
            to={"/dashboard/coaches"}
            style={{ display: "flex", gap: "18px", color: "#000" }}
          >
            {"<"} <TitleText title={"Coach Listing "} style={"0"} />{" "}
          </Link>{" "}
          {">"}
          <TitleText title={"Coach Details "} style={"0"} />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          border: "1px solid rgba(246, 133, 130, 1)",
          mt: "18px",
          height: "70vh",
          padding: "18px",
          overflow: "auto",
          borderRadius: "20px",
          gap: "20px",
          boxSizing: "border-box",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            borderRadius: "15px",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <img
              src={
                coachDetails?.CoachDetails?.r?.profile_img
                  ? `${Coach_imageUrl}/${coachDetails?.CoachDetails?.r?.profile_img}`
                  : dummycoachimage
              }
              alt="coachimage"
              style={{
                width: "180px",
                height: "180px",
                padding: "20px",
                border: "1px solid rgba(246, 133, 130, 1)",
                borderRadius: "20px",
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                padding: "18px",
                gap: "20px",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "start", gap: "5px" }}>
                <Typography
                  sx={{ fontSize: "18px", fontWeight: "600", width: "80px" }}
                >
                  Name : -{" "}
                </Typography>
                <Typography sx={{ fontSize: "18px" }}>
                  {" "}
                  {coachDetails?.CoachDetails?.r?.coach_name}
                </Typography>
              </Box>

              <Box sx={{ display: "flex", alignItems: "start", gap: "5px" }}>
                <Typography
                  sx={{ fontSize: "18px", fontWeight: "600", width: "80px" }}
                >
                  Email : -{" "}
                </Typography>
                <Typography sx={{ fontSize: "18px" }}>
                  {coachDetails?.CoachDetails?.r?.email}{" "}
                </Typography>
              </Box>

              <Box sx={{ display: "flex", alignItems: "start", gap: "5px" }}>
                <Typography
                  sx={{ fontSize: "18px", fontWeight: "600", width: "80px" }}
                >
                  Price : -{" "}
                </Typography>
                <Typography sx={{ fontSize: "18px" }}>
                  {coachDetails?.CoachDetails?.r?.price}$
                </Typography>
              </Box>

              <Box sx={{ display: "flex", alignItems: "start", gap: "5px" }}>
                <Typography
                  sx={{ fontSize: "18px", fontWeight: "600", width: "80px" }}
                >
                  Social : -{" "}
                </Typography>
                <Typography sx={{ fontSize: "18px" }}>
                  {coachDetails?.CoachDetails?.r?.website}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              gap: "5px",
            }}
          >
            <Typography sx={{ fontSize: "25px", fontWeight: "600" }}>
              {coachDetails?.CoachDetails?.r?.title}
            </Typography>
            <Typography sx={{ fontSize: "16px" }}>
              {" "}
              {coachDetails?.CoachDetails?.r?.description}
            </Typography>
            <Typography
              sx={{ fontSize: "18px" }}
              dangerouslySetInnerHTML={{ __html: details?.text }}
            />
          </Box>
          <Box
            sx={{ fontSize: "18px", display: "flex", gap: "10px", pb: "20px" }}
          >
            {" "}
            {coachDetails?.CoachDetails?.r?.tags?.split(",").map((el) => (
              <Chip label={el} variant="outlined" />
            ))}
          </Box>
        </Box>
        <Button
          onClick={() => RedirectToEditDetails()}
          variant="outlined"
          sx={{ height: "50px",border:"1px solid red",textTransform:"capitalize" ,color:"red"}}
        >
          <EditNoteIcon/>
        </Button>
      </Box>
      <Box sx={{ mt: "20px" }}>
        <Paper style={{ width: "100%", overflow: "hidden" }}>
          <TableContainer style={{ maxHeight: "75vh" }}>
            <Table stickyHeader sx={{}}>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      sx={{
                        textAlign: "center",
                        fontSize: "15px",
                        lineHeight: "120%",
                        fontFamily: "Montserrat",
                        fontWeight: "600",
                        background: "#F68582",
                        color: "#fff",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody sx={{ textAlign: "center" }}>
                {coachavilList.length < 1 || coachavilList == null ? (
                  <Box sx={{ textAlign: "center", padding: "10px", m: "10px" }}>
                    No Slots !
                  </Box>
                ) : (
                  coachavilList?.map((row, i) => (
                    <TableRow sx={{ textAlign: "center" }}>
                      <TableCell sx={{ textAlign: "center" }}>
                        {i + 1}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <img
                          src={
                            row?.cover_img
                              ? `${groupe_scoverurl}/${row?.cover_img}`
                              : dummyprofileimage
                          }
                          style={{
                            height: "45px",
                            width: "45px",
                            borderRadius: "10px",
                          }}
                          alt=""
                        />
                      </TableCell>
                      <TableCell sx={{ textAlign: "center", width: "325px" }}>
                        <Typography
                          noWrap
                          sx={{
                            width: "325px",
                          }}
                        >
                          {row?.title || "-----"}
                        </Typography>
                      </TableCell>

                      <TableCell sx={{ textAlign: "center" }}>
                        {" "}
                        {row?.date
                          ? (() => {
                              const inputDate = new Date(row.date);
                              return `${inputDate
                                .getDate()
                                .toString()
                                .padStart(2, "0")}/${(inputDate.getMonth() + 1)
                                .toString()
                                .padStart(2, "0")}/${inputDate.getFullYear()}`;
                            })()
                          : "N/A"}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {formatTime(row?.start_time)}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {formatTime(row?.end_time)}
                      </TableCell>
                    
 
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        
      </Box>
    </div>
  );
};

export default ViewCoachDetails;
