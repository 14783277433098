import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import {
  AddPrivacyandTermsConditiondata,
  GetTermsAndConditionata,
  UpdatePrivacyandTermsConditiondata,
} from "../../../../Redux/Slices/SettingData/SettingData.action";
import { Box, Button } from "@mui/material";
import { CommonButtonstyle } from "../../../../Components/CommonCss";
import {
  errortost,
  sucesstost,
} from "../../../../Components/Alerts/CustomAlert";

const TermsAndConditions = () => {
  let dispatch = useDispatch();

  let SettingData = useSelector((store) => store?.Settings);

  const [TermsAndcondition, SetTermsAndcondition] = useState(
    SettingData?.TermsAndConditions?.r?.description
  );
  console.log(TermsAndcondition);
  useEffect(() => {
    dispatch(GetTermsAndConditionata());
  }, []);

  let [loading, setloading] = useState(false);

  let AddUpdateTermscondition = () => {
    if (SettingData?.TermsAndConditions?.r?.id) {
      let TermsAndconditionformdata = new FormData();

      TermsAndconditionformdata.append("description", TermsAndcondition);
      TermsAndconditionformdata.append("type", 1);
      TermsAndconditionformdata.append(
        "id",
        SettingData?.TermsAndConditions?.r?.id
      );
      setloading(true);
      dispatch(
        UpdatePrivacyandTermsConditiondata({
          Contentdata: TermsAndconditionformdata,
          type: 1,
        })
      )
        .unwrap()
        .then((result) => {
          if (result?.s === 1) {
            setloading(false);
            dispatch(GetTermsAndConditionata());
            sucesstost("Terms and Conditions Updated !");
          } else {
            setloading(false);
            errortost("Something Wents Wrong Please try again !");
          }
        });
    } else {
      let TermsAndconditionformdata = new FormData();
      TermsAndconditionformdata.append("description", TermsAndcondition);
      TermsAndconditionformdata.append("type", 1);
      dispatch(
        AddPrivacyandTermsConditiondata({
          Contentdata: TermsAndconditionformdata,
          type: 1,
        })
      )
        .unwrap()
        .then((result) => {
          if (result?.s === 1) {
            setloading(false);
            sucesstost("Terms and Conditions Added !");
            dispatch(GetTermsAndConditionata());
          } else {
            setloading(false);
            errortost("Something Wents Wrong Please try again !");
          }
        });
    }
  };

  return (
    <>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
        <Button
          onClick={AddUpdateTermscondition}
          sx={{ ...CommonButtonstyle, width: "10%", mt: "-50px", mr: "0px" }}
          disabled={loading}
        >
          Save
        </Button>
      </Box>

      <ReactQuill
        value={TermsAndcondition}
        onChange={(value) => SetTermsAndcondition(value)}
        style={{
          width: "100%",
          textAlign: "left",
          height: "78vh",
          border: "none",
        }}
        theme="snow"
        modules={{
          toolbar: {
            container: [
              [
                {
                  header: "1",
                  style: { background: "rgba(246, 133, 130, 0.20)" },
                },
                {
                  header: "2",
                  style: { background: "rgba(246, 133, 130, 0.20)" },
                },
              ],
              [{ font: [] }],
              ["bold", "italic", "underline", "strike"],
              [{ color: [] }, { background: [] }],
              [{ list: "ordered" }, { list: "bullet" }],
              ["link"],
              ["clean"],
              [{ size: ["small", false, "large", "huge"] }], // Font size dropdown
            ],
          },
        }}
      />
    </>
  );
};

export default TermsAndConditions;
