import { createSlice } from "@reduxjs/toolkit";
import { AddAffermationitem, AddUpdateMantra, DeletethisAffermation, GetAffermationslist, GetMantra } from "./ManageContent.action";
let initialState = {
  loading: false,
  error: null,
  Affirmations: {},
  Mantra:{}
}

const ManageContentslice = createSlice({
  name: "ManageContent",
  initialState,
  extraReducers: (builder) => {
    // --------- Get Affermations list ---------
    builder
      .addCase(GetAffermationslist.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetAffermationslist.fulfilled, (state, action) => {
        state.loading = false;
        state.Affirmations = action?.payload;
      })
      .addCase(GetAffermationslist.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
    
    // --------- Add new Affermations ---------
    builder
      .addCase(AddAffermationitem.pending, (state) => {
        state.loading = true;
      })
      .addCase(AddAffermationitem.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(AddAffermationitem.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });

        // --------- Delete Affermation  ---------
    builder
      .addCase(DeletethisAffermation.pending, (state) => {
        state.loading = true;
      })
      .addCase(DeletethisAffermation.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(DeletethisAffermation.rejected, (state) => {
        state.error = true;
        state.loading = false;
      })
 
    // --------- Get Mantra ---------
    builder
      .addCase(GetMantra.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetMantra.fulfilled, (state, action) => {
        state.loading = false;
        state.Mantra = action?.payload?.r;
      })
      .addCase(GetMantra.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });


    // --------- Add Mantra ---------
    builder
      .addCase(AddUpdateMantra.pending, (state) => {
        state.loading = true;
      })
      .addCase(AddUpdateMantra.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(AddUpdateMantra.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
  
 
  },
});

export default ManageContentslice.reducer;
