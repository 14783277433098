import React, { useEffect, useState } from "react";
import TitleText from "./TitleText";
import { useDispatch, useSelector } from "react-redux";
import { GetNotifications } from "../Redux/Slices/Dashboard/Dashboard.action";
import { Box, Typography } from "@mui/material";
import bellicon from "../Utils/Logos/Bell.png";
import { formatTime } from "../Apis/Functions";
import EditNotificationsIcon from "@mui/icons-material/EditNotifications";
import Edit_Notifications from "./Popups/Edit_Notifications";

const NotificationHistory = () => {
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetNotifications());
  }, []);

  let NotificationsData = useSelector((store) => store?.Dashboard);
  const categorizeNotifications = (notifications) => {
    const Today = new Date();
    const Yesterday = new Date(Today);
    Yesterday.setDate(Today.getDate() - 1);
    const Tomorrow = new Date(Today);
    Tomorrow.setDate(Today.getDate() + 1);

    return notifications.reduce(
      (result, notification) => {
        const notificationDate = new Date(notification.date_time);

        if (notificationDate.toDateString() === Today.toDateString()) {
          result.Today.push(notification);
        } else if (
          notificationDate.toDateString() === Yesterday.toDateString()
        ) {
          result.Yesterday.push(notification);
        } else if (
          notificationDate.toDateString() === Tomorrow.toDateString()
        ) {
          result.Tomorrow.push(notification);
        } else {
          const formattedDate = notificationDate.toDateString();
          if (!result[formattedDate]) {
            result[formattedDate] = [];
          }
          result[formattedDate].push(notification);
        }

        return result;
      },
      { Yesterday: [], Today: [], Tomorrow: [] } // Initialize other categories
    );
  };

  const categorizedNotifications = categorizeNotifications(
    NotificationsData?.Notification?.r || []
  );

  const [isNotificationOpen, setisNotificationOpen] = useState(false);
  let [selectedData, setSelectedData] = useState({});
  const EditNotificationOpen = (data) => {
    setSelectedData(data);
    setisNotificationOpen(true);
  };
  const CloseEditPopup = () => {
    setisNotificationOpen(false);
  };

  return (
    <div>
      {isNotificationOpen ? (
        <Edit_Notifications
          modalcontrols={{ isNotificationOpen, CloseEditPopup, selectedData }}
        />
      ) : (
        ""
      )}
      <TitleText title={"Notification History"} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          mt: "10px",
          height: "90vh",
          overflow: "auto",
        }}
      >
        {Object.entries(categorizedNotifications).map(
          ([category, notifications]) => (
            <React.Fragment key={category}>
              <Typography variant="h6">{category}</Typography>
              {notifications?.length === 0 ? (
                <Typography sx={{color:"gray"}}>No Notifications for {category}.</Typography>
              ) : (
                notifications?.map((el) => (
                  <Box key={el.id}>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        background: "rgba(246, 133, 130, 0.25)",
                        padding: "15px",
                        borderRadius: "15px",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: "10px",
                          width: "88%",
                          borderRadius: "15px",
                        }}
                      >
                        <img
                          src={bellicon}
                          style={{
                            height: "27px",
                            width: "27px",
                            padding: "10px",
                            background: "#fff",
                            borderRadius: "18px",
                          }}
                          alt=""
                        />
                        <Box>
                          <Typography
                            sx={{
                              fontFamily: "Poppins",
                              lineHeight: "18px",
                              fontWeight: "600",
                            }}
                          >
                            {el?.title}
                          </Typography>
                          <Typography sx={{ fontFamily: "Poppins" }}>
                            {el?.body}
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography sx={{ textAlign: "end" }}>
                          {formatTime(el?.date_time.split(" ")[1])}{" "}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Typography
                            sx={{
                              textAlign: "end",
                              color: el?.is_send === 0 ? "red" : "green",
                              fontWeight: "500",
                            }}
                          >
                            {" "}
                            {el?.is_send === 0 ? "Pending" : "Completed"}
                          </Typography>
                          {el?.is_send === 0 ? (
                            <Box
                              sx={{
                                background: "#F68582",
                                padding: "8px 2px 3px 2px",
                                borderRadius: "50%",
                                transition: "background 0.3s ease",
                                "&:hover": {
                                  background: "#fff",
                                },
                              }}
                              
                              onClick={() => EditNotificationOpen(el)}
                            >
                              <EditNotificationsIcon
                                sx={{
                                  cursor: "pointer",
                                  height: "20px",
                                  width: "30px",
                                  color: "#fff",
                                  transition: "color 0.3s ease",
                                  "&:hover": {
                                    color: "#F68582",
                                  },
                                }}
                              />
                            </Box>
                          ) : (
                            ""
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ))
              )}
            </React.Fragment>
          )
        )}
      </Box>
    </div>
  );
};

export default NotificationHistory;
