import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  FormControl,
  Select,
  MenuItem,
  Button,
  TablePagination,
  Typography,
  Chip,
  Tooltip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { videocoverurl } from "../../../../Apis/Api";
import deletelogo from "../../../../Utils/Logos/deleteuser.png";
import editlogo from "../../../../Utils/Logos/edit.png";
import { GetAllCategory, GetallVideos } from "../../../../Redux/Slices/Resources/Resources.action";
import AddVideoPopup from "../../../../Components/Popups/AddVideoPopup";
import DeleteResources from "../../../../Components/Popups/DeleteResources";
import EditVideoPopup from "../../../../Components/Popups/EditVideoPopup";

const Videopage = () => {
  const dispatch = useDispatch();
  const columns = [
    { id: "#", label: "#" },
    { id: "Video", label: "Video " },
    { id: "Category", label: "Category " },
    { id: "Video Name", label: "Video Name" },
    { id: "Length", label: "Length" },
    // { id: "Price", label: "Price" },
    { id: "Action", label: "Action" },
  ];

  const [isaddvideo, setisaddvideo] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [VideoData, setVideodata] = useState([]);
let [filtervalue,setfiltervalue]=useState(0)
  const storeVideodata = useSelector((store) => store.Resources);
  useEffect(() => {
    setVideodata(storeVideodata?.VideoFiles?.r || []);
  }, [storeVideodata])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(GetallVideos({value:filtervalue,count:page * rowsPerPage,row_count: rowsPerPage}));
  };
  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    // dispatch(GetallVideos({value:filtervalue,count:page * rowsPerPage,row_count: rowsPerPage}));
  }
  const openaddvideo = () => {
    setisaddvideo(true);
  };
  const closeisaddvideo = () => {
    setisaddvideo(false);
  };
  const getfilterdvalue = (value) => {
    setfiltervalue(value)
    setPage(0);
    dispatch(GetallVideos({value:value,count:page * rowsPerPage,row_count: rowsPerPage}));
  };

  // --------- Delete Conformation Popup ------------
  let [deleteselected, setdeleteselected] = useState({});
  let [isdeleteresource, setisdeleteresource] = useState();
  let deleteselectedvideo = (data) => {
    setdeleteselected(data);
    setisdeleteresource(true);
  };
  let closedeleteresource = () => {
    setisdeleteresource(false);
  };
  let resourcetype = "video";
  // --------- Edit popuo Popup ------------
  let [editfiledata, seteditfiledata] = useState({});
  let [isvideoeditpopup, setisvideoeditpopup] = useState(false);

  let openvideoeditpopup = (data) => {
    seteditfiledata(data);
    setisvideoeditpopup(true);
  };
  let closevideoeditpopup = () => {
    setisvideoeditpopup(false);
  };

  useEffect(() => {
    dispatch(GetAllCategory())
    dispatch(GetallVideos({value:filtervalue,count:page * rowsPerPage,row_count: rowsPerPage}));
  }, [ page, rowsPerPage])

  let Rowdata = VideoData?.map((data, i) => ({
    rowid: i + 1 + page * 10,
    ...data,
  }))
  return (
    <>
      {isvideoeditpopup ? (
        <EditVideoPopup
          modalcontrols={{
            isvideoeditpopup,
            closevideoeditpopup,
            editfiledata,
            value:filtervalue,count:page * rowsPerPage,row_count: rowsPerPage
          }}
        />
      ) : (
        ""
      )}
      {isdeleteresource ? (
        <DeleteResources
          modalcontrols={{
            isdeleteresource,
            closedeleteresource,
            resourcetype,
            deleteselected,
            value:filtervalue,count:page * rowsPerPage,row_count: rowsPerPage
          }}
        />
      ) : (
        ""
      )}
      {isaddvideo ? (
        <AddVideoPopup modalcontrols={{ isaddvideo, closeisaddvideo , value:filtervalue,count:page * rowsPerPage,row_count: rowsPerPage}} />
      ) : (
        ""
      )}
      <Box
        sx={{
          width: "100%",
          height: "40px",
          display: "flex",
          justifyContent: "space-between",
          mt: "15px",
        }}
      >
        <Box
          sx={{
            width: "20%",
            height: "20%",
            display: "flex",
            alignItems: "center",
            background: "rgba(246, 133, 130, 0.50)",
          }}
        >
          <FormControl sx={{ width: "100%", height: "100%" }} size="small">
            <Select
              sx={{
                width: "100%",
                height: "60px",
                border: "1px solid #F68582",
                background: "#fff",
              }}
              labelId="demo-select-small-label"
              id="demo-select-small"
              label="All"
              onChange={(e) => getfilterdvalue(e?.target?.value)}
              defaultValue={0} // Set the value corresponding to "All" as the default value
            >
             <MenuItem value={0}>All</MenuItem>
              {storeVideodata?.CategoryList?.map((el)=>
              <MenuItem value={el?.id}>{el?.name} </MenuItem>
              )}
            </Select>
          </FormControl>
        </Box>
        <Button
          onClick={openaddvideo}
          sx={{
            padding: "5px 40px",
            textTransform: "capitalize",
            color: "#000",
            boxShadow:
              "2px 2px 8px 0px rgba(143, 95, 11, 0.50), 0px 3px 11.111px 0px #FBB15A inset, 0px -11px 11.111px 0px #D68835 inset",
            background: " linear-gradient(180deg, #FFCE98 0%, #F4AA50 100%);",
          }}
        >
          Add More
        </Button>
      </Box>

      <Box sx={{ mt: "20px" }}>
        <Paper style={{ width: "100%", overflow: "hidden" }}>
          <TableContainer style={{ maxHeight: "71vh" }}>
            <Table stickyHeader sx={{}}>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      sx={{
                        textAlign: "center",
                        fontSize: "15px",
                        lineHeight: "120%",
                        fontFamily: "Montserrat",
                        fontWeight: "600",
                        background: "#F68582",
                        color: "#fff",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {Rowdata.length < 1 ? (
                  <Box sx={{ textAlign: "center", padding: "10px", m: "10px" }}>
                    No Data
                  </Box>
                ) : (
                  Rowdata?.map((row, i) => (
                    <TableRow key={row.id} sx={{ textAlign: "center" }}>
                      <TableCell sx={{ textAlign: "center" }}>
                      {row?.rowid}
                      </TableCell>

                      <TableCell sx={{ textAlign: "center" }}>
                        {" "}
                        <img
                          src={` ${videocoverurl}/${row?.thumb}`}
                          style={{
                            height: "40px",
                            width: "50px",
                            borderRadius: "10px",
                          }}
                          alt=""
                        />
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <Tooltip
                          title={
                            <Box>
                              {row?.category?.map((item, index) => (
                                <Typography
                                  sx={{ fontSize: "14px" }}
                                  key={index}
                                >
                                  {item?.name}
                                </Typography>
                              ))}
                            </Box>
                          }
                        >
                          <Typography
                            noWrap
                            sx={{
                              width: "180px",
                              display: "flex",
                              m: "auto",
                              textOverflow: "ellipsis",
                              cursor: "pointer",
                              justifyContent:"center",
                              textAlign:"center"
                            }}
                          >
                            {row?.category[0]?.name}, {row?.category[1]?.name} {row?.category?.length<2?"":<span>....</span>} 
                            {/* {row?.category?.map((item, index) => (
                              <Typography sx={{ fontSize: "14px" }} key={index}>
                                {item?.name} ,
                              </Typography>
                            ))} */}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {" "}
                        {row?.title}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {" "}
                        {row?.length}
                      </TableCell>
                      {/* <TableCell sx={{ textAlign: "center" }}>
                        {" "}
                        {row?.price == 0 ? (
                          <Chip
                            label="Free"
                            color="success"
                            variant="outlined"
                          />
                        ) : (
                          <Typography>${row?.price}</Typography>
                        )}
                      </TableCell> */}
                      <TableCell
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Box
                          sx={{
                            height: "50px",
                            display: "flex",
                            gap: "20px",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            onClick={() => deleteselectedvideo(row)}
                            style={{
                              width: "30px",
                              height: "30px",
                              cursor: "pointer",
                            }}
                            src={deletelogo}
                            alt="deleteicon"
                          />

                          <img
                            onClick={() => openvideoeditpopup(row)}
                            style={{
                              width: "30px",
                              height: "30px",
                              cursor: "pointer",
                            }}
                            src={editlogo}
                            alt="editicon"
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        <Box>
          <TablePagination
            sx={{ mb: "-20px" }}
            component="div"
            count={storeVideodata?.VideoFiles?.count}
            page={page}
            onPageChange={handleChangePage}
            // rowsPerPage={10}
            // rowsPerPageOptions={[]}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Box>
    </>
  );
};

export default Videopage;
