import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Select,
  Button,
  FormControl,
  MenuItem,
  TablePagination,
  Chip,
  // TablePagination,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getGroupeSessionsbyid } from "../../../../Redux/Slices/Users/User.action";
import { groupe_scoverurl } from "../../../../Apis/Api";
import { formatTime } from "../../../../Apis/Functions";
const GroupeSession = () => {
  const columns = [
    { id: "#", label: "#" },
    { id: "Date", label: "Date" },
    { id: "Image", label: "Image" },
    { id: "Time Selected", label: "Time Selected" },
    { id: "Topic", label: "Topic" },
    { id: "Payment", label: "Payment" },
    { id: "Payment Status", label: "Payment Status" },
    // { id: "View", label: "View" },
  ];
  const { userId } = useParams();

  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(getGroupeSessionsbyid({ user_id:userId, count: 0 }));
  }, []);

  let sessionsdata = useSelector((store) => store?.Users);


  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
 
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // dispatch(GetAllUsers(newPage * rowsPerPage));
    dispatch(getGroupeSessionsbyid({ user_id:userId, count:newPage * rowsPerPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    // dispatch(GetAllUsers(0)); // Reset to the first page
    dispatch(getGroupeSessionsbyid({ user_id:userId, count:0 }));
  }; 

  return (
    <div>
      <Box sx={{ mt: "20px" }}>
        <Paper style={{ width: "100%", overflow: "hidden" }}>
          <TableContainer style={{ maxHeight: "71vh" }}>
            <Table stickyHeader sx={{}}>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      sx={{
                        textAlign: "center",
                        fontSize: "15px",
                        lineHeight: "120%",
                        fontFamily: "Montserrat",
                        fontWeight: "600",
                        background: "#F68582",
                        color: "#fff",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody sx={{ textAlign: "center" }}>
                {sessionsdata?.UserGroupeSessions?.r?.length == 0 || sessionsdata?.UserGroupeSessions?.r==null  ? (
                  <Box sx={{ textAlign: "center", padding: "10px", m: "10px" }}>
                    No Data
                  </Box>
                ) : (
                  sessionsdata?.UserGroupeSessions?.r?.map((row, i) => (
                    <TableRow key={row.id} sx={{ textAlign: "center" }}>
                      <TableCell sx={{ textAlign: "center" }}>
                        {i + 1}
                      </TableCell>

                      <TableCell sx={{ textAlign: "center" }}>
                        {" "}
                        {row?.date}
                      </TableCell>

                      <TableCell sx={{ textAlign: "center" }}>
                        <img
                          src={` ${groupe_scoverurl}/${row?.cover_img}`}
                          style={{
                            height: "40px",
                            width: "50px",
                            borderRadius: "10px",
                          }}
                          alt=""
                        />
                      </TableCell>

                      <TableCell sx={{ textAlign: "center" }}>
                        {" "}
                        {formatTime(row?.start_time) } to {formatTime(row?.end_time)}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {" "}
                        {row?.group_name}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {" "}
                        ${row?.price}
                      </TableCell>

                      <TableCell sx={{ textAlign: "center" }}>
                        {" "}
                        <Chip
                          sx={{
                            background:
                              row?.payment_status === 1
                                ? "green"
                                : row?.payment_status === 0
                                ? "red"
                                : row?.payment_status === -1
                                ? "gray"
                                : "gray",
                            color: "#fff",
                          }}
                          label={
                            row?.payment_status === 1
                              ? "Completed"
                              : row?.payment_status === 0
                              ? "Failed"
                              : row?.payment_status === -1
                              ? "Refund"
                              : "Refund"
                          }
                        />
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <TablePagination
            sx={{ mb: "-20px" }}
            component="div"
            count={sessionsdata?.UserGroupeSessions?.count || 0}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Box>
    </div>
  );
};

export default GroupeSession;
