import {
  Box,
  Button,
  CircularProgress,
  Input,
  Modal,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { modalstyle } from "./PopupsStyling";
import { Coach_imageUrl, User_Profileimage } from "../../Apis/Api";
import ActionButton from "./PopupComponent/ActionButton";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EmailInput from "../Inputs/EmailInput";
import AddLinkIcon from "@mui/icons-material/AddLink";
import { CommonButtonstyle } from "../../Components/CommonCss";
import CommonInput from "../Inputs/CommonInput";
import { useDispatch } from "react-redux";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import {
  AddUpdateIndiviSessionLink,
  AddnewCoachesDetails,
  GetCoachesDetailslist,
  GetIndividualSessions,
} from "../../Redux/Slices/Sessions/Session.action";
import { errortost, sucesstost } from "../Alerts/CustomAlert";
import TostContainer from "../Alerts/TostContainer";
import { formatTime } from "../../Apis/Functions";
import { GetRecentIndividualSessions } from "../../Redux/Slices/Dashboard/Dashboard.action";
import dummyuserimage from "../../Utils/Logos/Sidebar/user.png";
const ViewUserDetailsPopup = ({ modalcontrols }) => {
  let {
    isUserDetailsopen,
    closeuserdetails,
    selecteddata,
    count,
    rowsPerPage,
  } = modalcontrols;
  let fields = [
    {
      name: "Title",
      value: selecteddata?.title ? selecteddata?.title : " - - - ",
    },
    { name: "Date ", value: selecteddata?.date },
    { name: "Time ", value: formatTime(selecteddata?.start_time) },

    { name: "Payment ", value: `$${selecteddata?.price}` },
    {
      name: "Payment Staus",
      value:
        selecteddata?.payment_status === 0
          ? "Failed"
          : selecteddata?.status === 1
          ? "Completed"
          : selecteddata?.status === -1
          ? "Pending"
          : "",
    },
  ];

  let [link, setlink] = useState("");
  let dispatch = useDispatch();

  let handlelinkchange = (e) => {
    setlink(e.target.value);
  };

  const isLinkEmpty = () => {
    return link.trim() === "";
  };

  let Updatethislink = () => {
    let linkdata = new FormData();
    linkdata.append("link", link);
    linkdata.append("id", selecteddata?.id);
    dispatch(AddUpdateIndiviSessionLink(linkdata))
      .unwrap()
      .then((result) => {
        if (result?.s === 1) {
          sucesstost(
            `Link ${
              selecteddata?.link !== null ? "Added" : "Updated"
            } Sucessfully ! `
          );
          dispatch(
            GetIndividualSessions({ count: count, row_count: rowsPerPage })
          );
          dispatch(
            GetRecentIndividualSessions({
              count: count,
              row_count: rowsPerPage,
            })
          );
        } else {
          errortost("Something Wents wrong please try again !");
        }
      });
  };

  useEffect(() => {
    setlink(selecteddata?.link || "");
    // GetcoachesList();
  }, []);

  // let [coach_name, setcoach_name] = useState("");
  // let [coachlist, setcoachlist] = useState([]);
  // let [coachprofile_imagefile, setcoachprofile_imagefile] = useState(null);
  // let [coachprofile_image, setcoachprofile_image] = useState(null);
  // let [getcoachlistloader, setgetcoachlistloader] = useState(false);
  // let GetcoachesList = () => {
  //   dispatch(GetCoachesDetailslist(selecteddata?.id))
  //     .unwrap()
  //     .then((result) => {
  //       if (result?.r) {
  //         setcoachlist(result?.r);
  //         setgetcoachlistloader(false);
  //       } else {
  //         setgetcoachlistloader(false);
  //       }
  //     });
  //   setgetcoachlistloader(false);
  // };
  // let handeldCoachImage = (event) => {
  //   const file = event.target.files[0];
  //   setcoachprofile_imagefile(file);
  //   const objectURL = URL.createObjectURL(file);
  //   setcoachprofile_image(objectURL);
  // };
  let [addcoachloading, setaddcoachloading] = useState(false);
  // let AddnewCoach = () => {
  //   if (coachprofile_imagefile == null || coachprofile_image == null) {
  //     errortost("Please select coach image ..");
  //   } else if (coach_name === "") {
  //     errortost("Please enter coach name ");
  //   } else {
  //     let formdata = new FormData();
  // formdata.append(`coach_name[${coachlist?.length}]`, coach_name);
  // formdata.append(
  //   `profile_image[${coachlist?.length}]`,
  //   coachprofile_imagefile
  // );
  //     formdata.append(`coach_name[0]`, coach_name);
  //     formdata.append(`profile_img[0]`, coachprofile_imagefile);
  //     formdata.append("type", 6);
  //     formdata.append("ref_id", selecteddata?.id);
  //     try {
  //       setaddcoachloading(true);
  //       dispatch(AddnewCoachesDetails(formdata))
  //         .unwrap()
  //         .then((result) => {
  //           if (result?.s == 1) {
  //             setaddcoachloading(false);
  //             setcoachprofile_imagefile(null);
  //             setcoachprofile_image(null);
  //             setcoach_name("");
  //             closeuserdetails();
  //             sucesstost("Coach Details added sucessfully !");
  //           } else {
  //             setaddcoachloading(false);
  //             setcoachprofile_imagefile(null);
  //             setcoachprofile_image(null);
  //             setcoach_name("");
  //             closeuserdetails();
  //             errortost("Something wents wrong !");
  //           }
  //         });
  //     } catch (err) {
  //       setaddcoachloading(false);
  //       setcoachprofile_imagefile(null);
  //       setcoachprofile_image(null);
  //       setcoach_name("");
  //       closeuserdetails();
  //       setaddcoachloading(false);
  //     }
  //   }
  // };

  return (
    <>
      <TostContainer />
      <Modal open={isUserDetailsopen} onClose={closeuserdetails}>
        <Box
          sx={{
            ...modalstyle,
            borderRadius: "10px",
            width: { xs: "50vw", md: "50vw" },
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            m: "auto",
          }}
        >
          <Box>
            <Box
              sx={{
                width: "20%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid #F4AA50",
                borderRadius: "10px",
                padding: "10px 0px",
                m: "auto",
              }}
            >
              {selecteddata?.profile_img ? (
                <img
                  src={`${User_Profileimage}/${selecteddata?.profile_img}`}
                  style={{
                    height: "120px",
                    width: "120px",
                    borderRadius: "10px",
                  }}
                  alt=""
                />
              ) : (
                <AccountCircleIcon
                  sx={{ height: "100px", color: "#000", width: "100px" }}
                />
              )}
            </Box>

            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                height: "40vh",
                boxSizing: "border-box",
              }}
            >
              <Box
                sx={{
                  mt: "10px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  width: "40%",
                  height: "100%",
                }}
              >
                <Typography
                  sx={{
                    color: "#000",
                    textAlign: "start",
                    fontSize: "25px",
                    fontWeight: "600",
                    padding: "0.5vh 0vh",
                  }}
                >
                  Session Details
                </Typography>
                {fields?.map((fields) => (
                  <Box
                    sx={{
                      display: "flex",
                      color: "#000",
                      alignItems: "center",
                      gap: "5px",
                      justifyContent: "start",
                      padding: "0vh 1vw",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#000",
                        fontFamily: "Poppins",
                        fontSize: "18px",
                        fontWeight: "600",
                      }}
                    >
                      {fields?.name} :
                    </Typography>{" "}
                    <Typography
                      sx={{
                        color:
                          fields?.value === "Failed"
                            ? "red"
                            : fields?.value === "completed"
                            ? "green"
                            : fields?.value === "pending"
                            ? "blue"
                            : "",
                        fontFamily: "Poppins",
                        fontSize: "18px",
                        fontWeight: "400",
                      }}
                    >
                      {fields?.value}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>

            {selecteddata?.link !== null ? (
              <Typography
                sx={{
                  textAlign: "start",
                  m: "10px 0px",
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontWeight: "600",
                }}
              >
                Update Link :
              </Typography>
            ) : (
              <Typography
                sx={{
                  textAlign: "start",
                  m: "10px 0px",
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontWeight: "600",
                }}
              >
                Add Meeting Link :
              </Typography>
            )}
            <Box
              sx={{
                display: "flex",
                width: "100%",
                mb: "20px",
                boxSizing: "border-box",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              <Box sx={{ width: "80%" }}>
                <CommonInput
                  value={link}
                  placeholder={"Enter link here .. "}
                  onChange={handlelinkchange}
                />
              </Box>
              <Button
                disabled={isLinkEmpty()}
                onClick={Updatethislink}
                sx={{ ...CommonButtonstyle, width: "20%" }}
              >
                <AddLinkIcon sx={{ color: isLinkEmpty() ? "#000" : "#fff" }} />
              </Button>
            </Box>
            <Box sx={{ width: "40%", m: "auto" }}>
              <ActionButton title={"Close"} onclick={closeuserdetails} />
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ViewUserDetailsPopup;
