import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { modalstyle } from "./PopupsStyling";
import { useDispatch, useSelector } from "react-redux";
import {
  AddNewAudio,
  GetallAudios,
  GetallVideos,
} from "../../Redux/Slices/Resources/Resources.action";
import { Baseurl, getUserdata } from "../../Apis/Api";
import { toast } from "react-toastify";
import axios from "axios";
import { errortost, sucesstost } from "../Alerts/CustomAlert";
import { Categorys } from "./CommonData";

const AddVideoPopup = ({ modalcontrols }) => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  let { isaddvideo, closeisaddvideo,value,count,row_count } = modalcontrols;
  const [category, setCategory] = useState([]);
  const [VideoTitle, setVideoTitle] = useState("");
  const [VideoThumbnail, setVideoThumbnail] = useState(null);
  const [VideoFile, setVideoFile] = useState(null);
  const [Description, setDescription] = useState(null);
  const [duration, setvideoduration] = useState(null);
  let [loading, setloading] = useState(false);
  let dispatch = useDispatch();
 
  let ResourceData = useSelector((store) => store?.Resources);
  const [formErrors, setFormErrors] = useState({
    category: false,
    VideoTitle: false,
    VideoThumbnail: false,
    VideoFile: false,
  });
  const handleselectChange = (event) => {
    const {
      target: { value },
    } = event;
    setCategory(typeof value === "string" ? value.split(",") : value);
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormErrors({ ...formErrors, [name]: false });
    if (name === "category") {
      setCategory(value);
    } else if (name === "VideoTitle") {
      setVideoTitle(value);
    } else if (name === "Description") {
      setDescription(value);
    } 
  };

  const handleFileChange = (event, fileType) => {
    const file = event.target.files[0];
    if (!file) {
      console.log(`Please select a file for ${fileType}`);
      setFormErrors({ ...formErrors, [fileType]: true });
      return;
    }
    if (fileType === "VideoThumbnail" && !file.type.startsWith("image/")) {
      setFormErrors({ ...formErrors, [fileType]: true });
      return;
    }
    if (fileType === "VideoFile" && !file.type.startsWith("video/")) {
      console.log(`Please select a video file for ${fileType}`);
      setFormErrors({ ...formErrors, [fileType]: true });
      return;
    }
    setFormErrors({ ...formErrors, [fileType]: false });
    if (fileType === "VideoThumbnail") {
      setVideoThumbnail(file);
    } else if (fileType === "VideoFile") {
      setVideoFile(file);
      extractVideoDuration(file); // Call the function to extract video duration
    }
  };

  const extractVideoDuration = (videoFile) => {
    const video = document.createElement("video");
    video.src = URL.createObjectURL(videoFile);
    video.addEventListener("loadedmetadata", () => {
      if (!isNaN(video.duration) && isFinite(video.duration)) {
        const durationInSeconds = Math.round(video.duration); // Round to the nearest second
        const minutes = Math.floor(durationInSeconds / 60);
        const seconds = durationInSeconds % 60;
        let formattedDuration = "";
        if (minutes > 0) {
          formattedDuration += `${minutes} min `;
        }
        formattedDuration += `${seconds} sec`;
        setvideoduration(formattedDuration);
      } else {
        console.error("Error: Unable to retrieve video duration.");
      }
      URL.revokeObjectURL(video.src);
      video.remove();
    });
    video.addEventListener("error", () => {
      console.error("Error: Unable to load the video file.");
    });
  };

  const validateForm = () => {
    const errors = {
      VideoTitle: VideoTitle.trim() === "",
      VideoThumbnail: VideoThumbnail === null,
      VideoFile: VideoFile === null,
      // price: isFree ? false : !price,
    };
    setFormErrors(errors);
    return Object.values(errors).every((error) => !error);
  };

  const handeldAddVideo = async () => {
    const isValid = validateForm();
    if (isValid) {
      const f = new FormData();
      f.append("cat_type", category);
      f.append("title", VideoTitle);
      f.append("thumb", VideoThumbnail);
      f.append("file", VideoFile);
      f.append("description", Description);
      f.append("length", duration);
      // f.append("price", price);
      try {
        setloading(true);
        let { apikey, token } = getUserdata;
        const config = {
          headers: {
            apikey,
            token,
          },
        };
        let data = await axios.post(`${Baseurl}/admin/video_add`, f, config);
        if (data.status == 200) {
          setCategory([]);
          setVideoTitle("");
          setVideoThumbnail(null);
          setVideoFile(null);
          setvideoduration(null);
          closeisaddvideo();
          sucesstost("File added sucessfully");
          dispatch(GetallVideos({value:value ,count:count,row_count:row_count}));
        } else {
          setloading(false);
          setCategory([]);
          setVideoTitle("");
          setVideoThumbnail(null);
          setVideoFile(null);
          setvideoduration(null);
          closeisaddvideo();
          errortost("Faild , Please try again !");
        }
      } catch (err) {
        setloading(false);
        setCategory([]);
        setVideoTitle("");
        setVideoThumbnail(null);
        setVideoFile(null);
        setvideoduration(null);
        closeisaddvideo();
        errortost("Faild , Please try again !");
      }
    }
  };

  return (
    <Modal open={isaddvideo} onClose={closeisaddvideo}>
      <Box
        sx={{
          ...modalstyle,
          borderRadius: "10px",
          width: { xs: "50vw", md: "40vw" },
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <Typography
          sx={{
            fontWeight: "600",
            color: "#292D32",
            fontSize: "18px",
            fontFamily: "Poppins",
            textAlign: "center",
            fontSize: "20px",
          }}
        >
          Add New Video
        </Typography>

        <Box sx={{ height: "81vh", overflow: "auto", boxSizing: "border-box" }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "5px",
            }}
          >
            <InputLabel
              id="demo-select-small-label"
              sx={{
                fontSize: "15px",
                color: formErrors.category ? "red" : "#000",
              }}
            >
              Select Category
            </InputLabel>
            {/* <Select
            sx={{
              width: "100%",
              height: "50px", // Adjust the height as needed
              borderRadius: "10px",
              border: "1px solid #F68582",
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
            }}
            labelId="demo-customized-select-label"
            id="demo-customized-select"
            value={category}
            onChange={handleChange}
            name="category"
          >
            <MenuItem value="">Select Category</MenuItem>
            <MenuItem value={1}>Mind Consciousness </MenuItem>
            <MenuItem value={2}>Collective Consciousness</MenuItem>
            <MenuItem value={3}>Body Consciousness</MenuItem>
            <MenuItem value={4}>Spirit Consciousness </MenuItem>
            <MenuItem value={5}>Soul Consciousness </MenuItem>
            <MenuItem value={6}>Self Love</MenuItem>
          </Select> */}
            {/* Display error message if category is not selected */}

            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              sx={{
                width: "100%",
                height: "50px", // Adjust the height as needed
                borderRadius: "10px",
                border: "1px solid #F68582",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
              }}
              value={category}
              onChange={handleselectChange}
              input={<OutlinedInput label="Tag" />}
              renderValue={(selected) =>
                selected
                  .map(
                    (value) =>
                      Categorys.find((category) => category.value === value)
                        ?.lable
                  )
                  .join(", ")
              }
              MenuProps={MenuProps}
            >
              {Categorys.map((name) => (
                <MenuItem key={name.value} value={name?.value}>
                  <Checkbox checked={category.indexOf(name?.value) > -1} />
                  <ListItemText primary={name?.lable} />
                </MenuItem>
              ))}
            </Select>

            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <InputLabel
                id="demo-select-small-label"
                sx={{
                  fontSize: "15px",
                  color: formErrors.VideoTitle ? "red" : "#000",
                }}
              >
                Enter Video Title
              </InputLabel>
              <OutlinedInput
                sx={{
                  width: "100%",
                  height: "50px",
                  borderRadius: "10px",
                  border: "1px solid #F68582",
                  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
                }}
                id="outlined-adornment-weight"
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
                value={VideoTitle?.trimStart()}
                onChange={handleChange}
                name="VideoTitle"
              />
            </Box>
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              mt:"10px"
            }}
          >
            {/* ... existing code */}
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <InputLabel
                id="demo-select-small-label"
                sx={{
                  fontSize: "15px",
                  color: formErrors.VideoThumbnail ? "red" : "#000",
                }}
              >
                Video thumbnail
              </InputLabel>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  border: "1px solid #F68582",
                  padding: "10px",
                  borderRadius: "10px",
                }}
              >
                <Typography noWrap style={{ color: "#000", width: "50%" }}>
                  {VideoThumbnail
                    ? ` ${VideoThumbnail.name}`
                    : "No file chosen"}
                </Typography>
                <Input
                  type="file"
                  id="audio-cover-file-input"
                  style={{ display: "none" }}
                  onChange={(event) =>
                    handleFileChange(event, "VideoThumbnail")
                  }
                />
                <label htmlFor="audio-cover-file-input">
                  <Button
                    variant="contained"
                    component="span"
                    sx={{
                      background: "rgba(246, 133, 130, 0.50)",
                      textDecoration: "capitalize",
                      color: "#000",
                      height: "30px",
                      borderRadius: "10px",
                      transition: "background 0.3s ease", // Add a smooth transition for the background change
                      "&:hover": {
                        background: "rgba(246, 133, 130, 0.70)", // Slightly darker color on hover
                      },
                    }}
                  >
                    <Typography sx={{ textTransform: "capitalize" }}>
                      Browse
                    </Typography>
                  </Button>
                </label>
              </Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" ,mt:"10px"}}>
              <InputLabel
                id="demo-select-small-label"
                sx={{
                  fontSize: "15px",
                  color: formErrors.VideoFile ? "red" : "#000",
                }}
              >
                Upload File
              </InputLabel>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  border: "1px solid #F68582",
                  padding: "10px",
                  borderRadius: "10px",
                }}
              >
                <Typography noWrap style={{ color: "#000", width: "50%" }}>
                  {VideoFile ? `  ${VideoFile.name}` : "No file chosen"}
                </Typography>
                <Input
                  type="file"
                  id="music-file-input"
                  style={{ display: "none" }}
                  onChange={(event) => handleFileChange(event, "VideoFile")}
                />
                <label htmlFor="music-file-input">
                  <Button
                    variant="contained"
                    component="span"
                    sx={{
                      background: "rgba(246, 133, 130, 0.50)",
                      textDecoration: "capitalize",
                      color: "#000",
                      height: "30px",
                      borderRadius: "10px",
                      transition: "background 0.3s ease", // Add a smooth transition for the background change
                      "&:hover": {
                        background: "rgba(246, 133, 130, 0.70)", // Slightly darker color on hover
                      },
                    }}
                  >
                    <Typography sx={{ textTransform: "capitalize" }}>
                      Browse
                    </Typography>
                  </Button>
                </label>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                boxSizing: "border-box",
                mt:"10px"
              }}
            >
              <InputLabel
                id="demo-select-small-label"
                sx={{
                  fontSize: "15px",
                  color: formErrors.VideoTitle ? "red" : "#000",
                }}
              >
                Description
              </InputLabel>
              <TextField
                onChange={handleChange}
                name="Description"
                id="filled-multiline-static"
                sx={{
                  width: "99.8%",
                  borderRadius: "10px",
                  border: "1px solid #F68582",
                  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
                }}
                //   label="Multiline"
                multiline
                rows={4}
                value={Description?.trimStart()}
                //   defaultValue="Default Value"
                //   variant="filled"
              />
            </Box>
          </Box>

          {/* <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              boxSizing: "border-box",
              mt:"10px"
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <FormControl sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ color: formErrors.price ? "red" : "#000" }}>
                  Price
                </Typography>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="free"
                  name="radio-buttons-group"
                  value={isFree ? "free" : "paid"}
                  onChange={handleChange}
                  sx={{ display: "flex", flexDirection: "row" }}
                >
                  <FormControlLabel
                    sx={{ color: "#000" }}
                    value="free"
                    control={
                      <Radio
                        color="primary" // Set the desired color, such as "primary", "secondary", "default", or a custom color code
                        sx={{
                          "&.Mui-checked": {
                            color: "#F68582", // Replace with your custom color code
                          },
                        }}
                      />
                    }
                    label="Free"
                  />
                  <FormControlLabel
                    sx={{ color: "#000" }}
                    value="paid"
                    control={
                      <Radio
                        color="primary" // Set the desired color, such as "primary", "secondary", "default", or a custom color code
                        sx={{
                          "&.Mui-checked": {
                            color: "#F68582", // Replace with your custom color code
                          },
                        }}
                      />
                    }
                    label="Paid"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            {!isFree && (
              <OutlinedInput
                sx={{
                  width: "100%",
                  height: "50px",
                  borderRadius: "10px",
                  border: "1px solid #F68582",
                  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
                  mb:"15px",
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                  {
                    display: "none",
                  },
                }}
                id="outlined-adornment-weight"
                aria-describedby="outlined-weight-helper-text"
                value={price}
                type="Number"
                onChange={(e) => setPrice(e.target.value)}
                name="price"
                required={!isFree} // Make the input required only when it's not free
                onKeyDown={(evt) =>
                  ["e", "E", "+", "-",].includes(evt.key) &&
                  evt.preventDefault()
                }
              />
            )}
          </Box> */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              boxSizing: "border-box",
            }}
          >
            <Button
              disabled={loading}
              onClick={handeldAddVideo}
              sx={{
                height: "50px",
                width: "40%",
                textTransform: "capitalize",
                color: "#000",
                boxShadow:
                  "2px 2px 8px 0px rgba(143, 95, 11, 0.50), 0px 3px 11.111px 0px #FBB15A inset, 0px -11px 11.111px 0px #D68835 inset",
                background:
                  " linear-gradient(180deg, #FFCE98 0%, #F4AA50 100%);",
              }}
            >
              {loading ? (
                <CircularProgress sx={{ color: "#fff" }} />
              ) : (
                <Typography>Done</Typography>
              )}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddVideoPopup;
