import dashlogo from "../../Utils/Logos/Sidebar/dashboard.png";
import a_dashlogo from "../../Utils/Logos/Sidebar/a_dash.png";

import userlogo from "../../Utils/Logos/Sidebar/user.png";
import a_userlogo from "../../Utils/Logos/Sidebar/a_user.png";

import content from "../../Utils/Logos/Sidebar/managecontent.png";
import a_content from "../../Utils/Logos/Sidebar/a_content.png";

import ebooks from "../../Utils/Logos/Sidebar/ebooks.png";
import a_ebooks from "../../Utils/Logos/Sidebar/a_ebooks.png";

import resources from "../../Utils/Logos/Sidebar/resources.png";
import a_resources from "../../Utils/Logos/Sidebar/a_resorces.png";

import schedules from "../../Utils/Logos/Sidebar/schedules.png";
import a_schedules from "../../Utils/Logos/Sidebar/a_sedules.png";

import notification from "../../Utils/Logos/Sidebar/notification.png";
import a_notification from "../../Utils/Logos/Sidebar/a_notification.png";

import setting from "../../Utils/Logos/Sidebar/setting.png";
import a_setting from "../../Utils/Logos/Sidebar/a_setting.png";

import coaches from "../../Utils/Logos/Sidebar/coaches.png";
import a_coaches from "../../Utils/Logos/Sidebar/a_coaches.png";

import logout from "../../Utils/Logos/Sidebar/logout.png";
import a_logout from "../../Utils/Logos/Sidebar/a_logout.png";


import a_Avilability from"../../Utils/Logos/Sidebar/a_Avilability.png"
import Avilability from"../../Utils/Logos/Sidebar/Avilability.png"
export let sidebarcontent = [
    {
      logo: dashlogo,
      Activelogo: a_dashlogo,
      Title: "Dashboard",
      location: "/dashboard",
    },
    {
      logo: userlogo,
      Activelogo: a_userlogo,
      Title: "Users",
      location: "/dashboard/users",
    },
    {
      logo: content,
      Activelogo: a_content,
      Title: "Manage content",
      location: "/dashboard/managecontent",
    },
    {
      logo: ebooks,
      Activelogo: a_ebooks,
      Title: "Ebooks ",
      location: "/dashboard/ebooks",
    },
    {
      logo: resources,
      Activelogo: a_resources,
      Title: "Resources",
      location: "/dashboard/resources",
    },
    {
      logo: schedules,
      Activelogo: a_schedules,
      Title: "Booked Sessions",
      location: "/dashboard/schedules",
    },
    {
      logo: Avilability,
      Activelogo: a_Avilability,
      Title: "Availability",
      location: "/dashboard/avilability",
    },
    {
      logo: coaches,
      Activelogo: a_coaches,
      Title: "Coach",
      location: "/dashboard/coaches",
    },
    {
      logo: notification,
      Activelogo: a_notification,
      Title: "Notiifcations ",
      location: "/dashboard/notifications",
    },
    {
      logo: setting,
      Activelogo: a_setting,
      Title: "Settings ",
      location: "/dashboard/setting",
    },
    {
      logo: logout,
      Activelogo: a_logout,
      Title: "Logout ",
      // location: "/",
      key:1
    },
  ];
