import { Button, Chip, TablePagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
} from "@mui/material";
// import Linkicon from "../../../../Utils/Logos/Linkicon.png";
import Linkicon from "../../../../Utils/Logos/Linkicon.png";
import Viewicon from "../../../../Utils/Logos/viewmore.png";
import { useDispatch, useSelector } from "react-redux";
import { GetIndividualSessions } from "../../../../Redux/Slices/Sessions/Session.action";
import { User_Profileimage, groupe_scoverurl } from "../../../../Apis/Api";
import addlinkicon from "../../../../Utils/Logos/AddLink.png";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ViewUserDetailsPopup from "../../../../Components/Popups/ViewUserDetailsPopup";

import { formatTime } from "../../../../Apis/Functions";
import { useNavigate } from "react-router-dom";
const columns = [
  { id: "#", label: "#" },
  { id: "Date", label: "Date " },
  { id: " Profile", label: "Profile" },
  { id: "Title", label: "Title" },
 
  { id: "Time ", label: "Time " },
  { id: "Payment", label: "Payment" },
 
  { id: "Link", label: "Meeting Link" },
  { id: "Action", label: "Action" },
];

const Individual_Schedules = () => {
  let dispatch = useDispatch();
  let Individualsessions = useSelector((store) => store?.Sessions);
 
  let [selecteddata, setselecteddata] = useState([]);
  let [isUserDetailsopen, setisUserDetailsopen] = useState(false);
  let navigate=useNavigate()
  let openuserdetailspopup = (sessiondata) => {
    navigate("./view_individual_details",{ state: sessiondata?.id })
  };
  let closeuserdetails = () => {
    setisUserDetailsopen(false);
  };
  // ----------- pagination ------------
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(
      GetIndividualSessions({
        count: newPage * rowsPerPage,
        row_count: rowsPerPage,
      })
    );
  }

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  }
  useEffect(() => {
    dispatch(
      GetIndividualSessions({
        count: page * rowsPerPage,
        row_count: rowsPerPage,
      })
    );
  }, [rowsPerPage])
 
  let OpenThislinktourl = (row) => {
    if (row?.link) {
      const formattedLink = row.link.startsWith("http")
        ? row.link
        : `http://${row.link}`;
      window.open(formattedLink, "_blank", "noopener,noreferrer");
    }
  };
  return (
    <>
      {isUserDetailsopen ? (
        <ViewUserDetailsPopup
          modalcontrols={{
            isUserDetailsopen,
            closeuserdetails,
            selecteddata,
            count: page * rowsPerPage,
            rowsPerPage,
          }}
        />
      ) : (
        ""
      )}
      <Box sx={{ mt: "20px" }}>
        <Paper style={{ width: "100%", overflow: "hidden" }}>
          <TableContainer style={{ maxHeight: "75vh" }}>
            <Table stickyHeader sx={{}}>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      sx={{
                        textAlign: "center",
                        fontSize: "15px",
                        lineHeight: "120%",
                        fontFamily: "Montserrat",
                        fontWeight: "600",
                        background: "#F68582",
                        color: "#fff",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody sx={{ textAlign: "center" }}>
                {Individualsessions?.IndividualSessions?.r?.length < 1 ||
                Individualsessions?.IndividualSessions?.r == null ? (
                  <Box sx={{ textAlign: "center", padding: "10px", m: "10px" }}>
                    No Data
                  </Box>
                ) : (
                  Individualsessions?.IndividualSessions?.r?.map((row, i) => (
                    <TableRow key={row.id} sx={{ textAlign: "center" }}>
                      <TableCell sx={{ textAlign: "center" }}>
                        {i + 1}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {row?.date
                          ? (() => {
                              const inputDate = new Date(row.date);
                              return `${inputDate
                                .getDate()
                                .toString()
                                .padStart(2, "0")}/${(inputDate.getMonth() + 1)
                                .toString()
                                .padStart(2, "0")}/${inputDate.getFullYear()}`;
                            })()
                          : "N/A"}
                      </TableCell>

                      <TableCell sx={{ textAlign: "center" }}>
                        {" "}
                        {row?.profile_img == null ? (
                          <AccountCircleIcon
                            sx={{ height: "40px", width: "50px" }}
                          />
                        ) : (
                          <img
                            src={`${groupe_scoverurl}/${row?.cover_img}`}
                            style={{
                              height: "40px",
                              width: "50px",
                              borderRadius: "10px",
                            }}
                            alt=""
                          />
                        )}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {row?.title ? row?.title : " - "}
                      </TableCell>
                     
                      <TableCell sx={{ textAlign: "center" }}>
                        {formatTime(row?.start_time)} -{" "}
                        {formatTime(row?.end_time)}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        ${row?.price.toFixed(2)}
                      </TableCell>
                    

                      <TableCell
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Box
                          sx={{
                            height: "50px",
                            display: "flex",
                            mt: "5px",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                         {row?.link ? (
 
    <img

onClick={() => OpenThislinktourl(row)}
      style={{
        width: "50px",
        height: "50px",
        cursor: "pointer",
      }}
      src={Linkicon}
      alt="editlogo"
    />
 
) : (
  <img
    onClick={() => openuserdetailspopup(row)}
    style={{
      width: "30px",
      height: "30px",
      cursor: "pointer",
    }}
    src={addlinkicon}
    alt="editlogo"
  />
)}

                        </Box>
                      </TableCell>

                      <TableCell sx={{ textAlign: "center" }}>
                        <Box
                          sx={{
                            height: "50px",
                            display: "flex",
                            gap: "20px",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            onClick={() => openuserdetailspopup(row)}
                            style={{
                              width: "30px",
                              height: "30px",
                              cursor: "pointer",
                            }}
                            src={Viewicon}
                            alt="editlogo"
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <TablePagination
            // sx={{ mb: "-20px" }}
            
            // component="div"
            count={Individualsessions?.IndividualSessions?.count || 0}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Box>
    </>
  );
};

export default Individual_Schedules;
