import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Select,
  Button,
  FormControl,
  MenuItem,
  TablePagination,
  Chip,
  Modal,
  // TablePagination,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Swal from "sweetalert2";
import { modalstyle } from "../../../../Components/Popups/PopupsStyling";
import PopupTitle from "../../../../Components/Popups/PopupComponent/PopupTitle";
import { useDispatch, useSelector } from "react-redux";
import { User_Profileimage, groupe_scoverurl } from "../../../../Apis/Api";
import {
  DeleteGroupeSession,
  GetusersJoinedInGroupe,
} from "../../../../Redux/Slices/Sessions/Session.action";
import moment from "moment";
import { formatTime } from "../../../../Apis/Functions";
const Group_UserList = ({ modalcontrols }) => {
  let { isuserlistopen, closeuserlistmodal, groupeid } = modalcontrols;
  const columns = [
    { id: "#", label: "#" },
    { id: "Date", label: "Date" },
    { id: "User Profile", label: "User Profile" },
    { id: "User Name", label: "User Name" },
    { id: "Time", label: "Time" },
    { id: "Price", label: "Price" },
    { id: "Payment Status", label: "Payment Status" },
    // { id: "View", label: "View" },
  ];
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetusersJoinedInGroupe({ groupeid: groupeid, count: 0 }));
  }, []);
  let sessionsdata = useSelector((store) => store?.Sessions);
  let popuptitle = "Group Users List ";

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(
      GetusersJoinedInGroupe({
        groupeid: groupeid,
        count: newPage * rowsPerPage,
      })
    );
  };
  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    dispatch(GetusersJoinedInGroupe({ groupeid: groupeid, count: 0 }));
  };
  return (
    <div>
      <Modal open={isuserlistopen} onClose={closeuserlistmodal}>
        <Box
          sx={{
            ...modalstyle,
            borderRadius: "10px",
            width: { xs: "90vw", md: "80vw" },
            height: "70vh",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <PopupTitle popuptitle={popuptitle} />
          <Box sx={{ mt: "20px" }}>
            <Paper style={{ width: "100%", overflow: "hidden" }}>
              <TableContainer style={{ maxHeight: "50vh", overflow: "auto" }}>
                <Table stickyHeader sx={{}}>
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          sx={{
                            textAlign: "center",
                            fontSize: "15px",
                            lineHeight: "120%",
                            fontFamily: "Montserrat",
                            fontWeight: "600",
                            background: "#F68582",
                            color: "#fff",
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ textAlign: "center" }}>
                    {sessionsdata?.UsersjoindinGroupe?.length < 1 ||
                    sessionsdata?.UsersjoindinGroupe == null ? (
                      <Box
                        sx={{ textAlign: "center", padding: "10px", m: "10px" }}
                      >
                        No Users !
                      </Box>
                    ) : (
                      sessionsdata?.UsersjoindinGroupe?.map((row, i) => (
                        <TableRow key={row.id} sx={{ textAlign: "center" }}>
                          <TableCell sx={{ textAlign: "center" }}>
                            {i + 1}
                          </TableCell>

                          <TableCell sx={{ textAlign: "center" }}>
                            {" "}
                            {row?.date}
                          </TableCell>

                          <TableCell sx={{ textAlign: "center" }}>
                            {row?.profile_img == null ? (
                              <AccountCircleIcon
                                sx={{ height: "40px", width: "50px" }}
                              />
                            ) : (
                              <img
                                src={`${User_Profileimage}/${row?.profile_img}`}
                                style={{
                                  height: "40px",
                                  width: "50px",
                                  borderRadius: "10px",
                                }}
                                alt=""
                              />
                            )}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {" "}
                            {row?.user_name}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {" "}
                            {formatTime(row?.start_time)} to
                            {formatTime(row?.end_time)}
                          </TableCell>

                          <TableCell sx={{ textAlign: "center" }}>
                            {" "}
                            ${row?.price}
                          </TableCell>

                          <TableCell sx={{ textAlign: "center" }}>
                            {" "}
                            <Chip
                              sx={{
                                background:
                                  row?.payment_status === 1
                                    ? "green"
                                    : row?.payment_status === 0
                                    ? "red"
                                    : row?.payment_status === -1
                                    ? "gray"
                                    : "gray",
                                color: "#fff",
                              }}
                              label={
                                row?.payment_status === 1
                                  ? "Completed"
                                  : row?.payment_status === 0
                                  ? "Failed"
                                  : row?.payment_status === -1
                                  ? "Refund"
                                  : "Refund"
                              }
                            />
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            {/* 
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <TablePagination
            sx={{ mb: "-20px" }}
            // component="div"
            count={ sessionsdata?.UsersjoindinGroupe?.count || 2}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box> */}
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default Group_UserList;
