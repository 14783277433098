import { Box, Typography } from "@mui/material";
import React from "react";

const TitleText = ({ title ,style}) => {
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "start" ,mt:style||"20px"}}>
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontWeight: "600",
            lineHeight: "120%",
            fontSize: "20px",
          }}
        >
          
          {title}
        </Typography>
      </Box>
    </>
  );
};

export default TitleText;
