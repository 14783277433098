import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { GetDashGraphData } from "../../../../Redux/Slices/Dashboard/Dashboard.action";

const EarningsChart = () => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
 
  let dispatch = useDispatch();
 

  useEffect(() => {
    dispatch(GetDashGraphData());
  }, []);

  let Graphdata = useSelector((store) => store?.Dashboard?.GraphData);
  // console.log(Graphdata[0]?.months)
useEffect(() => {
    const ctx = chartRef.current.getContext("2d");
    // Check if there is an existing chart instance, and destroy it
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }
    const data = {
      datasets: 
      [
        {
          label: "Total number of Earning",
          data: [0, ...Graphdata[0]?.months?.map((month) => month?.earning) || []],
          borderColor: "#F68582",
          fill: false,
        },
        {
          label: "Previous Year",
          data:[0, ...Graphdata[1]?.months?.map((month) => month?.earning) || []],
          borderColor: "#F4AA50",
          fill: false,
          pointStyle: "circle", // Set point style to circle
          pointBackgroundColor: "#F4AA50", // Set point background color
        },
      ],
    };

    const options = {
      hoverRadius: 12,
      hoverBackgroundColor: "yellow",
      interaction: {
        mode: "nearest",
        intersect: true,
        axis: "x",
      },
      plugins: {
        tooltip: {
          enabled: true,
        },
      },
      animations: {
        radius: {
          duration: 5,
          easing: "linear",
          loop: (context) => context.active,
        },
      },
      scales: {
        x: {
          type: "category",
          labels: [
            "0",
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "June",
            "July",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          position: "bottom",
          grid: {
            display: false, // Hide the x-axis grid lines
          },
        },
        y: {
          labels: [0, 200, 400, 600, 800, 1000], // Specify the desired tick values
          beginAtZero: true, // Start the Y-axis from zero
          max: 1000, //
          min: 0,
        },
      },
      elements: {
        line: {
          tension: 0.4, // Adjust the tension for a smoother curve
          fill: false,
        },
      },
    };

    // Create a new Chart instance
    chartInstance.current = new Chart(ctx, {
      type: "line",
      data: data,
      options: options,
    });

    // Cleanup: Destroy the chart when the component unmounts
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [Graphdata]); // Empty dependency array to run the effect only once on mount

  return (
    <Box
      sx={{
        minHeight: "70px",
        borderRadius: "30px",
        boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.25)",
        padding: "20px 30px",
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          padding: "20px 20px",
          borderRadius: "30px",
          background: "#F7F9FB",
        }}
      >
        <Typography sx={{fontWeight:"600"}}>Total Earning  </Typography>
        <canvas ref={chartRef} width="100%" height="18px"></canvas>
      </Box>
    </Box>
  );
};

export default EarningsChart;
