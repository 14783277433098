import React, { useEffect } from "react";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { errortost } from "../../Components/Alerts/CustomAlert";
import { GetTermsAndConditionata } from "../../Redux/Slices/SettingData/SettingData.action";
import { useDispatch, useSelector } from "react-redux";
const Dashboard = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const navigate = useNavigate();
  let dispatch = useDispatch();
  useEffect(() => {
    const Apikye = JSON.parse(localStorage.getItem("m_authdata"));
    if (!Apikye?.apikye && !Apikye?.token) {
      errortost("Please Login !");
      navigate("/");
    }
  }, []);
  useEffect(() => {
    dispatch(GetTermsAndConditionata());
  }, []);
  return (
    <>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
      >
        <Box
          sx={{
            display:
              currentPath.startsWith(`/dashboard/user/`) ||
              currentPath.startsWith(`/dashboard/resources/blogs/addblogs`) ||
              currentPath.startsWith(`/dashboard/coaches/view_Coach`) ||
              currentPath.startsWith(`/dashboard/coaches/add_coach`) ||
              currentPath.startsWith("/dashboard/avilability/add_avilability")||
              currentPath.startsWith("/dashboard/avilability/view_avilability_Details")||
              currentPath.startsWith("/dashboard/schedules/view_individual_details")|| 
              currentPath.startsWith("/dashboard/schedules/groupsession/view_group_details")
                ? "none"
                : "block",
            width: "350px",
          }}
        >
          <Sidebar />
        </Box>
        <Box
          sx={{ width: "100%", padding: " 5px 30px", boxSizing: "border-box" }}
        >
          <Outlet />
        </Box>
      </Box>
    </>
  );
};

export default Dashboard;
