import React, { useState } from "react";
import TitleText from "../../../../Components/TitleText";
import { Box, Button } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";

const Avilability = () => {
  return (
    <div>
      <Box>
        <Outlet />
      </Box>
    </div>
  );
};

export default Avilability;
