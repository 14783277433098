import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";
import { modalstyle } from "./PopupsStyling";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import {
  AddNewAudio,
  GetAllCategory,
  GetallAudios,
} from "../../Redux/Slices/Resources/Resources.action";
import { ToastContainer, toast } from "react-toastify";
import { errortost, sucesstost } from "../Alerts/CustomAlert";
import { Categorys } from "./CommonData";
const AddAudioPopup = ({ modalcontrols }) => {

  const Categorydata = useSelector((store) => store?.Resources);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  let { isaddaudioopen, closeisaudio, value, count, row_count } = modalcontrols;
  const [category, setCategory] = useState([]);
  const [audioTitle, setAudioTitle] = useState("");
  const [audioCoverFile, setAudioCoverFile] = useState(null);
  const [musicFile, setMusicFile] = useState(null);
  const [duration, setaudioduration] = useState(null);
  // const [isFree, setIsFree] = useState(true);
  // const [price, setPrice] = useState(0); // Initialize price to 0
  let dispatch = useDispatch();
  let [loading, setloading] = useState(false);
  const [formErrors, setFormErrors] = useState({
    category: false,
    audioTitle: false,
    audioCoverFile: false,
    musicFile: false,
  });

  
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormErrors({ ...formErrors, [name]: false });
    if (name === "category") {
      setCategory(Array.isArray(value) ? value : [value]); // Always set category as an array
    } else if (name === "audioTitle") {
      setAudioTitle(value);
    }
    //  else if (name === "radio-buttons-group") {
    //   setIsFree(value === "free");
    // }
  };

  const handleselectChange = (event) => {
    const {
      target: { value },
    } = event;
    setCategory(typeof value === "string" ? value.split(",") : value);
  };
  const handleFileChange = (event, fileType) => {
    const file = event.target.files[0];
    if (!file) {
      console.log(`Please select a file for ${fileType}`);
      setFormErrors({ ...formErrors, [fileType]: true });
      return;
    }
    if (fileType === "audioCoverFile" && !file.type.startsWith("image/")) {
      setFormErrors({ ...formErrors, [fileType]: true });
      return;
    }
    if (fileType === "musicFile" && !file.type.startsWith("audio/")) {
      console.log(`Please select an audio file for ${fileType}`);
      setFormErrors({ ...formErrors, [fileType]: true });
      return;
    }
    setFormErrors({ ...formErrors, [fileType]: false });
    if (fileType === "audioCoverFile") {
      setAudioCoverFile(file);
    } else if (fileType === "musicFile") {
      setMusicFile(file);
      if (file.type.startsWith("audio/")) {
        extractAudioDuration(file);
      }
    }
  };
  const extractAudioDuration = (audioFile) => {
    const audio = new Audio();
    audio.src = URL.createObjectURL(audioFile);
    audio.addEventListener("loadedmetadata", () => {
      if (!isNaN(audio.duration) && isFinite(audio.duration)) {
        const durationInSeconds = Math.round(audio.duration); // Round to the nearest second
        const minutes = Math.floor(durationInSeconds / 60);
        const seconds = durationInSeconds % 60;
        let formattedDuration = "";
        if (minutes > 0) {
          formattedDuration += `${minutes} min `;
        }
        formattedDuration += `${seconds} sec`;
        setaudioduration(formattedDuration);
      } else {
        console.error("Error: Unable to retrieve audio duration.");
      }
      URL.revokeObjectURL(audio.src);
    });
    audio.addEventListener("error", () => {
      console.error("Error: Unable to load the audio file.");
    });
  };
  const validateForm = () => {
    const errors = {
      audioTitle: audioTitle.trim() === "",
      audioCoverFile: audioCoverFile === null,
      musicFile: musicFile === null,
      // price: isFree ? false : !price,
    };
    setFormErrors(errors);
    return Object.values(errors).every((error) => !error);
  };
  const handleAddAudio = async () => {
    const isValid = validateForm();
    if (isValid) {
      const f = new FormData();
      f.append("cat_type", category);
      f.append("title", audioTitle);
      f.append("cover_img", audioCoverFile);
      f.append("file", musicFile);
      f.append("length", duration);
      // f.append("price", price);
      setloading(true);
      dispatch(AddNewAudio(f))
        .unwrap()
        .then((result) => {
          if (result.s === 1) {
            setloading(false);
            setCategory([]);
            setAudioTitle("");
            setAudioCoverFile(null);
            setMusicFile(null);
            setaudioduration(null);
            sucesstost("File added sucessfully");
            closeisaudio();
            dispatch(
              GetallAudios({ value: 0, count: count, row_count: row_count })
            );
          } else {
            setloading(false);
            setloading(false);
            setCategory([]);
            setAudioTitle("");
            setAudioCoverFile(null);
            setMusicFile(null);
            errortost("Something wents wrong");
            closeisaudio();
          }
        });
    }
  };

  useEffect(()=>{
    dispatch(GetAllCategory())
  },[])
  return (
    <>
      <ToastContainer />
      <Modal open={isaddaudioopen} onClose={closeisaudio}>
        <Box
          sx={{
            ...modalstyle,
            borderRadius: "10px",
            width: { xs: "50vw", md: "40vw" },
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Typography
            sx={{
              fontWeight: "600",
              color: "#292D32",
              fontSize: "18px",
              fontFamily: "Poppins",
              textAlign: "center",
              fontSize: "20px",
            }}
          >
            Add New Audio
          </Typography>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "5px",
            }}
          >
            <InputLabel
              id="demo-select-small-label"
              sx={{
                fontSize: "15px",
                color: formErrors.category ? "red" : "#000",
              }}
            >
              Select Category
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              sx={{
                width: "100%",
                height: "50px", // Adjust the height as needed
                borderRadius: "10px",
                border: "1px solid #F68582",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
              }}
              value={category}
              onChange={handleselectChange}
              input={<OutlinedInput label="Tag" />}
              renderValue={(selected) =>
                selected
                  .map(
                    (value) =>
                      Categorys?.find((category) => category?.value === value)
                        ?.lable
                  )
                  .join(", ")
              }
              MenuProps={MenuProps}
            >
              {Categorys?.map((name) => (
                <MenuItem key={name?.value} value={name?.value}>
                  <Checkbox checked={category?.indexOf(name?.value) > -1} />
                  <ListItemText primary={name?.lable} />
                </MenuItem>
              ))}
            </Select>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <InputLabel
                id="demo-select-small-label"
                sx={{
                  fontSize: "15px",
                  color: formErrors.audioTitle ? "red" : "#000",
                }}
              >
                Enter Audio Title
              </InputLabel>
              <OutlinedInput
                sx={{
                  width: "100%",
                  height: "50px",
                  borderRadius: "10px",
                  border: "1px solid #F68582",
                  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
                }}
                id="outlined-adornment-weight"
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
                value={audioTitle?.trimStart()}
                onChange={handleChange}
                name="audioTitle"
              />
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "5px",
            }}
          >
            {/* ... existing code */}
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <InputLabel
                id="demo-select-small-label"
                sx={{
                  fontSize: "14px",
                  color: formErrors.audioCoverFile ? "red" : "#000",
                }}
              >
                Upload Audio Cover Image
              </InputLabel>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  border: "1px solid #F68582",
                  padding: "10px",
                  borderRadius: "10px",
                }}
              >
                <Typography noWrap style={{ color: "#000", width: "50%" }}>
                  {audioCoverFile
                    ? ` ${audioCoverFile.name}`
                    : "Choose cover image"}
                </Typography>
                <Input
                  type="file"
                  id="audio-cover-file-input"
                  style={{ display: "none" }}
                  onChange={(event) =>
                    handleFileChange(event, "audioCoverFile")
                  }
                />
                <label htmlFor="audio-cover-file-input">
                  <Button
                    variant="contained"
                    component="span"
                    sx={{
                      background: "rgba(246, 133, 130, 0.50)",
                      textDecoration: "capitalize",
                      color: "#000",
                      height: "30px",
                      borderRadius: "10px",
                      transition: "background 0.3s ease", // Add a smooth transition for the background change
                      "&:hover": {
                        background: "rgba(246, 133, 130, 0.70)", // Slightly darker color on hover
                      },
                    }}
                  >
                    <Typography sx={{ textTransform: "capitalize" }}>
                      Browse
                    </Typography>
                  </Button>
                </label>
              </Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px",mt:"10px" }}>
              <InputLabel
                id="demo-select-small-label"
                sx={{
                  fontSize: "14px",
                  color: formErrors.musicFile ? "red" : "#000",
                }}
              >
                Upload Music File
              </InputLabel>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  border: "1px solid #F68582",
                  padding: "10px",
                  borderRadius: "10px",
                }}
              >
                <Typography noWrap style={{ color: "#000", width: "50%" }}>
                  {musicFile ? `  ${musicFile.name}` : "Choose music file"}
                </Typography>
                <Input
                  type="file"
                  id="music-file-input"
                  style={{ display: "none" }}
                  onChange={(event) => handleFileChange(event, "musicFile")}
                />
                <label htmlFor="music-file-input">
                  <Button
                    variant="contained"
                    component="span"
                    sx={{
                      background: "rgba(246, 133, 130, 0.50)",
                      textDecoration: "capitalize",
                      color: "#000",
                      height: "30px",
                      borderRadius: "10px",
                      transition: "background 0.3s ease", // Add a smooth transition for the background change
                      "&:hover": {
                        background: "rgba(246, 133, 130, 0.70)", // Slightly darker color on hover
                      },
                    }}
                  >
                    <Typography sx={{ textTransform: "capitalize" }}>
                      Browse
                    </Typography>
                  </Button>
                </label>
              </Box>
            </Box>
          </Box>

          {/* <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <FormControl sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ color: formErrors.price ? "red" : "#000" }}>
                  Price
                </Typography>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="free"
                  name="radio-buttons-group"
                  value={isFree ? "free" : "paid"}
                  onChange={handleChange}
                  sx={{ display: "flex", flexDirection: "row" }}
                >
                  <FormControlLabel
                    sx={{ color: "#000" }}
                    value="free"
                    control={
                      <Radio
                        color="primary" // Set the desired color, such as "primary", "secondary", "default", or a custom color code
                        sx={{
                          "&.Mui-checked": {
                            color: "#F68582", // Replace with your custom color code
                          },
                        }}
                      />
                    }
                    label="Free"
                  />
                  <FormControlLabel
                    sx={{ color: "#000" }}
                    value="paid"
                    control={
                      <Radio
                        color="primary" // Set the desired color, such as "primary", "secondary", "default", or a custom color code
                        sx={{
                          "&.Mui-checked": {
                            color: "#F68582", // Replace with your custom color code
                          },
                        }}
                      />
                    }
                    label="Paid"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            {!isFree && (
              <OutlinedInput
                sx={{
                  width: "100%",
                  height: "50px",
                  borderRadius: "10px",
                  border: "1px solid #F68582",
                  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                }}
                id="outlined-adornment-weight"
                aria-describedby="outlined-weight-helper-text"
                type="Number"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                name="price"
                required={!isFree} // Make the input required only when it's not free
                onKeyDown={(evt) =>
                  ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
                }
              />
            )}
          </Box> */}

          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              disabled={loading}
              onClick={handleAddAudio}
              sx={{
                height: "50px",
                width: "40%",
                textTransform: "capitalize",
                color: "#000",
                boxShadow:
                  "2px 2px 8px 0px rgba(143, 95, 11, 0.50), 0px 3px 11.111px 0px #FBB15A inset, 0px -11px 11.111px 0px #D68835 inset",
                background:
                  " linear-gradient(180deg, #FFCE98 0%, #F4AA50 100%);",
              }}
            >
              {loading ? (
                <CircularProgress sx={{ color: "#fff" }} />
              ) : (
                <Typography>Done</Typography>
              )}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AddAudioPopup;
