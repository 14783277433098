import React, { useEffect, useState } from "react";
import { Box, Button, Chip, Tooltip, Typography } from "@mui/material";
import { iconbaseurl, imagebaseurl } from "../../Apis/Api";
import deleteuser from "../../Utils/Logos/deleteuser.png";
import blockuser from "../../Utils/Logos/blockuser.png";
import BlockUser from "../Popups/BlockUser";
import Deleteuser from "../Popups/Deleteuser";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Unblockuser from "../../Utils/Logos/unblock.png";
import { useDispatch, useSelector } from "react-redux";
import { getuserbyid } from "../../Redux/Slices/Users/User.action";
import { useParams } from "react-router-dom";

const UserDetailsBox = ({ data }) => {
  let boxstyle = {
    borderBottom: "1px solid  #F68582",
    dispatch: "flex",
    flexDirection: "column",
    padding: "15px",
    gap: "30px",
  };

  let titlefont = {
    fontStyle: "Poppins",
    fontSize: "16px",
    fontWeight: "550",
    lineHeight: "100%",
    mb: "5px",
  };
  let contnetfont = {
    fontStyle: "Poppins",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "100%",
  };

  let [isblocku, setisblocku] = useState(false);
  const { userId } = useParams();

  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(getuserbyid(userId));
  }, []);
  let openblocku = () => {
    setisblocku(true);
  };

  let closeblocku = () => {
    setisblocku(false);
  };

  let [isdeleteu, setisdeleteu] = useState(false);
  let opendeleteu = () => {
    setisdeleteu(true);
  };

  let closedeleteu = () => {
    setisdeleteu(false);
  };

  return (
    <>
      {isblocku ? (
        <BlockUser modalcontrols={{ isblocku, closeblocku, data }} />
      ) : (
        ""
      )}
      {isdeleteu ? (
        <Deleteuser modalcontrols={{ isdeleteu, closedeleteu, data }} />
      ) : (
        ""
      )}
      <Box
        sx={{
          width: "100%",
          height: "250px",
          borderTopRightRadius: "10px",
          borderTopLeftRadius: "10px",
          border: "1px solid #F68582",
          boxSizing: "border-box",
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            background: data?.userbyid?.status == 1 ? "green" : "red",
            fontSize: "16px",
            fontWeight: "600",
            fontFamily: "Poppins",
            color: "#fff",
            display: "inline-block",
            padding: "5px 9px",
            borderRadius: "5px",
          }}
        >
          {data?.userbyid?.status == 1 ? (
            <Typography>Active</Typography>
          ) : (
            <Typography>Inactive</Typography>
          )}
        </Box>

        {data?.userbyid?.profile_img == null ? (
          <AccountCircleIcon sx={{ height: "100%", width: "100%" }} />
        ) : (
          <img
            src={`${imagebaseurl}/${data?.userbyid?.profile_img}`}
            style={{ width: "100%", height: "100%" }}
            alt=""
          />
        )}
      </Box>
      <Box sx={{ ...boxstyle }}>
        <Typography sx={{ ...titlefont }}>User Name</Typography>
        <Typography sx={{ ...contnetfont }}>
          {" "}
          {data?.userbyid?.user_name == null ? (
            <Typography>-</Typography>
          ) : (
            data?.userbyid?.user_name
          )}
        </Typography>
      </Box>

      <Box sx={{ ...boxstyle }}>
        <Typography sx={{ ...titlefont }}>Full Name</Typography>
        <Typography sx={{ ...contnetfont }}>
          {" "}
          {data?.userbyid?.full_name}
        </Typography>
      </Box>
      <Box sx={{ ...boxstyle }}>
        <Typography sx={{ ...titlefont }}>Email Address</Typography>
        <Typography sx={{ ...contnetfont }}>
          {" "}
          {data?.userbyid?.email}
        </Typography>
      </Box>
      <Box sx={{ ...boxstyle }}>
        <Typography sx={{ ...titlefont }}>Phone number</Typography>
        <Typography sx={{ ...contnetfont }}>
          {" "}
          {data?.userbyid?.phone_no}
        </Typography>
      </Box>
      <Box sx={{ ...boxstyle }}>
        <Typography sx={{ ...titlefont }}>Schedule Time</Typography>
        <Typography sx={{ ...contnetfont }}>
          {" "}
          Bedtime : {data?.userbyid?.bedtime}
        </Typography>
        <Typography sx={{ ...contnetfont }}>
          {" "}
          Wakeup : {data?.userbyid?.wake_up}
        </Typography>
      </Box>
     
      <Box sx={{ ...boxstyle }}>
        <Typography sx={{ ...titlefont, mb: "8px" }}>Action </Typography>
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Tooltip
            title={
              data.userbyid.status == -1 ? "UnBlock User ?" : "Block User ?"
            }
            placement="bottom"
          >
            <img
              onClick={openblocku}
              src={data.userbyid.status == -1 ? Unblockuser : blockuser}
              style={{ width: "30px", height: "30px", cursor: "pointer" }}
              alt="blockuser"
            />
          </Tooltip>
          <Tooltip title="Delete User ?" placement="bottom">
            <img
              onClick={opendeleteu}
              src={deleteuser}
              style={{ width: "30px", height: "30px", cursor: "pointer" }}
              alt="deleteuser"
            />
          </Tooltip>
        </Box>
      </Box>
    </>
  );
};

export default UserDetailsBox;
