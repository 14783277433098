import React, { useState } from "react";
import {
  Box,
  Button,
  Modal,
  Typography,
} from "@mui/material";
import { modalstyle } from "./PopupsStyling";
import logoutpng from "../../Utils/Logos/logoutpng.png";
import { useNavigate } from "react-router-dom";

const LogoutPopup = ({ modalcontrols }) => {
  let { islogoutopen, closelogout } = modalcontrols;
  let navigate = useNavigate();
  let Loggingout = () => {
    localStorage.removeItem("m_authdata");
    navigate("/");
  };
  return (
    <>
    
      <Modal open={islogoutopen} onClose={closelogout}>
        <Box
          sx={{
            ...modalstyle,
            borderRadius: "10px",
            width: { xs: "50vw", md: "350px" },
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Box
            sx={{
              width: "20%",
              m: "auto",
              borderRadius: "50%",
              padding: "25px 10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "rgba(246, 133, 130, 0.50)",
            }}
          >
            <img
              style={{ width: "41px", height: "41px" }}
              src={logoutpng}
              alt=""
            />
          </Box>
          <Typography
            sx={{
              textAlign: "center",
              fontFamily: "Roboto",
              fontWeight: "500",
              fontSize: "24px",
              color: "#000",
            }}
          >
            Are You Sure?
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              fontFamily: "Roboto",
              fontWeight: "500",
              fontSize: "16px",
              color: "#000",
              fontWeight: "400",
            }}
          >
            {" "}
            Do you really want to Logout this account ?
          </Typography>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Button
              onClick={Loggingout}
              sx={{
                textTransform: "capitalize",
                mt: "10px",
                width: "100%",
                borderRadius: "14px",
                background: "#FF1607",
                color: "#fff",
                padding: "8px",
                "&:hover": {
                  background: "#A30000",
                },
              }}
            >
              Logout
            </Button>
            <Button
              onClick={() => closelogout()}
              sx={{
                textTransform: "capitalize",
                mt: "10px",
                borderRadius: "14px",
                background: "#E8E8E8",
                width: "100%",
                color: "#000",
                padding: "8px",
                "&:hover": {
                  background: "#B0B0B0",
                },
              }}
            >
              No
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default LogoutPopup;
