import {
  Box,
  Button,
  Input,
  Modal,
  Typography,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { modalstyle } from "./PopupsStyling";
import { Coach_imageUrl, User_Profileimage, groupe_scoverurl } from "../../Apis/Api";
import ActionButton from "./PopupComponent/ActionButton";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EmailInput from "../Inputs/EmailInput";
import AddLinkIcon from "@mui/icons-material/AddLink";
import { CommonButtonstyle } from "../../Components/CommonCss";
import CommonInput from "../Inputs/CommonInput";
import { useDispatch } from "react-redux";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import Linkicon from "../../Utils/Logos/Linkicon.png";

import {
  AddUpdateGroupeSessionLink,
  AddUpdateIndiviSessionLink,
  GetCoachesDetailslist,
  GetGroupeSessionlist,
  GetIndividualSessions,
  AddnewCoachesDetails,
} from "../../Redux/Slices/Sessions/Session.action";
import { errortost, sucesstost } from "../Alerts/CustomAlert";
import TostContainer from "../Alerts/TostContainer";
import { formatTime } from "../../Apis/Functions";
import { GetRecentIndividualSessions } from "../../Redux/Slices/Dashboard/Dashboard.action";

const ViewGroupedetails = ({ modalcontrols }) => {
  let {
    isgroupedetailopen,
    closegroupdetailspopup,
    selecteddata,
    count,
    rowsPerPage,
  } = modalcontrols;
  let fields = [
    {
      name: "Name",
      value: selecteddata?.group_name ? selecteddata?.group_name : " - - - ",
    },
    { name: "Date ", value: selecteddata?.date },
    { name: "Time ", value: formatTime(selecteddata?.start_time) },
    { name: "Topic ", value: selecteddata?.group_name },
    { name: "Payment ", value: `$${selecteddata?.price}` },
    {
      name: "Payment Staus",
      value:
        selecteddata?.payment_status === 0
          ? "Failed"
          : selecteddata?.status === 1
          ? "Completed"
          : selecteddata?.status === -1
          ? "Pending"
          : "",
    },
  ];

  let [link, setlink] = useState("");
  let dispatch = useDispatch();

  let handlelinkchange = (e) => {
    setlink(e.target.value);
  };

  useEffect(() => {
    setlink(selecteddata?.link || "");
  }, []);
console.log(selecteddata);
  const isLinkEmpty = () => {
    return link.trim() === "";
  };

  let Updatethislink = () => {
    let linkdata = new FormData();
    linkdata.append("link", link);
    linkdata.append("id", selecteddata?.id);
    dispatch(AddUpdateGroupeSessionLink(linkdata))
      .unwrap()
      .then((result) => {
        if (result?.s === 1) {
          sucesstost(
            `Link ${
              selecteddata?.link !== null ? "Added" : "Updated"
            } Sucessfully ! `
          );
          dispatch(
            GetGroupeSessionlist({ count: count, row_count: rowsPerPage })
          );
        } else {
          errortost("Something Wents wrong please try again !");
        }
      });
  };

  // let handlecoachchange = (e) => {
  //   setcoach_name(e.target.value);
  // };

  // let [coach_name, setcoach_name] = useState("");
  // let [coachlist, setcoachlist] = useState([]);
  // let [coachprofile_imagefile, setcoachprofile_imagefile] = useState(null);
  // let [coachprofile_image, setcoachprofile_image] = useState(null);
  // let [getcoachlistloader, setgetcoachlistloader] = useState(false);

  // useEffect(() => {
  //   GetcoachesList();
  // }, []);

  // let GetcoachesList = () => {
  //   dispatch(GetCoachesDetailslist(selecteddata?.id))
  //     .unwrap()
  //     .then((result) => {
  //       if (result?.r) {
  //         setcoachlist(result?.r);
  //         setgetcoachlistloader(false);
  //       } else {
  //         setgetcoachlistloader(false);
  //       }
  //     });
  //   setgetcoachlistloader(false);
  // };
 
  // let handeldCoachImage = (event) => {
  //   const file = event.target.files[0];
  //   setcoachprofile_imagefile(file);
  //   const objectURL = URL.createObjectURL(file);
  //   setcoachprofile_image(objectURL);
  // };
  // let [addcoachloading, setaddcoachloading] = useState(false);
  // let AddnewCoach = () => {
  //   if (coachprofile_imagefile == null || coachprofile_image == null) {
  //     errortost("Please select coach image ..");
  //   } else if (coach_name === "") {
  //     errortost("Please enter coach name ");
  //   } else {
  //     let formdata = new FormData();
  //     // formdata.append(`coach_name[${coachlist?.length}]`, coach_name);
  //     // formdata.append(
  //     //   `profile_image[${coachlist?.length}]`,
  //     //   coachprofile_imagefile
  //     // );
  //     formdata.append(`coach_name[0]`, coach_name);
  //     formdata.append(`profile_img[0]`, coachprofile_imagefile);
  //     formdata.append("type", 7);
  //     formdata.append("ref_id", selecteddata?.id);
  //     try {
  //       setaddcoachloading(true);
  //       dispatch(AddnewCoachesDetails(formdata))
  //         .unwrap()
  //         .then((result) => {
  //           if (result?.s == 1) {
  //             setaddcoachloading(false);
  //             setcoachprofile_imagefile(null);
  //             setcoachprofile_image(null);
  //             setcoach_name("");
  //             closegroupdetailspopup();
  //             sucesstost("Coach Details added sucessfully !");
  //           } else {
  //             setaddcoachloading(false);
  //             setcoachprofile_imagefile(null);
  //             setcoachprofile_image(null);
  //             setcoach_name("");
  //             closegroupdetailspopup();
  //             errortost("Something wents wrong !");
  //           }
  //         });
  //     } catch (err) {
  //       setaddcoachloading(false);
  //       setcoachprofile_imagefile(null);
  //       setcoachprofile_image(null);
  //       setcoach_name("");
  //       closegroupdetailspopup();
  //       setaddcoachloading(false);
  //     }
  //   }
  // };

  return (
    <>
      <TostContainer />
      <Modal open={isgroupedetailopen} onClose={closegroupdetailspopup}>
        <Box
          sx={{
            ...modalstyle,
            borderRadius: "10px",
            width: { xs: "50vw", md: "60vw" },
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            m: "auto",
          }}
        >
          <Box>
            <Box
              sx={{
                width: "20%",
                m:"auto",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                border:"1px solid #F4AA50",
                borderRadius:"10px",
                padding:"10px 0px"
              }}
            >
              {selecteddata?.cover_img ? (
                <img
                  src={`${groupe_scoverurl}/${selecteddata?.cover_img}`}
                  style={{
                    height: "120px",
                    width: "120px",
                    borderRadius: "10px",
                  }}
                  alt=""
                />
              ) : (
                <AccountCircleIcon
                  sx={{ height: "100px", color: "#000", width: "100px" }}
                />
              )}
            </Box>

            {/* <Box
              sx={{
                mt: "10px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              {fields?.map((fields) => (
                <Box
                  sx={{
                    display: "flex",
                    color: "#000",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#000",
                      fontFamily: "Poppins",
                      fontSize: "18px",
                      fontWeight: "600",
                    }}
                  >
                    {fields?.name}
                  </Typography>{" "}
                  <Typography
                    sx={{
                      color:
                        fields?.value === "Failed"
                          ? "red"
                          : fields?.value === "completed"
                          ? "green"
                          : fields?.value === "pending"
                          ? "blue"
                          : "",
                      fontFamily: "Poppins",
                      fontSize: "18px",
                      fontWeight: "400",
                    }}
                  >
                    {fields?.value}
                  </Typography>
                </Box>
              ))}
            </Box> */}
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                height: "40vh",
                boxSizing: "border-box",
              }}
            >
              <Box
                sx={{
                  mt: "10px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  width: "40%",
                  height: "100%",
                }}
              >
                <Typography
                  sx={{
                    color: "#000",
                    textAlign: "start",
                    fontSize: "25px",
                    fontWeight: "600",
                    padding: "0.5vh 0vh",
                  }}
                >
                  Session Details
                </Typography>
                {fields?.map((fields) => (
                  <Box
                    sx={{
                      display: "flex",
                      color: "#000",
                      alignItems: "center",
                      gap: "5px",
                      justifyContent: "start",
                      padding: "0vh 1vw",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#000",
                        fontFamily: "Poppins",
                        fontSize: "18px",
                        fontWeight: "600",
                      }}
                    >
                      {fields?.name} :
                    </Typography>{" "}
                    <Typography
                      sx={{
                        color:
                          fields?.value === "Failed"
                            ? "red"
                            : fields?.value === "completed"
                            ? "green"
                            : fields?.value === "pending"
                            ? "blue"
                            : "",
                        fontFamily: "Poppins",
                        fontSize: "18px",
                        fontWeight: "400",
                      }}
                    >
                      {fields?.value}
                    </Typography>
                  </Box>
                ))}
              </Box>
              {/* <Box
                sx={{
                  width: "60%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{
                    color: "#000",
                    textAlign: "center",
                    fontSize: "25px",
                    padding: "0vh 0vh",
                    fontWeight: "600",
                  }}
                >
                  Coach Details
                </Typography>
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    gap: "10px",
                  }}
                >
              
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      gap: "20px",
                      rowGap: "10px",
                      justifyContent: "center",
                      flexWrap: "wrap",
                      alignItems: "center",
                      overflow: "auto",
                      padding: "10px",
                      borderRadius: "10px",
                      boxSizing: "border-box",
                    }}
                  >
                    {coachlist?.length === 0 ? (
                      <Typography sx={{ color: "#000" }}>
                        No Coach Assign to this session yet
                      </Typography>
                    ) : (
                      coachlist?.map((el) => (
                        <Box
                          sx={{
                            display: "flex",
                            gap: "20px",
                            alignItems: "center",
                            border: "0.5px solid #F4AA50",
                            width: "100%",
                            borderRadius: "10px",
                            padding: "5px",
                          }}
                        >
                          <Box
                            sx={{
                              padding: "3px",
                            }}
                          >
                            <img
                              style={{ width: "85px", height: "85px" }}
                              src={`${Coach_imageUrl}/${el?.profile_img}`}
                              alt=""
                            />
                          </Box>
                          <Typography
                            sx={{ color: "#000", fontSize: "1.5rem" }}
                          >
                            {el?.coach_name}
                          </Typography>
                        </Box>
                      ))
                    )}
                  </Box>
                  {coachlist?.length > 0 ? (
                    ""
                  ) : (
                    <Box>
                      
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <label htmlFor="audio-cover-file-input">
                          <Box
                            sx={{
                              width: "100px",
                              height: "100px",
                              border: "1px solid #F4AA50",
                              padding: "5px",
                              borderRadius: "10px",
                              cursor: "pointer",
                            }}
                          >
                            {coachprofile_image ? (
                              <img
                                style={{ width: "100%", height: "100%" }}
                                src={coachprofile_image}
                                alt="Coachprofile_image"
                              />
                            ) : (
                              <AddAPhotoIcon
                                style={{
                                  color: "#F4AA50",
                                  width: "100%",
                                  height: "100%",
                                }}
                              />
                            )}
                          </Box>
                        </label>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          flexDirection: "column",
                          boxSizing: "border-box",
                          gap: "10px",
                          justifyContent: "start",
                          alignItems: "start",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#000",
                            textAlign: "start",
                            justifySelf: "start",
                            fontWeight: "600",
                          }}
                        >
                          Add Coach :
                        </Typography>
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Input
                            type="file"
                            id="audio-cover-file-input"
                            style={{ display: "none" }}
                            onChange={(event) => handeldCoachImage(event)}
                          />

                          <Box sx={{ width: "100%" }}>
                            <CommonInput
                              value={coach_name}
                              placeholder={"Enter coach name"}
                              onChange={handlecoachchange}
                            />
                          </Box>
                        </Box>

                        <Button
                          disabled={addcoachloading}
                          onClick={AddnewCoach}
                          sx={{
                            ...CommonButtonstyle,
                            width: "40%",
                            m: "auto",
                          }}
                        >
                          {addcoachloading ? <CircularProgress /> : "Add"}
                        </Button>
                      </Box>
                    </Box>
                  )}
                </Box>
              
              </Box> */}
            </Box>
            <Box></Box>
            <Box>
              {selecteddata?.link !== null ? (
                <Typography
                  sx={{
                    textAlign: "start",
                    m: "10px 0px",
                    color: "#000",
                    fontFamily: "Poppins",
                    fontSize: "18px",
                    fontWeight: "600",
                  }}
                >
                  Update Meeting Link :
                </Typography>
              ) : (
                <Typography
                  sx={{
                    textAlign: "start",
                    m: "10px 0px",
                    color: "#000",
                    fontFamily: "Poppins",
                    fontSize: "18px",
                    fontWeight: "600",
                  }}
                >
                  Add Meeting Link :
                </Typography>
              )}
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  mb: "20px",
                  boxSizing: "border-box",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                <Box sx={{ width: "80%" }}>
                  <CommonInput
                    value={link}
                    placeholder={"Enter link here .. "}
                    onChange={handlelinkchange}
                  />
                </Box>
                <Button
                  disabled={isLinkEmpty()}
                  onClick={Updatethislink}
                  sx={{ ...CommonButtonstyle, width: "20%" }}
                >
                  <AddLinkIcon
                    sx={{ color: isLinkEmpty() ? "#000" : "#fff" }}
                  />
                </Button>

                {selecteddata?.link !== null ? (
                  <Box
                   
                  >
                    <a
                      href={selecteddata?.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button  sx={{
                      backgroundImage:
                        "linear-gradient(180deg, #FFCE98 0%, #F4AA50 100%)",
                        width:"120px",
                        textAlign:"center",
                        textTransform:"capitalize",
                        color:"#fff"
                    }} >
                        View Link 
                      </Button>
                      
                    </a>
                  </Box>
                ) : (
                  ""
                )}
              </Box>
            </Box>
            <Box sx={{ width: "40%", m: "auto" }}>
            <ActionButton title={"Close"} onclick={closegroupdetailspopup} />
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ViewGroupedetails;
