import React, { useState, useEffect } from "react";
import {
  Box,
  InputLabel,
  Modal,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { modalstyle } from "./PopupsStyling";
import ActionButton from "./PopupComponent/ActionButton";
import { useDispatch } from "react-redux";
import { GetEbooklist, Getallcategorie, UpdateCategory } from "../../Redux/Slices/Ebooks/Ebooks.action";
import { errortost, sucesstost } from "../Alerts/CustomAlert";
import TostContainer from "../Alerts/TostContainer";

const EditCategory = ({ modalcontrols }) => {
  let { iseditEbook, closeeditcategory, editselected } = modalcontrols;
  let dispatch = useDispatch();

  let [categoryval, setcategoryval] = useState(editselected?.cat_name);
  let [isCategoryEdited, setCategoryEdited] = useState(false);
  let [loading, setloading] = useState(false);
  useEffect(() => {
    setCategoryEdited(categoryval !== editselected?.cat_name);
  }, [categoryval, editselected]);

  let handleChange = (e) => {
    setcategoryval(e.target.value);
  };

  let handeldUpdate = () => {
    let editdata = new FormData();
    editdata.append("cat_name", categoryval);
    editdata.append("id", editselected?.id);
    setloading(true);
    dispatch(UpdateCategory(editdata))
      .unwrap()
      .then((result) => {
        if (result?.s === 1) {
          setloading(false);
          sucesstost("Category Updated")
          dispatch(Getallcategorie())
          closeeditcategory()
        } else {
          setloading(false);
          errortost("Something wents wrong !")
          closeeditcategory()
        }
      });
  };

  return (
    <div>
        <TostContainer/>
      <Modal open={iseditEbook} onClose={closeeditcategory}>
        <Box
          sx={{
            ...modalstyle,
            borderRadius: "10px",
            width: { xs: "50vw", md: "30vw" },
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              padding: "10px 0px",
              cursor: "pointer",
            }}
          >
            <Typography
              sx={{
                fontSize: "18px",
                fontFamily: "Poppins",
                fontWeight: "600",
                lineHeight: "120%",
                color: "#000",
              }}
            >
              Update Category
            </Typography>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <InputLabel
              id="demo-select-small-label"
              sx={{
                fontSize: "15px",
              }}
            >
              Category name
            </InputLabel>
            <OutlinedInput
              sx={{
                width: "100%",
                height: "50px",
                borderRadius: "10px",
                border: "1px solid #F68582",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
              }}
              id="outlined-adornment-weight"
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
              defaultValue={categoryval}
              onChange={(e) => handleChange(e)}
              name="category"
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "70%",
              m: "auto",
              mt: "10px",
            }}
          >
            <ActionButton
              onclick={handeldUpdate}
              title={"Update"}
              loading={loading}
              disabled={!isCategoryEdited} // Disable the button if category is not edited
            />
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default EditCategory;
