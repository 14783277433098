import { Box } from "@mui/material";
import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const Coaches = () => {
  let navigate = useNavigate();
  let path = useLocation();
  return (
    <>
    <Box sx={{width:"100%"}}>
      <Outlet />
    </Box>
    </>
  );
};

export default Coaches;
