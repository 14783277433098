import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import {
  AddPrivacyandTermsConditiondata,
  GetPrivacydata,
  UpdatePrivacyandTermsConditiondata,
} from "../../../../Redux/Slices/SettingData/SettingData.action";
import { Box, Button } from "@mui/material";
import { CommonButtonstyle } from "../../../../Components/CommonCss";
import {
  errortost,
  sucesstost,
} from "../../../../Components/Alerts/CustomAlert";

const PrivacyPolicy = () => {
  let dispatch = useDispatch();
  let [loading, setloading] = useState(false);
  let SettingData = useSelector((store) => store?.Settings);
  const [Privacypolicydata, SetPrivacypolicydata] = useState(
    SettingData?.PrivacyPolicy?.r?.description
  );
  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(GetPrivacydata(2));
        setloading(false);  // Set loading to false once data is fetched
      } catch (error) {
        setloading(false);
        errortost("Error fetching data");
      }
    };

    fetchData();
  }, [dispatch]);
  
  useEffect(() => {
    // Update local state after the data is fetched
    if (!loading) {
        SetPrivacypolicydata(SettingData?.PrivacyPolicy?.r?.description || "");
    }
  }, [SettingData, loading]);

  let AddUpdatePolicy = () => {
    if (SettingData?.PrivacyPolicy?.r?.id) {
      let policy = new FormData();
      policy.append("description", Privacypolicydata);
      policy.append("type", 2);
      policy.append("id", SettingData?.PrivacyPolicy?.r?.id);
      setloading(true);
      dispatch(
        UpdatePrivacyandTermsConditiondata({ Contentdata: policy, type: 2 })
      )
        .unwrap()
        .then((result) => {
          if (result?.s === 1) {
            setloading(false);
            sucesstost("Policy Updated !");
            dispatch(GetPrivacydata(2));
          } else {
            errortost("Something Wents Wrong Please try again !");
          }
        });
    } else {
      let policy = new FormData();
      policy.append("description", Privacypolicydata);
      policy.append("type", 2);
      setloading(true);
      dispatch(
        AddPrivacyandTermsConditiondata({ Contentdata: policy, type: 2 })
      )
        .unwrap()
        .then((result) => {
          if (result?.s === 1) {
            setloading(false);
            sucesstost("Policy Added !");
            dispatch(GetPrivacydata(2));
          } else {
            errortost("Something Wents Wrong Please try again !");
            setloading(false);
          }
        });
    }
  };

  return (
    <div>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
        <Button
          onClick={AddUpdatePolicy}
          sx={{ ...CommonButtonstyle, width: "10%", mt: "-50px", mr: "0px" }}
          disabled={loading}
        >
          Save
        </Button>
      </Box>
      <ReactQuill
        value={Privacypolicydata}
        onChange={(value) => SetPrivacypolicydata(value)}
        style={{
          width: "100%",
          textAlign: "left",
          height: "78vh",

          border: "none",
        }}
        theme="snow"
        modules={{
          toolbar: {
            container: [
              [
                {
                  header: "1",
                  style: { background: "rgba(246, 133, 130, 0.20)" },
                },
                {
                  header: "2",
                  style: { background: "rgba(246, 133, 130, 0.20)" },
                },
              ],
              [{ font: [] }],
              ["bold", "italic", "underline", "strike"],
              [{ color: [] }, { background: [] }],
              [{ list: "ordered" }, { list: "bullet" }],
              ["link"],
              ["clean"],
              [{ size: ["small", false, "large", "huge"] }], // Font size dropdown
            ],
          },
        }}
      />
    </div>
  );
};

export default PrivacyPolicy;
