import {
  Box,
  Button,
  CircularProgress,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import ActionButton from "../../../../Components/Popups/PopupComponent/ActionButton";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  AddSelectedCoachAvilability,
  GetCoachesListByDateandTime,
} from "../../../../Redux/Slices/Sessions/Session.action";
import { Coach_imageUrl } from "../../../../Apis/Api";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ClearIcon from "@mui/icons-material/Clear";
import toast, { toastConfig } from "react-simple-toasts";
import "react-simple-toasts/dist/theme/dark.css";
import { useNavigate } from "react-router-dom";
const IndividualAvailabilityAdd = () => {
  const [isGetCoachlistTimeSelected, setisGetCoachlistTimeSelected] =
    useState(false);
  const [selectedCoaches, setSelectedCoaches] = useState([]);

  let [CoachesList, setCoacheslist] = useState([]);
  const initialFormState = {
    date: "",
    start_time: null,
    end_time: null,
  };
  const [formData, setFormData] = useState({ ...initialFormState });
  const [loading, setloading] = useState(false);
  const [validation, setValidation] = useState({
    date: false,
    start_time: true,
    end_time: true,
  });
  let dispatch = useDispatch();
  const handleDateChange = (event) => {
    if (isGetCoachlistTimeSelected) {
      setisGetCoachlistTimeSelected(false);
      setCoacheslist([]);
      setSelectedCoaches([]);
      return;
    }
    if (event && event.target) {
      const selectedDate = event.target.value;
      setFormData({ ...formData, date: selectedDate });
      setValidation({ ...validation, date: true });
    } else {
      console.error("Invalid event object:", event);
    }
  };
  let [istimeavilable, setistimeavilable] = useState(false);

  const handlestart_timeChange = (time) => {
    setFormData({ ...formData, start_time: time });
    if (!formData.date) {
      setValidation({ ...validation, date: false });
      setValidationErors({
        ...validationErrors,
        date: "Please select a date before choosing the time",
      });
      return;
    }
    const startTime = moment(time, "HH:mm:ss");
    const endTime = moment(formData.end_time, "HH:mm:ss");
    if (endTime.isValid() && endTime.isBefore(startTime)) {
      setistimeavilable(true);
      setValidation({ ...validation, end_time: false });
      setValidationErors({
        ...validationErrors,
        end_time: "End time must be after start time.",
      });
      return;
    }
    setValidation({ ...validation, start_time: true });
    setValidationErors({ ...validationErrors, start_time: "" });
  };

  const handleend_timeChange = (time) => {
    setFormData({ ...formData, end_time: time });
    if (!formData.date) {
      setValidation({ ...validation, date: false });
      setValidationErors({
        ...validationErrors,
        date: "Please select a date before choosing the time",
      });
      return;
    }

    const startTime = moment(formData.start_time, "HH:mm:ss");
    const endTime = moment(time, "HH:mm:ss");
    if (startTime.isValid() && endTime.isBefore(startTime)) {
      setistimeavilable(true);
      setValidation({ ...validation, end_time: false });
      setValidationErors({
        ...validationErrors,
        end_time: "End time must be after start time.",
      });
      return;
    }
    setValidation({ ...validation, end_time: true });
    setValidationErors({ ...validationErrors, end_time: "" });
  };
  const [validationErrors, setValidationErors] = useState({
    date: "",
    start_time: "",
    end_time: "",
  });
  let navigate = useNavigate();
  const handleActionClick = () => {
    const isFormValid =
      formData.date &&
      formData.start_time &&
      formData.end_time &&
      validation.date &&
      validation.start_time &&
      validation.end_time;
    if (!isFormValid) {
      setValidation({
        date: !!formData.date,
        start_time: !!formData.start_time,
        end_time: !!formData.end_time,
      });
      if (!formData.date && !formData.start_time && !formData.end_time) {
        toast("Please fill all the fields");
        return;
      }
      if (!formData.start_time && !formData.end_time) {
        toast("Please select a start and end time");
      }
      return;
    } else {
      let collecteddata = new FormData();
      collecteddata.append("date", formData.date);
      collecteddata.append(
        "start_time",
        moment.utc(formData.start_time).format("HH:mm:ss")
      );
      collecteddata.append(
        "end_time",
        moment.utc(formData.end_time).format("HH:mm:ss")
      );
      setloading(true);
      if (selectedCoaches.length < 1) {
        toast("please select Coach");
        return;
      }
      const coachIds = selectedCoaches.map((coach) => coach.id).join(",");
      collecteddata.append("coach_id", coachIds);
      collecteddata.append("type", 1);
      dispatch(AddSelectedCoachAvilability(collecteddata))
        .unwrap()
        .then((result) => {
          if (result.s === 1) {
            setloading(false);
            setisGetCoachlistTimeSelected(false);
            toast("Avilability added sucessfully !");
            setFormData({
              ...formData,
              date: "",
              start_time: null,
              end_time: null,
            });
            setValidation({
              ...validation,
              date: false,
              start_time: false,
              end_time: false,
            });
            setValidationErors({
              ...validationErrors,
              date: "",
              start_time: "",
              end_time: "",
            });

            setisGetCoachlistTimeSelected(false);
            setCoacheslist([]);
            setSelectedCoaches([]);

            navigate("/dashboard/avilability");
          } else {
            setloading(false);
          }
        });
    }
  };
  const SelectThisCoach = (data) => {
    const isCoachSelected = selectedCoaches.some(
      (coach) => coach.id === data.id
    );
    if (isCoachSelected) {
      toast("This coach is already selected");
    } else {
      setSelectedCoaches([...selectedCoaches, data]);
    }
  };
  const RemoveThisCoachFromList = (data) => {
    setSelectedCoaches((prevSelectedCoaches) =>
      prevSelectedCoaches.filter((coach) => coach.id !== data.id)
    );
  };
  toastConfig({
    theme: "dark",
  });
  let [checkcoachesloading, setcheckcoachesloading] = useState(false);

  let checkAvilableCoaches = () => {
    const isFormValid =
      formData.date &&
      formData.start_time &&
      formData.end_time &&
      validation.date &&
      validation.start_time &&
      validation.end_time;
    if (!isFormValid) {
      setValidation({
        date: !!formData.date,
        start_time: !!formData.start_time,
        end_time: !!formData.end_time,
      });
      if (!formData.date && !formData.start_time && !formData.end_time) {
        toast("Please fill all the fields");
        return;
      }
      if (!formData.start_time && !formData.end_time) {
        toast("Please select a start and end time");
      }
      return;
    } else {
      let collecteddata = new FormData();
      collecteddata.append("date", formData.date);
      collecteddata.append(
        "start_time",
        moment.utc(formData.start_time).format("HH:mm:ss")
      );

      collecteddata.append(
        "end_time",
        moment.utc(formData.end_time).format("HH:mm:ss")
      );
      setcheckcoachesloading(true);
      dispatch(
        GetCoachesListByDateandTime({
          date: formData.date,
          start_time: moment.utc(formData.start_time).format("HH:mm:ss"),
          end_time: moment.utc(formData.end_time).format("HH:mm:ss"),
        })
      )
        .unwrap()
        .then((result) => {
          if (result?.s === 1) {
            setloading(false);
            if (result?.r?.length >= 1) {
              setisGetCoachlistTimeSelected(true);
              setcheckcoachesloading(false);
              setCoacheslist(result?.r);
            } else {
              toast("No Coachs Avilable at this time !");
              setcheckcoachesloading(false);
            }
          } else {
            setcheckcoachesloading(false);
          }
        });
      return;
    }
  };
  return (
    <div>
      <Box
        sx={{
          borderRadius: "10px",
          width: "100%",
          padding: "0px 20px",
          display: "flex",
          justifyContent: "space-between",
          boxSizing: "border-box",
        }}
      >
        <Box
          sx={{
            borderRadius: "10px",
            width: { xs: "50vw", md: "40vw" },
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                width: "100%",
              }}
            >
              <InputLabel sx={{ color: validation.date ? "#000" : "red" }}>
                Date
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <TextField
                  sx={{ width: "100%" }}
                  type="date"
                  onChange={handleDateChange}
                  error={!validation.date}
                  inputProps={{
                    min: moment().format("YYYY-MM-DD"), // Set the minimum allowed date to today
                  }}
                />
              </LocalizationProvider>
            </Box>
            <Box
              sx={{
                display: "flex",
                color: "#000",
                justifyContent: "space-between",
                gap: "5px",
                flexDirection: "column",
                mt: "5px",
              }}
            >
              <Typography>Select Time</Typography>
              <Box
                sx={{
                  display: "flex",
                  color: "#000",
                  justifyContent: "space-between",
                  gap: "40px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    width: "100%",
                  }}
                >
                  <InputLabel
                    sx={{ color: validation.start_time ? "#000" : "red" }}
                  >
                    From
                  </InputLabel>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <TimePicker
                      value={formData.start_time}
                      onChange={handlestart_timeChange}
                      slotProps={{
                        openPickerIcon: {
                          style: {
                            color: "black",
                          },
                        },
                      }}
                      sx={{
                        borderRadius: "10px",
                        width: "100%",
                      }}
                      disabled={
                        !validation.date ||
                        (isGetCoachlistTimeSelected &&
                          selectedCoaches?.length >= 1)
                      }
                    />
                  </LocalizationProvider>
                  <Typography sx={{ color: "#000" }}>
                    {validationErrors?.start_time}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    width: "100%",
                  }}
                >
                  <InputLabel
                    sx={{ color: validation.end_time ? "#000" : "red" }}
                  >
                    To
                  </InputLabel>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <TimePicker
                      value={formData.end_time}
                      onChange={handleend_timeChange}
                      slotProps={{
                        openPickerIcon: {
                          style: {
                            color: "black",
                          },
                        },
                      }}
                      sx={{
                        borderRadius: "10px",
                        width: "100%",
                      }}
                      disabled={
                        !validation.date ||
                        (isGetCoachlistTimeSelected &&
                          selectedCoaches?.length >= 1)
                      }
                    />
                  </LocalizationProvider>
                  <Typography sx={{ color: "#000" }}>
                    {validationErrors?.end_time}
                  </Typography>
                </Box>
              </Box>
            </Box>
            {selectedCoaches?.length >= 1 ? (
              ""
            ) : (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  mt: "10px",
                }}
              >
                {" "}
                <Button
                  onClick={checkAvilableCoaches}
                  variant="outlined"
                  disabled={checkcoachesloading}
                  sx={{
                    textAlign: "end",
                    cursor: "pointer",
                    color: "red",
                    border: "1px solid red",
                    width: "30%",
                    height: "40px",
                    textTransform: "capitalize",
                  }}
                >
                  {checkcoachesloading ? (
                    <CircularProgress sx={{ height: "35px" }} />
                  ) : (
                    "    Check Availability"
                  )}
                </Button>
              </Box>
            )}

            {isGetCoachlistTimeSelected ? (
              <Box sx={{ width: "50%", m: "auto" }}>
                <ActionButton
                  disabled={loading || selectedCoaches?.length < 1}
                  onclick={handleActionClick}
                  loading={loading}
                  title={
                    isGetCoachlistTimeSelected
                      ? selectedCoaches.length < 1
                        ? "Select Coach to Add"
                        : "Add Availability"
                      : "Get Coaches List"
                  }
                />
              </Box>
            ) : (
              ""
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              padding: "10px 0px",
              height: "37vh",
              overflowY: "auto",
              mt: "10px",
            }}
          >
            {selectedCoaches?.map((coach) => (
              <Box
                key={coach.id}
                sx={{
                  display: "flex",
                  gap: "20px",
                  padding: "10px",
                  alignItems: "center",
                  boxSizing: "border-box",
                  justifyContent: "space-between",
                  borderRadius: "15px",
                  background: "rgba(246, 133, 130, 0.25)",
                  cursor: "pointer",
                  transition: "background 0.1s ease-in-out",
                  "&:hover": {
                    background: "rgba(246, 133, 130, 0.60)",
                  },
                }}
              >
                <Box sx={{ display: "flex", gap: "10px", width: "100%" }}>
                  {coach.profile_img ? (
                    <img
                      src={`${Coach_imageUrl}/${coach.profile_img}`}
                      style={{
                        width: "57px",
                        height: "57px",
                        borderRadius: "15px",
                        objectFit: "cover",
                      }}
                      alt=""
                    />
                  ) : (
                    <AccountCircleIcon sx={{ height: "57px", width: "57px" }} />
                  )}

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "center",
                      boxSizing: "border-box",
                    }}
                  >
                    <Box sx={{ width: "75%" }}>
                      <Typography sx={{ fontWeight: "600" }}>
                        {coach?.coach_name}
                      </Typography>
                      <Typography
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          maxWidth: "400px",
                        }}
                      >
                        {coach?.description}
                      </Typography>
                    </Box>

                    <Typography
                      sx={{
                        overflow: "hidden",
                        display: "flex",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        width: "20%",
                        justifySelf: "flex-end",
                        fontWeight: "600",
                      }}
                    >
                      {coach?.price}$/hr
                    </Typography>
                  </Box>
                </Box>

                <ClearIcon onClick={() => RemoveThisCoachFromList(coach)} />
              </Box>
            ))}
          </Box>
        </Box>
        <hr />
        <Box
          sx={{
            width: "40%",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            padding: "10px",
            boxSizing: "border-box",
            height: "88vh",
            mt: "-80px",
          }}
        >
          <Typography
            sx={{ textAlign: "center", fontSize: "18px", fontWeight: "600" }}
          >
            Select Coaches
          </Typography>

          {checkcoachesloading ? (
            <CircularProgress />
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                height: "80vh",
                overflow: "auto",
                padding: "10px 10px 0px 10px",
              }}
            >
              {!isGetCoachlistTimeSelected ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Please Select Date and time
                </Box>
              ) : CoachesList?.length === 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  No coaches available at selected time
                </Box>
              ) : (
                CoachesList?.map((el) => (
                  <Box
                    onClick={() => SelectThisCoach(el)}
                    sx={{
                      width: "100%",
                      display: "flex",
                      gap: "20px",
                      padding: "15px",
                      boxSizing: "border-box",
                      borderRadius: "15px",
                      background: " rgba(246, 133, 130, 0.25)",
                      cursor: "pointer",
                      transition: "background 0.1s ease-in-out",
                      "&:hover": {
                        background: " rgba(246, 133, 130, 0.60)",
                      },
                    }}
                  >
                    <Box sx={{ display: "flex", gap: "10px", width: "100%" }}>
                      {el?.profile_img ? (
                        <img
                          src={`${Coach_imageUrl}/${el?.profile_img}`}
                          style={{
                            width: "57px",
                            height: "57px",
                            borderRadius: "15px",
                            objectFit: "cover",
                          }}
                          alt=""
                        />
                      ) : (
                        <AccountCircleIcon
                          sx={{ height: "57px", width: "57px" }}
                        />
                      )}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          alignItems: "center",
                          boxSizing: "border-box",
                        }}
                      >
                        <Box sx={{ width: "75%" }}>
                          <Typography sx={{ fontWeight: "600" }}>
                            {el?.coach_name}
                          </Typography>
                          <Typography
                            sx={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              maxWidth: "400px",
                            }}
                          >
                            {el?.description}
                          </Typography>
                        </Box>

                        <Typography
                          sx={{
                            overflow: "hidden",
                            display: "flex",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            width: "20%",
                            justifySelf: "flex-end",
                            fontWeight: "600",
                          }}
                        >
                          {el?.price}$/hr
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                ))
              )}
            </Box>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default IndividualAvailabilityAdd;
