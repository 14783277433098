import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { modalstyle } from "./PopupsStyling";
import {
  Baseurl,
  audiocoverurl,
  blogscoverurl,
  getUserdata,
  videocoverurl,
} from "../../Apis/Api";
import { toast } from "react-toastify";
import {
  GetallAudios,
  GetallBlogs,
  GetallVideos,
} from "../../Redux/Slices/Resources/Resources.action";
import axios from "axios";
import { useDispatch } from "react-redux";

const DeleteResources = ({ modalcontrols }) => {
  let { isdeleteresource, closedeleteresource, resourcetype, deleteselected ,value ,count ,row_count } =
    modalcontrols;
  let [loading, setloading] = useState(false);
  let dispatch = useDispatch();

  let deleteresource = async () => {
    try {
      setloading(true);
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      let passdata = {
        status: 0,
        id: deleteselected?.id,
      };
      let data = await axios.post(
        `${Baseurl}/admin/${
          resourcetype == "audio"
            ? "audio_edit"
            : resourcetype == "blogs"
            ? "blogs_edit"
            : "video_edit"
        }`,
        passdata,
        config
      );
      if (data.status == 200) {
        setloading(false);
        dispatch(
          resourcetype === "audio"
            ? GetallAudios({value:value,count:count,row_count:row_count})
            : resourcetype === "blogs"
            ? GetallBlogs({value:value,count:count,row_count:row_count})
            : GetallVideos({value:value,count:count,row_count:row_count})
        );

        closedeleteresource();
        toast.success("File Deleted sucessfully", {
          position: "top-right",
          autoClose: 800,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        setloading(false);
        closedeleteresource();
        toast.error("Faild , Please try again !", {
          position: "top-right",
          autoClose: 800,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (err) {
      closedeleteresource();
      setloading(false);
      toast.error("Faild , Please try again !", {
        position: "top-right",
        autoClose: 800,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <>
      <Modal open={isdeleteresource} onClose={closedeleteresource}>
        <Box
          sx={{
            ...modalstyle,
            borderRadius: "10px",
            width: { xs: "50vw", md: "350px" },
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Typography
            sx={{
              color: "#000",
              fontFamily: "Poppins",
              display: "flex",
              gap: "5px",
            }}
          >
            Want to Delete this{" "}
            {resourcetype == "audio" ? (
              <Typography> Audio </Typography>
            ) : (
              <Typography> Video </Typography>
            )}
          </Typography>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "13px",
              padding: "10px",
              background: "rgba(128, 128, 128, 0.15)",
              borderRadius: "10px",
            }}
          >
            <img
              style={{ width: "50px", height: "50px", borderRadius: "10px" }}
              src={`${
                resourcetype === "audio"
                  ? audiocoverurl
                  : resourcetype === "blogs"
                  ? blogscoverurl
                  : videocoverurl
              }/${
                resourcetype == "audio"
                  ? deleteselected?.cover_img:
                  resourcetype === "blogs"?deleteselected.cover_img
                  : deleteselected?.thumb
              }`}
              alt="user image"
            />
            <Typography sx={{ color: "#000" }}>
              {deleteselected?.title}{" "}
            </Typography>
          </Box>

          <Box sx={{ display: "flex", gap: "10px" }}>
            <Button
              onClick={deleteresource}
              sx={{
                textTransform: "capitalize",
                mt: "10px",
                width: "100%",
                borderRadius: "14px",
                background: "#FF1607",
                color: "#fff",
                padding: "8px",
                "&:hover": {
                  background: "#A30000",
                },
              }}
            >
              {loading ? (
                <CircularProgress sx={{ color: "#fff" }} />
              ) : (
                <Typography>Delete</Typography>
              )}
            </Button>
            <Button
              onClick={() => closedeleteresource()}
              sx={{
                textTransform: "capitalize",
                mt: "10px",
                borderRadius: "14px",
                background: "#E8E8E8",
                width: "100%",
                color: "#000",
                padding: "8px",
                "&:hover": {
                  background: "#B0B0B0",
                },
              }}
            >
              No
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default DeleteResources;
