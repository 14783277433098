import axios from "axios";
import { Baseurl, getUserdata } from "../../../Apis/Api";
import { createAsyncThunk } from "@reduxjs/toolkit";
 
//  -------------  Get Mantra   -----------
export const GetMantra = createAsyncThunk(
    "ManageContent/getmantra",
    async () => {
      try {
        let { apikey, token } = getUserdata;
        const config = {
          headers: {
            apikey,
            token,
          },
        }
        let data = await axios.get(`${Baseurl}/mantra/mantra_get`, config);
        return data.data;
      } catch (error) {
        throw error;
      }
    }
  )
  
  //  ------------- Add Update Mantra  -----------
  export const AddUpdateMantra = createAsyncThunk(
    "ManageContent/addupdatemantra",
    async ({mantra,actiontype}) => {

      try {

        let { apikey, token } = getUserdata;

        const config = {
          headers: {
            apikey,
            token,
          },
        }
        let data = await axios.post(
          `${Baseurl}/mantra/${actiontype=="edit"?"mantra_add":"mantra_edit"}`,
          mantra,
          config
        )

        return data.data;

      } catch (error) {
        throw error;
      }
    }
  )
  
  
//  -------------  Get Affermations   -----------
export const GetAffermationslist = createAsyncThunk(
  "ManageContent/getaffermations",
  async ({count,row_count}) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      }
      let data = await axios.get(`${Baseurl}/admin/affirmations_get?count=${count}&row_count=${row_count}`, config);
      return data.data;
    } catch (error) {
      throw error;
    }
  }
)
  
//  ------------- Add New Ebook Item -----------
export const AddAffermationitem = createAsyncThunk(
  "ManageContent/addaffermations",
  async ({affermativedata,type}) => {
   console.log(affermativedata);
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      }


      let data = await axios.post(
        `${Baseurl}/admin/${type==1?"affirmations_add":"affirmations_edit"}`,
        affermativedata,
        config
      )
      return data.data;
    } catch (error) {
      throw error;
    }
  }
)
  
//  ------------- Delete Affermations  -----------
export const DeletethisAffermation = createAsyncThunk(
  "ManageContent/deleteaffermation",
  async (deletedata) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      }
      let data = await axios.post
      ( `${Baseurl}/admin/affirmations_edit`,
        deletedata,
        config
      )
      return data.data

    } catch (error) {
      throw error;
    }
  }
)
 
 
