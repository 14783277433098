import { Box, Button, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import TitleText from "../../../../Components/TitleText";
import { useDispatch, useSelector } from "react-redux";
import EditNoteIcon from "@mui/icons-material/EditNote";
import {
  DeleteAvilabilitySession,
  GetSessionDataById,
  GetusersJoinedInSessions,
} from "../../../../Redux/Slices/Sessions/Session.action";
import { formatTime } from "../../../../Apis/Functions";
import { Coach_imageUrl, groupe_scoverurl } from "../../../../Apis/Api";
import dummyprofileimage from "../../../../Utils/Logos/dummyprofile.jpg";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import toast from "react-simple-toasts";
const ViewAvilabilityDetails = () => {
  let params = useLocation();
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetSessionDataById(params?.state?.id));
  }, []);
  let DetailsById = useSelector((store) => store?.Sessions);

  console.log(params);
  const updatePayload = (originalIds, idToDelete) => {
    const idsList = originalIds.split(",");
    let updateIds = idToDelete.toString();
    const updatedIdsList = idsList.filter((id) => id !== updateIds);
    const updatedIds = updatedIdsList.join(",");
    const payload = {
      remainingIds: updatedIds,
    };
    return payload;
  };
  let navigate = useNavigate();
  let DeletethisCoachfromthissession = (Data) => {
    if (DetailsById?.GetAvilibilityDetailsById?.r?.coaches?.length == 1) {
      Swal.fire({
        title: "Oho!",
        text: "You cannot delete this coach. At least one coach must be present for the session",
        icon: "warning",
        confirmButtonColor: "  rgba(246, 133, 130, 1)",
        cancelButtonColor: "#d33",
      });
    } else {
      Swal.fire({
        title: "Are you sure ?",
        text: "Are you sure you want to remove this coach from the session?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes Remove it !",
      }).then((result) => {
        if (result.isConfirmed) {
          let updatedlist = updatePayload(
            DetailsById?.GetAvilibilityDetailsById?.r?.coach_id,
            Data?.id
          );
          console.log(updatedlist);
          let formdata = new FormData();
          formdata.append("coach_id", updatedlist?.remainingIds);
          formdata.append(
            "availability_id",
            DetailsById?.GetAvilibilityDetailsById?.r?.id
          );
          dispatch(DeleteAvilabilitySession(formdata))
            .unwrap()
            .then((result) => {
              if (result?.s == 1) {
                toast("Coach removed from this session !");
                dispatch(GetSessionDataById(params?.state));
              }
            });
        }
      });
    }
  };

  let RedirectToEditDetails = () => {
    navigate(
      `/dashboard/avilability/view_avilability_Details/${
        DetailsById?.GetAvilibilityDetailsById?.r?.type == 1
          ? "update1_details"
          : "update_details"
      } `,
      {
        state:{id: DetailsById?.GetAvilibilityDetailsById?.r?.id ,type: params?.state?.type,
          count: params?.state?.count,
          row_count: params?.state?.row_count,},
      }
    );
  };
 
  let GetbacktoList = () => {
    navigate("/dashboard/avilability", {
      state: {
        type: params?.state?.type,
        count: params?.state?.count,
        row_count: params?.state?.row_count,
      },
    });
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: "18px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            gap: "18px",
            width: "100%",
          }}
        >
          <Box
            onClick={() => GetbacktoList()}
            style={{ display: "flex", gap: "18px", color: "#000" ,cursor:"pointer" }}
          >
            {"<"} <TitleText title={"Availability"} style={"0"} />{" "}
          </Box>{" "}
          {">"}
          <TitleText title={"Availability Details "} style={"0"} />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          width: "100%",
          border: "1px solid rgba(246, 133, 130, 1)",
          mt: "18px",
          height: "85vh",
          padding: "18px",
          overflow: "auto",
          borderRadius: "20px",
          gap: "20px",
          boxSizing: "border-box",
        }}
      >
        <Box
          sx={{
            width: "60%",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            borderRadius: "15px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              boxSizing: "border-box",

              position: "relative",
            }}
          >
            <Button
              onClick={() => RedirectToEditDetails()}
              variant="outlined"
              sx={{
                position: "absolute",
                top: "0",
                right: "0",
                height: "50px",
                border: "1px solid red",
                textTransform: "capitalize",
                color: "red",
              }}
            >
              <EditNoteIcon />
            </Button>
            <Box
              sx={{
                padding: "5px",

                width: "120px",
                display: "flex",
                justifyContent: "center",
                borderRadius: "10px",
                background: " rgba(246, 133, 130, 1)",
              }}
            >
              <Typography sx={{ textAlign: "center", color: "#fff" }}>
                {DetailsById?.GetAvilibilityDetailsById?.r?.type == 1
                  ? "Individual"
                  : "Group"}
              </Typography>
            </Box>
            <img
              src={
                DetailsById?.GetAvilibilityDetailsById?.r?.cover_img
                  ? `${groupe_scoverurl}/${DetailsById?.GetAvilibilityDetailsById?.r?.cover_img}`
                  : dummyprofileimage
              }
              alt="session Cover image"
              style={{
                width: "180px",
                height: "180px",
                padding: "20px",
                border: "1px solid rgba(246, 133, 130, 1)",
                borderRadius: "20px",
                marginTop: "10px",
              }}
            />

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                padding: "18px",
                gap: "20px",
                boxSizing: "border-box",
              }}
            >
              {DetailsById?.GetAvilibilityDetailsById?.r?.type === 1 ? (
                ""
              ) : (
                <Box sx={{ display: "flex", alignItems: "start", gap: "5px" }}>
                  <Typography
                    sx={{ fontSize: "18px", fontWeight: "600", width: "60px" }}
                  >
                    Title : -{" "}
                  </Typography>
                  <Typography sx={{ fontSize: "18px" }}>
                    {" "}
                    {DetailsById?.GetAvilibilityDetailsById?.r?.title ||
                      "------"}
                  </Typography>
                </Box>
              )}
              <Box sx={{ display: "flex", alignItems: "start", gap: "5px" }}>
                <Typography
                  sx={{ fontSize: "18px", fontWeight: "600", width: "60px" }}
                >
                  Date : -{" "}
                </Typography>
                <Typography sx={{ fontSize: "18px" }}>
                  {DetailsById?.GetAvilibilityDetailsById?.r?.date
                    ? (() => {
                        const inputDate = new Date(
                          DetailsById?.GetAvilibilityDetailsById?.r?.date
                        );
                        return `${inputDate
                          .getDate()
                          .toString()
                          .padStart(2, "0")}/${(inputDate.getMonth() + 1)
                          .toString()
                          .padStart(2, "0")}/${inputDate.getFullYear()}`;
                      })()
                    : "N/A"}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "20px" }}>
                <Box sx={{ display: "flex", alignItems: "start", gap: "5px" }}>
                  <Typography
                    sx={{ fontSize: "18px", fontWeight: "600", width: "100px" }}
                  >
                    Start time : -{" "}
                  </Typography>
                  <Typography sx={{ fontSize: "18px" }}>
                    {formatTime(
                      DetailsById?.GetAvilibilityDetailsById?.r?.start_time
                    )}
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", alignItems: "start", gap: "5px" }}>
                  <Typography
                    sx={{ fontSize: "18px", fontWeight: "600", width: "100px" }}
                  >
                    End Time : -{" "}
                  </Typography>
                  <Typography sx={{ fontSize: "18px" }}>
                    {formatTime(
                      DetailsById?.GetAvilibilityDetailsById?.r?.end_time
                    )}
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ display: "flex", alignItems: "start", gap: "5px" }}>
                <Typography
                  sx={{ fontSize: "18px", fontWeight: "600", width: "70px" }}
                >
                  Price : -{" "}
                </Typography>
                <Typography sx={{ fontSize: "18px" }}>
                  ${" "}
                  {DetailsById?.GetAvilibilityDetailsById?.r?.price.toFixed(2)}
                </Typography>
              </Box>

              <Box sx={{ display: "flex", alignItems: "start", gap: "5px" }}>
                <Typography
                  sx={{ fontSize: "18px", fontWeight: "600", width: "60px" }}
                >
                  Link : -{" "}
                </Typography>
                <Typography sx={{ fontSize: "18px" }}>
                  {DetailsById?.GetAvilibilityDetailsById?.r?.link || "----"}
                </Typography>
              </Box>

              {DetailsById?.GetAvilibilityDetailsById?.r?.type == 1 ? (
                ""
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "start",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
                    Description : -{" "}
                  </Typography>
                  <Typography sx={{ fontSize: "18px" }}>
                    {DetailsById?.GetAvilibilityDetailsById?.r?.description ||
                      "-------"}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            border: "1px solid red",
            borderRadius: "15px",
            width: "40%",
            padding: "10px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Typography sx={{ textAlign: "center", fontWeight: "600" }}>
            Coaches
          </Typography>

          {DetailsById?.GetAvilibilityDetailsById?.r?.coaches?.map((el) => (
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                width: "100%",

                borderRadius: "15px",
                padding: "10px",
                boxSizing: "border-box",
                background: " rgba(246, 133, 130, 0.25)",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", gap: "20px" }}>
                {el?.profile_img ? (
                  <img
                    src={`${Coach_imageUrl}/${el?.profile_img}`}
                    style={{
                      width: "57px",
                      height: "57px",
                      borderRadius: "15px",
                      objectFit: "cover",
                    }}
                    alt=""
                  />
                ) : (
                  <AccountCircleIcon sx={{ height: "57px", width: "57px" }} />
                )}
                <Box>
                  <Typography sx={{ fontWeight: "600" }}>
                    {el?.coach_name}
                  </Typography>
                  <Typography
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      maxWidth: "400px",
                    }}
                  >
                    Email : {el?.email}
                  </Typography>
                  <Box sx={{ display: "flex", gap: "10px" }}>
                    <Typography
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        maxWidth: "400px",
                      }}
                    >
                      {/* <span style={{ fontWeight: "600" }}> Price :</span> $ */}
                      Price : {el?.price || "Null"}
                    </Typography>

                    <Typography
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        maxWidth: "400px",
                      }}
                    >
                      Website : {el?.website}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <DeleteIcon
                sx={{
                  padding: "4px",
                  border: "1px solid red",
                  borderRadius: "15px",
                  cursor: "pointer",
                  width: "30px",
                  height: "30px",
                  color: "rgba(246, 133, 130, 1)",
                }}
                onClick={() => DeletethisCoachfromthissession(el)}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </div>
  );
};

export default ViewAvilabilityDetails;
