import { TablePagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Select,
  Button,
  FormControl,
  MenuItem,
  // TablePagination,
} from "@mui/material";
import deletelogo from "../../../../Utils/Logos/deleteuser.png";
import editlogo from "../../../../Utils/Logos/edit.png";
import { useDispatch, useSelector } from "react-redux";
import { Getallcategorie } from "../../../../Redux/Slices/Ebooks/Ebooks.action";
import DeleteEbook from "../../../../Components/Popups/DeleteEbook";
import EditCategory from "../../../../Components/Popups/EditCategory";

const columns = [
  { id: "#", label: "#" },
  { id: "Categories name", label: "Categories name" },
  { id: "Action", label: "Action" },
]

const EbooksCategories = () => {
  let dispatch = useDispatch();
  let Ebookslist = useSelector((store) => store.Ebooks);
  useEffect(() => {
    dispatch(Getallcategorie());
  }, []);

  // --------- Delete Conformation Popup ------------
  let ebooktype = "ebookcategory";
  let [deleteselected, setdeleteselected] = useState({});
  let [isdeleteEbook, setisdeleteebook] = useState();
  let openebooklistdelete = (data) => {
    setdeleteselected(data);
    setisdeleteebook(true);
  };
  let closedeleteebook = () => {
    setisdeleteebook(false);
  };

  // --------- Edit Conformation Popup ------------
  let [editselected, seteditselected] = useState({});
  let [iseditEbook, setiseditEbook] = useState();
 
  let openeditcategory = (data) => {
    seteditselected(data);
    setiseditEbook(true);
  }
  let closeeditcategory = () => {
    setiseditEbook(false);
  }
 
 
  return (
    <>
 
      {iseditEbook && (
        <EditCategory
          modalcontrols={{
            iseditEbook,
            closeeditcategory,
            editselected,
          }}
        />
      )}

      {isdeleteEbook && (
        <DeleteEbook
          modalcontrols={{
            isdeleteEbook,
            closedeleteebook,
            deleteselected,
            ebooktype,
          }}
        />
      )}
      <Box sx={{ mt: "20px" }}>
        <Paper style={{ width: "100%", overflow: "hidden" }}>
          <TableContainer style={{ maxHeight: "71vh" }}>
            <Table stickyHeader sx={{}}>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      sx={{
                        textAlign: "center",
                        fontSize: "15px",
                        lineHeight: "120%",
                        fontFamily: "Montserrat",
                        fontWeight: "600",
                        background: "#F68582",
                        color: "#fff",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody sx={{ textAlign: "center" }}>
                {Ebookslist?.EbooksCategories?.length < 1 ? (
                  <Box sx={{ textAlign: "center", padding: "10px", m: "10px" }}>
                    No Data
                  </Box>
                ) : (
                  Ebookslist?.EbooksCategories?.map((row, i) => (
                    <TableRow key={row.id} sx={{ textAlign: "center" }}>
                      <TableCell sx={{ textAlign: "center" }}>
                        {i + 1}
                      </TableCell>

                      <TableCell sx={{ textAlign: "center" }}>
                        {" "}
                        {row?.cat_name}
                      </TableCell>

                      <TableCell
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Box
                          sx={{
                            height: "50px",
                            display: "flex",
                            gap: "20px",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            onClick={() => openebooklistdelete(row)}
                            style={{
                              width: "30px",
                              height: "30px",
                              cursor: "pointer",
                            }}
                            src={deletelogo}
                            alt="deletelogo"
                          />
                          <img
                            onClick={() => openeditcategory(row)}
                            style={{
                              width: "30px",
                              height: "30px",
                              cursor: "pointer",
                            }}
                            src={editlogo}
                            alt="editlogo"
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        {/* <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <TablePagination
            sx={{ mb: "-20px" }}
            // component="div"
            // count={storeAudiodata?.AudioFiles?.count || 0}
            // page={page}
            // onPageChange={handleChangePage}
            // rowsPerPage={rowsPerPage}
            // onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box> */}
      </Box>
    </>
  );
};

export default EbooksCategories;
