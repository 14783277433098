import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { CommonButtonstyle } from "../../../../Components/CommonCss";
import TitleText from "../../../../Components/TitleText";

import {
  Box,
  Grid,
  Paper,
  Typography,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Select,
  Button,
  FormControl,
  MenuItem,
  TablePagination,
  Chip,
  Modal,
  // TablePagination,
} from "@mui/material";
import AddLinkIcon from "@mui/icons-material/AddLink";
import { formatTime } from "../../../../Apis/Functions";
import { useDispatch, useSelector } from "react-redux";
import {
  AddUpdateIndiviSessionLink,
  EditAvilabilitySessionDetails,
  GetIndividualSessions,
  GetSessionDataById,
  GetusersJoinedInSessions,
} from "../../../../Redux/Slices/Sessions/Session.action";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import toast from "react-simple-toasts";
import { GetRecentIndividualSessions } from "../../../../Redux/Slices/Dashboard/Dashboard.action";
import CommonInput from "../../../../Components/Inputs/CommonInput";
import {
  Coach_imageUrl,
  User_Profileimage,
  groupe_scoverurl,
} from "../../../../Apis/Api";
const ViewGroupSessionDetails = () => {
  const columns = [
    { id: "#", label: "#" },
    { id: "User Profile", label: "User Profile" },
    { id: "User Name", label: "User Name" },
    { id: "Email", label: "Email" },
    { id: "Phone No.", label: "Phone No." },
    { id: "Payment Status", label: "Payment Status" },
  ];
  let DetailsById = useSelector((store) => store?.Sessions);
  let fields = [
    {
      name: "Title",
      value: DetailsById?.GetAvilibilityDetailsById?.r?.title
        ? DetailsById?.GetAvilibilityDetailsById?.r?.title
        : " - - - ",
    },
    { name: "Date ", value: DetailsById?.GetAvilibilityDetailsById?.r?.date },
    {
      name: "Start Time ",
      value: formatTime(DetailsById?.GetAvilibilityDetailsById?.r?.start_time),
    },
    {
      name: "End Time ",
      value: formatTime(DetailsById?.GetAvilibilityDetailsById?.r?.end_time),
    },

    {
      name: "Payment ",
      value: `$ ${DetailsById?.GetAvilibilityDetailsById?.r?.price}`,
    },
  ];

  let [link, setlink] = useState(
    DetailsById?.GetAvilibilityDetailsById?.r?.link || ""
  );
  let dispatch = useDispatch();
  let params = useLocation();
  let handlelinkchange = (e) => {
    setlink(e.target.value);
  };
  const isLinkEmpty = () => {
    return link.trim() === "";
  };

  let Updatethislink = () => {
    let linkdata = new FormData();
    linkdata.append("link", link);
    linkdata.append(
      "availability_id",
      DetailsById?.GetAvilibilityDetailsById?.r?.id
    );

    dispatch(EditAvilabilitySessionDetails(linkdata))
      .unwrap()
      .then((result) => {
        if (result?.s === 1) {
          dispatch(GetSessionDataById(params?.state));
          dispatch(
            GetRecentIndividualSessions({
              count: 0,
              row_count: 10,
            })
          );
          toast("Meeting Link added sucessfully !");
        } else {
          toast("Something Wents wrong please try again !");
        }
      });
  };
  useEffect(() => {
    dispatch(GetSessionDataById(params?.state))
      .unwrap()
      .then((result) => {
        setlink(result?.r?.link || "");
      });
    dispatch(GetusersJoinedInSessions(params?.state));
  }, []);
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: "18px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            gap: "18px",
            width: "100%",
          }}
        >
          <Link
            to={"/dashboard/schedules/groupsession"}
            style={{ display: "flex", gap: "18px", color: "#000" }}
          >
            {"<"} <TitleText title={" Group Sessions "} style={"0"} />{" "}
          </Link>{" "}
          {">"}
          <TitleText title={"Session Details "} style={"0"} />
        </Box>
      </Box>
      <Box sx={{ height: "84vh", overflow: "auto" ,border:"1px solid red",padding:"0px 10px 10px 10px",mt:"20px",borderRadius:"15px"}}>
        <Box
          sx={{
            borderRadius: "10px",
            width: { xs: "50vw", md: "100%" },
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            m: "auto",
         
            mt: "20px",
           
            boxSizing: "border-box",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",

              //   height: "70vh",
              boxSizing: "border-box",
              //   border:"1px solid red"
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                width: "55%",
              }}
            >
              <Box
                sx={{
                  width: "60%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px solid #F4AA50",
                  borderRadius: "10px",
                  padding: "10px 0px",
                  m: "auto",
                }}
              >
                {DetailsById?.GetAvilibilityDetailsById?.r?.cover_img ? (
                  <img
                    src={`${groupe_scoverurl}/${DetailsById?.GetAvilibilityDetailsById?.r?.cover_img}`}
                    style={{
                      height: "120px",
                      width: "120px",
                      borderRadius: "10px",
                    }}
                    alt=""
                  />
                ) : (
                  <AccountCircleIcon
                    sx={{ height: "100px", color: "#000", width: "100px" }}
                  />
                )}
              </Box>

              {fields?.map((fields) => (
                <Box
                  sx={{
                    display: "flex",
                    color: "#000",
                    alignItems: "start",
                    gap: "5px",
                    justifyContent: "start",
                    padding: "0vh 1vw",
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#000",
                      fontFamily: "Poppins",
                      fontSize: "18px",
                      fontWeight: "600",
                    }}
                  >
                    {fields?.name} :
                  </Typography>{" "}
                  <Typography
                    sx={{
                      color:
                        fields?.value === "Failed"
                          ? "red"
                          : fields?.value === "completed"
                          ? "green"
                          : fields?.value === "pending"
                          ? "blue"
                          : "",
                      fontFamily: "Poppins",
                      fontSize: "18px",
                      fontWeight: "400",
                    }}
                  >
                    {fields?.value}
                  </Typography>
                </Box>
              ))}

              <Box
                sx={{
                  color: "#000",
                  alignItems: "start",
                  gap: "5px",

                  padding: "0vh 1vw",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    color: "#000",
                    fontFamily: "Poppins",
                    fontSize: "18px",
                    fontWeight: "600",
                  }}
                >
                  Description :
                </Typography>{" "}
                <Typography
                  sx={{
                    color:
                      fields?.value === "Failed"
                        ? "red"
                        : fields?.value === "completed"
                        ? "green"
                        : fields?.value === "pending"
                        ? "blue"
                        : "",
                    fontFamily: "Poppins",
                    fontSize: "18px",
                    fontWeight: "400",
                  }}
                >
                  {DetailsById?.GetAvilibilityDetailsById?.r?.description}
                </Typography>
              </Box>

              <Typography sx={{fontWeight:"600",paddingLeft:"15px" , fontSize: "18px",
                    fontWeight: "600",}}>
           Meeting Link : 
                </Typography>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  mb: "20px",
                  boxSizing: "border-box",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                <Box sx={{ width: "80%" }}>
                  <CommonInput
                    value={link}
                    placeholder={"Enter link here .. "}
                    onChange={handlelinkchange}
                  />
                </Box>
                <Button
                  disabled={isLinkEmpty()}
                  onClick={Updatethislink}
                  sx={{ ...CommonButtonstyle, width: "20%" }}
                >
                  <AddLinkIcon
                    sx={{ color: isLinkEmpty() ? "#000" : "#fff" }}
                  />
                </Button>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                width: "30%",
              }}
            >
                <Typography sx={{fontWeight:"600"}}>
              Coach Details : 
                </Typography>
              {DetailsById?.GetAvilibilityDetailsById?.r?.coaches?.map((el) => (
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    width: "100%",

                    borderRadius: "15px",
                    padding: "10px",
                    boxSizing: "border-box",
                    border: "1px solid red",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ display: "flex", gap: "20px" }}>
                    {el?.profile_img ? (
                      <img
                        src={`${Coach_imageUrl}/${el?.profile_img}`}
                        style={{
                          width: "57px",
                          height: "57px",
                          borderRadius: "15px",
                          objectFit: "cover",
                        }}
                        alt=""
                      />
                    ) : (
                      <AccountCircleIcon
                        sx={{ height: "57px", width: "57px" }}
                      />
                    )}
                    <Box>
                      <Typography sx={{ fontWeight: "600" }}>
                        {el?.coach_name}
                      </Typography>
                      <Typography
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          maxWidth: "400px",
                        }}
                      >
                        Email : {el?.email}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>

        <Box sx={{ mt: "20px" }}>
          <Paper style={{ width: "100%", overflow: "hidden" }}>
            <TableContainer style={{ maxHeight: "50vh", overflow: "auto" }}>
              <Table stickyHeader sx={{}}>
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        sx={{
                          textAlign: "center",
                          fontSize: "15px",
                          lineHeight: "120%",
                          fontFamily: "Montserrat",
                          fontWeight: "600",
                          background: "#F68582",
                          color: "#fff",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody sx={{ textAlign: "center" }}>
                  {DetailsById?.usersJoinedInSession?.r?.length < 1 ||
                  DetailsById?.usersJoinedInSession?.r == null ? (
                    <Box
                      sx={{ textAlign: "center", padding: "10px", m: "10px" }}
                    >
                      No Users !
                    </Box>
                  ) : (
                    DetailsById?.usersJoinedInSession?.r?.map((row, i) => (
                      <TableRow key={row.id} sx={{ textAlign: "center" }}>
                        <TableCell sx={{ textAlign: "center" }}>
                          {i + 1}
                        </TableCell>

                        <TableCell sx={{ textAlign: "center" }}>
                          {row?.user_details?.profile_img == null ? (
                            <AccountCircleIcon
                              sx={{ height: "40px", width: "50px" }}
                            />
                          ) : (
                            <img
                              src={`${User_Profileimage}/${row?.user_details?.profile_img}`}
                              style={{
                                height: "40px",
                                width: "50px",
                                borderRadius: "10px",
                              }}
                              alt=""
                            />
                          )}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {" "}
                          {row?.user_details?.full_name}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {" "}
                          {row?.user_details?.email}
                        </TableCell>

                        <TableCell sx={{ textAlign: "center" }}>
                          {" "}
                          {row?.user_details?.phone_no}
                        </TableCell>

                        <TableCell sx={{ textAlign: "center" }}>
                          {" "}
                          <Chip
                            sx={{
                              background:
                                row?.payment_status === 1
                                  ? "green"
                                  : row?.payment_status === 0
                                  ? "red"
                                  : row?.payment_status === -1
                                  ? "gray"
                                  : "gray",
                              color: "#fff",
                            }}
                            label={
                              row?.payment_status === 1
                                ? "Completed"
                                : row?.payment_status === 0
                                ? "Failed"
                                : row?.payment_status === -1
                                ? "Refund"
                                : "Refund"
                            }
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          {/* 
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <TablePagination
            sx={{ mb: "-20px" }}
            // component="div"
            count={ sessionsdata?.UsersjoindinGroupe?.count || 2}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box> */}
        </Box>
      </Box>
    </div>
  );
};

export default ViewGroupSessionDetails;
