import {
  Box,
  Breadcrumbs,
  Button,
  Input,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import TitleText from "../../../../Components/TitleText";
import { CommonButtonstyle } from "../../../../Components/CommonCss";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import ActionButton from "../../../../Components/Popups/PopupComponent/ActionButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  AddnewCoachesDetails,
  EditCoachesDetails,
} from "../../../../Redux/Slices/Sessions/Session.action";
import { useDispatch } from "react-redux";
import {
  errortost,
  sucesstost,
} from "../../../../Components/Alerts/CustomAlert";
import ReactQuill from "react-quill";
import TostContainer from "../../../../Components/Alerts/TostContainer";
import toast from "react-simple-toasts";
import { Coach_imageUrl } from "../../../../Apis/Api";
import { Chips } from "primereact/chips";

let Lablestyle = {
  fontSize: "15px",
  mt: "10px",
  fontWeight: "500",
};
let Inputboxstyle = {
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  gap: "5px",
  width: "100%",
};
let inputstyle = {
  width: "100%",
  height: "50px",
  borderRadius: "10px",
};

const EditCoachDetails = () => {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let location = useLocation();
  const initialData = location?.state || {};
  let [imgPreview, setimgPreview] = useState(initialData.profile_img || null);
  let [isSelected, setIsSelected] = useState(false);
  let handleFileChange = (e) => {
    const file = e?.target?.files[0];
    if (file) {
      const objectURL = URL?.createObjectURL(file);
      setimgPreview(objectURL);
      setIsSelected(true);
      if (file.size <= 510241024) {
        formik.setFieldValue("profile_img", file);
      } else {
        errortost(`File size exceeds the limit of 5MB`);
      }
    }
  }
  const [value, setValue] = useState(initialData?.tags?.split(',')?.map(tag => tag?.trim()) || []);
 
  let AddNewtags=(tagvalue)=>{
    setValue(tagvalue)
    formik.setFieldValue("tags", value);
  }
  let [loading, setloading] = useState(false);
  const formik = useFormik({
    initialValues: {
      coach_name: initialData.coach_name || "",
      title: initialData.title || "",
      email: initialData.email || "",
      website: initialData.website || "",
      text: initialData.text || "",
      price: initialData.price || "",
      description: initialData.description || "",
      profile_img: null,
      tags: initialData?.tags?.split(',')?.map(tag => tag?.trim()) || [],
    },

    validationSchema: Yup.object().shape({
      coach_name: Yup.string().required("Coach name is required!"),
      email: Yup.string()
        .matches(
          /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
          "Please enter a valid email address"
        )
        .required("Email address is required!"),
      title: Yup.string().required("Title is required!"),
      text: Yup.string().required("Text is required!"),
      price: Yup.string().required("Price is required!"),
      description: Yup.string().required("Description is required!"),
      tags: Yup.array().required("Tags are required!"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const updatedValues = {};

      for (const key in values) {
        if (values[key] !== formik.initialValues[key]) {
          updatedValues[key] = values[key];
        }
      }

      const formData = new FormData();
      for (const key in updatedValues) {
        formData.append(key, updatedValues[key]);
      }
      formData.append("id", location?.state?.id);

      setloading(true);
      await dispatch(EditCoachesDetails(formData))
        .unwrap()
        .then((res) => {
          if (res?.s === 1) {
            toast(" Updated successfully!");
            resetForm();
            setimgPreview(null);
            setloading(false);
            navigate(-1)
          } else {
            errortost(res?.m);
            setloading(false);
          }
        })
        .catch((error) => {
          toast("Something went wrong, please try again.");
          setloading(false);
        });
    },
  })


  return (
    <div>
      <TostContainer />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: "20px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            gap: "10px",
            width: "100%",
          }}
        >
          <Link
            to={"/dashboard/coaches"}
            style={{ display: "flex", gap: "20px", color: "#000" }}
          >
            {"<"} <TitleText title={"Coach Listing "} style={"0"} />{" "}
          </Link>{" "}
          <Box
            onClick={() => navigate(-1)}
            sx={{
              display: "flex",
              gap: "20px",
              color: "#000",
              cursor: "pointer",
            }}
          >
            {"<"} <TitleText title={"Details"} style={"0"} />{" "}
          </Box>
          {">"}
          <TitleText title={"Update Details "} style={"0"} />
        </Box>
      </Box>
      <Box
        sx={{
          height: "100%",
          display: "flex",
          width: "100%",
          flexDirection: "column",
          mt: "1vh",
          gap: "20px",
          padding: "0px 20px 20px 20px",
          boxSizing: "border-box",
          height: "90vh",
        }}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
        >
          <Box
            sx={{
              height: "100%",
              display: "flex",
              width: "100%",

              boxSizing: "border-box",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                width: "25%",
                height: "240px",
                padding: "10px",

                boxSizing: "border-box",
              }}
            >
              <Typography
                sx={{
                  color:
                    formik.touched.profile_img && formik.errors.profile_img
                      ? "red"
                      : "#000",
                  pb: "10px",
                }}
              >
                Profile Image
              </Typography>
              <Input
                type="file"
                id="image-cover-file-input"
                style={{ display: "none" }}
                onChange={(event) => handleFileChange(event, "image")}
              />
              <label
                htmlFor="image-cover-file-input"
                style={{ height: "100%" }}
              >
                <Box
                  sx={{
                    width: "320px",
                    border:
                      formik.touched.profile_img && formik.errors.profile_img
                        ? "2px solid red"
                        : "1px solid #F4AA50",
                    height: "100%",
                    borderRadius: "10px",
                    cursor: "pointer",
                    boxSizing: "border-box",
                    padding: "20px",
                  }}
                >
                  {imgPreview ? (
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      src={
                        isSelected
                          ? imgPreview
                          : `${Coach_imageUrl}/${imgPreview}`
                      }
                      alt="Coachprofile_image"
                    />
                  ) : (
                    <AddAPhotoIcon
                      style={{
                        color: "#F4AA50",
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  )}
                </Box>
              </label>
            </Box>

            <Box
              sx={{
                width: "70%",
                padding: "0px 10px",
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                height: "88vh",
                overflow: "auto",
                // boxSizing:"border-box"
              }}
            >
              {/* ---------------------Coach Name  ------------------- */}
              <Box sx={{ width: "100%", display: "flex", gap: "20px" }}>
                <Box sx={{ ...Inputboxstyle }}>
                  <InputLabel
                    id="demo-select-small-label"
                    sx={{
                      ...Lablestyle,
                      color:
                        formik.touched.coach_name && formik.errors.coach_name
                          ? "red"
                          : "#000",
                    }}
                  >
                    Coach Name <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <OutlinedInput
                    sx={{
                      ...inputstyle,
                      "& .MuiOutlinedInput-notchedOutline ": {
                        border: "1px solid #F68582",
                      },
                      "& .MuiOutlinedInput-notchedOutline:focus": {
                        borderColor: "none",
                      },
                      "& .Mui-focused": {
                        borderColor: "none",
                      },
                    }}
                    id="outlined-adornment-weight"
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                    type="text"
                    name="coach_name"
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value.trimStart() !== value) {
                        e.target.value = value.trimStart();
                      }
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.coach_name}
                    onKeyUp={formik.handleBlur}
                    placeholder="Coach Name"
                  />
                </Box>

                <Box sx={{ ...Inputboxstyle }}>
                  <InputLabel
                    id="demo-select-small-label"
                    sx={{
                      ...Lablestyle,
                      color:
                        formik.touched.email && formik.errors.email
                          ? "red"
                          : "#000",
                    }}
                  >
                    Email <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <OutlinedInput
                    sx={{
                      ...inputstyle,
                      "& .MuiOutlinedInput-notchedOutline ": {
                        border: "1px solid #F68582",
                      },
                      "& .MuiOutlinedInput-notchedOutline:focus": {
                        borderColor: "none",
                      },
                      "& .Mui-focused": {
                        borderColor: "none",
                      },
                    }}
                    id="outlined-adornment-weight"
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                    placeholder="Enter Email"
                    name="email"
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value.trimStart() !== value) {
                        e.target.value = value.trimStart();
                      }
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    onKeyUp={formik.handleBlur}
                  />
                </Box>
              </Box>
              {/* ---------------------Title ------------------- */}
              <Box sx={{ ...Inputboxstyle }}>
                <InputLabel
                  id="demo-select-small-label"
                  sx={{
                    ...Lablestyle,
                    color:
                      formik.touched.title && formik.errors.title
                        ? "red"
                        : "#000",
                  }}
                >
                  Title <span style={{ color: "red" }}>*</span>
                </InputLabel>
                <OutlinedInput
                  sx={{
                    ...inputstyle,
                    "& .MuiOutlinedInput-notchedOutline ": {
                      border: "1px solid #F68582",
                    },
                    "& .MuiOutlinedInput-notchedOutline:focus": {
                      borderColor: "none",
                    },
                    "& .Mui-focused": {
                      borderColor: "none",
                    },
                  }}
                  id="outlined-adornment-weight"
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    "aria-label": "weight",
                  }}
                  placeholder="Enter Title"
                  name="title"
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value.trimStart() !== value) {
                      e.target.value = value.trimStart();
                    }
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.title}
                  onKeyUp={formik.handleBlur}
                />
              </Box>
              {/* ---------------------Websites ------------------- */}
              <Box sx={{ ...Inputboxstyle }}>
                <InputLabel
                  id="demo-select-small-label"
                  sx={{
                    ...Lablestyle,
                    color:
                      formik.touched.website && formik.errors.website
                        ? "red"
                        : "#000",
                  }}
                >
                  Website
                </InputLabel>
                <OutlinedInput
                  sx={{
                    ...inputstyle,
                    "& .MuiOutlinedInput-notchedOutline ": {
                      border: "1px solid #F68582",
                    },
                    "& .MuiOutlinedInput-notchedOutline:focus": {
                      borderColor: "none",
                    },
                    "& .Mui-focused": {
                      borderColor: "none",
                    },
                  }}
                  id="outlined-adornment-weight"
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    "aria-label": "weight",
                  }}
                  placeholder="Enter Link here"
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value.trimStart() !== value) {
                      e.target.value = value.trimStart();
                    }
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.website}
                  onKeyUp={formik.handleBlur}
                  name="website"
                />
              </Box>
              {/* --------------------- Coach Price ------------------- */}
              <Box sx={{ ...Inputboxstyle }}>
                <InputLabel
                  id="demo-select-small-label"
                  sx={{
                    ...Lablestyle,
                    color:
                      formik.touched.price && formik.errors.price
                        ? "red"
                        : "#000",
                  }}
                >
                  Price <span style={{ color: "red" }}>*</span>
                </InputLabel>
                <OutlinedInput
                  sx={{
                    ...inputstyle,
                    "& .MuiOutlinedInput-notchedOutline ": {
                      border: "1px solid #F68582",
                    },
                    "& .MuiOutlinedInput-notchedOutline:focus": {
                      borderColor: "none",
                    },
                    "& .Mui-focused": {
                      borderColor: "none",
                    },
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        display: "none",
                      },
                  }}
                  id="outlined-adornment-weight"
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    "aria-label": "weight",
                  }}
                  type="Number"
                  placeholder=" Enter Price"
                  name="price"
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value.trimStart() !== value) {
                      e.target.value = value.trimStart();
                    }
                    formik.handleChange(e);
                  }}
                  onKeyDown={(evt) =>
                    ["e", "E", "+", "-"].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  onBlur={formik.handleBlur}
                  value={formik.values.price}
                />
              </Box>
              {/* ---------  Coach Text ------------------- */}

              <Box sx={{ ...Inputboxstyle }}>
                <InputLabel
                  id="demo-select-small-label"
                  sx={{
                    ...Lablestyle,
                    color:
                      formik.touched.text && formik.errors.text
                        ? "red"
                        : "#000",
                  }}
                >
                  Text <span style={{ color: "red" }}>*</span>
                </InputLabel>
                <ReactQuill
                  value={formik.values.text}
                  name="text"
                  onChange={(content, delta, source, editor) => {
                    content = content.replace(/(^<[^>]*>)([\s]*)/gm, "$1");
                    formik.setFieldValue("text", content);
                  }}
                  style={{
                    width: "100%",
                    textAlign: "left",
                    height: "20vh",
                    paddingBottom: "40px",
                  }}
                  theme="snow"
                  modules={{
                    toolbar: {
                      container: [
                        [],
                        [{ font: [] }],
                        ["bold", "italic", "underline", "strike"],
                        [{ color: [] }, { background: [] }],
                        [{ list: "ordered" }, { list: "bullet" }],
                        ["link"],
                        ["clean"],
                        [{ size: ["small", false, "large"] }], // Font size dropdown
                      ],
                    },
                  }}
                />
                {/* <OutlinedInput
                    sx={{
                      ...inputstyle,
                      "& .MuiOutlinedInput-notchedOutline ": {
                        border: "1px solid #F68582",
                      },
                      "& .MuiOutlinedInput-notchedOutline:focus": {
                        borderColor: "none",
                      },
                      "& .Mui-focused": {
                        borderColor: "none",
                      },
                    }}
                    id="outlined-adornment-weight"
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                    placeholder="Enter coach text "
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value.trimStart() !== value) {
                        e.target.value = value.trimStart();
                      }
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.text}
                    onKeyUp={formik.handleBlur}
                    name="text"
                  /> */}
              </Box>
              {/* ---------  Description ------------------- */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  boxSizing: "border-box",
                }}
              >
                <InputLabel
                  id="demo-select-small-label"
                  sx={{
                    ...Lablestyle,
                    color:
                      formik.touched.description && formik.errors.description
                        ? "red"
                        : "#000",
                  }}
                >
                  Description <span style={{ color: "red" }}>*</span>
                </InputLabel>
                <TextField
                  name="description"
                  id="filled-multiline-static"
                  sx={{
                    width: "100%",
                    borderRadius: "20px",
                    boxSizing: "border-box",
                    "& .MuiOutlinedInput-notchedOutline ": {
                      border: "1px solid #F68582",
                    },
                    "& .MuiOutlinedInput-notchedOutline:focus": {
                      borderColor: "none",
                    },
                    "& .Mui-focused": {
                      borderColor: "none",
                    },
                  }}
                  placeholder="Description"
                  multiline
                  rows={4}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value.trimStart() !== value) {
                      e.target.value = value.trimStart();
                    }
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.description}
                  onKeyUp={formik.handleBlur}
                />

                {/* ---------------------Tags ------------------- */}
                {/* <Box sx={{ ...Inputboxstyle }}>
                  <InputLabel
                    id="demo-select-small-label"
                    sx={{
                      ...Lablestyle,
                      color:
                        formik.touched.tags && formik.errors.tags
                          ? "red"
                          : "#000",
                    }}
                  >
                    Tags <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <OutlinedInput
                    sx={{
                      ...inputstyle,
                      "& .MuiOutlinedInput-notchedOutline ": {
                        border: "1px solid #F68582",
                      },
                      "& .MuiOutlinedInput-notchedOutline:focus": {
                        borderColor: "none",
                      },
                      "& .Mui-focused": {
                        borderColor: "none",
                      },
                    }}
                    id="outlined-adornment-weight"
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                    placeholder="Enter tags, separated by commas"
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value.trimStart() !== value) {
                        e.target.value = value.trimStart();
                      }
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.tags}
                    onKeyUp={formik.handleBlur}
                    name="tags"
                  />
                </Box> */}


                
<Box sx={{ ...Inputboxstyle }}>
                  <InputLabel
                    id="demo-select-small-label"
                    sx={{
                      ...Lablestyle,
                      color:
                        formik.touched.tags && formik.errors.tags
                          ? "red"
                          : "#000",
                    }}
                  >
                    Tags <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <div className="card p-fluid">
            <Chips invalid value={value} onChange={(e) => AddNewtags(e.value)}  placeholder="" />
        </div>
                  {/* <OutlinedInput
                    sx={{
                      ...inputstyle,
                      "& .MuiOutlinedInput-notchedOutline ": {
                        border: "1px solid #F68582",
                      },
                      "& .MuiOutlinedInput-notchedOutline:focus": {
                        borderColor: "none",
                      },
                      "& .Mui-focused": {
                        borderColor: "none",
                      },
                    }}
                    id="outlined-adornment-weight"
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                    placeholder="Enter tags, separated by commas"
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value.trimStart() !== value) {
                        e.target.value = value.trimStart();
                      }
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.tags}
                    onKeyUp={formik.handleBlur}
                    name="tags"
                  /> */}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "30%",
                    m: "auto",
                    mt: "20px",
                  }}
                >
                  <ActionButton
                    loading={loading}
                    disabled={loading}
                    title={"Update"}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </form>
      </Box>
    </div>
  );
};

export default EditCoachDetails;
